import React,{Component} from 'react';
import {Redirect} from 'react-router-dom';
import { API_URL } from '../../Api_Request';
import axios from 'axios';
import '../assign/adduser.css';
import Swal from 'sweetalert2';
import Menu from '../menu/menu';
import Select from 'react-select';
import { connect } from 'react-redux';
import { getuserrole, edituserrole } from '../../redux/actions/addRoleAction'

class Adduser extends Component{
    constructor(props){
        super(props)
      this.state={  
        firstName:'',
        lastName:'',
        email:'',
        phone:'',
        role : '',
        data:[],
        firstNameError : '',
        lastNameError : '',
        emailError : '',
        phoneError : '',
        roleError : '',
        role : null,
        err : false,
        userId : '',
        gotData : false
    }
    }

    componentDidMount() {
        if(this.props.location.state === undefined || this.props.location.state === "" ){
            return this.props.history.push('/admin/manageusers')
          }else{
            this.props.getuserrole(this.props.location.state.userId)
            this.setState({
                userId : this.props.location.state.userId 
            })
        }
    }

    componentWillReceiveProps(nextProps){
        let {getuserrole,edituserrole} = nextProps.userRoles 
        if(getuserrole){
            this.setState({
                        firstName : getuserrole.first_name,
                        lastName : getuserrole.last_name,
                        email : getuserrole.email,
                        phone : getuserrole.phone,
                        role : getuserrole.roleid,
                        gotData : true
                    })
        }
        if(edituserrole === "Success"){
            Swal.fire(
            {icon: 'success',
            text: `User information updated successfully !!!`}
            )
            .then(() => {
                        this.props.history.push("/admin/manageusers")
                    })
        }
    }
   
    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
            firstNameError : '',
            lastNameError : '',
            emailError : '',
            phoneError : '',
            roleError : '',
            err : false
        });
      }

      handleRole = selectedRole => {
        this.setState({ 
            role : selectedRole.value });
        console.log(selectedRole);
      };

    emailValidation = e => {
        // console.log(e.target.value);
        if(this.state.email.includes("@")) {
            const Email_Id = e.target.value
            axios.get( `${API_URL}/signup/${Email_Id}`)
                .then(res => {
                    if(res.data.length > 0){
                        this.setState({
                        emailError : "Email_Id is already registered !!!",
                        err : true
                    })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    number = e => {
        const num = String.fromCharCode(e.which);

        if(!(/[0-9]/.test(num))) {
            e.preventDefault();
            this.setState({
                phoneError : 'Enter only numbers',
                err : true
            })
        }
    }

    validate = () => {
        let isError = this.state.err;

        let firstNameError = ''
        let lastNameError = ''
        let emailError = ''
        let phoneError = ''

        if(this.state.firstName === '') {
            isError = true;
            firstNameError = 'First name should not be empty';
        }
        if(this.state.firstName.length < 4) {
            isError = true;
            firstNameError = 'First name should contain at least 4 characters';
        }

        if(this.state.lastName === '') {
            isError = true;
            lastNameError = 'Last name should not be empty';
        }

        if(this.state.email === '') {
            isError = true;
            emailError = 'Email_Id should not be empty';
        }

        if(this.state.phone === '') {
            isError = true;
            phoneError = 'Phone number should not be empty';
        }

        this.setState({
        ...this.state,
        firstNameError:firstNameError,
        lastNameError:lastNameError,
        emailError:emailError,
        phoneError:phoneError
        });

        return isError;
    }
    
    handleSubmit=(e)=>{
        e.preventDefault();
        let {err,emailError,firstNameError,lastNameError,phoneError,phone,role,userId} = this.state
        err = this.validate();
        let addedby = null
        const username = JSON.parse(localStorage.getItem('capability'))
        addedby = username.username
        if(!err){
            let roles = null
            if(role !== null){
                roles = role;
              }

            const user={
                phone : phone,
                role_id : roles,
                userId : userId
        }
        this.props.edituserrole(user)
            }else{
                
                Swal.fire(
                    {icon: 'error',
                    text: `${emailError}
                    ${firstNameError}
                    ${lastNameError}
                    ${phoneError}`}
                )
        }    
    }

    handleCancel = (e) => {
        this.props.history.push('/admin/manageusers')
      }
    render(){
        const {user, isAuthenticated} = this.props.auth;
        if(user && user.role === "user" || user && user.role === "mentor" || user && user.role === "testuser"){
              return <Redirect to='/login' />
            }
          else{

            const options = [{ value: 1, label: 'Admin' },
            { value: 2, label: 'Mentor' },
            // { value: 3, label: "Customer" },
            { value: "", label: 'User' }
              ]

        const addRole = (
            <div>
            <section className="addlogin">
                <div className="container pt-5">
                    {/* <h2 className="pt-5">Capability <span>A</span>pp</h2> */}
                    <form className="demo mt-3 pt-3 box" onSubmit={(e) => this.handleSubmit(e)}>
                        <h6 className="text-center">Manage <span>U</span>ser</h6>
                        <div className="form-group login-form-data mx-auto">
                                <div className=" ">
                                    <div className="text-left"><label >First Name</label></div>
                                     <div className="error">{this.state.firstNameError}</div>
                                    <div className=""><input className="form-control"
                                    required="required" onChange={(e) => this.handleChange(e)}
                                    minLength="4" value={this.state.firstName} disabled = {true}
                                     placeholder="Enter First Name" type="text" name="firstName" /></div>
                                </div>
                            </div>
                        <div className="form-group login-form-data mx-auto">
                                <div className=" ">
                                    <div className="text-left"><label >Last Name</label></div>
                                     <div className="error">{this.state.lastNameError}</div>
                                    <div className=""><input className="form-control" disabled = {true}
                                    required="required" onChange={(e) => this.handleChange(e)}
                                    value={this.state.lastName} type="text" name="lastName" /></div>
                                </div>
                            </div>
                            <div className="form-group login-form-data mx-auto">
                                <div className=" ">
                                    <div className="text-left"><label >Email</label></div>
                                     <div className="error">{this.state.emailError}</div>
                                    <div className=""><input className="form-control"
                                    onBlur={e => this.emailValidation(e)} disabled = {true}
                                    required="required" onChange={(e) => this.handleChange(e)}
                                    value={this.state.email} type="email" name="email" /></div>
                                </div>
                            </div>
                            <div className="form-group login-form-data mx-auto">
                                <div className=" ">
                                    <div className="text-left"><label >Phone</label></div>
                                     <div className="error">{this.state.phoneError}</div>
                                    <div className=""><input className="form-control"
                                    onChange={(e) => this.handleChange(e)} required="required"
                                    value={this.state.phone} type="text" name="phone" 
                                     onKeyPress={e => this.number(e)} maxLength="10" minLength="10"/>
                                     </div>
                                </div>
                            </div>
                            <div className="form-group login-form-data mx-auto">
                                <div className=" ">
                                    <div className="text-left"><label >Assign role</label></div>
                                     <div className="error">{this.state.roleError}</div>
                                    <div className="">
                                        {
                                            this.state.gotData &&
                                            <Select name="role"
                                        defaultValue={this.state.role}
                                        options = {options}
                                        onChange={this.handleRole}
                                        />
                                        }
                                     </div>
                                </div>
                            </div>
                            {/* <div className="form-group col-sm-12 mt-4">
                                <center><button className="btn btn-primary ml-auto login-button" type="submit">Update User Info</button></center>
                            </div> */}
                            <div className="mt-5 text-center">
                                    <button type="submit"
                                    className="btn btn-primary mr-2"
                                    style={{ backgroundColor: "#003399",color:"white"}}
                                    value="save">Save</button>
                                    <button type="reset" 
                                    className="btn btn-danger" 
                                    value="clear" onClick={(e) => {this.handleCancel(e)}}>Cancel</button>
                            </div>
                        </form>
                    
                </div>

                
            </section>

            {/* <section className="login-form">
                <div className="container">
                    
                
                    <div class="">
                        
                    </div>
                </div>
            </section> */}
            
        </div>
        )

                return(
                    <div>
                        {addRole}
                            
                    </div>
                   
        )
    }
    }
}

const mapStateToProps = state => ({
    auth : state.auth,
    userRoles : state.userRoles
  })
  
const mapDispatchToProps = {
    getuserrole,
    edituserrole
}
  
  export default connect(mapStateToProps,mapDispatchToProps)(Adduser);