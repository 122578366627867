import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar } from "@material-ui/core";
import Table from "../../reusableComponents/MaterialTable";
import ButtonField from "../../reusableComponents/ButtonField";
import { ListAlt as ListAltIcon, Search as SearchIcon, Add as AddIcon } from "@material-ui/icons";
import CustomizedDialogs from "../../reusableComponents/CustomizedDialogs";
import QueryOptions from "./queryOptions";

const useStyles = makeStyles((theme) => ({
    tableImage: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    industryList: {
        listStyleType: "none",
    },
}));

const Query = ({ }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    //   const { isAuthenticated } = auth;


    //   useEffect(() => {
    //     if (isAuthenticated) {
    //       getCoachList();
    //       clearCoachInfo();
    //     }
    //   }, [isAuthenticated]);

    const columns = [
       
        {
            title: "Scenario Name",
            field: "scenarioName",
        },
         {
            title: "Technology",
            field: "technology",
        },
        {
            title: "Domain",
            field: "domain",
        },
        {
            title: "Function",
            field: "function",
        },
        {
            title: "Scenario Type",
            field: "scenarioType",
        },
        {
            title: "Scenario Description",
            field: "scenarioDescription",
        },
    ];


    return (
        <Container maxWidth="lg" className="adminMvk">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <QueryOptions/>
                </Grid>
            </Grid>
        </Container>
    );
};

Query.propTypes = {
    auth: PropTypes.object.isRequired,
    getCoachList: PropTypes.func.isRequired,
    clearCoachInfo: PropTypes.func.isRequired,
    adminCoach: PropTypes.object.isRequired,
};

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   adminCoach: state.adminCoach,
// });

// const mapDispatchToProps = { getCoachList, clearCoachInfo };

export default connect()(Query);
