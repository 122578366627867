import React, { useEffect, useState } from "react";
import SelectSingleValues from "../reusableComponents/SelectSingleValues";
import TextFieldGroup from "../reusableComponents/TextFieldGroup";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import {
  getTopicsAndChapters,
  addquestion,
  addFreeTextVoiceQuestion,
} from "../../redux/actions/questionsAction";
import {
  uploadImage,
  uploadAudio,
  uploadVideo,
  clearImageData,
  clearAudioData,
  clearVideoData,
} from "../../redux/actions/uploadFileActions";
import loader from ".././805.gif";
import FileUpload from "../reusableComponents/FileUpload";
import { Grid, Button, CircularProgress, Card, CardMedia } from "@mui/material";
import Mic3Recorder from "../reusableComponents/audioRecorder";
import MediaRecorder from "../reusableComponents/MediaRecorder";
import { useDispatch, useSelector } from "react-redux";
import SingleChoiceQuestions from "./SingleChoiceQuestions";
import MultipleChoiceQuestions from "./MultipleChoiceQuestions";

const AddQuestion = ({ history }) => {
  const { topicId } = useParams();
  const { auth, questions, fileData } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { imageData, uploadFileProcess, audioData, videoData } = fileData;
  const { isAuthenticated, user } = auth;
  const { questionrelateddata } = questions;
  const [chapters, setChapters] = useState([]);
  const [questionType, setQuestionType] = useState(1);
  const [selectedValue, setSelectedValue] = useState(null);
  const [recordAudio, setRecordAudio] = useState(false);
  const [recordVideo, setRecordVideo] = useState(false);
  const [optionValues, setOptionValues] = useState({});
  const [checkedStates, setCheckedStates] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
  });
  const [selectedValues, setSelectedValues] = useState({});
  const [enableSubmit, setEnableSubmit] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getTopicsAndChapters());
      dispatch(clearImageData());
      dispatch(clearAudioData());
      dispatch(clearVideoData());
    }
  }, [auth]);

  useEffect(() => {
    if (questionrelateddata?.topicData?.data) {
      const defaultTopic = questionrelateddata?.topicData?.data[1];
      const selectedTopic = questionrelateddata?.topicData?.data?.filter(
        (item) => item.id == (topicId || 7)
      );
      formik.setFieldValue(
        "questionTopic",
        selectedTopic?.[0] ? selectedTopic[0] : defaultTopic
      );
    }
    if (questionrelateddata?.chapterdata?.data) {
      if (!topicId) {
        setChapters(questionrelateddata?.chapterdata?.data);
      } else {
        const filteredChapters = questionrelateddata?.chapterdata?.data?.filter(
          (chapter) => {
            if (chapter.topicId === (parseInt(topicId) || 7)) {
              chapter.name = chapter.chapterName;
              return chapter;
            }
          }
        );
        setChapters(filteredChapters);
      }
    }
  }, [questionrelateddata]);

  const formik = useFormik({
    initialValues: {
      questionTopic: {},
      questionComplexity: {},
      questionType: {},
      questionChapter: {},
      questionDescription: "",
    },
    validationSchema: Yup.object({
      questionTopic: Yup.object()
        .nullable()
        .required("Question Topic is required field"),
      questionDescription: Yup.string()
        .trim()
        .required("Question Description is required field"),
      questionComplexity: Yup.object()
        .nullable()
        .required("Question complexitiy is required field"),
      questionType: Yup.object()
        .nullable()
        .required("Question type is required field"),
      questionChapter: Yup.object()
        .nullable()
        .required("Question chapter is required field"),
    }),

    onSubmit: async (values) => {
      const choiceValues = [];
      const answer = [];
      if (questionType === 1 || questionType === 2 || questionType === 6) {
        for (let item in optionValues) {
          choiceValues.push({ name: optionValues[item] });
        }
      }
      if (questionType === 2) {
        for (let item in selectedValues) {
          answer.push({ name: selectedValues[item] });
        }
      } else if (questionType === 1 || questionType === 6) {
        if (selectedValue != null) {
          answer.push({ name: selectedValue });
        }
      }
      const data = {
        questionTopic: values?.questionTopic?.id,
        questionComplexity: values?.questionComplexity?.id,
        questionType: values?.questionType?.id,
        questionDescription: values?.questionDescription,
        description: values?.questionDescription,
        questionChapter: values?.questionChapter?.id,
        choices: choiceValues,
        answer: answer,
        status: 0,
        imageFlag: imageData ? 1 : 0,
        audioFlag: audioData ? 1 : 0,
        videoFlag: videoData ? 1 : 0,
        imageInfo: imageData ? imageData : {},
        audioInfo: audioData ? audioData : {},
        videoInfo: videoData ? videoData : {},
        insertedBy: user?.id,
      };
      const navigationParams = {
        history: history,
        tid: topicId,
      };
      if (!data?.questionTopic) {
        Swal.fire({ icon: "error", text: "Please select Question Topic" });
        setEnableSubmit(true);
      } else if (!data?.questionComplexity) {
        Swal.fire({ icon: "error", text: "Please select Question Complexity" });
        setEnableSubmit(true);
      } else if (!data?.questionType) {
        Swal.fire({ icon: "error", text: "Please select Question Type" });
        setEnableSubmit(true);
      } else if (!data?.questionChapter) {
        Swal.fire({ icon: "error", text: "Please select Question Chapter" });
        setEnableSubmit(true);
      } else if (!data?.questionDescription) {
        Swal.fire({
          icon: "error",
          text: "Please select Question Description",
        });
        setEnableSubmit(true);
      } else if (data?.questionType === 1 || data?.questionType === 6) {
        if (data?.choices?.length <= 1) {
          Swal.fire({ icon: "error", text: "Please enter at least 2 options" });
          setEnableSubmit(true);
        } else if (data?.answer?.length <= 0) {
          Swal.fire({ icon: "error", text: "Please select an answer" });
          setEnableSubmit(true);
        } else {
          dispatch(addquestion(data, navigationParams));
          setEnableSubmit(true);
        }
      } else if (data?.questionType === 2) {
        if (data?.choices?.length <= 2) {
          Swal.fire({ icon: "error", text: "Please enter at least 3 options" });
          setEnableSubmit(true);
        } else if (data?.answer?.length <= 1) {
          Swal.fire({ icon: "error", text: "Please select multiple answers" });
          setEnableSubmit(true);
        } else {
          dispatch(addquestion(data, navigationParams));
          setEnableSubmit(true);
        }
      } else if (
        data?.questionType === 3 ||
        data?.questionType === 4 ||
        data?.questionType === 5 ||
        data?.questionType === 7
      ) {
        dispatch(addFreeTextVoiceQuestion(data, navigationParams));
        setEnableSubmit(true);
      }
    },
  });

  const uploadRecordedAudio = (e) => {
    setEnableSubmit(false);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch(uploadAudio(formData));
  };

  const uploadRecordedVideo = (e) => {
    setEnableSubmit(false);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch(uploadVideo(formData));
  };

  const uploadQuestionImage = (e) => {
    setEnableSubmit(false);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch(uploadImage(formData));
  };

  const handleTopics = (topic) => {
    formik.setFieldValue("questionTopic", topic);
    setEnableSubmit(false);
    const filteredChapters = questionrelateddata?.chapterdata?.data?.filter(
      (chapter) => {
        if (chapter.topicId === topic?.id) {
          chapter.name = chapter.chapterName;
          return chapter;
        }
      }
    );
    setChapters(filteredChapters);
  };

  const handleQuestionType = (qType) => {
    formik.setFieldValue("questionType", qType);
    setEnableSubmit(false);
    if (qType?.id) {
      setOptionValues({});
      setSelectedValue(null);
      setSelectedValues({});
      setCheckedStates({
        option1: false,
        option2: false,
        option3: false,
        option4: false,
      });
      setQuestionType(qType?.id);
    }
  };

  const handleRadio = (event) => {
    setEnableSubmit(false);
    setSelectedValue(event?.target?.value);
  };

  const handleOptionValues = (e) => {
    setEnableSubmit(false);
    if (!e.target.value || e.target.value === "") {
      let deletedValues = optionValues;
      delete deletedValues[e.target.name];
      setOptionValues(deletedValues);
    } else {
      setOptionValues({ ...optionValues, [e.target.name]: e.target.value });
    }
  };

  const handleCheckbox = (event) => {
    setEnableSubmit(false);
    setCheckedStates({
      ...checkedStates,
      [event?.target?.name]: event?.target?.checked,
    });
    if (event?.target?.checked) {
      setSelectedValues({
        ...selectedValues,
        [event?.target?.name]: event?.target?.value,
      });
    } else if (!event?.target?.checked) {
      if (selectedValues?.[event?.target?.name]) {
        let deletedValues = selectedValues;
        delete deletedValues[event?.target?.name];
        setSelectedValues(deletedValues);
      }
    }
  };

  const handleMultiOptionValues = (e) => {
    setEnableSubmit(false);
    if (!e.target.value || e.target.value === "") {
      let deletedValues = optionValues;
      delete deletedValues[e.target.name];
      setOptionValues(deletedValues);
    } else {
      setOptionValues({ ...optionValues, [e.target.name]: e.target.value });
    }
    if (selectedValues?.[e.target.name]) {
      setSelectedValues({
        ...selectedValues,
        [e?.target?.name]: e?.target?.value,
      });
    }
  };

  const handleClose = () => {
    history.push(`/admin/questions?id=${topicId}`);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid
        container
        spacing={1}
        style={{ marginLeft: "1px", paddingRight: "40px" }}
      >
        <Grid item xs={12} md={4}>
          <SelectSingleValues
            label="Select Question Topic"
            name="questionTopic"
            values={questionrelateddata?.topicData?.data || []}
            onSelect={(topic) => handleTopics(topic)}
            selected={formik.values.questionTopic}
            errors={
              formik.touched.questionTopic && formik.errors.questionTopic
                ? formik.errors.questionTopic
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectSingleValues
            label="Select Question Complexity"
            name="questionComplexity"
            values={questionrelateddata?.complexData?.data || []}
            onSelect={(complexity) => {
              setEnableSubmit(false);
              formik.setFieldValue("questionComplexity", complexity);
            }}
            selected={formik.values.questionComplexity}
            errors={
              formik.touched.questionComplexity &&
              formik.errors.questionComplexity
                ? formik.errors.questionComplexity
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectSingleValues
            label="Select Question Type"
            name="questionType"
            values={questionrelateddata?.typeData?.data || []}
            onSelect={(qType) => handleQuestionType(qType)}
            selected={formik.values.questionType}
            errors={
              formik.touched.questionType && formik.errors.questionType
                ? formik.errors.questionType
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectSingleValues
            label="Select Question Chapter"
            name="questionChapter"
            values={chapters || []}
            onSelect={(chapter) => {
              setEnableSubmit(false);
              formik.setFieldValue("questionChapter", chapter);
            }}
            selected={formik.values.questionChapter}
            errors={
              formik.touched.questionChapter && formik.errors.questionChapter
                ? formik.errors.questionChapter
                : null
            }
          />
        </Grid>
        {(questionType === 3 ||
          questionType === 4 ||
          questionType === 5 ||
          questionType === 7) && (
          <Grid item xs={12} md={12}>
            <FileUpload
              onChange={(e) => uploadQuestionImage(e)}
              changeLabel={"Upload"}
            />
            {uploadFileProcess ? (
              <Grid sx={{ maxWidth: 345 }} align="center">
                <CircularProgress />
              </Grid>
            ) : (
              imageData &&
              imageData.link && (
                <Card sx={{ maxWidth: 345 }}>
                  <CardMedia
                    component="img"
                    alt="questionImage"
                    height="140"
                    image={imageData?.link}
                  />
                </Card>
              )
            )}
          </Grid>
        )}
        {
          <Grid container>
            <Grid item md={6} xs={12}>
              {(questionType === 4 || questionType === 5) && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setRecordAudio(false)}
                  >
                    Upload Pre-Recorded Audio
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning ml-5"
                    onClick={() => setRecordAudio(true)}
                  >
                    Record Audio
                  </button>
                  {recordAudio ? (
                    <div className="mt-3">
                      <Mic3Recorder />
                    </div>
                  ) : (
                    <div>
                      <input
                        className="btn btn-info mt-3"
                        type="file"
                        accept="audio/mpeg"
                        onChange={(e) => uploadRecordedAudio(e)}
                      ></input>
                      <br />
                      {audioData && audioData?.link && (
                        <audio controls>
                          <source src={audioData?.link} type="audio/mpeg" />
                        </audio>
                      )}
                    </div>
                  )}
                </>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {questionType === 5 && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setRecordVideo(false)}
                  >
                    Upload Pre-Recorded Video
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning ml-5"
                    onClick={() => setRecordVideo(true)}
                  >
                    Record Video
                  </button>
                  {recordVideo ? (
                    <div className="mt-3">
                      <MediaRecorder />
                    </div>
                  ) : (
                    <div>
                      <input
                        className="btn btn-info mt-3"
                        type="file"
                        accept="video/*"
                        onChange={(e) => uploadRecordedVideo(e)}
                      ></input>
                      <br />
                      {videoData && videoData?.link && (
                        <video controls>
                          <source src={videoData?.link} type="video/mp4" />
                        </video>
                      )}
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        }
        <Grid item xs={12} md={12}>
          <TextFieldGroup
            label="Question Description"
            variant="outlined"
            type="text"
            name="questionDescription"
            multiline
            rows={3}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.questionDescription}
            errors={
              formik.touched.questionDescription &&
              formik.errors.questionDescription
                ? formik.errors.questionDescription
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={9}>
          {questionType === 1 || questionType === 6 ? (
            <SingleChoiceQuestions
              optionValues={optionValues}
              handleRadio={handleRadio}
              handleOptionValues={handleOptionValues}
              selectedValue={selectedValue}
              questionType={questionType}
            />
          ) : questionType === 2 ? (
            <MultipleChoiceQuestions
              optionValues={optionValues}
              checkedStates={checkedStates}
              handleCheckbox={handleCheckbox}
              handleMultiOptionValues={handleMultiOptionValues}
            />
          ) : null}
        </Grid>
        <Grid container item xs={12} md={9} justifyContent="flex-end">
          <Button
            buttonStyle="submit"
            type="submit"
            name="submit"
            color="primary"
            variant="contained"
            disabled={enableSubmit}
          >
            Submit
          </Button>
          <Button
            style={{ marginLeft: "15px" }}
            type="button"
            name="Cancel"
            color="error"
            variant="contained"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddQuestion;
