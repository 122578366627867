import { GET_PRODUCTIVITY_DATABASES,GET_PRODUCTIVITY_DATABASES_INFO } from "../type";
import axios from "axios";
import { API_URL } from "../../../Api_Request";
import Swal from "sweetalert2";

export const getDatabases = () => (dispatch) => {
  axios
    .get(`${API_URL}/database/getAllDatabases`)
    .then((res) => {
      dispatch({
        type: GET_PRODUCTIVITY_DATABASES,
        payload: res.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err && err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const getDatabasesInfo = (id) => (dispatch) => {
  axios
    .get(`${API_URL}/database/getDatabaseInfo/${id}`)
    .then((res) => {
      dispatch({
        type: GET_PRODUCTIVITY_DATABASES_INFO,
        payload: res.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err && err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};
export const addDatabase = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/database/addDatabase`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: `Database Added successfully !!!`,
      });

      dispatch(getDatabases());
    })
    .catch((err) => {
      const { message } = err && err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const editDataBase = (data) => (dispatch) => {
  axios
    .put(`${API_URL}/database/updateDatabase`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: `Database Updated successfully !!!`,
      });
      dispatch(getDatabases());
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};
