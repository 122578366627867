import React, { Component, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { singleuser } from "../../redux/actions/visualsAction";
import {
  usertestsdata,
  getBarComparitiveChart,
} from "../../redux/actions/visualsAction";

import Swal from "sweetalert2";
import loader from ".././805.gif";
import BarGraph from "../reusableComponents/BarGraph"
const VisualsNew = ({
    // history,
    match
}) => {

    const { auth , visuals} = useSelector((state) => state);
    const dispatch = useDispatch();

    const userEmail = visuals?.singleuservisualdata ? visuals?.singleuservisualdata[0]?.userEmail : ""
    const firstName = visuals?.singleuservisualdata ? visuals?.singleuservisualdata[0]?.First_Name : ""
    const latest = visuals?.singleusertestsdata?.latest
    const oldest = visuals?.singleusertestsdata?.oldest


    const [loaded, setLoaded] = useState(false)

    const {userId} = match.params

    useEffect(()=>{
        dispatch(getBarComparitiveChart(userId))
        dispatch(singleuser(userId))
        if(userEmail){
            dispatch(usertestsdata(userEmail))
            // console.log("userEmail",userEmail)
        }
        
    },[])

    useEffect(()=>{
        if (visuals?.singleuservisualdata?.length > 0){
            setLoaded(true)
        }
    },[visuals?.singleuservisualdata])


    console.log("new Visuals match",visuals)

    return ( 
        <>
            <div className="container">
                {/* {role === "user" || role === "testuser" ? <UserMenu /> : null} */}
                <div
                className="image-container"
                style={{ display: loaded === false ? "block" : "none" }}
                >
                <p className="image-holder">
                    <img src={loader} alt="" />
                </p>
                </div>
                <div className="font-weight-bold row justify-content-end" style={{ color: "#eb9752" }}>
                <div className="col-sm-4 text-center">Name : {firstName}</div>
                <div className="col-sm-4 text-center">First Test Date : {oldest}</div>
                <div className="col-sm-4 text-center">Recent Test Date : {latest}</div>
                </div>

                {/* <div id="groupBarChart" className="mt-5"></div> */}
                <BarGraph
                barGraphData={visuals?.barComparitiveChartData?.barGraphData || []}
                barGraphLabels={visuals?.barComparitiveChartData?.barGraphLabels || []}
                />
            </div>
        </>
     );
}
 
export default VisualsNew;