import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import isEmpty from './is-empty';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const useStyles = makeStyles(theme => ({
    imageView: {
        // display: 'flex',
        // justifyContent: 'center'
    },
    profileImageUploadFile: {
        display: 'none'
    },
    largeAvatar: {
        cursor: 'pointer',
        textTransform: 'capitalize',
        fontSize: 20,
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
}));

const PhotoUpload = ({
    imageName,
    imageUrl,
    onChange,
    disabled
}) => {

    const classes = useStyles();

    return (
        <div className={classes.imageView} >
            <input 
                className={classes.profileImageUploadFile}
                accept="image/x-png,image/gif,image/jpeg"
                id="contained-button-file"
                type="file"
                onChange={onChange}
                disabled={disabled||false}
            />
            <label htmlFor="contained-button-file">
                <Avatar 
                    alt={imageName}
                    src={!isEmpty(imageUrl) ? `${imageUrl}` : null}
                    className={classes.largeAvatar}
                >
                    <AddPhotoAlternateIcon style={{fontSize:"30px"}} />
                </Avatar>
            </label>
        </div>
    );
};

export default PhotoUpload;