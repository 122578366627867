import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { getFeedbackSession } from "../../../redux/actions/productivityActions/debuggingCodeActions";
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import "./ViewDebuggingSession.css"



const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: [16, "!important"],
      },
    },
  },
});


const ViewDebuggingSession = () => {
  const params = useParams();
  const { codeReducer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { debuggingSessionData } = codeReducer;

  useEffect(() => {
    if (params?.sessionId) {
      dispatch(getFeedbackSession(params.sessionId));
    }
  }, [params.sessionId]);

  return (
    <Grid container>
      <Card sx={{ minWidth: 1000 , marginBottom:"4px"}} variant="outlined">
          <CardContent>
            <ThemeProvider theme={theme}>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography variant="h6 ">
                    <b>Name: {debuggingSessionData?.capabilityUser?.first_name}</b>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography></Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6 ">
                    <b>Date:{new Date(debuggingSessionData?.createdAt).toDateString()}</b>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                <Typography><b>Session Id: {debuggingSessionData?.sessionId}</b></Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>
              {" "}
              <b>Topic: {debuggingSessionData?.capabilityTopic?.name}</b>
        </Typography>
                </Grid>
                <Grid item xs={4}>
                <Typography>
                <b> Complexity:{debuggingSessionData?.capabilityComplexity?.name}</b>
        </Typography>
                </Grid>
              </Grid>
            </ThemeProvider>
          </CardContent>
        </Card>
      <Grid item xs={12} md={12}>
        {debuggingSessionData?.userFeedbacks?.map((item) => {
          return (
            <>
            <Typography variant="h6" sx={{marginTop:"2px",marginLeft:"2px"}}>Debugging Code</Typography>
              <pre className="debuggingresult">
                <code className="debuggingresult">
                  <b> {item?.debuggingCode?.debuggingCode}</b>
                </code>
              </pre>
              <Typography variant="h6" sx={{marginBottom:"2px",marginLeft:"2px"}}>User Feedback: </Typography>
              <pre className="debugginganswer">
                <code className="debugginganswer">
                  <b> {item?.userFeedback}</b>
                </code>
              </pre>
              <hr class="solid"/>
            </>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default ViewDebuggingSession;
