import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar, Button } from "@material-ui/core";
import Table from "../../reusableComponents/MaterialTable";
import ButtonField from "../../reusableComponents/ButtonField";
import {
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import CustomizedDialogs from "../../reusableComponents/CustomizedDialogs";
import AddNewScenario from "./AddNewScenario";
import EditScenario from "./EditScenario";

import { getScenarioList } from "../../../redux/actions/productivityActions/scenarioAction";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const Scenario = ({}) => {
  const [open, setOpen] = useState(false);
  const [scenarioId, setscenarioId] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const classes = useStyles();
  //   const { isAuthenticated } = auth;

  const { auth, scenarioReducer } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getScenarioList());
    }
  }, [auth.isAuthenticated]);

  //   useEffect(() => {
  //     if (isAuthenticated) {
  //       getCoachList();
  //       clearCoachInfo();
  //     }
  //   }, [isAuthenticated]);

  const columns = [
    {
      title: "Scenario Name",
      field: "scenarioName",
    },
    {
      title: "Technology",
      field: "technology",
    },
    {
      title: "Domain",
      field: "domain",
    },
    {
      title: "Function",
      field: "function",
    },
    {
      title: "Scenario Type",
      field: "scenarioType",
    },
    {
      title: "Scenario Description",
      field: "scenarioDescription",
    },
    {
      title: "Scenario Status",
      field: "status",
    },
    {
      title: "Edit",
      field: "edit",
    },
  ];

  const data = scenarioReducer?.getScenarioList?.map((item) => {
    return {
      _id: item._id,
      scenarioName: item.name,
      technology: item.technology?.name,
      domain: item.domain?.name,
      function: item.function?.name,
      scenarioType: item.scenarioType,
      scenarioDescription: item.scenarioDescription,
      status: item?.status ? "Enabled" : "Disabled",
      edit: (
        <Button
          variant="contained"
          color="primary"
          type="submit"
          name="edit"
          onClick={() => {
            setscenarioId(item._id);
            setOpenEdit(true);
          }}
        >
          Edit
        </Button>
      ),
    };
  });

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            title={
              <ButtonField
                variant="outlined"
                name="Add New Scenario"
                icon={<AddIcon />}
                onClick={() => setOpen(true)}
              />
            }
            columns={columns}
            data={data}
            pageSize={10}
            // selectedRow={(row) => history.push(`/admin/coach/${row._id}`)}
          />
        </Grid>
      </Grid>
      <CustomizedDialogs
        title="Add New Scenario "
        children={<AddNewScenario setOpenPopup={setOpen} />}
        openPopup={open}
        setOpenPopup={setOpen}
      />
      <CustomizedDialogs
        title="Edit Domain"
        children={
          <EditScenario scenarioId={scenarioId} setOpenPopup={setOpenEdit} />
        }
        openPopup={openEdit}
        setOpenPopup={setOpenEdit}
      />
    </Container>
  );
};

Scenario.propTypes = {
  auth: PropTypes.object.isRequired,
  getCoachList: PropTypes.func.isRequired,
  clearCoachInfo: PropTypes.func.isRequired,
  adminCoach: PropTypes.object.isRequired,
};

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   adminCoach: state.adminCoach,
// });

// const mapDispatchToProps = { getCoachList, clearCoachInfo };

export default Scenario;
