import React, { Component } from "react";
import Menu from "../menu/menu";
import { Redirect, NavLink } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  getTopics,
  assignTopics,
  editTopics,
  deleteTopics,
} from "../../redux/actions/assignTopicsAction";
import loader from ".././805.gif";

class Assign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.location.state.userId,
      userName: this.props.location.state.userName,
      userEmail: this.props.location.state.userEmail,
      userPhone: this.props.location.state.userPhone,
      isLoaded: false,
      topicData: [],
      complexData: [],
      selectedData: [],
      mappedData: [],
      topics: [],
      skill: [],
      selectedTopic: null,
      selectedComplex: null,
      defaultTopic: null,
      defaultComplex: null,
      gotdata: false,
      selectedRow: -1,
      editedERow: false,
      err: false,
      editSkillButton: false,
      add: false,
    };
  }

  componentDidMount() {
    this.props.getTopics(this.state.userId);
  }

  componentWillReceiveProps(nextProps) {
    const { getusertopics } = nextProps.getusertopics;
    if (getusertopics) {
      let { topicData, complexData, mappedData } = getusertopics;
      if (topicData.data.length > 0) {
        topicData.data.map((topics) => {
          let topic = { label: topics.name, value: topics.id };
          // console.log(topic)
          this.state.topics.push(topic);
        });
        this.setState({
          topicData: topicData.data,
        });
        console.log(this.state.topicData);
      }
      if (complexData.data.length > 0) {
        complexData.data.map((complex) => {
          let skills = { label: complex.name, value: complex.id };
          // console.log(skills)
          this.state.skill.push(skills);
        });
        this.setState({
          complexData: complexData.data,
        });
        console.log(this.state.complexData);
      }
      if (mappedData.data.length > 0) {
        this.setState({
          mappedData: mappedData.data,
        });
        console.log(this.state.mappedData);
      }
      this.setState({
        isLoaded: true,
        gotdata: true,
      });
    }
  }

  handleTopic = (selectedTopic) => {
    this.setState({ selectedTopic, add: true });
    // console.log(selectedTopic);
  };

  handleComplex = (selectedComplex) => {
    this.setState({ selectedComplex, editSkillButton: true });
    // console.log(selectedComplex);
  };

  handleBack = (e) => {
    this.props.history.push("/admin/users");
  };

  validate = () => {
    const { err, mappedData, selectedTopic } = this.state;
    let isError = err;
    if (selectedTopic.value === undefined) {
      isError = true;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: true,
      });

      Toast.fire({
        icon: "error",
        title: "Please enter Topic name !!!",
      });
    }
    mappedData.map((topic) => {
      if (topic.topic_id === selectedTopic.value) {
        isError = true;
        Swal.fire({ icon: "error", text: `Topic name is already added !!!` });
      }
    });

    return isError;
  };

  handleSave = (e) => {
    const { selectedTopic, selectedComplex, userId } = this.state;
    let { err } = this.state;
    const { user } = this.props.auth;
    const email = user && user.email;
    let topics = null;
    let skills = null;
    let topicName = "";
    let skillNames = null;
    err = this.validate();
    if (err) {
      this.setState({
        error: true,
      });
    } else {
      if (selectedTopic !== null) {
        topics = selectedTopic.value;
        topicName = selectedTopic.label;
      }
      if (selectedComplex !== null) {
        let skillids = selectedComplex.map((skill) => skill.value);
        skills = skillids.join();
        let skillnames = selectedComplex.map((skill) => skill.label);
        skillNames = skillnames.join();
      }
      console.log(topics, skills, userId);
      const usertopics = {
        topics: topics,
        skills: skills,
        userId: userId,
        topicName: topicName,
        skillNames: skillNames,
        assignedBy: email,
      };
      console.log(usertopics);
      this.props.assignTopics(usertopics);
      this.setState({
        add: false,
        selectedComplex: null,
        selectedTopic: null,
        isLoaded: false,
        gotdata: false,
        topics: [],
        skill: [],
      });
    }
  };

  handleUpdate = (e, item, index) => {
    const { selectedComplex, userId } = this.state;
    const { user } = this.props.auth;
    const email = user && user.email;
    // console.log("hi",item,index)
    // console.log(selectedComplex)
    let skills = null;
    let skillNames = null;
    if (selectedComplex !== null) {
      let skillids = selectedComplex.map((skill) => skill.value);
      skills = skillids.join();
      let skillnames = selectedComplex.map((skill) => skill.label);
      skillNames = skillnames.join();
    }
    const userSkills = {
      topics: item.topic_id,
      skills: skills,
      userId: item.user_id,
      skillNames: skillNames,
      assignedBy: email,
    };
    console.log(userSkills);
    this.props.editTopics(userSkills);
    this.setState({
      editedERow: false,
      isLoaded: false,
      gotdata: false,
      topics: [],
      skill: [],
    });
  };

  handleEdit = (e, index, item) => {
    // console.log(index)
    // console.log(item)
    this.setState({
      editedERow: true,
      selectedRow: index,
    });
  };

  handleDelete = (e, item, index) => {
    const { user } = this.props.auth;
    const email = user && user.email;
    const assignedTopic = {
      topics: item.topic_id,
      userId: item.user_id,
      assignedBy: email,
    };
    // console.log(assignedTopic);
    this.props.deleteTopics(assignedTopic);
    this.setState((prevState) => ({
      mappedData: prevState.mappedData.filter(
        (m) => m.topic_id !== item.topic_id
      ),
      editedERow: false,
      isLoaded: false,
      gotdata: false,
      topics: [],
      skill: [],
    }));
  };

  handleCancel = (e) => {
    this.setState({
      editedERow: false,
    });
  };

  render() {
    const {
      userName,
      userEmail,
      mappedData,
      userPhone,
      skill,
      topics,
      selectedComplex,
      selectedTopic,
    } = this.state;

    const { user, isAuthenticated } = this.props.auth;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div>
          {/* <h1>Dashboard Component</h1> */}
          <section>
            <div
              className="image-container"
              style={{
                display: this.state.isLoaded === false ? "block" : "none",
              }}
            >
              <p className="image-holder">
                <img src={loader} alt="" />
              </p>
            </div>
            <div
              className="container"
              style={{
                display: this.state.isLoaded !== false ? "block" : "none",
              }}
            >
              <h2 className="text-center mt-5 " style={{ color: "#003399" }}>
                Assign Topics and Skill Level
              </h2>
              {
                <div className="row mt-5 font-weight-bold">
                  <div className="col-sm-4">
                    Name : <span className="text-uppercase">{userName}</span>
                  </div>
                  <div className="col-sm-4">Email : {userEmail}</div>
                  <div className="col-sm-4">Phone : {userPhone}</div>
                </div>
              }
              <div className="row mt-5 ">
                <div className="col-sm-4">
                  {this.state.gotdata && (
                    <Select
                      name="topic"
                      options={topics}
                      className="basic-multi-select"
                      placeholder="Select Topic"
                      onChange={this.handleTopic}
                      defaultValue={selectedTopic}
                    />
                  )}
                </div>
                <div className="col-sm-4">
                  {this.state.gotdata && (
                    <Select
                      isMulti
                      name="skill"
                      options={skill}
                      className="basic-multi-select"
                      placeholder="Select Skill Level"
                      onChange={this.handleComplex}
                      defaultValue={selectedComplex}
                    />
                  )}
                </div>
                <div className="col-sm-4">
                  <button
                    type="submit"
                    onClick={(e) => {
                      this.handleSave(e);
                    }}
                    disabled={this.state.add !== true}
                    className="btn btn-primary mr-2"
                    style={{ backgroundColor: "#003399", color: "white" }}
                    value="save"
                  >
                    Add
                  </button>
                  <button
                    type="reset"
                    className="btn btn-danger"
                    value="clear"
                    onClick={(e) => {
                      this.handleBack(e);
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
              <table className="table text-center table-bordered mt-5">
                <thead>
                  <tr style={{ backgroundColor: "#003399", color: "white" }}>
                    <th>Topics</th>
                    <th>Skill level</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {mappedData.length <= 0 ? (
                    <tr>
                      <td>All Topics</td>
                      <td>All Skills</td>
                    </tr>
                  ) : (
                    <React.Fragment>
                      {mappedData.map((item, index) => (
                        <tr key={item.topic_id}>
                          <td>{item.topic_name}</td>
                          <td>
                            {this.state.editedERow === true &&
                            this.state.selectedRow === index ? (
                              <Select
                                defaultValue={item.selectedskills}
                                isMulti
                                name="skill"
                                options={skill}
                                className="basic-multi-select"
                                placeholder="Select Skill Level"
                                onChange={this.handleComplex}
                              />
                            ) : (
                              item.skill_names
                            )}
                          </td>
                          <td>
                            {this.state.editedERow === true &&
                            this.state.selectedRow === index ? (
                              <div>
                                <button
                                  className="btn btn-primary  mr-2"
                                  style={{
                                    backgroundColor: "#003399",
                                    color: "white",
                                  }}
                                  disabled={this.state.editSkillButton !== true}
                                  onClick={(e) =>
                                    this.handleUpdate(e, item, index)
                                  }
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => this.handleCancel(e)}
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button
                                  className="btn btn-success  mr-2"
                                  onClick={(e) =>
                                    this.handleEdit(e, index, item)
                                  }
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) =>
                                    this.handleDelete(e, item, index)
                                  }
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  getusertopics: state.getusertopics,
});

const mapDispatchToProps = {
  getTopics,
  assignTopics,
  editTopics,
  deleteTopics,
};

export default connect(mapStateToProps, mapDispatchToProps)(Assign);
