import React, { Component } from "react";
import Menu from "../menu/menu";
import "../questions/questions.css";
import AssignToolbarSelect from "./assigntoolbarselect";
import { Redirect } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { assignuser } from "../../redux/actions/questionPapersAction";
import loader from ".././805.gif";
// import Swal from 'sweetalert2';

class SelectQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      id: null,
      userdata: [],
      paperName: "",
      topicName: "",
      skillName: "",
      topicId: "",
      skillId: "",
      searchtext: "",
      pid: "",
      data: [],
    };
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableHeadCell: {
          fixedHeaderCommon: {
            backgroundColor: "#1976d2",
          },
        },
        MuiTableCell: {
          /* color: rgba(0, 0, 0, 0.87); */
          head: {
            color: "#fff",
            fontWeight: "bold",
          },
        },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: "#2196F3",
          },
        },
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            // margin : "0px 50px",
            border: "1px solid #dee2e6",
            borderCollapse: "collapse",
          },
        },
        MuiTypography: {
          h6: {
            marginLeft: "30px",
            fontWeight: "bold",
            color: "#2196F3",
          },
        },
        // MUIDataTableSelectCell: {
        //   root: {
        //     display: 'none'
        //   }
        // }
      },
    });

  componentDidMount() {
    const { topicId, skillId } = this.state;
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      return this.props.history.push("/menu/questionpapers");
    } else {
      let id = this.props.location.state.id;
      this.props.assignuser(id);
      this.setState({
        paperName: this.props.location.state.paperName,
        topicName: this.props.location.state.topicnames,
        skillName: this.props.location.state.skillnames,
        topicId: this.props.location.state.topicids,
        skillId: this.props.location.state.skillids,
        pid: this.props.location.state.id,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.questionpapers.assigntestuserdata) {
      return null;
    } else if (nextProps.questionpapers.assigntestuserdata.length < 0) {
      this.setState({
        userdata: nextProps.questionpapers.assigntestuserdata,
        isLoaded: true,
        searchtext: "",
      });
    } else {
      this.setState({
        userdata: nextProps.questionpapers.assigntestuserdata,
        isLoaded: false,
        searchtext: "",
      });
    }
  }

  handleBack = () => {
    this.props.history.push(`/admin/questionpapers`);
  };

  //  search bar
  // handleSearch(e) {
  //   const { assigntestuserdata } = this.props.questionpapers;
  //   let newList = [];
  //   if (e.target.value !== "") {
  //     const filter = e.target.value;
  //     newList = this.state.userdata.filter(function(item){
  //       return (item.first_name.toLowerCase().search(filter.toLowerCase()) !== -1 || item.email.toLowerCase().search(filter.toLowerCase()) !== -1);
  //     });
  //     this.setState({
  //       userdata: newList
  //     });
  //   } else {
  //     this.props.assignuser(this.state.pid)
  //     this.setState({
  //       userdata: assigntestuserdata
  //     });
  //   }
  // }

  render() {
    const { user } = this.props.auth;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      const { topicId } = this.state;
      // console.log(topicId)

      // TABLE
      const columns = [
        {
          name: "id",
          label: "User Id",
          options: { filter: false, sort: true, display: false },
        },
        {
          name: "first_name",
          label: "Name",
          options: { filter: false, sort: true },
        },
        {
          name: "email",
          label: "E-Mail",
          options: { filter: false, sort: true },
        },
      ];
      const { assigntestuserdata } = this.props.questionpapers;
      const data = this.state.questionsDatas;

      const options = {
        filterType: "checkbox",
        filter:false,
        serverSide: false,
        pagination: true,
        // searchText: true,
        selectableRows: "multiple",
        download: false,
        viewColumns: false,
        rowsPerPage: 6,
        selectableRowsOnClick: true,
        rowsPerPageOptions: [10],
        rowHover: true,
        search: true,
        print: false,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
          <AssignToolbarSelect
            paperName={this.state.paperName}
            topicId={this.state.topicId}
            skillId={this.state.skillId}
            selectedRows={selectedRows}
            displayData={displayData}
            setSelectedRows={setSelectedRows}
            paperId={this.state.pid}
          />
        ),
        responsive: "scrollMaxHeight",
        // rowsDeleted: false
        // onTableChange: (action, tableState) => {
        //     console.log(action);
        //     console.log(tableState);

        // }
      };

      return (
        <div>
          {/* <div className="mt-5">  */}
          {/* <div className="add">
                    <button type="button" id="btn" value="add question" className="btn btn-primary" onClick={() => this.handleQuestion()}>Add Question</button>
                </div> */}
          <div className="row font-weight-bold ">
            <div className="col-sm-4">
              Question Paper Name : {this.state.paperName}
            </div>
            <div className="col-sm-4">
              Question Topic : {this.state.topicName}
            </div>
            <div className="col-sm-4">
              Question Skill Level : {this.state.skillName}
              <span className="pl-5">
                <button
                  className="btn btn-danger"
                  onClick={() => this.handleBack()}
                >
                  Back
                </button>
              </span>
            </div>
          </div>
          {/* <input type="text" className="mr-5 ml-5 mt-3 pr-5 pl-5" value={this.state.searchtext} onChange={(e) =>this.handleSearch(e)} placeholder="Search by User Name or Email..." /> */}
          <div className="mutable">
            {assigntestuserdata ? (
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  className="mt-5"
                  title={"Assign Test"}
                  data={this.state.userdata}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            ) : (
              <div className="image-container">
                <p className="image-holder">
                  <img src={loader} alt="" />
                </p>
              </div>
            )}
          </div>
        </div>
        // </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  questionpapers: state.questionpapers,
});

const mapDispatchToProps = {
  assignuser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectQuestions);
