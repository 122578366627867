import { GETQUESTIONCOMPLEXITY, ADDQUESTIONCOMPLEXITY, EDITQUESTIONCOMPLEXITY, GETSTATUSCOMPLEXITY } from './type';
import axios from 'axios';
import { API_URL } from '../../Api_Request';
import Swal from 'sweetalert2';



export const getQuestioncomplexity = () => dispatch => {
    axios.get(`${API_URL}/complexity`)
        .then(res => {
          dispatch({
              type : GETQUESTIONCOMPLEXITY, 
              payload : res.data
          })
          
          // console.log(res.data)
      })
      .catch(err => console.log(err))
  }

export const addnewtype = (params) => dispatch => {
    axios.post(`${API_URL}/complexity`, params)
    .then(res => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000
      })
      
      Toast.fire({
        icon: 'success',
        title: 'Question Complexity added successfully'
      })
      dispatch(getQuestioncomplexity())
      dispatch({
          type : ADDQUESTIONCOMPLEXITY, 
          payload : "Success"
      })
      
      // console.log(res.data)
  })
  .catch(err => console.log(err))
}

export const editQuestionComplexity = (params) => dispatch => {
    axios.put(`${API_URL}/complexity/${params.cid}`,params)
    .then(res => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000
      })
      Toast.fire({
        icon: 'success',
        title: 'Question Complexity updated successfully'
      })
      dispatch(getQuestioncomplexity())
      dispatch({
          type : EDITQUESTIONCOMPLEXITY, 
          payload : res.data
      })
      
      // console.log(res.data)
  })
  .catch(err => console.log(err))
}

export const getstatusonskills = () => dispatch => {
  axios.get(`${API_URL}/complexity/statuson`)
    .then(res => {
      dispatch({
          type : GETSTATUSCOMPLEXITY, 
          payload : res.data
      })
      
      // console.log(res.data)
  })
  .catch(err => console.log(err))
}