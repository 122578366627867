import React, { useState, useEffect } from "react";
import Menu from "../menu/menu";
import "./results.css";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getInterviewResultsList } from "../../redux/actions/InterviewAction";
import Tables from "../tables/tables";
import loader from ".././805.gif";

const InterviewResult = ({
  getInterviewResultsList,
  InterviewResults,
  history,
}) => {
  useEffect(() => {
    getInterviewResultsList();
    console.log("InterviewResults", InterviewResults);
  }, []);

  // console.log("InterviewResults.interviewResultSList",InterviewResults?.interviewResultSList)
  const data = InterviewResults?.interviewResultSList?.map((item) => {
    return {
      id: item.id,
      test_id: item.test_id,
      first_name: item.name,
      email: item.email,
      phone: item.phone,
      tresult: item.tresult,
    };
  });

  // TABLE
  const columns1 = [
    {
      name: "id",
      label: "User Id",
      options: { filter: false, sort: false, display: false },
    },
    {
      name: "test_id",
      label: "Test Id",
      options: { filter: false, sort: false, display: false },
    },
    {
      name: "first_name",
      label: "Name",
      options: { filter: false, sort: true },
    },
    {
      name: "email",
      label: "Email_ID",
      options: { filter: false, sort: false },
    },
    {
      name: "phone",
      label: "Phone Number",
      options: { filter: false, sort: false },
    },
    // {
    //   name: "topicname",
    //   label: "Question Topic",
    //   options: { filter: true, sort: false },
    // },
    // {
    //   name: "skill_level",
    //   label: "Skill Level",
    //   options: { filter: false, sort: false },
    // },
    {
      name: "tresult",
      label: "Results",
      options: { filter: false, sort: false },
    },
  ];

  // const data = resultsData;

  const options1 = {
    filterType: "checkbox",
    serverSide: false,
    pagination: true,
    // searchText: true,
    download: false,
    rowsPerPage: 10,
    selectableRows: "none",
    rowsPerPageOptions: [10],
    viewColumns: false,
    rowHover: true,
    search: true,
    print: false,
    onRowClick: (rowData) =>
      // console.log("rowData",rowData),
      history.push({
        pathname: `/menu/viewresults`,
        state: {
          userId: rowData[0],
          testId: rowData[1],
          topics: rowData[1],
          interview: true,
        },
      }),
    responsive: "scrollMaxHeight",
  };

  return (
    <div className="restable">
      {/* <InterviewMenu /> */}
      {data && data ? (
        <Tables
          title="Results"
          data={data}
          columns={columns1}
          options={options1}
        />
      ) : (
        <div className="image-container">
          <p className="image-holder">
            <img src={loader} alt="" />
          </p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  InterviewResults: state.InterviewResults,
});

const mapDispatchToProps = {
  getInterviewResultsList,
};
export default connect(mapStateToProps, mapDispatchToProps)(InterviewResult);
