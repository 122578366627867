import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Card, CardMedia } from "@mui/material";

import tick from "../../../images/logo/tick.png";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";
import PhotoUpload from "../../reusableComponents/PhotoUpload";

import {
  getTechnologyList,
  getDomainList,
} from "../../../redux/actions/productivityActions/domainAction";
import { getFunctionList } from "../../../redux/actions/productivityActions/functionAction";
import {
  getScenarioInfo,
  editScenarioData,
  editScenarioImage,
} from "../../../redux/actions/productivityActions/scenarioAction";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const EditScenario = ({ setOpenPopup, scenarioId }) => {
  const classes = useStyles();
  const [fileData, setFileData] = useState();
  const [domainList, setDomainList] = useState([]);
  const [functionList, setFunctionList] = useState([]);
  const [editImage, setEditImage] = useState(true);
  const [imageUploaded, setImageUploaded] = useState(false);
  const dispatch = useDispatch();
  const { scenarioReducer } = useSelector((state) => state);
  const { auth, domainReducer, functionReducer } = useSelector(
    (state) => state
  );

  useEffect(() => {
    if (auth?.isAuthenticated) {
      dispatch(getTechnologyList());
      dispatch(getDomainList());
      dispatch(getFunctionList());
      dispatch(getScenarioInfo(scenarioId));
    }
  }, [auth?.isAuthenticated]);

  const scenarioInfo = scenarioReducer?.getScenarioInfo;
  console.log(scenarioInfo);

  const image = scenarioInfo?.scenarioImage?.link;

  console.log(image);

  useEffect(() => {
    if (domainReducer?.getDomainList?.length > 0 && scenarioInfo) {
      const selecetedDoamin = domainReducer?.getDomainList?.find(
        (item) => item._id?.toString() === scenarioInfo?.domain?.toString()
      );
      formik.setFieldValue("selectDomain", selecetedDoamin || {});
    }
  }, [domainReducer?.getDomainList, scenarioInfo]);

  useEffect(() => {
    if (domainReducer?.getTechnologyList?.length > 0 && scenarioInfo) {
      const selecetedTechnology = domainReducer?.getTechnologyList?.find(
        (item) => item._id?.toString() === scenarioInfo?.technology?.toString()
      );

      formik.setFieldValue("selectTechnology", selecetedTechnology || {});
      const filteredList = domainReducer?.getDomainList?.filter(
        (item) => item?.technology?._id === selecetedTechnology?._id?.toString()
      );
      setDomainList(filteredList);
    }
  }, [domainReducer?.getTechnologyList, scenarioInfo]);

  useEffect(() => {
    if (functionReducer?.getFunctionList?.length > 0 && scenarioInfo) {
      const selecetedFunction = functionReducer?.getFunctionList?.find(
        (item) => item._id?.toString() === scenarioInfo?.function?.toString()
      );

      formik.setFieldValue("selectFunction", selecetedFunction || {});
      const filteredList = functionReducer?.getFunctionList?.filter(
        (item) => item?.technology?._id === selecetedFunction?._id?.toString()
      );
      setFunctionList(filteredList);
    }
  }, [functionReducer?.getFunctionList, scenarioInfo]);

  console.log("functionreducer", functionReducer);

  useEffect(() => {
    if (scenarioInfo) {
      formik.setFieldValue(
        "scenarioName",
        scenarioInfo ? scenarioInfo?.name : ""
      );

      formik.setFieldValue(
        "scenarioCode",
        scenarioInfo ? scenarioInfo?.scenarioCode : ""
      );

      formik.setFieldValue(
        "scenarioType",
        scenarioInfo ? scenarioInfo?.scenarioType : ""
      );

      formik.setFieldValue(
        "scenarioDescription",
        scenarioInfo ? scenarioInfo?.scenarioDescription : ""
      );
      formik.setFieldValue(
        "enableStatus",
        scenarioInfo?.status
          ? { name: "Enabled", _id: true }
          : { name: "Disbaled", _id: false }
      );

      // formik.setFieldValue(
      //   "functionDescription", scenarioInfo ? scenarioInfo?.scenarioImage.link : ""
      // );
    }
  }, [scenarioInfo]);

  const technologyList = domainReducer?.getTechnologyList?.map((item) => {
    return {
      _id: item._id,
      name: item.name,
    };
  });

  const handleTechnology = (technology) => {
    formik.setFieldValue("selectTechnology", technology);
    const filteredList = domainReducer?.getDomainList?.filter(
      (item) => item?.technology?._id === technology?._id?.toString()
    );
    setDomainList(filteredList);
  };

  const handleDomain = (domain) => {
    formik.setFieldValue("selectDomain", domain);
    const filteredList = functionReducer?.getFunctionList?.filter(
      (item) => item?.domain?._id === domain?._id?.toString()
    );
    setFunctionList(filteredList);
  };

  const formik = useFormik({
    initialValues: {
      selectTechnology: "",
      selectDomain: "",
      selectFunction: "",
      scenarioName: "",
      scenarioCode: "",
      scenarioType: "",
      scenarioDescription: "",
      enableStatus: "",
    },
    validationSchema: Yup.object({
      selectTechnology: Yup.object()
        .nullable()
        .required("Technology is reqired field"),
      selectDomain: Yup.object().nullable().required("Domain is reqired field"),
      selectFunction: Yup.object()
        .nullable()
        .required("Function is reqired field"),
      scenarioName: Yup.string()
        .min(5)
        .trim()
        .required("Scenario Name is required field"),
      scenarioCode: Yup.string()
        .min(2)
        .max(4)
        .trim()
        .required("Scenario Code is required field"),
      scenarioType: Yup.string()
        .min(1)
        .trim()
        .required("Scenario Type is required field"),
      scenarioDescription: Yup.string()
        .trim()
        .required("Scenario Description is required field"),
    }),

    onSubmit: async (values) => {
      const formData = new FormData();

      if (imageUploaded) {
        const data = {
          _id: scenarioId,
          technology: values?.selectTechnology?._id,
          domain: values?.selectDomain?._id,
          function: values?.selectFunction?._id,
          name: values?.scenarioName,
          scenarioCode: values?.scenarioCode,
          scenarioType: values?.scenarioType,
          file: fileData,
          scenarioDescription: values?.scenarioDescription,
          status: values?.enableStatus?._id,
        };
        for (let key in data) {
          formData.append(key, data[key]);
        }
        dispatch(editScenarioImage(formData));
      } else {
        const data = {
          _id: scenarioId,
          technology: values?.selectTechnology?._id,
          domain: values?.selectDomain?._id,
          function: values?.selectFunction?._id,
          name: values?.scenarioName,
          scenarioCode: values?.scenarioCode,
          scenarioType: values?.scenarioType,
          scenarioImage: scenarioInfo?.scenarioImage
            ? scenarioInfo?.scenarioImage
            : null,
          scenarioDescription: values?.scenarioDescription,
          status: values?.enableStatus?._id,
        };
        dispatch(editScenarioData(data));
      }

      setOpenPopup(false);
    },
  });

  const uploadQuestionImage = (e) => {
    setImageUploaded(true);
    e.preventDefault();
    setFileData(e?.target?.files[0]);
    // setImage(URL.createObjectURL(fileData));
    //  console.log("image",image)
    // formData.append("file", e.target.files[0]);
    // props.uploadImage(formData);
  };

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Technology"
            name="selectTechnology"
            values={technologyList || []}
            onSelect={(technology) => handleTechnology(technology)}
            selected={formik.values.selectTechnology}
            errors={
              formik.touched.selectTechnology && formik.errors.selectTechnology
                ? formik.errors.selectTechnology
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Domain"
            name="selectDomain"
            values={domainList || []}
            onSelect={(domain) => handleDomain(domain)}
            selected={formik.values.selectDomain}
            errors={
              formik.touched.selectDomain && formik.errors.selectDomain
                ? formik.errors.selectDomain
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Function"
            name="selectFunction"
            values={functionList || []}
            onSelect={(batch) => formik.setFieldValue("selectFunction", batch)}
            selected={formik.values.selectFunction}
            errors={
              formik.touched.selectFunction && formik.errors.selectFunction
                ? formik.errors.selectFunction
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ marginTop: "-15px" }}>
          <TextFieldGroup
            variant="outlined"
            label="Scenario Name"
            name="scenarioName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.scenarioName}
            errors={
              formik.touched.scenarioName && formik.errors.scenarioName
                ? formik.errors.scenarioName
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Scenario Code"
            name="scenarioCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.scenarioCode}
            errors={
              formik.touched.scenarioCode && formik.errors.scenarioCode
                ? formik.errors.scenarioCode
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Scenario Type"
            name="scenarioType"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.scenarioType}
            errors={
              formik.touched.scenarioType && formik.errors.scenarioType
                ? formik.errors.scenarioType
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectSingleValues
            label="Enable/Disable Scenario"
            name="enableStatus"
            values={
              [
                { name: "Enabled", _id: true },
                { name: "Disabled", _id: false },
              ] || []
            }
            onSelect={(status) => {
              formik.setFieldValue("enableStatus", status);
            }}
            selected={formik.values.enableStatus}
            errors={
              formik.touched.enableStatus && formik.errors.enableStatus
                ? formik.errors.enableStatus
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            multiline="multiline"
            rows={4}
            label="Scenario Description"
            name="scenarioDescription"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.scenarioDescription}
            errors={
              formik.touched.scenarioDescription &&
              formik.errors.scenarioDescription
                ? formik.errors.scenarioDescription
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6} align="center" style={{ paddingTop: "3%" }}>
          {image && editImage ? (
            <>
              <Button
                buttonStyle="submit"
                type="save"
                name="save"
                color="primary"
                className={classes.inputButtonFields}
                variant="contained"
                onClick={() => setEditImage(false)}
              >
                Edit Image
              </Button>
              <Card sx={{ maxWidth: 345, marginTop: "2%" }}>
                <CardMedia
                  component="img"
                  alt="questionImage"
                  height="140"
                  image={image && image}
                />
              </Card>
            </>
          ) : imageUploaded ? (
            <>
              <img src={tick} alt="hello" width="100" height="100" />
              <span>Image uploaded sucessfully</span>
            </>
          ) : (
            <>
              <Typography>Please click here to upload file *</Typography>
              <br />
              <PhotoUpload
                imageName={""}
                onChange={(e) => uploadQuestionImage(e)}
                imageUrl={fileData}
                disabled={""}
              />
            </>
          )}
        </Grid>

        <Grid item xs={12} md={6} align="right">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="save"
              name="save"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </Grid>
        {/* 
        <Grid item xs={12} md={6}>
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="reset"
              name="reset"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
            // onClick={submitForm}
            >
              Reset
            </Button>
          </div>
        </Grid> */}
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

// EditScenario.propTypes = {
//   auth: PropTypes.object.isRequired,
//   addnewchapter: PropTypes.func.isRequired,
// };

// const mapStateToProps = (state) => ({
//   auth: state.auth,
// });

// const mapDispatchToProps = {

// };

export default connect()(EditScenario);
//   mapStateToProps,
//   mapDispatchToProps
