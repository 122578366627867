import React, { useState, useEffect, useContext } from 'react';
import { AiAssistantContext } from '../../contexts/ai_assistant_context';
import { Box, Chip, Paper, Button, Typography } from '@material-ui/core';

const QuestionList = () =>{

    const { questionAnalysisArray, selectedQuestion, setSelectedQuestion} = useContext(AiAssistantContext);

    return questionAnalysisArray?.length != 0 && (
        <Paper style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: "100%",
            width: "100%",
            margin: 0,
            padding: 0,
            backgroundColor: '#18479f'
            // backgroundColor: '#f0f0f0',
            // backgroundColor: '#C6D1E8'
            // backgroundColor: '#C6D1E8' : '#18479f'
          }}
          elevation= {16}
          >
              <Box style={{flex: 9, padding: 20, paddingLeft: 10, marginBottom: 15, overflow: 'auto', overflowY: 'scroll'}}>
              <Box>
                <Typography 
                    style={{ 
                    width: '100%', 
                    borderRadius: 3,
                    fontSize: '1.5rem', 
                    backgroundColor: "#f1f4fa", 
                    marginBottom: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    textAlign: 'center'
                }}
                variant='body1'>Questions</Typography>
              </Box>         
              <Box>
            {questionAnalysisArray && questionAnalysisArray.map((questionAnalysis)=>{
                const isSelected = questionAnalysis['questionId'] == selectedQuestion?.questionId;
                const isCorrect = questionAnalysis['isCorrect'];
                return(
                <Box key={questionAnalysis['questionId']}>
                <Button 
                variant='outlined'
                style={{
                    marginTop:10, 
                    marginBottom: 10,
                    backgroundColor: !isSelected ? (isCorrect ? "#2BD322": "#d3222b") : "#f1f4fa",
                    borderColor: !isSelected ? "#f1f4fa" : (isCorrect ? "#2BD322": "#d3222b"),
                    borderWidth: 2,
                    textTransform: 'none',
                    width: "100%",
                }}
                onClick = {()=>setSelectedQuestion(questionAnalysis)}
                ><Typography variant='body1'>{questionAnalysis['questionDescription']}</Typography></Button>
                </Box>
                )
            })}
        </Box>
           
        </Box>
    </Paper>
   )
}

export default QuestionList;