import { USERRESULTS, ALLRESULTS } from "./type";
import axios from "axios";
import { API_URL } from "../../Api_Request";
import Swal from "sweetalert2";

export const userResultsData = (email) => (dispatch) => {
  axios
    .get(`${API_URL}/userresults/${email}`)
    .then((res) => {
      dispatch({
        type: USERRESULTS,
        payload: res.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const allResults = () => (dispatch) => {
  axios
    .get(`${API_URL}/results`)
    .then((res) => {
      dispatch({
        type: ALLRESULTS,
        payload: res.data,
      });

      //   console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const dateFilter = (data) => (dispatch) => {
// console.log("dataFilter",data)
  axios
    .post(`${API_URL}/results/adminFilterResults`,data)
    .then((res) => {
      dispatch({
        type: ALLRESULTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};