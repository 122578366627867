import React, { Component } from "react";
import * as d3 from "d3";
import "./GroupBarChart.css";
import Menu from "../menu/menu";
import UserMenu from "../users/usermenu/usermenu";
import $ from "jquery";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { singleuser } from "../../redux/actions/visualsAction";
import {
  usertestsdata,
  getBarComparitiveChart,
} from "../../redux/actions/visualsAction";
import loader from ".././805.gif";
import BarGraph from "../reusableComponents/BarGraph";

class Visuals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      fname: "",
      oldest: "",
      latest: "",
      barGraphData: [],
      barGraphLabels: [],
    };
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let userid = params.get("id");
    console.log("id ",this.props?.auth?.user?.id,userid)
    if (!userid) {
      userid = this.props?.auth?.user?.id;
      this.setState({
        fname: this.props?.auth?.user?.first_name,
        oldest: this.props?.location?.state?.oldest,
        latest: this.props?.location?.state?.latest,
      });
    }
    this.props.singleuser(userid);
    const username = JSON.parse(localStorage.getItem("capability"));
    const email = username.username;
    this.props.usertestsdata(email);
    this.props.getBarComparitiveChart(userid);
  }

  componentWillReceiveProps(nextProps) {
    const { singleuservisualdata, barComparitiveChartData } = nextProps.visuals;
    if (singleuservisualdata) {
      if (singleuservisualdata.length > 0) {
        // let d = document.getElementById("groupBarChart");
        // d.innerHTML = "";
        // this.GroupbarChart(singleuservisualdata);
        this.setState({
          isLoaded: true,
        });
      } else {
        this.setState({
          isLoaded: true,
        });
        const { user } = this.props.auth;
        const role = user && user.role;
        if (role !== "user") {
          Swal.fire({
            icon: "warning",
            text: "User has not taken any tests yet !!!",
          }).then(() => {
            this.props.history.push("/admin/userlists");
          });
        } 
        // else {
        //   Swal.fire({
        //     icon: "warning",
        //     text: "User has not taken any tests yet !!!",
        //   }).then(() => {
        //     this.props.history.push("/admin/userdashboard");
        //   });
        // }
      }
    }
    if (!nextProps.visuals.singleusertestsdata) {
      return null;
    } else if (nextProps.visuals.singleusertestsdata.length < 0) {
      this.setState({ oldest: nextProps.visuals.singleusertestsdata });
    } else {
      this.setState({
        oldest: nextProps.visuals.singleusertestsdata.oldest,
        latest: nextProps.visuals.singleusertestsdata.latest,
      });
    }
    console.log(barComparitiveChartData, "sdfdsf");
  }

  // GroupbarChart = (data) => {
  //   // var cwidth=$("#groupBarChart").width();
  //   // var cheight=$("#groupBarChart").height();

  //   var margin = { top: 130, right: 20, bottom: 100, left: 40 },
  //     width = 1200 - margin.left - margin.right,
  //     height = 550 - margin.top - margin.bottom;

  //   var svg = d3
  //     .select("#groupBarChart")
  //     .append("svg")
  //     // .attr("width", width +  margin.left + margin.right)
  //     // .attr("heigth", height + margin.top + margin.bottom )
  //     .attr("preserveAspectRatio", "xMinYMin meet")
  //     .attr("viewBox", "10 0 1000 700");
  //   // console.log("measure",chartWidth, chartHeight)

  //   var g = svg
  //     .append("g")
  //     .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
  //   var svg = d3.select("svg");

  //   // var tip = d3.tip()
  //   // .attr('class', 'd3-tip')
  //   // .offset([-10, 0])
  //   // .html(function(d) {
  //   //   console.log(d)
  //   //   return "<strong>Skill Level: </strong>" + d.Skill_Level + "<br><strong>Test Date: </strong>" +format( d.Test_Date)+"<br><strong>Topic Name: </strong>" + d.Topic_Name;
  //   // });

  //   // svg.call(tip);

  //   var defs = svg.append("defs");

  //   var dropShadowFilter = defs
  //     .append("svg:filter")
  //     .attr("id", "drop-shadow")
  //     .attr("filterUnits", "userSpaceOnUse")
  //     .attr("width", "250%")
  //     .attr("height", "250%");
  //   dropShadowFilter
  //     .append("svg:feGaussianBlur")
  //     .attr("in", "SourceGraphic")
  //     .attr("stdDeviation", 2)
  //     .attr("result", "blur-out");
  //   dropShadowFilter
  //     .append("svg:feColorMatrix")
  //     .attr("in", "blur-out")
  //     .attr("type", "hueRotate")
  //     .attr("values", 180)
  //     .attr("result", "color-out");
  //   dropShadowFilter
  //     .append("svg:feOffset")
  //     .attr("in", "color-out")
  //     .attr("dx", 3)
  //     .attr("dy", 3)
  //     .attr("result", "the-shadow");
  //   dropShadowFilter
  //     .append("svg:feBlend")
  //     .attr("in", "SourceGraphic")
  //     .attr("in2", "the-shadow")
  //     .attr("mode", "normal");

  //   var x0 = d3.scaleBand().rangeRound([0, width]).paddingInner(0.4);

  //   // text label for the x axis

  //   var x1 = d3.scaleBand().padding(0.2);

  //   var y = d3.scaleLinear().rangeRound([height, 0]);

  //   var z = d3.scaleOrdinal().range(["#eb9752", "#5fb83d", "#cf5340"]);

  //   var a = d3.scaleOrdinal().range(["#6FF32C", "#2CBDF3", "#F99D2F"]);

  //   g.append("g")
  //     // add the Y gridlines
  //     .attr("class", "grid")
  //     .call(d3.axisLeft(y).tickSize(-width).tickFormat(""));

  //   var parse = d3.timeParse("%d/%m/%Y");
  //   var format = d3.timeFormat("%d/%b");

  //   const render = (data) => {
  //     var columns = d3.keys(data[0]);
  //     console.log(columns);

  //     var name = data[0].First_Name;

  //     var i = 1;
  //     data.forEach(function (d) {
  //       d.num = i + "-" + d.Topic_Name;
  //       i++;
  //     });

  //     // data.forEach(function(d){
  //     //     d.Test_Date = parse(d.Test_Date)
  //     //     console.log(d.Test_Date)
  //     // })

  //     var keys = ["Total_Marks", "Correct_Answers", "Wrong_Answers"];

  //     x0.domain(
  //       data.map(function (d) {
  //         return d.num;
  //       })
  //     );
  //     x1.domain(keys).rangeRound([0, x0.bandwidth()]);
  //     y.domain([
  //       0,
  //       d3.max(data, function (d) {
  //         return d3.max(keys, function (key) {
  //           return d[key];
  //         });
  //       }),
  //     ]).nice();

  //     // svg.append("text")
  //     //     .attr("x", (width)/2)
  //     //     .attr("y", 40)
  //     //     .attr("fill", "rgb(141, 205, 207)")
  //     //     .attr("class","svgtitle")
  //     //     .attr("text-anchor", "middle")
  //     //     .text("Comparative Chart "+ "("+ name +")")

  //     var barG = g
  //       .append("g")
  //       .selectAll("g")
  //       .attr("class", "main_group")
  //       .data(data)
  //       .enter()
  //       .append("g")
  //       .attr("transform", function (d) {
  //         return "translate(" + x0(d.num) + ",0)";
  //       });
  //     //.attr("fill","pink")
  //     //   .on('mouseover', tip.show)
  //     //  .on('mouseout', tip.hide)

  //     barG
  //       .selectAll(".bars-container-back")
  //       .data(function (d, i) {
  //         return keys.map(function (key) {
  //           return { key: key, value: d[key], level: d.Skill_Level };
  //         });
  //       })
  //       .enter()
  //       .append("rect")
  //       .attr("class", "bars-container-back")
  //       .attr("x", function (d) {
  //         return x1(d.key) - 4;
  //       })
  //       .attr("y", function (d) {
  //         console.log(d.value);
  //         return y(d.value) - 4;
  //       })
  //       .attr("width", x1.bandwidth() + 8)
  //       .attr("height", function (d) {
  //         return height - y(d.value) + 3;
  //       })
  //       .attr("fill", function (d) {
  //         return d.level == "Intermediate"
  //           ? "#2CBDF3"
  //           : d.level == "Beginner"
  //           ? "#6FF32C"
  //           : "#F99D2F";
  //       })
  //       .attr("stroke-width", "1px")
  //       .attr("stroke", "transparent")
  //       .transition()
  //       .delay(500)
  //       .duration(150);
  //     // .attr("stroke", "red")

  //     barG
  //       .selectAll(".bars-container-middle")
  //       .data(function (d) {
  //         return keys.map(function (key) {
  //           return { key: key, value: d[key] };
  //         });
  //       })
  //       .enter()
  //       .append("rect")
  //       .attr("class", "bars-container-middle")
  //       .attr("x", function (d) {
  //         return x1(d.key) - 1;
  //       })
  //       .attr("y", function (d) {
  //         return y(d.value) - 1;
  //       })
  //       .attr("width", x1.bandwidth() + 2)
  //       .attr("height", function (d) {
  //         return height - y(d.value);
  //       })
  //       .attr("fill", "white")
  //       .attr("stroke", "none");
  //     //.style("opacity",0.6);

  //     barG
  //       .selectAll(".bars")
  //       .data(function (d) {
  //         return keys.map(function (key) {
  //           return { key: key, value: d[key] };
  //         });
  //       })
  //       .enter()
  //       .append("rect")
  //       .attr("class", "bars")
  //       .attr("x", function (d) {
  //         return x1(d.key);
  //       })
  //       .attr("width", x1.bandwidth())
  //       .attr("fill", function (d) {
  //         return z(d.key);
  //       })
  //       .attr("y", height)
  //       .transition()
  //       .delay(function (d, i) {
  //         return i * 250;
  //       }) // this is to do left then right bars
  //       .duration(250)
  //       .attr("y", function (d) {
  //         return y(d.value);
  //       })
  //       .attr("height", function (d) {
  //         return height - y(d.value);
  //       });
  //     //.style("opacity",0.6);

  //     var bartext = d3.format("");

  //     barG
  //       .selectAll(".text")
  //       .data(function (d) {
  //         return keys.map(function (key) {
  //           return { key: key, value: d[key], date: d["Test_Date"] };
  //         });
  //       })
  //       .enter()
  //       .append("text")
  //       .attr("class", "bar-label")
  //       .attr("x", function (d) {
  //         return x1(d.key);
  //       })
  //       .attr("y", function (d) {
  //         console.log(d.Test_Date);
  //         return y(d.value) - 10;
  //       })
  //       .style("font-size", "10px")
  //       .text(function (d) {
  //         return d.key == "Total_Marks" ? d.date : "";
  //       });
  //     // .text(function(d){ return bartext(d.value)})

  //     //remove underscore
  //     // var newStr = str.replace(/_/g, "")

  //     g.append("g")
  //       .attr("class", "axis")
  //       .attr("transform", "translate(0," + height + ")")
  //       .call(
  //         d3.axisBottom(x0).tickFormat(function (d, i) {
  //           return d;
  //         })
  //       )
  //       .selectAll("text")
  //       .attr("dx", "-.8em")
  //       .attr("dy", ".15em")
  //       .attr("class", "bar-label")
  //       .attr("transform", "rotate(-65)")
  //       .style("text-anchor", "end");

  //     var yaxis = g
  //       .append("g")
  //       .attr("class", "axis")
  //       .attr("transform", "translate(0,0)")
  //       .call(d3.axisLeft(y))
  //       .attr("class", "bar-label");

  //     yaxis.selectAll(".domain ").remove();

  //     var legend = svg
  //       .selectAll(".legend")
  //       .data(keys)
  //       .enter()
  //       .append("g")
  //       .attr("class", "legend");

  //     legend
  //       .append("circle")
  //       .attr("r", 3)
  //       .attr("cx", function (d, i) {
  //         return 90 + i * 120;
  //       })
  //       .attr("cy", 70)
  //       // .attr("width", 18)
  //       // .attr("height", 18)
  //       .style("fill", function (d) {
  //         return z(d);
  //       });

  //     legend
  //       .append("text")
  //       .attr("x", function (d, i) {
  //         return 90 + (i * 120 + 8);
  //       })
  //       .attr("y", 70)
  //       .attr("dy", ".35em")
  //       .style("text-anchor", "start")
  //       .text(function (d) {
  //         return d.replace(/_/g, " ");
  //       });

  //     svg
  //       .append("text")
  //       .attr("class", "legend")
  //       .attr("x", 10)
  //       .attr("y", 70)
  //       .attr("dy", ".35em")
  //       .text("Scores : ");

  //     var skey = ["Beginner", "Intermediate", "Expert"];

  //     var legend1 = svg
  //       .selectAll(".legend1")
  //       .data(skey)
  //       .enter()
  //       .append("g")
  //       .attr("class", "legend");
  //     //.attr("transform", function(d, i) { return "translate(0," + ((i+3)*20-70 ) + ")"; });

  //     legend1
  //       .append("circle")
  //       .attr("r", 3)
  //       .attr("cx", function (d, i) {
  //         return 90 + i * 120;
  //       })
  //       .attr("cy", 90)
  //       // .attr("width", 18)
  //       // .attr("height", 18)
  //       .style("fill", function (d) {
  //         return a(d);
  //       });

  //     legend1
  //       .append("text")
  //       .attr("x", function (d, i) {
  //         return 90 + (i * 120 + 8);
  //       })
  //       .attr("y", 90)
  //       .attr("dy", ".35em")
  //       .style("text-anchor", "start")

  //       .text(function (d) {
  //         return d.replace(/_/g, " ");
  //       });

  //     svg
  //       .append("text")
  //       .attr("class", "legend")
  //       .attr("x", 10)
  //       .attr("y", 90)
  //       .attr("dy", ".35em")
  //       .text("Skill Levels : ");
  //   };
  //   console.log("inside render");
  //   render(data);
  // };

  // componentWillUnmount(){
  //     d3.select("svg").remove();
  //     }

  render() {
    const { user } = this.props.auth;
    const { barComparitiveChartData } = this.props.visuals;
    const role = user && user.role;
    return (
      <div className="container">
        {/* {role === "user" || role === "testuser" ? <UserMenu /> : null} */}
        <div
          className="image-container"
          style={{ display: this.state.isLoaded === false ? "block" : "none" }}
        >
          <p className="image-holder">
            <img src={loader} alt="" />
          </p>
        </div>
        <div className="font-weight-bold row justify-content-end" style={{ color: "#eb9752" }}>
          <div className="col-sm-4 text-center">Name : {this.state.fname}</div>
          <div className="col-sm-4 text-center">First Test Date : {this.state.oldest}</div>
          <div className="col-sm-4 text-center">Recent Test Date : {this.state.latest}</div>
        </div>

        {/* <div id="groupBarChart" className="mt-5"></div> */}
        <BarGraph
          barGraphData={barComparitiveChartData?.barGraphData || []}
          barGraphLabels={barComparitiveChartData?.barGraphLabels || []}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  visuals: state.visuals,
});

const mapDispatchToProps = {
  singleuser,
  usertestsdata,
  getBarComparitiveChart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Visuals);
