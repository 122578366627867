import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { getInterviewQuestionPaper } from "../../redux/actions/userDashboarAction";
import "./dashboard.css";
import { Link, Redirect, NavLink } from "react-router-dom";
import loader from ".././805.gif";
import UserModal from "./userModal";
import CustomizedDialogs from "../reusableComponents/CustomizedDialogs";
import Logo from "../../images/logo/ibridgelogo.png";
import { logoutUser } from "../../redux/actions/authActions";
import Test from "../../images/test1.png";
import InterviewMenu from "../users/InterviewMenu/interviewMenu";

const InterviewDashboard = ({
  auth,
  getInterviewQuestionPaper,
  userDashboard,
  logoutUser,
  history,
}) => {
  const [open, setOpen] = useState(false);
  const [paper, setPaper] = useState();
  const { interviewPapers } = userDashboard;
  const { user, isAuthenticated } = auth;

  useEffect(() => {
    getInterviewQuestionPaper();
  }, []);

  useEffect(() => {
    if (user && user.role !== "Interview Admin") {
      history.push("/login");
    }
  }, [auth]);
  const handleSelect = (selectedPaper) => {
    setOpen(true);
    setPaper(selectedPaper);
  };

  return (
    <div>
      <section className="dashboard">
        <div className="container">
          <InterviewMenu />
          {/* <nav className="navbar navbar-expand-lg fixed-top dashboard-menu">
            <div className="container">
              <div className="navbar-brand" to="">
                <img
                  style={{
                    width: "200px",
                    height: "55px",
                    objectFit: "cover",
                  }}
                  src={Logo}
                  alt=""
                />
              </div>

              {/* <div
                className="collapse navbar-collapse justify-content-center"
                id="navbarNav"
              >
                <ul className="nav">
                  <Typography variant="h3">Interview Portal</Typography>
                </ul>
                <ul className="dropdown-menu">
                    <li className="nav-item">
                      <Link className="nav-link" to="/menu/users">
                        Result
                      </Link>
                    </li>
                    <li>
                      <NavLink
                        className="nav-link"
                        to="#"
                        onClick={() => {this.handleLogout();}}
                      >
                        Logout{" "}
                      </NavLink>
                    </li>
                  </ul>
              </div> */}
          {/* <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarNav"
              >
                <ul className="nav">
                  <li className="dropdown">
                    <Link
                      // to=""
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <i className="fa fa-user"></i> {user && user.first_name}
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link className="nav-link" to="/menu/interviewResult">
                          Result
                        </Link>
                      </li>

                      <li>
                        <NavLink
                          className="nav-link"
                          to="#"
                          onClick={() => {
                            handleLogout();
                          }}
                        >
                          Logout{" "}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div> */}
          {/* </nav> */}
          <div className="row mt-5 ">
            {interviewPapers ? (
              interviewPapers?.map((top, index) => {
                return (
                  <div className="col-sm-3 mt-5" key={top.id}>
                    <div
                      className="card"
                      onClick={() => handleSelect(top)}
                      //   onMouseEnter={() =>this.changeCardColor(top.id) }
                      //   onMouseLeave={() =>this.changeCardColor1(top.id)}
                    >
                      <div className="card-body d-flex p-0 card-content">
                        <div className="align-self-center icons">
                          <img src={Test} width="50px" height="50px" alt="" />
                        </div>
                        <div className="title align-self-center text-center">
                          <h5>{top.name}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="image-container">
                <p className="image-holder">
                  <img src={loader} alt="" />
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
      <CustomizedDialogs
        title="Enter Interview Candidate Details"
        children={
          <UserModal setOpen={setOpen} paper={paper} history={history} />
        }
        openPopup={open}
        setOpenPopup={setOpen}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  userDashboard: state.userDashboard,
});

const mapDispatchToProps = {
  getInterviewQuestionPaper,
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewDashboard);
