// import React, { useEffect } from "react";
// import { connect } from "react-redux";
// import PropTypes from "prop-types";
// import Tables from "../reusableComponents/MaterialTable";
// import { getCustomerList } from "../../redux/actions/customerActions";
// import { Grid, Breadcrumbs, Typography } from "@material-ui/core";
// import { Link, Redirect } from "react-router-dom";
// // import Menu from "../menu/menu";
// import loader from "../805.gif";

// const CustomerList = ({ getCustomerList, auth, history, customer }) => {
//   useEffect(() => {
//     getCustomerList();
//     document.title = "Customer List - Capability App";
//   }, []);

//   useEffect(() => {
//     const { user } = auth;
//     console.log(user);
//     if (
//       user &&
//       user.role !== "Super Admin" &&
//       user &&
//       user.role !== "Customer Admin"
//     ) {
//       history.push("/login");
//     }
//   }, [auth]);

//   const { customerList } = customer;
//   //columns
  // const columns = [
  //   { field: "customer_id", title: "User Id", hidden: true },
  //   { field: "customer_name", title: "Name" },
  //   {
  //     title: "Action",
  //     field: "details",
  //     render: (rowData) => (
  //       // <Link to={`/editprofile/${rowData.id}`}>{rowData.details}</Link>
  //       <Link to={`/admin/usergroups/${rowData.customer_id}`}>
  //         {rowData.details}
  //       </Link>
  //     ),
  //   },
  // ];

//   const options = {
//     headerStyle: {
//       backgroundColor: "#003399",
//       color: "#FFF",
//     },
//     scrollable: true,
//   };

//   console.log("customer list inda",customerList);

  // const data =
  //   customerList &&
  //   customerList.map((item) => {
  //     return {
  //       customer_id: item.customer_id,
  //       customer_name: item.customer_name,
  //       details: "View User-Sub-Groups",
  //     };
  //   });

  // const handleCustomer = () => {
  //   history.push("/admin/addcustomer");
  // };

//   return (
//     <div>
//       {/* <Menu /> */}
//       <div>
        // <div class="text-center">
        //   <button
        //     type="button"
        //     id="btn"
        //     value="add new user"
        //     className="btn btn-primary"
        //     style={{ backgroundColor: "#003399", color: "whitesmoke" }}
        //     onClick={handleCustomer}
        //   >
        //     Add New Customer
        //   </button>
        // </div>
//         <div className="mutable">
//           {data && data ? (
//             <Tables
//               title="Customer List"
//               data={data}
//               columns={columns}
//               options={options}
//               style={{ color: "#003399" }}
//             />
//           ) : (
//             <div className="image-container">
//               <p className="image-holder">
//                 <img src={loader} alt="" />
//               </p>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//     // <React.Fragment>
//     //   {/* <Header />
//     //   <InterLayout> */}
//     //   <Grid md={12} style={{ marginBottom: "5px" }}>
//     //     <Breadcrumbs aria-label="breadcrumb">
//     //       <Typography color="textSecondary">Admin</Typography>
//     //       <Typography color="textPrimary">Customer List</Typography>
//     //     </Breadcrumbs>
//     //   </Grid>
//     //   <Grid
//     //     lg={12}
//     //     spacing={4}
//     //     style={{
//     //       position: "absolute",
//     //       flexGrow: 1,
//     //       flexDirection: "row",
//     //       width: "90%",
//     //       margin: "0 auto",
//     //     }}
//     //   >
//     //     <Tables
//     //       title="Customer List"
//     //       data={data && data ? data : []}
//     //       columns={columns}
//     //       options={options}
//     //     />
//     //   </Grid>
//     //   {/* </InterLayout> */}
//     // </React.Fragment>
//   );
// };

// CustomerList.propTypes = {
//   getCustomerList: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   customer: PropTypes.object.isRequired,
// };

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   customer: state.customer,
// });

// const mapDispatchToProps = { getCustomerList };

// export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar } from "@material-ui/core";
import Table from "../reusableComponents/MaterialTable";
import ButtonField from "../reusableComponents/ButtonField";
import { getCustomerList } from "../../redux/actions/customerActions";
import {
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const CustomerList = ({ getCustomerList, auth, history, customer }) => {
 
  useEffect(() => {
    getCustomerList();
    document.title = "Customer List - Capability App";
  }, []);

  useEffect(() => {
    const { user } = auth;
    console.log(user);
    if (
      user &&
      user.role !== "Super Admin" &&
      user &&
      user.role !== "Customer Admin"
    ) {
      history.push("/login");
    }
  }, [auth]);

  const { customerList } = customer;
  const columns = [
    { field: "customer_id", title: "User Id", hidden: true },
    { field: "customer_name", title: "Name" },
    {
      title: "Action",
      field: "details",
      render: (rowData) => (
        // <Link to={`/editprofile/${rowData.id}`}>{rowData.details}</Link>
        <Link to={`/admin/usergroups/${rowData.customer_id}`}>
          {rowData.details}
        </Link>
      ),
    },
  ];

  const data =
  customerList &&
  customerList.map((item) => {
    return {
      customer_id: item.customer_id,
      customer_name: item.customer_name,
      details: "View User-Sub-Groups",
    };
  });

  const handleCustomer = () => {
    history.push("/admin/addcustomer");
  };

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            title={
              <ButtonField
                variant="outlined"
                name="Add New Customer"
                icon={<AddIcon />}
                onClick={() => handleCustomer()}
              />
            }
            columns={columns}
            data={data}
            pageSize={10}
            // selectedRow={(row) => history.push(`/admin/coach/${row._id}`)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

CustomerList.propTypes = {
  getCustomerList: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  customer: state.customer,
});

const mapDispatchToProps = { getCustomerList };

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
