import React, { Component } from "react";
//import data from './data.json';
import * as d3 from "d3";
import "./GroupBarChart.css";
// import Menu from "../menu/menu";
// import UserMenu from '../users/usermenu/usermenu';
import $ from "jquery";
import { connect } from "react-redux";
import { allusers } from "../../redux/actions/visualsAction";
import Swal from "sweetalert2";
import { Redirect } from "react-router-dom";
import loader from ".././805.gif";

class Performancechart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.props.allusers();
  }

  componentWillReceiveProps(nextProps) {
    const { allusersvisualdata } = nextProps.visuals;
    if (allusersvisualdata) {
      if (allusersvisualdata.length > 0) {
        let d = document.getElementById("groupBarChart");
        d.innerHTML = "";
        this.GroupbarChart(allusersvisualdata);
        this.setState({
          isLoaded: true,
        });
      } else {
        this.setState({
          isLoaded: true,
        });
        Swal.fire({
          icon: "warning",
          text: "No Data found to display chart !!!",
        }).then(() => {
          this.props.history.push("/login");
        });
      }
    }
  }

  GroupbarChart = (data) => {
    var Topic_Name = [];

    data.forEach(function (d) {
      Topic_Name.push(d.Topic_Name);
    });

    var Topic_Name = [...new Set(Topic_Name)];
    Topic_Name.unshift("-Topics-");
    console.log(Topic_Name);

    // filtering all the skill
    var skill_Name = [];

    data.forEach(function (d) {
      skill_Name.push(d.Skill_Level);
    });

    var skill_Name = [...new Set(skill_Name)];
    skill_Name.unshift("-Skill Levels-");
    console.log(skill_Name);

    //taking all the unique names
    var names = [];

    data.forEach(function (d) {
      names.push(d.First_Name);
    });

    var dis_names = [...new Set(names)];

    // data for default sctter plot
    var coll = [];

    for (var i = 0; i < dis_names.length; i++) {
      var person = new Object();
      person.name = dis_names[i];
      var te = [];
      for (var j = 0; j < data.length; j++) {
        if (dis_names[i] == data[j]["First_Name"]) {
          te.push({
            Total_Marks: data[j]["Total_Marks"],
            Correct_Answers: data[j]["Correct_Answers"],
            Skill_Level: data[j]["Skill_Level"],
            Topic_Name: data[j]["Topic_Name"],
          });
        }
      }
      // console.log("each test:", te)
      person.test = te;
      coll.push(person);
    }

    // calculating average percentage for all the students
    var per = [];

    coll.forEach(function (d) {
      var cal = new Object();
      cal.First_Name = d.name;
      var no_of_test = d["test"].length;
      var sum = 0;
      d["test"].forEach(function (d) {
        sum += (d.Correct_Answers / d.Total_Marks) * 100;
      });
      cal.avg = sum / no_of_test;
      per.push(cal);
    });

    console.log(per);

    // create the drop down menu of topic names
    var selector = d3
      .select("#groupBarChart")
      .append("select")
      .attr("id", "topicselector")
      .selectAll("option")
      .data(Topic_Name)
      .enter()
      .append("option")
      .text(function (d) {
        return d;
      })
      .attr("value", function (d, i) {
        return d;
      });

    var topic;
    d3.select("#topicselector").on("change", function (d) {
      topic = this.value;
      topic_update();
    });

    // create the drop down for skills
    var selector = d3
      .select("#groupBarChart")
      .append("select")
      .attr("id", "skillselector")
      .selectAll("option")
      .data(skill_Name)
      .enter()
      .append("option")
      .text(function (d) {
        return d;
      })
      .attr("value", function (d, i) {
        return d;
      });

    var skill;
    d3.select("#skillselector").on("change", function (d) {
      skill = this.value;
      skill_update();
    });

    // chart for topic filter
    function topic_update() {
      console.log(topic);
      var per = [];

      coll.forEach(function (d) {
        var cal = new Object();
        cal.First_Name = d.name;
        var no_of_test = 0;
        var sum = 0;
        d["test"].forEach(function (d) {
          if (d.Topic_Name == topic) {
            sum += (d.Correct_Answers / d.Total_Marks) * 100;
            no_of_test++;
          }
        });
        cal.avg = sum / no_of_test;
        per.push(cal);
      });

      per = per.filter(function (d) {
        return d.avg >= 0 || isNaN(d.avg) === false;
      });
      console.log("topic per", per);

      d3.select("svg").remove();
      ScatterPlot(per);
    }

    // chart for skill filter
    function skill_update() {
      console.log(skill);
      var per = [];

      coll.forEach(function (d) {
        var cal = new Object();
        cal.First_Name = d.name;
        var no_of_test = 0;
        var sum = 0;
        d["test"].forEach(function (d) {
          if (d.Topic_Name == topic && d.Skill_Level == skill) {
            sum += (d.Correct_Answers / d.Total_Marks) * 100;
            no_of_test++;
          }
        });
        cal.avg = sum / no_of_test;
        per.push(cal);
      });

      per = per.filter(function (d) {
        return d.avg >= 0 || isNaN(d.avg) === false;
      });
      console.log("topic per", per);

      d3.select("svg").remove();
      ScatterPlot(per);
    }

    ScatterPlot(per);

    function ScatterPlot(per) {
      d3.select("svg").remove();
      var div = d3
        .select("#groupBarChart")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

      // set the dimensions and margins of the graph
      var margin = { top: 60, right: 30, bottom: 0, left: 40 },
        width = 1000 - margin.left - margin.right,
        height = 450 - margin.top - margin.bottom;

      // append the svg object to the body of the page
      var svg = d3
        .select("#groupBarChart")
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", "10 0 1000 550")
        // .attr("width", width + margin.left + margin.right)
        // .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // Add the grey background that makes ggplot2 famous
      svg
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("height", height)
        .attr("width", width)
        .style("fill", "#EBEBEB")
        .style("stroke", "gray")
        .style("stroke-width", "2px");

      svg
        .append("line")
        .attr("x1", 0)
        .attr("y1", height / 2)
        .attr("x2", width)
        .attr("y2", height / 2)
        .style("fill", "gray")
        .style("stroke", "orange")
        .style("stroke-width", "2px");


        
      svg
      .append("line")
      .attr("x1", 0)
      .attr("y1", height / 4)
      .attr("x2", width)
      .attr("y2", height / 4)
      .style("fill", "gray")
      .style("stroke", "green")
      .style("stroke-width", "2px");

      var aspect = width / height,
        chart = d3.select("#groupBarChart");
      d3.select(window).on("resize", function () {
        var targetWidth = chart.node().getBoundingClientRect().width;
        chart.attr("width", targetWidth);
        chart.attr("height", targetWidth / aspect);
      });

      // Add X axis
      var x = d3
        .scaleBand()
        .domain(
          per.map(function (d) {
            return d.First_Name;
          })
        )
        .range([0, width]);

      var xaxis = svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(
          d3
            .axisBottom(x)
            .tickSize(-height * 1.3)
            .ticks(10)
        )
        // .select(".domain").remove()
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");

      xaxis.select(".domain").remove();

      // Add Y axis
      var y = d3
        .scaleLinear()
        .domain(
          d3.extent(per, function (d) {
            return d.avg;
          })
        )
        .range([height, 0])
        .nice();

      var yaxis = svg
        .append("g")
        .call(
          d3
            .axisLeft(y)
            .tickSize(-width * 1.3)
            .ticks(7)
        )
        .select(".domain")
        .remove();

      console.log(y.ticks()[0]);
      // console.log("all the points", yaxis.ticks(yaxis.ticks()[0]))

      // Customization
      svg.selectAll(".tick line").attr("stroke", "white");

      // Add X axis label:
      // svg.append("text")
      //     .attr("text-anchor", "end")
      //     .attr("x", width/2 + margin.left)
      //     .attr("y", height + margin.top + 20)
      //     .text("Sepal Length");

      var number_format = d3.format(".3n");

      var mean = 0;
      per.forEach(function (d) {
        mean += d.avg;
      });
      mean = mean / per.length;
      console.log("mean", number_format(mean));

      var line_data = [
        { x: 0, y: y(mean) },
        { x: x.range()[1], y: y(mean) },
      ];

      var line = d3
        .line()
        .x(function (d) {
          return d.x;
        })
        .y(function (d) {
          return d.y;
        });

      // svg
      //   .append("path")
      //   .attr("d", line(line_data))
      //   .attr("stroke", "red")
      //   .attr("stroke-width", 2)
      //   .attr("fill", "none");

      // Y axis label:
      svg
        .append("text")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-90)")
        .attr("y", -margin.left + 20)
        .attr("x", -margin.top - height / 2 + 20)
        .text("Average percentage");

      // Color scale: give me a specie name, I return a color
      var color = d3
        .scaleOrdinal()
        .domain(["setosa", "versicolor", "virginica"])
        .range(["#F8766D", "#00BA38", "#619CFF"]);

      // Add dots
      // svg.append('g')
      //     .selectAll("dot")
      svg
        .selectAll("dot")
        .data(per)
        .enter()
        .append("circle")
        .attr("cx", function (d) {
          return x(d.First_Name);
        })
        .attr("cy", function (d) {
          return y(d.avg);
        })
        .attr("r", 5)
        .style("fill", function (d) {
          console.log(d,"slkd")
          if(d.avg <=25)
         { return "red";}
         else if(d.avg>25 && d.avg<65)
         {return "orange";}
         else
         {
          return "green";
         }
        })
        .on("mouseover", function (d) {
          div.transition().duration(200).style("opacity", 0.9);
          div
            .html(d.First_Name + "<br/>" + number_format(d.avg))
            .style("left", d3.event.pageX + "px")
            .style("top", d3.event.pageY - 28 + "px");
        })
        .on("mouseout", function (d) {
          div.transition().duration(500).style("opacity", 0);
        });
    }
    console.log("inside render");
    // groupbarChart(data)
  };

  componentWillUnmount() {
    d3.selectAll("svg").remove();
  }

  render() {
    const { user } = this.props.auth;
    const role = user && user.role;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div className="container centop">
          {/* {role === 'user' ? <UserMenu/> : <Menu /> } */}
          {/* <Menu /> */}
          <div
            className="image-container"
            style={{
              display: this.state.isLoaded === false ? "block" : "none",
            }}
          >
            <p className="image-holder">
              <img src={loader} alt="" />
            </p>
          </div>
          <center>
            <div className="fontSize badge badge-info text-center">
              User Performance chart based on Percentage
            </div>
          </center>
          <div id="groupBarChart"></div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  visuals: state.visuals,
});

const mapDispatchToProps = {
  allusers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Performancechart);
