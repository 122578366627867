import { 
    GET_INTERVIEW_RESULTS_LIST, 
    GET_INTERVIEW_RESULT_SHEET, 
} from './type';
import axios from 'axios';
import { API_URL } from '../../Api_Request';
import Swal from 'sweetalert2';

export const getInterviewResultsList = () => dispatch => {
    axios
    .get(`${API_URL}/interviewModule/getAllInterviewUserResults`)
        .then(res => {
          dispatch({
              type : GET_INTERVIEW_RESULTS_LIST, 
              payload : res.data
          })
          
          // console.log(res.data)
      })
    .catch(err => console.log(err))
}

export const getInterviewResultSheet = (params) => dispatch => {
    axios
    .get(`${API_URL}/interviewModule/getInterviewUserAnswerSheet/?iduser=${params.iduser}&idtest=${params.idtest}`)
        .then(res => {
            console.log("res.data",res.data)
          dispatch({
              type : GET_INTERVIEW_RESULT_SHEET, 
              payload : res.data
          })
          
          // console.log(res.data)
      })
    .catch(err => console.log(err))
}