// import React, { Component } from "react";
// // import Menu from "../menu/menu";
// import "./results.css";
// import { Redirect } from "react-router-dom";
// import { connect } from "react-redux";
// import { allResults } from "../../redux/actions/resultsAction";
// import Tables from "../tables/tables";
// import Table from "../reusableComponents/MaterialTable";
// import loader from ".././805.gif";
// import ResultFilter from "./resultFilter";
// class Results extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       resultsData: [],
//     };
//   }

//   componentDidMount() {
//     this.props.allResults();
//     const { allResultsdata } = this.props.resultsData;
//     this.setState({
//       resultsData: allResultsdata,
//       isLoaded: true,
//     });
//   }

//   componentWillReceiveProps(nextProps) {
//     // console.log(nextProps.resultsData)
//     if (!nextProps.resultsData.allResultsdata) {
//       return null;
//     } else if (nextProps.resultsData.allResultsdata.length < 0) {
//       this.setState({
//         resultsData: nextProps.resultsData.allResultsdata,
//         isLoaded: true,
//       });
//     } else {
//       this.setState({
//         resultsData: nextProps.resultsData.allResultsdata,
//         isLoaded: false,
//       });
//     }
//   }

//   render() {
//     const { user } = this.props.auth;
//     if ((user && user.role === "user") || (user && user.role === "testuser")) {
//       return <Redirect to="/login" />;
//     } else {
//       // const {resultsData} = this.props.resultsData
//       // console.log(resultsData);

//       const data = this.state.resultsData || [];

//       // TABLE
//       const columns1 = [
//         // {
//         //   field: "id",
//         //   title: "User Id",
//         //   options: { filter: false, sort: false, hidden: true },
//         // },
//         // {
//         //   field: "test_id",
//         //   title: "Test Id",
//         //   options: { filter: false, sort: false, hidden: true },
//         // },
//         {
//           field: "first_name",
//           title: "Name",
//           options: { filter: false, sort: true },
//         },
//         {
//           field: "email",
//           title: "Email_ID",
//           options: { filter: false, sort: false },
//         },
//         {
//           field: "topicname",
//           title: "Question Topic",
//           options: { filter: true, sort: false },
//         },
//         {
//           field: "skill_level",
//           title: "Skill Level",
//           options: { filter: true, sort: false },
//         },
//         {
//           field: "tresult",
//           title: "Results",
//           options: { filter: false, sort: false },
//         },
//         // user && user.role === "Super Admin"
//         //   ? {
//         //       field: "customer_name",
//         //       title: "Customer",
//         //       options: { filter: false, sort: false },
//         //     }
//         //   : null,
//         // {
//         //   field: "user_group_name",
//         //   title: "Sub-Group",
//         //   options: { filter: true, sort: false },
//         // },
//         {
//           field: "insertedDate",
//           title: "Date",
//           options: { filter: false, sort: false },
//         },
//         {
//           field: "expiredTime",
//           title: "Extra Time Taken",
//           options: { filter: false, sort: true },
//         },
//       ];

//       // const data = resultsData;

//       // const options1 = {
//       //   filterType: "checkbox",
//       //   serverSide: false,
//       //   pagination: true,
//       //   // searchText: true,
//       //   download: false,
//       //   rowsPerPage: 10,
//       //   selectableRows: "none",
//       //   rowsPerPageOptions: [10],
//       //   viewColumns: false,
//       //   rowHover: true,
//       //   search: true,
//       //   print: false,
//       //   onRowClick: (rowData) =>
//       //     this.props.history.push({
//       //       pathname: `/admin/viewresults`,
//       //       state: {
//       //         userId: rowData[0],
//       //         testId: rowData[1],
//       //         topics: rowData[5],
//       //       },
//       //     }),
//       //   responsive: "scrollMaxHeight",
//       // };

//       return (
//         <div className="restable">
//           <ResultFilter />
//           {/* <Menu /> */}
//           {data && data ? (
//             <Table
//               title="Results"
//               data={data}
//               columns={columns1}
//               pageSize={10}
//               selectedRow={(row) =>
//                 // console.log("row",row)
//                 this.props.history.push({
//                   pathname: `/admin/viewresults`,
//                   state: {
//                     userId: row.id,
//                     testId: row.test_id,
//                     topics: row.topicname,
//                   },
//                 })
//               }
//             />
//           ) : (
//             <div className="image-container">
//               <p className="image-holder">
//                 <img src={loader} alt="" />
//               </p>
//             </div>
//           )}
//         </div>
//       );
//     }
//   }
// }

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   resultsData: state.resultsData,
// });

// export default connect(mapStateToProps, { allResults })(Results);

import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { allResults } from "../../redux/actions/resultsAction";
import Table from "../reusableComponents/MaterialTable";
import Spinner from "../reusableComponents/Spinner"; // Ensure this is the correct import path
import ResultFilter from "./resultFilter";
import ButtonField from "../reusableComponents/ButtonField";
import { makeStyles } from "@material-ui/core/styles";  


const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  formSubmit: {
    textTransform: "capitalize",
    backgroundColor: "#003299",
    // marginRight: "10px",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#F8F9F9",
    "&:hover": {
      background: "#004499",
    },
  },
  industryList: {
    listStyleType: "none",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

const  Results = ({ auth, resultsData, allResults, history }) =>{
  
  const [resultsDataState, setResultsDataState] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    allResults();
  }, [allResults]);

  useEffect(() => {
    if (resultsData.allResultsdata) {
      setResultsDataState(resultsData.allResultsdata);
      setIsLoaded(resultsData.allResultsdata.length > 0);
    } else {
      setIsLoaded(false); // Set isLoaded to false if there is no data
    }
  }, [resultsData]);

  const { user } = auth;

  if (user && (user.role === "user" || user.role === "testuser")) {
    return <Redirect to="/login" />;
  }

  const data = resultsDataState;

  const columns1 = [
    {
      field: "first_name",
      title: "Name",
      options: { filter: false, sort: true },
    },
    {
      field: "email",
      title: "Email_ID",
      options: { filter: false, sort: false },
    },
    {
      field: "topicname",
      title: "Question Topic",
      options: { filter: true, sort: false },
    },
    {
      field: "skill_level",
      title: "Skill Level",
      options: { filter: true, sort: false },
    },
    {
      field: "tresult",
      title: "Results",
      options: { filter: false, sort: false },
    },
    {
      field: "insertedDate",
      title: "Date",
      options: { filter: false, sort: false },
    },
    {
      field: "expiredTime",
      title: "Extra Time Taken",
      options: { filter: false, sort: true },
    },
    {
      title: "View Details",
      field: "details",
      render: (row) => (
        <ButtonField
          buttonStyle={classes.formSubmit}
          type="button"
          name="View Result"
          onClick={() =>
            history.push({
              pathname: `/admin/viewresults`,
              state: {
                userId: row.id,
                testId: row.test_id,
                topics: row.topicname,
              },
            })
          }
        />
      ),
    },
  ];

  return (
    <div className="restable">
      <ResultFilter />
      {isLoaded ? (
        <Table
          title="Results"
          data={data}
          columns={columns1}
          pageSize={10}
          // selectedRow={(row) =>
          //   history.push({
          //     pathname: `/admin/viewresults`,
          //     state: {
          //       userId: row.id,
          //       testId: row.test_id,
          //       topics: row.topicname,
          //     },
          //   })
          // }
        />
      ) : (
        <div className="spinner-container">
          <Spinner /> {/* Display Spinner while data is loading */}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  resultsData: state.resultsData,
});

export default connect(mapStateToProps, { allResults })(Results);
