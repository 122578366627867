import React, { Component } from "react";
import "../questions_complexity/questionComplexity.css";
// import Menu from "../menu/menu";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  getQuestionTopics,
  addnewtopic,
  editQuestionTopic,
} from "../../redux/actions/questionTopicsAction";
import { CircularProgress, Grid } from "@mui/material";
import {
  uploadImage,
  clearImageData,
} from "../../redux/actions/uploadFileActions";
import loader from ".././805.gif";
import Spinner from "../reusableComponents/Spinner";
import PhotoUpload from "../reusableComponents/PhotoUpload";
import AvatarReusable from "../reusableComponents/Avatar";

class QuestionTopic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empty: false,
      isLoaded: false,
      topicData: [],
      addTopic: false,
      editElement: -1,
      name: "",
      acronym: "",
      image_url: "",
      img_source: "",
      err: false,
      status: 1,
      editableid: "",
      Record: {
        name: "",
        acronym: "",
        status: "",
      },
      image_url: "",
      disable: true,
    };
  }

  componentDidMount() {
    this.props.getQuestionTopics();
    this.props.clearImageData();
  }

  componentWillReceiveProps(nextProps) {
    let { gettopics } = nextProps.questiontopics;
    if (gettopics) {
      this.setState({
        topicData: gettopics,
        searchData: gettopics,
        isLoaded: true,
      });
    }
  }

  handleName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  handleAcronym(e) {
    this.setState({
      acronym: e.target.value,
    });
    //console.log(this.state.acronym);
  }

  handleimage_url(e) {
    this.setState({
      image_url: e.target.files[0],
    });
  }

  handleSaveStatus() {
    this.setState({
      status: this.state.status === 0 ? 1 : 0,
    });
    console.log(this.state.status);
  }

  handleTopic = () => {
    const { addTopic } = this.state;
    console.log(addTopic);
    if (this.state.addTopic === false) {
      this.setState({
        editElement: -1,
        name: "",
        acronym: "",
        addTopic: true,
      });
    }
  };

  handleCancelData = () => {
    if (this.state.addTopic === true) {
      this.setState({
        addTopic: false,
      });
    }
  };

  handleEdit(data, id) {
    const { imageData } = this.props.fileData;
    console.log("disable status is", this.state.disable);
    
    this.props.clearImageData();
    if (this.state.addTopic === false) {
      this.setState({
        editableid: data.id,
        name: data.name,
        acronym: data.acronym,
        status: data.status === 0 ? 0 : 1,
        editElement: id,
        image_url: imageData ? imageData?.link : data.image_url,
        img_source: imageData ? imageData?.img_source : data.img_source,
        disable: false,
      });
      console.log("disable status after setting", this.state.disable);
      console.log(data.image_url);
    } else {
      Swal.fire({ icon: "error", text: `please enter the details` });
      // alert("please enter the details");
    }
  }

  handleUpdate(index) {
    const { name, acronym } = this.state;
    const { imageData } = this.props.fileData;
    let { err } = this.state;
    console.log(index + 1);
    if (name === "") {
      err = true;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: true,
      });

      Toast.fire({
        icon: "error",
        title: "Please enter Topic name !!!",
      });
    } else if (acronym === "") {
      err = true;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: true,
      });

      Toast.fire({
        icon: "error",
        title: "Please enter Acronym !!!",
      });
    } else {
      err = false;

      const userTopicEdit = {
        name: this.state.name,
        acronym: this.state.acronym,
        status: this.state.status === 0 ? 0 : 1,
        id: this.state.editableid,
        image_url: imageData ? imageData?.link : this.state.image_url,
        img_source: imageData ? imageData?.img_source : this.state.img_source,
      };
      this.props.editQuestionTopic(userTopicEdit);
      this.props.clearImageData();
      this.setState({
        isLoaded: false,
        name: "",
        acronym: "",
        addTopic: false,
        editElement: -1,
        image_url: "",
        img_source: "",
      });
    }
  }

  handleStatus(data, status, index) {
    const dummyRecord = this.state.Record;
    dummyRecord.name = data.name;
    dummyRecord.acronym = data.acronym;
    dummyRecord.status = status === 0 ? 1 : 0;

    const dummytopicData = this.state.topicData;
    dummytopicData.image_url = data.image_url;
    dummytopicData[index] = dummyRecord;
    this.setState({
      topicData: dummytopicData,
      status: status === 0 ? 1 : 0,
      Record: {
        name: "",
        acronym: "",
        status: "",
        image_url: "",
      },
    });
    // this.setState({
    //   status:this.state.status === 0 ? 1 : 0,

    //   })
    console.log(this.state.status);
  }

  validate = () => {
    const { name, acronym, err, topicData } = this.state;
    let isError = err;
    if (name === "" || name.trim() === "") {
      isError = true;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: true,
      });

      Toast.fire({
        icon: "error",
        title: "Please enter Topic name !!!",
      });
    }
    if (acronym === "" || acronym.trim() === "") {
      isError = true;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: true,
      });

      Toast.fire({
        icon: "error",
        title: "Please enter Acronym !!!",
      });
    }
    topicData.map((topic) => {
      if (name.toLowerCase() === topic.name.toLowerCase()) {
        isError = true;
        Swal.fire({ icon: "error", text: `Topic name is already added !!!` });
      }
    });

    return isError;
  };

  handleSaveData() {
    let { err } = this.state;

    const { imageData } = this.props.fileData;
    err = this.validate();
    if (err) {
      this.setState({
        error: true,
      });
    } else {
      const userQTopic = {
        name: this.state.name,
        acronym: this.state.acronym,
        status: this.state.status === 0 ? 1 : 0,
        image_url: imageData ? imageData?.link : "",
        img_source: imageData ? imageData?.img_source : "",
        // status:this.state.status
      };
      this.props.addnewtopic(userQTopic);

      this.props.clearImageData();
      this.setState({
        isLoaded: false,
        name: "",
        acronym: "",
        addTopic: false,
        editElement: -1,
        image_url: "",
        img_source: "",
        // disable:true,
      });
    }
  }

  handleClick() {
    this.setState({
      error: false,
    });
  }

  //  search bar
  handleSearch(e) {
    let newList = [];
    if (e.target.value !== "") {
      const filter = e.target.value;
      newList = this.state.searchData.filter(function (item) {
        return (
          item.name.toLowerCase().search(filter.toLowerCase()) !== -1 ||
          item.acronym.toLowerCase().search(filter.toLowerCase()) !== -1
        );
      });
      this.setState({
        topicData: newList,
      });
    } else {
      this.setState({
        topicData: this.state.searchData,
      });
    }
  }

  uploadQuestionImage = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    this.props.uploadImage(formData);
  };

  render() {
    const url = process.env.REACT_APP_FRONT_END_URL_PATH;

    const { addTopic, topicData } = this.state;
    const { imageData, uploadFileProcess } = this.props.fileData;

    const { user } = this.props.auth;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      const { editElement } = this.state;
      // console.log(topicData);
      let { gettopics } = this.props.questiontopics;
      const tblTopicData =
        topicData &&
        topicData.map((item, index) => {
          return (
            <tr key={index}>
              <td>
                {editElement === index ? (
                  <>
                    {uploadFileProcess ? (
                      <Grid sx={{ maxWidth: 345 }} align="center">
                        <CircularProgress />
                      </Grid>
                    ) : (
                      <PhotoUpload
                        key={index}
                        imageName={"logo"}
                        onChange={(e) => this.uploadQuestionImage(e)}
                        imageUrl={imageData ? imageData?.link : item.image_url}
                        disabled={
                          this.state.editElement === index ? false : true
                        }
                      />
                    )}
                  </>
                ) : (
                  <AvatarReusable
                    imageUrl={item.image_url}
                    // className={classes.largeAvatar}
                  />
                )}
              </td>
              <td>
                {editElement === index ? (
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.handleName(e);
                    }}
                    defaultValue={item.name}
                  />
                ) : (
                  item.name
                )}
              </td>
              <td>
                {editElement === index ? (
                  <input
                    type="text"
                    placeholder="use underscore, ex: React_JS"
                    className="form-control"
                    onChange={(e) => {
                      this.handleAcronym(e);
                    }}
                    defaultValue={item.acronym}
                  />
                ) : (
                  item.acronym
                )}
              </td>
              <td>
                <label className="switch" id="status">
                  <input
                    type="checkbox"
                    checked={item.status === 0 ? false : true}
                    disabled={this.state.editElement === index ? false : true}
                    onChange={() => this.handleStatus(item, item.status, index)}
                  />
                  <span className="slider round" />
                </label>
              </td>
              <td>
                <div>
                  {editElement === index ? (
                    <button
                      className="btn btn-primary"
                      onClick={(e) => this.handleUpdate(index)}
                      style={{ backgroundColor: "#003399" }}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="btn btn-success"
                      onClick={(e) => this.handleEdit(item, index)}
                    >
                      Edit
                    </button>
                  )}
                </div>
              </td>
            </tr>
          );
        });

      return (
        <div>
          {/* <Menu /> */}
          <div className="side-content questionTopic ">
            <div>
              <br />
              <center>
                <button
                  type="button"
                  id="add-row"
                  value="add question topic"
                  className="btn "
                  style={{ backgroundColor: "#003399", color: "white" }}
                  onClick={() => this.handleTopic()}
                >
                  Add Question Topic
                </button>
                <br />
                <input
                  type="text"
                  className="mp-5 input container"
                  onChange={(e) => this.handleSearch(e)}
                  placeholder="Search by Topic Name..."
                />
              </center>
            </div>
            {/* <div className="alert" style={{ display: this.state.error === true ? 'block' : 'none' }}>
          <span className="closebtn" onClick={() => { this.handleClick() }}>&times;</span>
          <strong>Please Enter Details....</strong>
        </div> */}
            <div
              className="image-container"
              style={{
                display: this.state.isLoaded === false ? "block" : "none",
              }}
            >
              <p className="image-holder">
                {/* <img src={loader} alt="" /> */}
                <Spinner/>
              </p>
            </div>
            {this.state.isLoaded ? (
              <div className="table-responsive p-5">
                <table
                  className="table table-bordered table-hover"
                  style={{
                    display:
                      this.state.isLoaded === true ? "inline-table" : "none",
                  }}
                >
                  <thead className="thead-bg headers">
                    <tr>
                      <th>Logo</th>
                      <th>Name</th>
                      <th>Acronym</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addTopic && (
                      <tr>
                        <td>
                          {console.log("imageurl:", this.state.image_url)}
                          <PhotoUpload
                            imageName={"logo"}
                            onChange={(e) => this.uploadQuestionImage(e)}
                            imageUrl={imageData ? imageData?.link : ""}
                          />
                          {/* <PhotoUpload
                            imageName={"logo"}
                            imageUrl={`${url}public/images/topics/${this.state.image_url}`}
                          /> */}
                          {/* <p>this is logo </p> */}
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter topic name"
                            value={this.state.name}
                            onChange={(e) => this.handleName(e)}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ex: React_JS"
                            value={this.state.acronym}
                            onChange={(e) => this.handleAcronym(e)}
                          />
                        </td>
                        <td>
                          <label className="switch" id="status">
                            <input
                              type="checkbox"
                              value={this.state.status}
                              onChange={() => this.handleSaveStatus()}
                            />
                            <span className="slider round" />
                          </label>
                        </td>
                        <td>
                          <button
                            type="button"
                            id="qtopicbutton"
                            className="btn"
                            style={{
                              backgroundColor: "#003399",
                              color: "white",
                            }}
                            value="save"
                            onClick={() => {
                              this.handleSaveData();
                            }}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            id="qtopicbutton"
                            className="btn btn-danger"
                            value="cancel"
                            onClick={() => {
                              this.handleCancelData();
                            }}
                          >
                            Cancel
                          </button>
                        </td>
                      </tr>
                    )}
                    {tblTopicData}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  questiontopics: state.questiontopics,
  fileData: state.fileData,
});

const mapDispatchToProps = {
  getQuestionTopics,
  addnewtopic,
  editQuestionTopic,
  uploadImage,
  clearImageData,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTopic);
