import React, { Component, useEffect } from "react";

import loader from ".././805.gif";
import { currentUser } from "../../redux/actions/authActions";
import setAuthToken from "../../redux/utils/setAuthToken";
import { connect, useDispatch, useSelector } from "react-redux";

import { getIbridgeUser } from "../../redux/actions/iBridgeActions";

const IbridgeUserDashboard = ({ match, history }) => {
  const { iBridge, auth } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { user } = match.params;
  const { ibridgeUserData } = iBridge;

  useEffect(() => {
    dispatch(getIbridgeUser(user));
  }, []);

    useEffect(() => {
        dispatch(getIbridgeUser(user))
      }, []);
    
      useEffect(() => {
        if (ibridgeUserData) {
          const local = {
            token: ibridgeUserData.token,
            username: ibridgeUserData.username,
          };
          localStorage.setItem("capability", JSON.stringify(local));
          setAuthToken(local.token);
          dispatch(currentUser(local));
          setTimeout(history.push({pathname:"/menu/userdashboard",state:{value:1}}), 5000);
        }
      }, [ibridgeUserData]);

  return (
    <div className="row quset font-weight-bold pr-5 pl-5">
      <div className="image-container">
        <p className="image-holder">
          <img src={loader} alt="" />
        </p>
      </div>
    </div>
  );
};

export default IbridgeUserDashboard;
