import React, {useState, useEffect} from 'react';
import {Grid, Box, Button, TextField, Typography, Paper} from '@material-ui/core'
import { fetchOverallData, fetchClusterData, fetchTopicWiseData, loadingCapabilityApplication } from '../../redux/actions/capabilityAnalysisApplicationAction';
import OverviewTable from './topicWiseOverviewTable';
import { useSelector, useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { API_URL } from '../../Api_Request';
import axios from 'axios';
import SelectSingleValues from "../reusableComponents/SelectSingleValues";
// const topics = [
//     { name: 'MS-SQL' }, { name: 'Oracle SQL' }, { name: 'DW Concepts' }, { name: 'Business 101' },
//     { name: 'Microsoft Excel' }, { name: 'T-SQL' }, { name: 'Tableau' }, { name: 'Php' },
//     { name: 'ReactJS' }, { name: 'JavaScript' }, { name: 'Unix/Linux' }, { name: 'PL-SQL' },
//     { name: 'RDBMS' }, { name: 'SSRS' }, { name: 'Cognos' }, { name: 'POWER BI' },
//     { name: 'SSAS Tabular Model' }, { name: 'JAVA' }, { name: 'Developer Testing' },
//     { name: 'Python' }, { name: 'Pandas' }, { name: 'Informatica' }, { name: 'HTML' },
//     { name: 'Cascading Style Sheet' }, { name: 'Human Resources Recruitment' },
//     { name: 'Retail Domain' }, { name: 'Android' }, { name: 'SSIS' },
//     { name: 'Computer Fundamental' }, { name: 'Devops' }, { name: '.NET' },
//     { name: 'MYSQL' }, { name: 'Quality Assurance' }, { name: 'Wealth Management' },
//     { name: 'Git and Github' }, { name: 'US HealthCare Basics' }, { name: 'Payer' },
//     { name: 'Snowflake' }, { name: 'IOS' }, { name: 'MongoDB' }, { name: 'Angular' },
//     { name: 'testing 1' }, { name: 'Technology 101' }, { name: 'COMMUNICATION' }
//   ];
//   const sortedTopics = topics.slice().sort((a, b) => {
//     return a.name.localeCompare(b.name);
//   });
const TopicWise = () => {
    
    const [userEmail, setUserEmail] = useState('');
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [sortedTopics, setSortedTopics] = useState(null);
    const isEmailValid = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    const { capabilityAnalysisApplicationReducer } = useSelector(
      (state) => state
    );
    const dispatch = useDispatch();
  
    const fetchTopicWiseDataDetails = () => {
      dispatch(loadingCapabilityApplication());
      dispatch(fetchTopicWiseData({"email":userEmail,"topic": selectedTopic.name}));
    }

    const fetchTopics = async () => {
      await axios.get(`${API_URL}/capabilityAnalysisApplication/topicList`, {params: {email: userEmail} })
      .then((res)=>{
        const temp = res.data.distinctTopics
        const sortedTopicList = temp.slice().sort((a,b)=>{
          return a.name.localeCompare(b.name);
        });
        setSortedTopics(sortedTopicList);
      })
      .catch((err)=>{
        console.log(err);
        Swal.fire({
          icon: "error",
          text: "Email not found in database",
        });
      })
    }
  
    return(
    <Grid container spacing={1}>
        <Grid item xs={12}
        style={{
           marginLeft: 350,
           marginBottom: 40
          }}
        >
        <Typography variant='h6' >
          Personalized Recommender System
        </Typography>
        </Grid>
            <Grid item xs={12}>
            <Box
                style={{
                  display: "flex", 
                  justifyContent: "space-between",
                  width: "45%"
              }}
                >
                <Box>
                  <Box>
                <TextField
                style={{
                  width: 200,
                }}
                  label="Enter User's Email"
                  type="email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  error={!isEmailValid(userEmail) && userEmail}
                  helperText={userEmail && !isEmailValid(userEmail)}
                />
                  </Box>
                  <Box>
                <Button
                variant='outlined'
                style={{
                  width: "150px",
                  textTransform: 'none',
                  marginTop: 8
                }}
                onClick={fetchTopics}
                disabled={userEmail.length == 0} 
                >
                  Fetch Topics
                </Button>
                  </Box>
                </Box>
                {sortedTopics &&
                <Box>
                <SelectSingleValues
                  style={{
                    width: 200,
                  }}
                  placeholder="Select a topic"
                  name="topic"
                  values={sortedTopics} 
                  onSelect={(topic) => setSelectedTopic(topic)}
                  selected={selectedTopic}
                />
                </Box>}
              </Box>
              <Grid item xs = {12}>
          <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 40
          }}
          >
                <Button 
                    variant='outlined'
                    style={{
                        width: "150px",
                        textTransform: "none",                      
                      }}
                    onClick={fetchTopicWiseDataDetails}
                    disabled={!(userEmail && selectedTopic) || capabilityAnalysisApplicationReducer.loading}
                >
                    Analyse
                </Button>
                </Box>
            </Grid>
          </Grid>
          {capabilityAnalysisApplicationReducer.topicWiseData ?
          (
            <Grid container spacing={1}>
            <Grid item xs = {12}>
          <Typography 
                variant='body1'
            >
              Name: {capabilityAnalysisApplicationReducer.topicWiseData.Name}
            </Typography>
          </Grid>
                <Grid item xs = {4}>
                <Paper
                style={{
                    flex: 1,
                    border: '1px solid #ccc', 
                    padding: '10px',
                    marginRight: 30,
                    flexDirection: "column",
                  height: 400,
                  overflow: "hidden",
                  overflowY: "scroll", 
                }}
                >
                <Typography
                style={{
                    display: 'block',
                    marginBottom: 20
                }}
                >
                    Tests Attempted: {capabilityAnalysisApplicationReducer.topicWiseData['Tests attempted']}
                </Typography>
                {capabilityAnalysisApplicationReducer.topicWiseData.Result.map((obj, index) => (
                    <>
                    {Object.keys(obj).map((key) => (
                        <Typography
                            key={index + "-" + key} 
                            variant='bold2'
                            style={{
                                display: 'block',
                                marginBottom: 8
                            }}
                        >
                            {key}: {obj[key]}
                        </Typography>
                    ))}
                    <Typography>---</Typography>
                    </>
                ))}
 
                </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    >
                        <Paper style={{padding: 20}}>
                            <Typography style={{display: 'block', marginBottom: 8, fontWeight: 'bold'}}>Recommendation</Typography>
                            <Typography>{capabilityAnalysisApplicationReducer.topicWiseData['Recommendations']}</Typography>
                        </Paper>
                        <Paper
                        style={{
                            overflowX: 'auto',
                           border: '1px solid #ccc', 
                           padding: '10px',
                           marginRight: 30,
                           marginTop: 60,
                           height: 200,
                           width: 600
                        }}>
                        <OverviewTable  overviewData = {capabilityAnalysisApplicationReducer.topicWiseData.Overview} />
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
          ) 
          :
          capabilityAnalysisApplicationReducer.errorMessage &&  (
            <Grid item xs={12}
            >
              <Box>
                <Typography variant='h5' color='error'>
                  {capabilityAnalysisApplicationReducer.errorMessage}
                </Typography>
            </Box>
        </Grid>
          )
        }
  </Grid>
                    
    )
}

export default TopicWise;