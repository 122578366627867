import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar, Button } from "@material-ui/core";
import Table from "../../reusableComponents/MaterialTable";
import ButtonField from "../../reusableComponents/ButtonField";
import {
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import CustomizedDialogs from "../../reusableComponents/CustomizedDialogs";
import AddNewDomain from "./AddNewDomain";
import EditDomain from "./EditDomain";

// import { useSelector, useDispatch } from "react-redux"
import { getDomainList } from "../../../redux/actions/productivityActions/domainAction";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const Domain = ({ auth, getDomainList, domainReducer }) => {
  const [open, setOpen] = useState(false);
  const [domainId, setdomainId] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const classes = useStyles();
  const { isAuthenticated } = auth;

  useEffect(() => {
    if (auth.isAuthenticated) {
      getDomainList();
    }
  }, [auth.isAuthenticated]);

  const columns = [
    {
      title: "Domain Name",
      field: "domainName",
    },
    {
      title: "Technology",
      field: "technology",
    },
    {
      title: "Domain Website",
      field: "domainWebsite",
    },
    {
      title: "Domain Description",
      field: "domainDescription",
    },
    {
      title: "Domain Status",
      field: "status",
    },
    {
      title: "Edit",
      field: "edit",
    },
  ];

  const data = domainReducer?.getDomainList?.map((item) => {
    return {
      _id: item._id,
      domainName: item.name,
      technology: item.technology?.name,
      domainWebsite: item.domainWebsite,
      domainDescription: item.domainDescription,
      status: item?.status ? "Enabled" : "Disabled",
      edit: (
        <Button
          variant="contained"
          color="primary"
          type="submit"
          name="edit"
          onClick={() => {
            setdomainId(item._id);
            setOpenEdit(true);
          }}
        >
          Edit
        </Button>
      ),
    };
  });

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            title={
              <ButtonField
                variant="outlined"
                name="Add New Domain"
                icon={<AddIcon />}
                onClick={() => setOpen(true)}
              />
            }
            columns={columns}
            data={data}
            pageSize={10}
            // selectedRow={(row) => history.push(`/admin/coach/${row._id}`)}
          />
        </Grid>
      </Grid>
      <CustomizedDialogs
        title="Add New Domain"
        children={<AddNewDomain setOpenPopup={setOpen} />}
        openPopup={open}
        setOpenPopup={setOpen}
      />
      <CustomizedDialogs
        title="Edit Domain"
        children={<EditDomain domainId={domainId} setOpenPopup={setOpenEdit} />}
        openPopup={openEdit}
        setOpenPopup={setOpenEdit}
      />
    </Container>
  );
};

Domain.propTypes = {
  auth: PropTypes.object.isRequired,
  getDomainList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  domainReducer: state.domainReducer,
});

const mapDispatchToProps = {
  getDomainList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Domain);
