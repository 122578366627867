import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getScatterGraphData } from "../../redux/actions/visualsAction";
import { adminTopics } from "../../redux/actions/TopicsAction";
import { Grid, Button } from "@mui/material";
import ResultFilter from "../results/resultFilter";
import SelectSingleValues from "../reusableComponents/SelectSingleValues";
import QuadrantChart from "./QuadrantChart";
import PerformanceScatterChart from "./PerformanceScatterChart";
import { Typography } from "@mui/material";

const NewPerformanceGraph = () => {
  const { visuals, assignedTopics } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { scatterGraphData } = visuals;
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [scatterData, setScatterData] = useState([]);
  const skills = [
    { name: "Beginner", value: 1 },
    { name: "Intermediate", value: 2 },
    { name: "Expert", value: 3 },
  ];
  // console.log(assignedTopics);

  useEffect(() => {
    dispatch(adminTopics());
    dispatch(getScatterGraphData());
  }, []);

  useEffect(() => {
    setScatterData(scatterGraphData);
  }, [scatterGraphData]);

  const handleFilters = () => {
    if (!selectedSkill && selectedTopic) {
      const filteredData = scatterGraphData?.filter(
        (item) => item.topic_id === selectedTopic?.id
      );
      setScatterData(filteredData);
    } else if (!selectedTopic && selectedSkill) {
      const filteredData = scatterGraphData?.filter(
        (item) => item.skill_level_id === selectedSkill?.value
      );
      setScatterData(filteredData);
    } else if (selectedTopic && selectedSkill) {
      const filteredData = scatterGraphData?.filter(
        (item) =>
          item.skill_level_id === selectedSkill?.value &&
          item.topic_id === selectedTopic?.id
      );
      setScatterData(filteredData);
    } else {
      setScatterData(scatterGraphData);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={1}></Grid>
      <Grid item xs={12} md={11}>
        <ResultFilter scatterFilter={true} />
      </Grid>
      <Grid item xs={12} md={1}></Grid>
      <Grid item xs={12} md={4}>
        <SelectSingleValues
          label="Select Topic"
          name="topic"
          values={assignedTopics?.adminTopics || []}
          onSelect={(topicData) => setSelectedTopic(topicData)}
          selected={selectedTopic}
          // errors={
          //   formik.touched.selectDomain && formik.errors.selectDomain
          //     ? formik.errors.selectDomain
          //     : null
          // }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectSingleValues
          label="Select Skills"
          name="topic"
          values={skills}
          onSelect={(skillData) => setSelectedSkill(skillData)}
          selected={selectedSkill}
          // errors={
          //   formik.touched.selectDomain && formik.errors.selectDomain
          //     ? formik.errors.selectDomain
          //     : null
          // }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          buttonStyle="submit"
          type="button"
          name="submit"
          color="primary"
          variant="contained"
          onClick={() => handleFilters()}
        >
          Filter By Topic and Skill
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <PerformanceScatterChart scatterData={scatterData} />
      </Grid>
    </Grid>
  );
};

export default NewPerformanceGraph;
