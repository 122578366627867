import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar, Button } from "@material-ui/core";
import Table from "../../reusableComponents/MaterialTable";
import ButtonField from "../../reusableComponents/ButtonField";
import {
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import CustomizedDialogs from "../../reusableComponents/CustomizedDialogs";
import AddNewFunction from "./AddNewFunction";
import EditFunction from "./EditFunction";

import { getFunctionList } from "../../../redux/actions/productivityActions/functionAction";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const Function = ({}) => {
  const [open, setOpen] = useState(false);
  const [functionId, setfunctionId] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const classes = useStyles();
  //   const { isAuthenticated } = auth;

  const { auth, functionReducer } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getFunctionList());
    }
  }, [auth.isAuthenticated]);

  const columns = [
    {
      title: "Function Name",
      field: "functionName",
    },
    {
      title: "Technology",
      field: "technology",
    },
    {
      title: "Domain",
      field: "domain",
    },
    {
      title: "Business value",
      field: "businessValue",
    },
    {
      title: "Business Size",
      field: "businessSize",
    },
    {
      title: "Function Description",
      field: "functionDescription",
    },
    {
      title: "Function Status",
      field: "status",
    },
    {
      title: "Edit",
      field: "edit",
    },
  ];

  const data = functionReducer?.getFunctionList?.map((item) => {
    return {
      _id: item._id,
      functionName: item.name,
      technology: item.technology?.name,
      domain: item.domain?.name,
      businessValue: item.functionBusinessValue,
      businessSize: item.functionBusinessSize,
      functionDescription: item.functionDescription,
      status: item?.status ? "Enabled" : "Disabled",
      edit: (
        <Button
          variant="contained"
          color="primary"
          type="submit"
          name="edit"
          onClick={() => {
            setfunctionId(item._id);
            setOpenEdit(true);
          }}
        >
          Edit
        </Button>
      ),
    };
  });

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <Table
            title={
              <ButtonField
                variant="outlined"
                name="Add New Function"
                icon={<AddIcon />}
                onClick={() => setOpen(true)}
              />
            }
            columns={columns}
            data={data}
            pageSize={10}
            style={{
              maxWidth: '100%', // Allows the container to adapt to the width of its parent
              width: '100%',    // Ensures the container takes the full width of its parent
              height: 'auto',   // Allows the height to adjust based on content
              overflow: 'auto', // Adds scrollbars if the content overflows
              boxSizing: 'border-box' // Includes padding and border in the element's total width and height
            }}
            // selectedRow={(row) => history.push(`/admin/coach/${row._id}`)}
          />
        </Grid>
      </Grid>
      <CustomizedDialogs
        title="Add New Function "
        children={<AddNewFunction setOpenPopup={setOpen} />}
        openPopup={open}
        setOpenPopup={setOpen}
      />
      <CustomizedDialogs
        title="Edit Domain"
        children={
          <EditFunction functionId={functionId} setOpenPopup={setOpenEdit} />
        }
        openPopup={openEdit}
        setOpenPopup={setOpenEdit}
      />
    </Container>
  );
};

Function.propTypes = {
  auth: PropTypes.object.isRequired,
  getCoachList: PropTypes.func.isRequired,
  clearCoachInfo: PropTypes.func.isRequired,
  adminCoach: PropTypes.object.isRequired,
};

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   adminCoach: state.adminCoach,
// });

// const mapDispatchToProps = { getCoachList, clearCoachInfo };

export default Function;
