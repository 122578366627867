import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import Table from "../../reusableComponents/MaterialTable";
import { Button } from "@material-ui/core";
import ButtonField from "../../reusableComponents/ButtonField";
import { Add as AddIcon } from "@material-ui/icons";
import CustomizedDialogs from "../../reusableComponents/CustomizedDialogs";
import AddNewTechnology from "./AddNewTechnology";

import { getTechnologyList } from "../../../redux/actions/productivityActions/domainAction";
import EditTechnology from "./EditTechnology";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const TechnologyList = ({}) => {
  const { auth, domainReducer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedTechnology, setselectedTechnology] = useState({});
  const classes = useStyles();
  const { isAuthenticated } = auth;

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getTechnologyList());
    }
  }, [isAuthenticated]);

  const columns = [
    {
      title: "Technology Name",
      field: "name",
    },
    {
      field: "status",
      title: "Status",
    },
    {
      field: "edit",
      title: "Edit",
    },
  ];

  const data = domainReducer?.getTechnologyList.map((item) => {
    const TechnologyData = {
      _id: item._id,
      name: item.name,
      status: item.status,
    };
    return {
      _id: item._id,
      name: item.name,
      status: item.status ? "Enabled" : "Disabled",
      edit: (
        <Button
          variant="contained"
          color="primary"
          type="submit"
          name="submit"
          onClick={() => {
            setOpenEdit(true);
            setselectedTechnology(TechnologyData);
          }}
        >
          Edit
        </Button>
      ),
    };
  });

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            title={
              <ButtonField
                variant="outlined"
                name="Add New Technology"
                icon={<AddIcon />}
                onClick={() => setOpen(true)}
              />
            }
            columns={columns}
            data={data || []}
            pageSize={10}
            // selectedRow={(row) => history.push(`/admin/coach/${row._id}`)}
          />
        </Grid>
      </Grid>
      <CustomizedDialogs
        title="Add New Technology"
        children={<AddNewTechnology setOpenPopup={setOpen} />}
        openPopup={open}
        setOpenPopup={setOpen}
      />
      <CustomizedDialogs
        title="Edit Technology"
        children={
          <EditTechnology
            selectedTechnology={selectedTechnology}
            setOpenPopup={setOpenEdit}
          />
        }
        openPopup={openEdit}
        setOpenPopup={setOpenEdit}
      />
    </Container>
  );
};

export default TechnologyList;
