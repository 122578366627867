import React, { Component } from "react";
import axios from "axios";
// import Menu from "../users/usermenu/usermenu";
import { Redirect, Link } from "react-router-dom";
import { API_URL } from "../../Api_Request";
import Swal from "sweetalert2";
import "../users/userdashboard/userdashboard.css";
import { connect } from "react-redux";
import {
  selecttest,
  testquestions,
} from "../../redux/actions/selectTestAction";
import { deletetest } from "../../redux/actions/testAction";
import loader from ".././805.gif";

class selectTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      name: "",
      topicId: "",
      skillId: "",
      testid: "",
      topicData: [],
      complexData: [],
      userData: [],
      userName: "",
      userId: "",
      topic: "",
      skill: "",
      acronym: "",
      date: new Date()
        .toISOString()
        .replace(/T/, " ")
        .replace(/\..+/, "")
        .slice(0, 10),
      isSelectTest: true,
      isStartTest: false,
      singleskill: false,
      continue: true,
      opt:false,
    };
  }

  componentDidMount() {
    // console.log(this.props.location)
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      return this.props.history.push("/login");
    } else {
      this.setState({
        topicId: this.props.location.state.userId,
      });
      const { user } = this.props.auth;
      const uname = `${user && user.first_name} ${user && user.last_name}`;
      const params = {
        topicId: this.props.location.state.userId,
        uid: user && user.id,
        email: user && user.email,
      };

      this.props.selecttest(params);
      this.setState({
        userName: uname,
        isLoaded: true,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.selecttestdata.testquestions)
    const { user } = this.props.auth;
    const { selecttestdata } = nextProps.selecttestdata;
    if (selecttestdata) {
      this.setState({
        skillId: selecttestdata.complexData.data[0].id,
        isLoaded: false,
        // name : selecttestdata.complexData.data[0].name
      });
    }
    let questions = [];
    if (!nextProps.selecttestdata.testquestions) {
      return null;
    } else {
      questions = nextProps.selecttestdata.testquestions;
      console.log(questions);
      if (questions.length >= 10) {
        const test = {
          userId: user && user.id,
          topicId: this.state.topicId,
          skillId: this.state.skillId,
          acronym: this.state.acronym,
          skill: this.state.skill,
        };
        console.log(test);
        axios
          .post(`${API_URL}/tests`, test)
          .then((res) => {
            console.log(res.data);
            this.setState({
              testid: res.data,
              isSelectTest: false,
              isStartTest: true,
              isLoaded: false,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  handleChange = (e) => {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
      continue: false,
    });
  };

  //Generating test ID
  handleSelect = () => {
    const { skill, topicId } = this.state;

    const { selecttestdata, testquestions } = this.props.selecttestdata;
    const { user } = this.props.auth;
    let complexData = selecttestdata && selecttestdata.complexData;
    let topicData = selecttestdata && selecttestdata.topicData;

    topicData.data.map((topics) => {
      if (topics.id === this.state.topicId) {
        this.setState({
          name: topics.name,
        });
        return (this.state.acronym = topics.acronym);
      }
    });

    complexData.data.map((skills) => {
      console.log(skills);
      if (skills.name === skill) {
        this.setState({
          skillId: skills.id,
          // name : skills.name
        });
        return (this.state.skillId = skills.id);
      }
    });

    if (!this.state.skillId) {
      Swal.fire({
        icon: "warning",
        text: "Please select complexity level !!!",
      });
    } else {
      const params = {
        topicId: topicId,
        skillId: this.state.skillId,
      };
      console.log(params);
      this.props.testquestions(params);
      this.setState({
        isLoaded: true,
      });
    }
  };

  //redirecting to test component
  handleStart = (e) => {
    this.props.history.push({
      pathname: "/menu/test",
      state: {
        userId: this.state.userId,
        userName: this.state.userName,
        topicId: this.state.topicId,
        skillId: this.state.skillId,
        testid: this.state.testid,
        topic: this.state.name,
        skill: this.state.skill,
      },
    });
  };

  handleCancel = (e) => {
    this.props.history.push("/menu/userdashboard");
  };

  //  componentWillUnmount() {
  //    if(!this.state.testid || this.state.testid !== ""){
  //     const {user} = this.props.auth;
  //     const test = {
  //       userId : user && user.id,
  //       testId : this.state.testid
  //     }
  //     this.props.deletetest(test)
  //    }
  //   }

  changeCardColor = (id) => {

    this.setState({ opt: true })

  }

  changeCardColor1 = (id) => {

    this.setState({ opt: false })

  }


  handleCancelTest = (e) => {
    const { user } = this.props.auth;
    const test = {
      userId: user && user.id,
      testId: this.state.testid,
    };
    console.log(test);
    Swal.fire({
      title: "Are you sure you want to cancel the Test?",
      // text: 'You will not be able to recover this imaginary file!',
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((res) => {
      if (res.value) {
        this.props.deletetest(test);
        this.props.history.push("/menu/userdashboard");
      } else if (res.dismiss == "cancel") {
        console.log("cancel");
        console.log(test);
      }
      // this.props.deletetest(test)
    });
  };

  render() {
    const { selecttestdata } = this.props.selecttestdata;
    const { isLoaded } = this.state;
    const { user } = this.props.auth;
    let topicData = selecttestdata && selecttestdata.topicData;
    let complexData = selecttestdata && selecttestdata.complexData;
    const selectTest = (
      <div
        className="container cen"
        style={{ display: this.state.isSelectTest === true ? "block" : "none" }}
      >
        {/* <div>
            <center><h2><label className="cen badge badge-light align-center">Topic and skill level</label></h2></center>
        </div> */}
        <div className="row mt-5">
          <div className="col-sm-6 text-center dashboard">
            <h2>
              <label
                className="badge badge-info"
                style={{ backgroundColor: "#003399", color: "white" }}
              >
                Selected Topic
              </label>
            </h2>
            {/* <h2><label className="badge badge-light">{this.state.name}</label></h2> */}
            {topicData
              ? topicData.data.map((top, index) => {
                  return top.id === this.state.topicId ? (
                    // <div className=" text-center mt-5" key={top.id}>
                    <center key={top.id}>
                      <div className="card testcard">
                        <div className="card-body d-flex p-0 card-content">
                          <div className="align-self-center icons"
                           onMouseEnter={() =>this.changeCardColor(top.id) }
                           onMouseLeave={() =>this.changeCardColor1(top.id)}>
                            <img
                             className={this.state.opt?"cardImg2":"cardImg"}
                              src={top.image_url}
                              width="80px"
                              height="82px"
                            />
                          </div>
                          <div className="title align-self-center text-center">
                            <h5>{top.name}</h5>
                          </div>
                        </div>
                      </div>
                    </center>
                  ) : // </div>
                  null;
                })
              : null}
            {/* <select className="form-control" onChange={(e) => this.handleChange(e)} name="topic"> 
                        <option>Select Topic</option> 
                        {
                            this.state.topicData.map(topic => {                        
                                
                                return  <option key={topic.id} value={topic.name}>{topic.name}</option>
                            })
                        }
                        </select> */}
          </div>
          <div className="col-sm-6 text-center">
            {topicData && complexData && (
              <React.Fragment>
                <h2>
                  <label
                    className="badge badge-info"
                    style={{ backgroundColor: "#003399", color: "white" }}
                  >
                    Assigned Skill Level
                  </label>
                </h2>
                <select
                  className="form-control mt-4"
                  onChange={(e) => this.handleChange(e)}
                  name="skill"
                >
                  <option selected="true" disabled="disabled">
                    Select
                  </option>
                  {complexData.data.map((complex) => (
                    <option key={complex.id} value={complex.name}>
                      {complex.name}
                    </option>
                  ))}
                </select>
              </React.Fragment>
            )}
            {/* {
                      topicData && complexData &&
                      (complexData.data.length === 1 ?
                      <h2>
                        <label className="badge badge-info">Assigned Skill Level</label><br/>
                        <label className="badge badge-light mt-4" onChange={(e) => this.handleChange(e)}
                        name="skill" value={complexData.data[0].name}>{complexData.data[0].name}</label>
                        </h2>
                      :
                      <React.Fragment>
                      <h2><label className="badge badge-secondary">Skill Level</label></h2>
                      <select className="form-control mt-4"  onChange={(e) => this.handleChange(e)} name="skill">
                        <option  selected="true" disabled="disabled">Select</option>  
                            {complexData.data.map(complex=>
                              ( 
                                    <option key={complex.id} value={complex.name}>{complex.name}</option>
                                )
                            )}
                        </select >
                      </React.Fragment>)
                      // :
                      // null
                    } */}
          </div>
        </div>
        <div className="mt-5 text-center">
          <button
            type="submit"
            onClick={this.handleSelect}
            disabled={this.state.continue}
            className="btn mr-3"
            style={{ backgroundColor: "#003399", color: "white" }}
          >
            Continue
          </button>
          <button
            type="reset"
            className="btn btn-danger"
            value="clear"
            onClick={(e) => {
              this.handleCancel(e);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );

    const startTest = (
      <div
        className="container mt-5"
        style={{
          display: this.state.isSelectTest === false ? "block" : "none",
        }}
      >
        <span className="mt-5"></span>
        <div
          id="details"
          className="row bg-white shadow-sm mt-5 font-weight-bold"
        >
          <div className="col-sm-6 p-3">
            <div>
              <label className="mr-1">Name : </label>
              <span className="text-uppercase">{this.state.userName}</span>
            </div>
            <div>
              <label className="mr-1">Topic Name : </label>
              {this.state.name}
            </div>
            <div>
              <label className="mr-1">Date : </label>
              {this.state.date}
            </div>
          </div>
          <div className="col-sm-6 p-3">
            <div>
              <label className="mr-1">Test ID : </label>
              {this.state.testid}
            </div>
            <div>
              <label className="mr-1">Skill Level : </label>
              {this.state.skill}
            </div>
          </div>
          <div className="col-sm-12 p-3 text-center">
            <p className="text-light bg-dark font-weight-bold">Test rules</p>
            <p>Test will contain upto 20 questions</p>
            <p>
              Questions will be combination of single choice,multiple
              choice,Voice question and freeform question
            </p>
            <p>
              You can skip questions, which will be considered as not attempted
            </p>
            <p>You can not go back once moved to another question</p>
            <p>Results will apear instantly once the test is completed</p>
            <div className="mt-5 text-center">
              <button
                className="btn "
                onClick={(e) => this.handleStart(e)}
                type="submit"
                style={{ backgroundColor: "#003399", color: "white" }}
              >
                Start Test
              </button>
              <button
                className="btn btn-danger ml-3"
                onClick={(e) => this.handleCancelTest(e)}
                type="submit"
              >
                Cancel Test
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    if ((user && user.role === "admin") || (user && user.role === "mentor")) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div className="mt-5">
          {/* <Menu /> */}
          {isLoaded && (
            <div className="image-container">
              <p className="image-holder">
                <img src={loader} alt="" />
              </p>
            </div>
          )}

          {/* {this.state.isSelectTest && {selectTest}}

            {this.state.isStartTest && {startTest}} */}
          {topicData && complexData && selectTest}
          {startTest}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  selecttestdata: state.selecttestdata,
});

const mapDispatchToProps = {
  selecttest,
  testquestions,
  deletetest,
};

export default connect(mapStateToProps, mapDispatchToProps)(selectTest);
