import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { clearTerminal } from '../../redux/actions/productivityActions/testAction';


const PythonTerminal = ({code}) => {
  const [inputValue, setInputValue] = useState('');
  const [pos, setPos] = useState(0)
  const { productivityTest, runTerminalReducer } = useSelector((state) => state);
  const { dispatch } = useDispatch()
  const socket = runTerminalReducer.socketConnection
  
  useEffect(()=>{
    if(productivityTest.clearTerminal)
    {
      setInputValue("")
      // dispatch(clearTerminal({clear: false}))
    }
  }, [productivityTest.clearTerminal])

  const sendInput = () =>{
    console.log('send ',inputValue.substring(pos-1))
    socket.emit('input', inputValue.substring(pos-1))
  }

  const setCursor = (pos) => {
    const inputElement = document.getElementById('pythonInput')
    inputElement.focus()
    inputElement.setSelectionRange(pos-1, pos-1)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendInput()
    }
  };

  useEffect(()=>{
    if(socket){
    socket.on('output', (compilerData)=>{  
      console.log('COMPILER OUTPUT: ', compilerData)   
      setInputValue((prev)=>prev+compilerData)
      const inputElement = document.getElementById('pythonInput')
      const newPos = inputElement.value.length
      setPos(newPos)
      setCursor(newPos)
      
      // if(compilerData.trim() === '>>>'){
      //   console.log(compilerData.trim())
      //   socket.disconnect()
      // }

      
    })
  }
  }, [socket])


  return (
    <div>
      <textarea
        id="pythonInput"
        rows="40"
        cols="80"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        style={{ backgroundColor: 'black', color: 'white', fontFamily: 'monospace' }}
      />
    </div>
  );
};

export default PythonTerminal;
