import { API_URL } from "../../../Api_Request";
import {GET_CORRECT_SQL_QUERY, SQL_CODE_SUBMISSION, GET_BOILER_PLATE_CODE, PYTHON_CODE_SUBMISSION, EXECUTE_SQL, PYTHON_CODE_INI, SQL_TEST_CASE_EVALUATION_ENABLED, CLEAR_CODE_SUBMISSION_REDUCER } from "../type";
import { clearSQLException, clearSQLData } from "./testAction";

import axios from 'axios'

export const sqlTestCaseEvaluationEnabled = (id) => (dispatch)=>{
    console.log("question iD test case: ", id)
    axios.get(`${API_URL}/codeSubmission/sqlTestCaseEvaluationEnabled/${id}`)
    .then((res)=>{
        console.log("sqlTestCaseEvaluationEnabled act: ", res);
        dispatch({type: SQL_TEST_CASE_EVALUATION_ENABLED, payload: true});
    })
    .catch((err)=>{
        console.log(err);
        console.log('err1')
        // Dispatch an action with payload false to handle the error
        dispatch({type: SQL_TEST_CASE_EVALUATION_ENABLED, payload: false});
    })
}

export const executeSQLSubmission = (data) => (dispatch)=>{
    axios.post(`${API_URL}/codeSubmission/sqlCodeSubmission`, data)
    .then((res)=>{
        console.log('codeSubmission/sqlCodeSubmission', res)
        dispatch({
            type: SQL_CODE_SUBMISSION,
            payload: res.data
        })        
        dispatch(clearSQLException());
    })
    .catch((error)=>
    { 
        console.log(error.response.data)
        dispatch({
            type: SQL_CODE_SUBMISSION,
            payload: error.response.data
        }) 
          dispatch(clearSQLData());
        });
}

export const getBoilerPlateCode = (id) =>(dispatch)=>{
    axios.get
    (`${API_URL}/codeSubmission/getBoilerPlateCode/${id}`)
    .then((res)=>{
        dispatch({
            type: GET_BOILER_PLATE_CODE,
            payload: res.data.boilerPlateCode
        }) 
    })
    .catch((error)=>
    {
        console.log(error)
    })
   
}

export const executePythonSubmission = (data) => (dispatch) => {
    axios.post(`${API_URL}/codeSubmission/pythonCodeSubmission`, data)
    .then((res)=>{
        // console.log('res')
        dispatch({
            type: PYTHON_CODE_SUBMISSION,
            payload: res.data
        })        
        dispatch(clearSQLException());
    })
    .catch((error)=>
    { 
        // console.log('sda')
        console.log(error.response.data)
        dispatch({
            type: PYTHON_CODE_SUBMISSION,
            payload: error?.response?.data
        }) 
        dispatch({
            type: EXECUTE_SQL,
            payload: {
              type: "error",
              data: error?.response?.data.error,
            },
          });
          dispatch(clearSQLData());
        });
}

export const executePythonInitialEvaluation = (data) => (dispatch) => {
    axios.post(`${API_URL}/codeSubmission/pythonCodeInitialEvaluate`, data)
    .then((res)=>{
        // console.log('res')
        dispatch({
            type: PYTHON_CODE_INI,
            payload: res.data
        })        
        dispatch(clearSQLException());
    })
    .catch((error)=>
    { 
        // console.log('sda')
        console.log(error.response.data)
        dispatch({
            type: PYTHON_CODE_INI,
            payload: error?.response?.data
        }) 
        dispatch({
            type: EXECUTE_SQL,
            payload: {
              type: "error",
              data: error?.response?.data.error,
            },
          });
          dispatch(clearSQLData());
        });
}

export const clearCodeSubmissionStates = () => (dispatch) => {
    dispatch({type: CLEAR_CODE_SUBMISSION_REDUCER})
}

export const getCorrectQuery = (questionId) => (dispatch) => {
    axios.get
    (`${API_URL}/codeSubmission/getCorrectSqlQuery/${questionId}`)
    .then((res)=>{
        dispatch({
            type: GET_CORRECT_SQL_QUERY,
            payload: res.data.correctSqlQuery
        }) 
    })
    .catch((error)=>
    {
        console.log(error)
    })
}