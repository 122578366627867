import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar } from "@material-ui/core";
import AceEditor from "react-ace";
import Table from "../../reusableComponents/MaterialTable";
import ButtonField from "../../reusableComponents/ButtonField";
import { ListAlt as ListAltIcon, Search as SearchIcon, Add as AddIcon } from "@material-ui/icons";
import CustomizedDialogs from "../../reusableComponents/CustomizedDialogs";
import PythonOptions from "./pythonOptions";
import Smallbutton from "../../reusableComponents/smallButton";
import Smallbuttonclear from "../../reusableComponents/smallClearButton";
import "./python.scss"

import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-python";



const useStyles = makeStyles((theme) => ({
    tableImage: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    industryList: {
        listStyleType: "none",
    },
}));

const themes = [
    "monokai",
    "github",
    "tomorrow",
    "kuroir",
    "twilight",
    "xcode",
    "textmate",
    "solarized_dark",
    "solarized_light",
    "terminal",
  ];
  
  const styles = (theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      "z-index": 50,
    },
    codeNow: {
      backgroundColor: "#17479e",
      "&:hover": {
        backgroundColor: "#17479e",
      },
      color: " white",
      fontWeight: "bold",
      marginLeft: "10px",
    },
    editorHieght: {
      height: "550px",
    },
    customButton: {
      backgroundColor: "#17479e",
      color: " white",
      fontWeight: "bold",
      marginBottom:"15px",
      marginRight: "30px",
      "&:hover": {
        backgroundColor: "#5254ff",
      },
  },
  });
  
  themes.forEach((theme) => require(`ace-builds/src-noconflict/theme-${theme}`));

const Python = ({ }) => {
    const [theme, setTheme] = useState("monokai");
    const [open, setOpen] = useState(false);
    const [python, setPython] = useState("");
    const classes = useStyles();
    //   const { isAuthenticated } = auth;

    //   useEffect(() => {
    //     if (isAuthenticated) {
    //       getCoachList();
    //       clearCoachInfo();
    //     }
    //   }, [isAuthenticated]);

    const columns = [

        {
            title: "Scenario Name",
            field: "scenarioName",
        },
        {
            title: "Technology",
            field: "technology",
        },
        {
            title: "Domain",
            field: "domain",
        },
        {
            title: "Function",
            field: "function",
        },
        {
            title: "Scenario Type",
            field: "scenarioType",
        },
        {
            title: "Scenario Description",
            field: "scenarioDescription",
        },
    ];


    return (
        <Container maxWidth="lg" className="adminMvk">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PythonOptions />
                </Grid>
                <Grid item xs={6}>
                <div className="python-editor-banner">
                          <div>
                            <select
                              name="Theme"
                              onChange={(e)=>setTheme(e.target.value)}
                              value={theme}
                              placeholder="Theme"
                              style={{
                                color: "black",
                                "border-radius": "3px",
                                fontFamily: "monospace",
                              }}
                            >
                              {themes.map((lang) => (
                                <option key={lang} value={lang}>
                                  {lang}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div id="runButton">
                            <Smallbutton
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={""}
                            >
                              Execute
                            </Smallbutton>
                          </div>
                          <div id="clearButton">
                            <Smallbuttonclear
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={()=>setPython("")}
                            >
                              Clear
                            </Smallbuttonclear>
                          </div>
                        </div>
                    <AceEditor
                        placeholder="Enter code here.."
                        mode="javascript"
                        theme={theme}
                        name="blah2"
                        width="608px"
                        height="500px"
                        //   onLoad={this.onLoad}
                        //   onChange={this.onChange}
                        fontSize={18}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={python}
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize:1,
                        }} />

                </Grid>
                <Grid item xs={6}>

                </Grid>
            </Grid>
        </Container>
    );
};

Python.propTypes = {
    auth: PropTypes.object.isRequired,
};

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   adminCoach: state.adminCoach,
// });

// const mapDispatchToProps = { getCoachList, clearCoachInfo };

export default connect()(Python);
