import React, { Component } from "react";
import axios from "axios";
import { Redirect, Link } from "react-router-dom";
import { API_URL } from "../../Api_Request";
import Swal from "sweetalert2";
import Timer from "react-compound-timer";
import { connect } from "react-redux";
import {
  testquestions,
  predefinedtestquestions,
} from "../../redux/actions/selectTestAction";
import {
  clearAudioData,
  clearVideoData,
  clearImageData,
  clearFileData,
  uploadUserFile,
} from "../../redux/actions/uploadFileActions";
import {
  posttest,
  postInterviewTestAnswers,
} from "../../redux/actions/testAction";
import "./test.css";
import loader from ".././805.gif";
import {
  Card,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import DialogModal from "../reusableComponents/Modal/DialogModal";
import AudioRecorder from "../reusableComponents/audioRecorder";
import MediaRecorder from "../reusableComponents/MediaRecorder";
import Menu from "../users/TestMenu/TestMenu";
import FileUpload from "../reusableComponents/FileUpload";

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      userId: "",
      userName: "",
      topicId: "",
      skillId: "",
      topic: "",
      skill: "",
      typeId: 1,
      testid: "",
      data: [],
      selectedIndex: 0,
      gotData: false,
      nextButton: false,
      questionId: "",
      choiceId: "",
      uquery: "",
      itime: "",
      utime: "",
      stime: "",
      etime: "",
      mchoice: [],
      userAnswers: [],
      len: "",
      query: "",
      schoice: "",
      paperId: "",
      isOntime: false,
      openImagePopup: false,
    };
  }

  // handleNavigation() {
  //   // console.log("asdfiuhsd");
  //   const { audioData } = this.props.fileData;
  //   this.setState({
  //     etime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
  //   });
  //   const { data, selectedIndex } = this.state;
  //   let uans = {
  //     userid: this.state.userId,
  //     questionId: data[selectedIndex].id,
  //     choiceid: 0,
  //     testid: this.state.testid,
  //     itime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
  //     utime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
  //     total: this.state.len,
  //     mchoice: this.state.mchoice,
  //     questionTypeId: data[selectedIndex].questiontype_id,
  //     query: this.state.query,
  //     audio_location: audioData?.link ? audioData?.link : null,
  //     startTime: this.state.stime,
  //     endTime: this.state.etime,
  //     expiredTime: this.state.isOntime,
  //   };
  //   this.state.userAnswers.push(uans);
  //   console.log(this.state.userAnswers);
  //   this.setState({
  //     // selectedIndex: 0,
  //     nextButton: false,
  //     schoice: "",
  //     mchoice: [],
  //     query: "",
  //   });
  //   this.props.clearAudioData();
  //   return this.handleTest();
  // }

  componentDidMount() {
    // const test1=document.getElementById("test1");
    // test1.addEventListener('contextmenu', (e) => {
    //   e.preventDefault();
    // });
    // let c=0;
    // document.addEventListener("visibilitychange",onTabchange,false);

    // function onTabchange() {
    //    document.title=document.visibilityState;
    //   console.log(document.visibilityState);

    //  if(document.hidden)
    //  {

    //     if(c<3)
    //    {{
    //    Swal.fire({
    //      title: 'You have navigated out of the test page',
    //      text: 'Do you want to continue the test',
    //      icon: 'warning',
    //      color:'red',
    //      confirmButtonText: 'Yes'
    //    })
    //   }
    //   c++;
    //   console.log(c);
    //  }
    //   else
    //   {{
    //    Swal.fire({
    //      title: 'you have moved out of the test page for 3 times',
    //      text: 'test is submitted',
    //      icon: 'warning',
    //      color:'red',
    //      confirmButtonText: 'submit',
    //      // timer: 2000,
    //   })
    //   }
    //   document.removeEventListener("visibilitychange",onTabchange,false);
    //   // console.log(this.state.data)
    //   // console.log(this.state.selectedIndex)
    //   // console.log(this.state.userAnswers)
    //   };
    // }
    // }
    // console.log(this.props.location.state);
    // console.log("tests auth",auth);
    // const { user } = this.props.auth;
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      return this.props.history.push("/login");
    } else {
      if (
        this.props.location.state.paperId &&
        this.props.location.state.interview
      ) {
        const params = {
          paperId: this.props.location.state.paperId,
        };
        this.props.predefinedtestquestions(params);
        const { predefinedquestions } = this.props.selecttestdata;
        const { user } = this.props.auth;
        const uid = user && user.id;

        if (predefinedquestions) {
          this.setState({
            isLoaded: true,
            selectedIndex: 0,
            gotData: true,
            stime: new Date()
              .toISOString()
              .replace(/T/, " ")
              .replace(/\..+/, ""),
            data:
              user?.role === "Interview Admin"
                ? predefinedquestions
                    .sort(() => Math.random() - 0.5)
                    .slice(0, 14)
                : predefinedquestions,
            len:
              user?.role === "Interview Admin"
                ? 15
                : predefinedquestions.length,
            userId: this.props.location.state.interview
              ? this.props.location.state.userId
              : uid,
            userName: this.props.location.state.userName,
            testid: this.props.location.state.testid,
            topicId: this.props.location.state.topicId,
            skillId: this.props.location.state.skillId,
            topic: this.props.location.state.topic,
            skill: this.props.location.state.skill,
          });
        }
      } else if (this.props.location.state.paperId) {
        const params = {
          paperId: this.props.location.state.paperId,
        };
        this.props.predefinedtestquestions(params);
        const { predefinedquestions } = this.props.selecttestdata;
        const { user } = this.props.auth;
        const uid = user && user.id;
        if (predefinedquestions) {
          this.setState({
            isLoaded: true,
            selectedIndex: 0,
            gotData: true,
            stime: new Date()
              .toISOString()
              .replace(/T/, " ")
              .replace(/\..+/, ""),
            data: predefinedquestions,
            len: predefinedquestions.length,
            userId: uid,
            userName: this.props.location.state.userName,
            testid: this.props.location.state.testid,
            topicId: this.props.location.state.topicId,
            skillId: this.props.location.state.skillId,
            topic: this.props.location.state.topic,
            skill: this.props.location.state.skill,
          });
        }
      } else {
        const params = {
          topicId: this.props.location.state.topicId,
          skillId: this.props.location.state.skillId,
        };
        // console.log(params);
        this.props.testquestions(params);
        const { testquestions } = this.props.selecttestdata;
        const { user } = this.props.auth;
        const uid = user && user.id;
        if (testquestions) {
          this.setState({
            isLoaded: true,
            selectedIndex: 0,
            gotData: true,
            stime: new Date()
              .toISOString()
              .replace(/T/, " ")
              .replace(/\..+/, ""),
            data: testquestions,
            len: testquestions.length,
            userId: uid,
            userName: this.props.location.state.userName,
            testid: this.props.location.state.testid,
            topicId: this.props.location.state.topicId,
            skillId: this.props.location.state.skillId,
            topic: this.props.location.state.topic,
            skill: this.props.location.state.skill,
          });
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.fileData);
    const { testresult } = nextProps.testresult;
    const { audioData, videoData, userFileData } = nextProps.fileData;
    const { predefinedquestions, testquestions } = nextProps.selecttestdata;
    const { user } = this.props.auth;
    const uid = user && user.id;
    if (!this.state.isLoaded && this.state.data?.length === 0) {
      if (predefinedquestions) {
        this.setState({
          isLoaded: true,
          selectedIndex: 0,
          gotData: true,
          stime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
          data:
            user?.role === "Interview Admin"
              ? predefinedquestions.sort(() => Math.random() - 0.5).slice(0, 14)
              : predefinedquestions,
          len:
            user?.role === "Interview Admin" ? 15 : predefinedquestions.length,
          userId: this.props.location.state.interview
            ? this.props.location.state.userId
            : uid,
          userName: this.props.location.state.userName,
          testid: this.props.location.state.testid,
          topicId: this.props.location.state.topicId,
          skillId: this.props.location.state.skillId,
          topic: this.props.location.state.topic,
          skill: this.props.location.state.skill,
        });
      }
      if (testquestions) {
        this.setState({
          isLoaded: true,
          selectedIndex: 0,
          gotData: true,
          stime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
          data: testquestions,
          len: testquestions.length,
          userId: uid,
          userName: this.props.location.state.userName,
          testid: this.props.location.state.testid,
          topicId: this.props.location.state.topicId,
          skillId: this.props.location.state.skillId,
          topic: this.props.location.state.topic,
          skill: this.props.location.state.skill,
        });
      }
    }
    if (audioData) {
      this.setState({ nextButton: true });
    }
    if (videoData) {
      this.setState({ nextButton: true });
    }
    if (userFileData) {
      this.setState({ nextButton: true });
    }
    if (!testresult) {
      return null;
    } else {
      if (testresult.success === true) {
        Swal.fire({
          icon: "success",

          text: "You have successfully completed the test!!!. You can check your results on Results tab",
        });

        // document.removeEventListener('contextmenu', (e) => {
        //   e.preventDefault();
        // });

        if (this.props.location.state.interview) {
          this.props.history.push("/menu/interviewDashboard");
        } else {
          this.props.history.push("/menu/userresults");
        }
      }
    }
  }

  handleChange(e) {
     console.log(e.target.name, e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
      nextButton: true,
    });
  }

  handleMulChange = (e) => {
    let { mchoice } = this.state;

    if (e.target.checked === true) {
      mchoice.push(e.target.value);
      this.setState({
        mchoice: mchoice,
        nextButton: true,
      });
      // console.log(this.state.mchoice);
    } else {
      // console.log(mchoice);
      var index = mchoice.indexOf(e.target.value);
      if (index > -1) {
        mchoice.splice(index, 1);
      }
      this.setState({
        mchoice: mchoice,
        nextButton: true,
      });
      // console.log(this.state.mchoice);
    }
  };

  handleNext(e, choice) {
    e.preventDefault();
    // console.log("hi", this.state.userId);
    const { audioData, videoData, userFileData } = this.props.fileData;
    if (this.state.selectedIndex === this.state.data.length - 1) {
      this.state.etime = new Date()
        .toISOString()
        .replace(/T/, " ")
        .replace(/\..+/, "");

      if (this.state.selectedIndex === this.state.data.length - 1) {
        this.setState({
          etime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
        });
        let uans = {
          userid: this.state.userId,
          questionId: choice.id,
          choiceid: this.state.schoice,
          testid: this.state.testid,
          itime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
          utime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
          total: this.state.len,
          mchoice: this.state.mchoice,
          questionTypeId: choice.questiontype_id,
          query: this.state.query,
          startTime: this.state.stime,
          endTime: this.state.etime,
          expiredTime: this.state.isOntime,
          audio_location: audioData?.link ? audioData?.link : null,
          video_location: videoData?.link ? videoData?.link : null,
          file_location: userFileData?.link ? userFileData?.link : null,
        };
        this.state.userAnswers.push(uans);
        this.setState({
          // selectedIndex: 0,
          nextButton: false,
          schoice: "",
          mchoice: [],
          query: "",
        });
        this.props.clearAudioData();
        this.props.clearFileData();
        return this.handleTest();
      } else {
        this.props.clearAudioData();
        this.props.clearFileData();
        return;
      }
    }

    if (this.state.selectedIndex > this.state.data.length - 1) {
      alert("test complete");

      if (document.hidden) {
        alert("user leaving");
      }
    }

    let uans = {
      userid: this.state.userId,
      questionId: choice.id,
      choiceid: this.state.schoice,
      testid: this.state.testid,
      itime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
      utime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
      total: this.state.len,
      mchoice: this.state.mchoice,
      questionTypeId: choice.questiontype_id,
      query: this.state.query,
      audio_location: audioData?.link ? audioData?.link : null,
      video_location: videoData?.link ? videoData?.link : null,
      file_location: userFileData?.link ? userFileData?.link : null,
      startTime: this.state.stime,
      endTime: this.state.etime,
      expiredTime: this.state.isOntime,
    };

    this.state.userAnswers.push(uans);
    console.log(this.state.userAnswers);

    this.setState((prevState) => ({
      selectedIndex: prevState.selectedIndex + 1,
      nextButton: false,
      schoice: "",
      mchoice: [],
      query: "",
    }));
    this.props.clearAudioData();
    this.props.clearImageData();
    this.props.clearVideoData();
    this.props.clearFileData();
  }

  handleSkip(e, choice) {
    e.preventDefault();
    const { audioData, videoData, userFileData } = this.props.fileData;
    this.state.mchoice.push("0");
    if (this.state.selectedIndex === this.state.data.length - 1) {
      this.state.etime = new Date()
        .toISOString()
        .replace(/T/, " ")
        .replace(/\..+/, "");

      if (this.state.selectedIndex === this.state.data.length - 1) {
        this.setState({
          etime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
        });
        // console.log("ld", this.state.etime);
        let uans = {
          userid: this.state.userId,
          questionId: choice.id,
          choiceid: 0,
          testid: this.state.testid,
          itime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
          utime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
          total: this.state.len,
          mchoice: this.state.mchoice,
          questionTypeId: choice.questiontype_id,
          query: this.state.query,
          audio_location: audioData?.link ? audioData?.link : null,
          video_location: videoData?.link ? videoData?.link : null,
          file_location: userFileData?.link ? userFileData?.link : null,
          startTime: this.state.stime,
          endTime: this.state.etime,
          expiredTime: this.state.isOntime,
        };
        this.state.userAnswers.push(uans);
        // console.log(this.state.userAnswers);
        this.setState({
          // selectedIndex: 0,
          nextButton: false,
          schoice: "",
          mchoice: [],
          query: "",
        });
        this.props.clearAudioData();
        this.props.clearImageData();
        this.props.clearVideoData();
        this.props.clearFileData();
        return this.handleTest();
      } else {
        this.props.clearAudioData();
        this.props.clearImageData();
        this.props.clearVideoData();
        this.props.clearFileData();
        return;
      }
    }

    if (this.state.selectedIndex > this.state.data.length - 1) {
      alert("test complete");
    }

    let uans = {
      userid: this.state.userId,
      questionId: choice.id,
      choiceid: 0,
      testid: this.state.testid,
      itime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
      utime: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
      total: this.state.len,
      mchoice: this.state.mchoice,
      questionTypeId: choice.questiontype_id,
      query: this.state.query,
      audio_location: audioData?.link ? audioData?.link : null,
      video_location: videoData?.link ? videoData?.link : null,
      file_location: userFileData?.link ? userFileData?.link : null,
      startTime: this.state.stime,
      endTime: this.state.etime,
      expiredTime: this.state.isOntime,
    };

    this.state.userAnswers.push(uans);

    this.setState((prevState) => ({
      selectedIndex: prevState.selectedIndex + 1,
      nextButton: false,
      schoice: "",
      mchoice: [],
      query: "",
    }));
  }

  handleTest = () => {
    const { userAnswers } = this.state;
    if (this.props.location.state.interview) {
      this.props.postInterviewTestAnswers(userAnswers);
    } else {
    this.props.posttest(userAnswers);
    }
    this.setState({
      isLoaded: false,
    });
  };

  setopenImagePopup = (data) => {
    this.setState({
      openImagePopup: data,
    });
  };

  handleUploadFile = (e) => {
    // console.log("file upload");
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    this.props.uploadUserFile(formData);
  };

  componentWillUnmount() {
    this.props.history.goForward();
  }

  render() {
    const { data, selectedIndex, len, openImagePopup } = this.state;
    const { uploadFileProcess, fileData, videoData, userFileData } =
      this.props.fileData;

    const Test = (
      <div className="container mt-4">
        <Menu ibridge={false} />
        <DialogModal
          title="Image"
          openPopup={openImagePopup}
          setOpenPopup={() => this.setopenImagePopup()}
        >
          <div
            style={{ width: "1000px", height: "100vh", marginBottom: "-10px" }}
          >
            <Card>
              <CardMedia
                component="img"
                alt="questionImage"
                // height="140"
                image={
                  data[selectedIndex]?.image_location
                    ? data[selectedIndex]?.image_location
                    : ""
                }
              />
            </Card>
          </div>
        </DialogModal>
        {this.state.gotData && (
          <div className="row">
            <div
              className="col-sm-12 row testbox"
              style={{ marginLeft: "0px", marginBottom: "-10px" }}
            >
              {/* Index */}
              <div className="font-weight-bold col-sm-4 text-left  qno">
                {`${selectedIndex + 1}/${len}`}
              </div>
              {/* Timer */}
              <div className="font-weight-bold col-sm-4 text-center text-info">
                <div className="timercolor">
                  <i class="far fa-clock mr-2"> </i>

                  <Timer
                    checkpoints={[
                      {
                        time: 1200000,
                        callback: () => {
                          Swal.fire({
                            icon: "success",
                            text: "Allocated time has been completed. You can still continue and submit the test.",
                            timer: 5000,
                            // showConfirmButton: false,
                          });
                          this.setState({
                            isOntime: true,
                          });
                        },
                      },
                    ]}
                  >
                    <Timer.Hours /> : <Timer.Minutes /> : <Timer.Seconds />
                  </Timer>
                </div>
              </div>
              {/* {type} */}
              {data[selectedIndex].questiontype_id === 1 ? (
                <div className="font-weight-bold col-sm-4 text-right  qno">
                  Single Choice Question
                </div>
              ) : data[selectedIndex].questiontype_id === 2 ? (
                <div className="font-weight-bold col-sm-4  qno">
                  Multiple Choice Question
                </div>
              ) : data[selectedIndex].questiontype_id === 3 ? (
                <div className="font-weight-bold col-sm-4  qno">
                  Free Text Question
                </div>
              ) : data[selectedIndex].questiontype_id === 4 ? (
                <div className="font-weight-bold col-sm-4  qno">
                  Voice Type Question
                </div>
              ) :data[selectedIndex].questiontype_id === 6 ? (
                <div className="font-weight-bold col-sm-4  qno">
                  Boolean Type Question
                </div>
              ) : (
                <div className="font-weight-bold col-sm-4  qno">
                  Video Type Question
                </div>
              )}
              {/* <div className="font-weight-bold col-sm-4 text-center text-info">
                {`${selectedIndex + 1}/${len}`}
              </div> */}
              {/* <div className="font-weight-bold col-sm-4 text-right text-info">
                <Timer initialTime={0}>
                  <Timer.Hours /> : <Timer.Minutes /> : <Timer.Seconds />
                </Timer>
              </div> */}
            </div>
            <form
              className="col-sm-12 mt-4 testbox testboxheight"
              onSubmit={(e) => this.handleNext(e, data[selectedIndex])}
            >
              <div className="row">
                <div
                  className="testboxheight col-sm-6 mt-4 test font-weight-bold leftalign"
                  name="question"
                  value={data[selectedIndex].description}
                  disabled
                >
                  Question {selectedIndex + 1}: <br />
                  <br />
                  <div className="textselect">
                    <div>{data[selectedIndex].description}</div>
                  </div>
                  {(data[selectedIndex].questiontype_id === 3 ||
                    data[selectedIndex].questiontype_id === 4 ||
                    data[selectedIndex].questiontype_id === 5 ||
                    data[selectedIndex].questiontype_id === 7) &&
                  data[selectedIndex].image_location ? (
                    <Card
                      sx={{ maxWidth: 345 }}
                      onClick={() => this.setopenImagePopup(true)}
                    >
                      <CardMedia
                        component="img"
                        alt="questionImage"
                        height="140"
                        image={data[selectedIndex].image_location}
                      />
                    </Card>
                  ) : null}
                  {(data[selectedIndex].questiontype_id === 4 ||
                    data[selectedIndex].questiontype_id === 5) &&
                  data[selectedIndex].audio_location ? (
                    <div>
                      <audio
                        style={{ marginTop: "5px" }}
                        src={data[selectedIndex].audio_location}
                        controls
                      />
                    </div>
                  ) : null}
                  {data[selectedIndex].questiontype_id === 5 &&
                  data[selectedIndex].video_location ? (
                    <div>
                      <video
                        style={{ width: "50%" }}
                        src={data[selectedIndex].video_location}
                        controls
                      />
                    </div>
                  ) : null}
                </div>
                <div className="col-sm-6 test  testboxheight pt-5 font-weight-bold">
                  {data[selectedIndex].choices.map(
                    (ch, index, arr) =>
                      data[selectedIndex].questiontype_id === 1 ? (
                        <div className="form-check" key={ch.choice_id}>
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              onChange={(e) => this.handleChange(e)}
                              value={ch.choice_id}
                              name="schoice"
                            />
                            {ch.choice}
                          </label>
                        </div>
                      ) : data[selectedIndex].questiontype_id === 2 ? (
                        <div className="form-check" key={ch.choice_id}>
                          <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              value={ch.choice_id}
                              onChange={(e) => this.handleMulChange(e)}
                              name={`choice${index + 1}`}
                            />
                            {ch.choice}
                          </label>
                        </div>
                      ) :
                      data[selectedIndex].questiontype_id === 6 ? (
                        <div className="form-check" key={ch.choice_id}>
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              onChange={(e) => this.handleChange(e)}
                              value={ch.choice_id}
                              name="schoice"
                            />    
                            {ch.choice}
                          </label>
                        </div> ): null
                    // (<div key={ch.choice_id}>{ch.choice}</div>)
                  )}
                  {data[selectedIndex].questiontype_id === 3 ? (
                    <div>
                      <textarea
                        className="form-control col-sm-12"
                        name="query"
                        rows="3"
                        cols="100"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.query}
                        placeholder="Enter Answer"
                      />
                    </div>
                  ) : null}
                  {data[selectedIndex].questiontype_id === 4 ? (
                    <div>
                      {uploadFileProcess ? (
                        <Grid sx={{ maxWidth: 345 }} align="center">
                          <CircularProgress />
                        </Grid>
                      ) : (
                        <AudioRecorder />
                      )}
                      {fileData && fileData?.audioData && (
                        <audio src={fileData.audioData.link} controls />
                      )}

                      <textarea
                        className="form-control col-sm-12"
                        name="query"
                        rows="3"
                        cols="100"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.query}
                        placeholder="Enter comments"
                      />
                    </div>
                  ) : null}
                  {data[selectedIndex].questiontype_id === 5 ? (
                    <div>
                      {uploadFileProcess ? (
                        <Grid sx={{ maxWidth: 345 }} align="center">
                          <CircularProgress />
                        </Grid>
                      ) : (
                        <MediaRecorder />
                      )}
                      {videoData && (
                        <video
                          style={{ width: "50%" }}
                          src={videoData?.link}
                          controls
                        />
                      )}

                      <textarea
                        className="form-control col-sm-12"
                        name="query"
                        rows="3"
                        cols="100"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.query}
                        placeholder="Enter comments"
                      />
                    </div>
                  ) : null}

             
                  {data[selectedIndex].questiontype_id === 7 ? (
                    <div>
                      {uploadFileProcess ? (
                        <Grid sx={{ maxWidth: 345 }} align="center">
                          <CircularProgress />
                        </Grid>
                      ) : (
                        <FileUpload
                          onChange={(e) => this.handleUploadFile(e)}
                          changeLabel={"Upload File"}
                        />
                      )}
                      {userFileData?.link ? (
                        <Typography variant="h5" color={"red"}>
                          File Uploaded Successfully
                        </Typography>
                      ) : null}
                      <textarea
                        className="form-control col-sm-12"
                        name="query"
                        rows="3"
                        cols="100"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.query}
                        placeholder="Enter comments"
                      />
                    </div>
                  ) : null}
                </div>
                <div className="col-sm-6 leftalign"></div>
                <div className="col-sm-6">
                  <span className="col-sm-6">
                    <button
                      className="btn  float-left buttonsize"
                      disabled={this.state.nextButton === true}
                      onClick={(e) => this.handleSkip(e, data[selectedIndex])}
                      type="button"
                      style={{ backgroundColor: "#003399", color: "white" }}
                    >
                      Skip
                    </button>
                  </span>
                  <span className="col-sm-6">
                    <button
                      className="btn float-right buttonsize"
                      disabled={this.state.nextButton !== true}
                      onClick={(e) => this.handleNext(e, data[selectedIndex])}
                      type="submit"
                      style={{ backgroundColor: "#003399", color: "white" }}
                    >
                      Next
                    </button>
                  </span>
                </div>
                {/* <div className="leftalign">
    
    <button class="btn btn-primary float-left mt-5 mr-2 buttonsize" type="button">Skip</button>
<button class="btn btn-primary
               mt-5 buttonsize" disabled="" type="submit">Next</button>
</div> */}
              </div>
            </form>
          </div>
        )}
      </div>
    );

    return (
      <div className="container mt-6 mb-5">
        <div
          className="image-container"
          style={{ display: this.state.isLoaded === false ? "block" : "none" }}
        >
          <p className="image-holder">
            <img src={loader} alt="" />
          </p>
        </div>
        <div className="container">
          {/* <div id="details" className="row bg-white mt-6 testbox">
            <div className="col-sm-6 p-3 font-weight-bold">
              <div>
                <label className="mr-1">Name : </label>
                <span className="text-uppercase">
                  {this.state.userName.toUpperCase()}
                </span>
              </div>
              <div>
                <label className="mr-1">Topic Name : </label>
                {this.state.topic}
              </div>
            </div>
            <div className="col-sm-6 p-3 font-weight-bold">
              <div>
                <label className="mr-1">Test ID : </label>
                {this.state.testid}
              </div>
              <div>
                <label className="mr-1">Skill Level : </label>
                {this.state.skill}
              </div>
            </div>
          </div> */}
          {Test}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  selecttestdata: state.selecttestdata,
  testresult: state.testresult,
  fileData: state.fileData,
});

const mapDispatchToProps = {
  testquestions,
  posttest,
  postInterviewTestAnswers,
  predefinedtestquestions,
  clearAudioData,
  clearVideoData,
  clearImageData,
  uploadUserFile,
  clearFileData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Test);
