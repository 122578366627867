import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTestQuestions,
  executeJavaScript,
  clearSQLData,
  clearSQLException,
  completeTest,
} from "../../redux/actions/productivityActions/testAction";
import { clearHintData } from "../../redux/actions/productivityActions/openAIActions";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, Card, CardContent } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AceEditor from "react-ace";
import { useParams } from "react-router-dom";
import ProductivityResultTabs from "./ProductivityResultTabs";
import Smallbutton from "../reusableComponents/smallButton";
import Smallbuttonclear from "../reusableComponents/smallClearButton";
import SelectSingleValues from "../reusableComponents/SelectSingleValues";
import SplitView from "../reusableComponents/SplitView";
import ProductivitySchema from "./ProductivitySchema";
import Swal from "sweetalert2";
import Paper from "@mui/material/Paper";

import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/python";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const themes = [
  "monokai",
  "github",
  "tomorrow",
  "kuroir",
  "twilight",
  "xcode",
  "textmate",
  "solarized_dark",
  "solarized_light",
  "terminal",
];

const modes = ["javascript", "java", "python", "mysql"];

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
    "z-index": 50,
  },
  codeNow: {
    backgroundColor: "#17479e",
    "&:hover": {
      backgroundColor: "#17479e",
    },
    color: " white",
    // fontWeight: "bold",
    marginLeft: "10px",
  },
  editorHieght: {
    height: "550px",
  },
  customButton: {
    backgroundColor: "#17479e",
    color: " white",
    fontWeight: "bold",
    marginBottom: "15px",
    marginRight: "30px",
    "&:hover": {
      backgroundColor: "#5254ff",
    },
  },
});

themes.forEach((theme) => require(`ace-builds/src-noconflict/theme-${theme}`));

const JavaScriptCoding = ({ history }) => {
  const { testId } = useParams();
  const { productivityTest, auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [theme, setTheme] = useState("monokai");
  const [mode, setMode] = useState("javascript");
  const [open, setOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [database, setDatabases] = useState(null);
  const [questionList, setQuestionList] = useState([]);
  const [editorData, setEditorData] = useState("");
  const classes = useStyles();
  const { isAuthenticated, user } = auth;
  const [value, setValue] = React.useState(0);
  const [codeSection, setCodeSection] = useState(false);
  const [btndisabled, setBtndisabled] = useState(true);
  const [finishbtn, setFinishbtn] = useState(true);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [edtbtn, setEdtbtn] = useState(false);

  useEffect(()=>{
    dispatch(clearHintData());
  }, [])

  useEffect(() => {
    setEdtbtn(false);
  }, [editorData]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getTestQuestions(testId));
    }
    dispatch(clearSQLData());
    dispatch(clearSQLException());
    localStorage.removeItem("executedCodes");
  }, [isAuthenticated]);

  useEffect(() => {
    if (productivityTest?.testQuestions?.completed) {
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 5000,
      });
      Toast.fire({
        icon: "success",
        title:
          "Code session is over. You can create a new session by selecting domain in Dashboard",
      }).then(() => {
        history.push(`/menu/userdashboard`);
      });
    }
    if (productivityTest?.testQuestions) {
      let mappedData = productivityTest?.testQuestions?.questionsData?.map(
        (item) => {
          item.name = item?.questionDescription;
          return item;
        }
      );
      if (mappedData?.length > 0) {
        setQuestion(mappedData[0]);
        setQuestionNumber(1);
        setBtndisabled(false);
        dispatch(clearSQLData());
        dispatch(clearSQLException());
      }
      setCodeSection(true);
      setQuestionList(mappedData);
    }
  }, [productivityTest?.testQuestions]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditor = (e) => {
    setEditorData(e);
  };

  const handleQuestion = (questionData) => {
    const index = questionList.findIndex(
      (item) => item._id === questionData?._id
    );
    setQuestionNumber(index + 1);
    setQuestion(questionData);
    setBtndisabled(false);
    dispatch(clearSQLData());
    dispatch(clearSQLException());
    let executedCodes = JSON.parse(localStorage.getItem("executedCodes")) || [];
    let enteredCode = executedCodes?.find(
      (item) => item?.questionId == questionData?._id
    );
    setEditorData(enteredCode?.query || "");
  };

  const handleExecute = (e) => {
    const data = {
      domain: productivityTest?.testQuestions?.domain?._id,
      functionId: productivityTest?.testQuestions?.function?._id,
      scenarioId: productivityTest?.testQuestions?.scenario?._id,
      question: question?._id,
      query: editorData,
      testId: testId,
    };
    let executedCodes = JSON.parse(localStorage.getItem("executedCodes")) || [];
    let removeSelectedQuestionData = executedCodes?.filter(
      (item) => item?.questionId?.toString() != question?._id?.toString()
    );

    removeSelectedQuestionData = [
      ...removeSelectedQuestionData,
      {
        questionId: question?._id,
        query: editorData,
      },
    ];
    localStorage.setItem(
      "executedCodes",
      JSON.stringify(removeSelectedQuestionData)
    );
    dispatch(executeJavaScript(data));
    setFinishbtn(false);
    console.log(data);
    setEdtbtn(true);
  };

  const handleCompleteTest = () => {
    dispatch(completeTest(testId, history));
  };

  return (
    <>
      {/* <Container maxWidth="lg" className="adminMvk"> */}
      <Paper elevation={3} style={{ paddingBottom: "20px" }}>
        <Grid container spacing={2} style={{ marginTop: "5px" }}>
          <Grid item xs={12} md={3} align="center">
            <Typography color="primary" variant="h5" fontWeight={500}>
              Domain: {productivityTest?.testQuestions?.domain?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} align="center">
            <Typography color="primary" variant="h5" fontWeight={500}>
              Function: {productivityTest?.testQuestions?.function?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} align="center">
            <Typography color="primary" variant="h5" fontWeight={500}>
              Scenario: {productivityTest?.testQuestions?.scenario?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} style={{ marginLeft: "10px" }}>
            {" "}
            <SelectSingleValues
              label="Select Question"
              name="question"
              values={questionList || []}
              onSelect={(questionData) => handleQuestion(questionData)}
              selected={question}
              multiline1={true}
              // errors={
              //   formik.touched.selectDomain && formik.errors.selectDomain
              //     ? formik.errors.selectDomain
              //     : null
              // }
            />
          </Grid>
          <Grid item xs={12} md={1} >
            {codeSection ? (
              <button
                className="btn btn-primary"
                onClick={() => handleCompleteTest()}
                disabled={finishbtn}
              >
                Finish Coding
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => setCodeSection(true)}
                disabled={btndisabled}
              >
                Start Coding
              </button>
            )}
          </Grid>
        </Grid>
      </Paper>
      {codeSection ? (
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            TabIndicatorProps={{
              style: { background: "#2a52be", height: "3px" },
            }}
          >
            <Tab
              label={`Code Editor --- Selected Question: ${questionNumber}/${
                questionList?.length || 0
              }`}
              {...a11yProps(0)}
            />
            {productivityTest?.testQuestions?.scenario?.scenarioImage?.link ? (
              <Tab label="Scenario Image" {...a11yProps(1)} />
            ) : null}
            {/* <Tab label="Database Model" {...a11yProps(2)} /> */}
          </Tabs>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Grid container spacing={2}>
              <SplitView>
                <Grid item xs={12}>
                  <div className="python-editor-banner">
                    <div>
                      <select
                        name="Theme"
                        onChange={(e) => setTheme(e.target.value)}
                        value={theme}
                        placeholder="Theme"
                        style={{
                          color: "black",
                          borderRadius: "3px",
                          fontFamily: "monospace",
                        }}
                      >
                        {themes.map((lang) => (
                          <option key={lang} value={lang}>
                            {lang}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                      <select
                        name="Mode"
                        onChange={(e) => setMode(e.target.value)}
                        value={mode}
                        placeholder="Mode"
                        style={{
                          color: "black",
                          borderRadius: "3px",
                          fontFamily: "monospace",
                        }}
                      >
                        {modes.map((lang) => (
                          <option key={lang} value={lang}>
                            {lang}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div id="runButton">
                      <Smallbutton
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleExecute()}
                        disabled={edtbtn}
                      >
                        Execute
                      </Smallbutton>
                    </div>
                    <div id="clearButton">
                      <Smallbuttonclear
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => setEditorData("")}
                      >
                        Clear
                      </Smallbuttonclear>
                    </div>
                  </div>
                  <AceEditor
                    placeholder="Code here..."
                    mode={mode}
                    theme={theme}
                    name="blah2"
                    // onLoad={this.onLoad}
                    onChange={(e) => handleEditor(e)}
                    fontSize={14}
                    showPrintMargin={true}
                    width="100%"
                    height="95vh"
                    showGutter={true}
                    highlightActiveLine={true}
                    value={editorData}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: true,
                      showLineNumbers: true,
                      tabSize: 2,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ProductivityResultTabs
                    output={
                      productivityTest?.sqlData ? productivityTest?.sqlData : ""
                    }
                    exception={
                      productivityTest?.sqlException
                        ? productivityTest?.sqlException
                        : ""
                    }
                  />
                </Grid>
              </SplitView>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {productivityTest?.testQuestions?.scenario?.scenarioImage?.link && (
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid xs={12} md={12} align="center">
                      <img
                        src={
                          productivityTest?.testQuestions?.scenario
                            ?.scenarioImage?.link
                        }
                        style={{ width: "40%" }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </TabPanel>
        </>
      ) : null}
      {/* <TabPanel value={value} index={2} dir={theme.direction}>
        <ProductivitySchema schemaList={productivityTest?.sqlSchema} />
      </TabPanel> */}
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
};

export default JavaScriptCoding;
