import React, {useState, useEffect} from 'react';
import {Grid, Box, Button, TextField, Typography, Paper} from '@material-ui/core'
import { fetchOverallData, fetchClusterData, fetchTopicWiseData, loadingCapabilityApplication } from '../../redux/actions/capabilityAnalysisApplicationAction';
import ClusterRepresentationTable from './clusterRepresentationTable';
import { useSelector, useDispatch } from "react-redux";
import SelectSingleValues from "../reusableComponents/SelectSingleValues";
import axios from 'axios';
import Swal from "sweetalert2";
import { API_URL } from "../../Api_Request"

// const topics = [
    // { name: 'MS-SQL' }, { name: 'Oracle SQL' }, { name: 'DW Concepts' }, { name: 'Business 101' },
    // { name: 'Microsoft Excel' }, { name: 'T-SQL' }, { name: 'Tableau' }, { name: 'Php' },
    // { name: 'ReactJS' }, { name: 'JavaScript' }, { name: 'Unix/Linux' }, { name: 'PL-SQL' },
    // { name: 'RDBMS' }, { name: 'SSRS' }, { name: 'Cognos' }, { name: 'POWER BI' },
    // { name: 'SSAS Tabular Model' }, { name: 'JAVA' }, { name: 'Developer Testing' },
    // { name: 'Python' }, { name: 'Pandas' }, { name: 'Informatica' }, { name: 'HTML' },
    // { name: 'Cascading Style Sheet' }, { name: 'Human Resources Recruitment' },
    // { name: 'Retail Domain' }, { name: 'Android' }, { name: 'SSIS' },
    // { name: 'Computer Fundamental' }, { name: 'Devops' }, { name: '.NET' },
    // { name: 'MYSQL' }, { name: 'Quality Assurance' }, { name: 'Wealth Management' },
    // { name: 'Git and Github' }, { name: 'US HealthCare Basics' }, { name: 'Payer' },
    // { name: 'Snowflake' }, { name: 'IOS' }, { name: 'MongoDB' }, { name: 'Angular' },
    // { name: 'testing 1' }, { name: 'Technology 101' }, { name: 'COMMUNICATION' }
  // ];
// let topics
// let sortedTopics

const Cluster = () => {
    
    const [userEmail, setUserEmail] = useState('');
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [topics, setTopics] = useState(null);
    const [sortedTopics, setSortedTopics] = useState(null);
    const isEmailValid = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    const { capabilityAnalysisApplicationReducer } = useSelector(
      (state) => state
    );
    const dispatch = useDispatch();
  
    const fetchClusterDetails = () => {
      dispatch(loadingCapabilityApplication());
      dispatch(fetchClusterData({"email":userEmail,"topic": selectedTopic.name}));
 
    }

    // useEffect(()=>{
    //   console.log('useEffect topic')
    //   if(topics){
    //     sortedTopics = topics.slice().sort((a,b)=>{
    //       return a.name.localeCompare(b.name);
    //     })
    //   }
    // },[topics])

    const fetchTopics = async () => {
      await axios.get(`${API_URL}/capabilityAnalysisApplication/topicList`, {params: {email: userEmail} })
      .then((res)=>{
        const temp = res.data.distinctTopics
        const sortedTopicList = temp.slice().sort((a,b)=>{
          return a.name.localeCompare(b.name);
        });
        setSortedTopics(sortedTopicList);
      })
      .catch((err)=>{
        console.log(err);
        Swal.fire({
          icon: "error",
          text: "Email not found in database",
        });
      })
    }
  
    return(
    <Grid container spacing={1}>
        <Grid item xs={12}
        style={{
           marginLeft: 350,
           marginBottom: 40
          }}
        >
        <Typography variant='h6' >
          Capability Analysis
        </Typography>
        </Grid>
            <Grid item xs={12}>
            <Box
                style={{
                  display: "flex", 
                  justifyContent: "space-between",
                  width: "45%"
              }}
                >
                <Box>
                  <Box>
                <TextField
                style={{
                  width: 200,
                }}
                  label="Enter User's Email"
                  type="email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  error={!isEmailValid(userEmail) && userEmail}
                  helperText={userEmail && !isEmailValid(userEmail)}
                />
                  </Box>
                  <Box>
                <Button
                variant='outlined'
                style={{
                  width: "150px",
                  textTransform: 'none',
                  marginTop: 8
                }}
                onClick={fetchTopics}
                disabled={userEmail.length == 0} 
                >
                  Fetch Topics
                </Button>
                  </Box>
                </Box>
                {sortedTopics &&
                <Box>
                <SelectSingleValues
                  style={{
                    width: 200,
                  }}
                  placeholder="Select a topic"
                  name="topic"
                  values={sortedTopics} 
                  onSelect={(topic) => setSelectedTopic(topic)}
                  selected={selectedTopic}
                />
                </Box>}

              </Box>
              <Grid item xs = {12}>
          <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 40
          }}
          >
                <Button 
                    variant='outlined'
                    style={{
                        width: "150px",
                        textTransform: "none",                      
                      }}
                    onClick={fetchClusterDetails}
                    disabled={!(userEmail && selectedTopic) || capabilityAnalysisApplicationReducer.loading}
                >
                    Analyse
                </Button>
                </Box>
            </Grid>

          </Grid>
          {capabilityAnalysisApplicationReducer.clusterData? (
          <Grid item xs={12}>
            
            <Box
            style={{
              display: 'flex',
              height: 500
            }}>
              <Paper
              style={{flex: 1, height: 400}}>
                <Box style={{padding: 20}}>
                <Typography>
              Name: {capabilityAnalysisApplicationReducer.clusterData.Name}
            </Typography>
                <Typography>
                Tests Attempted: {capabilityAnalysisApplicationReducer.clusterData["Tests attempted"]}
                </Typography>
                <Typography>
                Percentage: {capabilityAnalysisApplicationReducer.clusterData["Percentage"]}
                </Typography>
                <Typography>
                Percentile: {capabilityAnalysisApplicationReducer.clusterData["Percentile"]}
                </Typography>
                </Box>
              </Paper>
              <Paper style={{
                flex: 2,
                height: 400,
                width: 200,
                overflow: 'hidden',
                overflowY: 'scroll',
                padding: 40
                }}>
                  <Typography style={{display: 'block', marginBottom: 8}}>Candidates with similar capability</Typography>
              <ClusterRepresentationTable clusterData={capabilityAnalysisApplicationReducer.clusterData['Candidates with similar capability']}/>
              </Paper>
            </Box>
          </Grid>
          )
          :
          capabilityAnalysisApplicationReducer.errorMessage &&  (
            <Grid item xs={12}
            >
              <Box>
                <Typography variant='h5' color='error'>
                  {capabilityAnalysisApplicationReducer.errorMessage}
                </Typography>
            </Box>
        </Grid>
          )
        }
  </Grid>
                    
    )
}

export default Cluster;