import {
  GET_ALL_CUSTOMERS,
  GET_ALL_USER_SUBGROUPS,
  ADD_CUSTOMER,
  GET_GROUP_USERS,
} from "./type";
import axios from "axios";
import { API_URL } from "../../Api_Request";
import Swal from "sweetalert2";

//Get Customer List
export const getCustomerList = () => (dispatch) => {
  axios
    .get("/api/customers")
    .then((res) => {
      dispatch({
        type: GET_ALL_CUSTOMERS,
        payload: res.data && res.data.data,
      });
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

//Get User Sub group List
export const getUserSubGroupList = () => (dispatch) => {
  axios
    .get(`/api/customers/usergroup`)
    .then((res) => {
      dispatch({
        type: GET_ALL_USER_SUBGROUPS,
        payload: res.data && res.data.data,
      });
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

// Add Customer
export const addCustomer = (data, history) => (dispatch) => {
  axios
    .post("/api/customers/addcustomer", data)
    .then((res) => {
      const { message } = res.data;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });

      Toast.fire({
        icon: "success",
        title: "Customer Added successfully",
      });
      history.push("/admin/customerlist");
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

// Add User Sub Group
export const addSubGroup = (data, history) => (dispatch) => {
  axios
    .post("/api/customers/addsubgroup", data)
    .then((res) => {
      const { message } = res.data;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });

      Toast.fire({
        icon: "success",
        title: "Sub Group Added successfully",
      });
      history.push("/admin/usergroups/0");
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

//Get Users from Particular Sub group
export const getGroupUsers = (id) => (dispatch) => {
  axios
    .get(`/api/customers/groupusers/${id}`)
    .then((res) => {
      dispatch({
        type: GET_GROUP_USERS,
        payload: res.data && res.data.data,
      });
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};
