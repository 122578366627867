import { POSTTEST, DELETETEST, QUESTIONSNULL } from "./type";
import axios from "axios";
import { API_URL } from "../../Api_Request";
// import Swal from 'sweetalert2';

export const posttest = (params) => (dispatch) => {
  //  console.log(params)
  axios
    .post(`${API_URL}/testquestions`, params)
    .then((res) => {
      // console.log("result",res.data)
      // Swal.fire(
      //   {icon: 'success',
      //   text: 'You have successfully completed the test!!!. You can check your results on Results tab'}
      // )
      // this.props.history.push("/menu/userresults")
      dispatch({
        type: POSTTEST,
        payload: { success: true },
      });
      dispatch({
        type: QUESTIONSNULL,
        payload: null,
      });
    })
    .catch((err) => console.log(err));
};

export const postInterviewTestAnswers = (params) => (dispatch) => {
  // console.log(params)
  axios
    .post(`${API_URL}/testquestions/postInterviewTestAnswers`, params)
    .then((res) => {
      dispatch({
        type: POSTTEST,
        payload: { success: true },
      });
      dispatch({
        type: QUESTIONSNULL,
        payload: null,
      });
    })
    .catch((err) => console.log(err));
};

export const deletetest = (params) => (dispatch) => {
  console.log(params);
  axios
    .post(`${API_URL}/tests/delete`, params)
    .then((res) => {
      dispatch({
        type: DELETETEST,
        payload: { success: true },
      });
      // this.props.history.push("/menu/userdashboard")
    })
    .catch((err) => console.log(err));
};
