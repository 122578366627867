import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTrueTechnologyList } from "../../../redux/actions/productivityActions/domainAction";
import CustomizedDialogs from "../../reusableComponents/CustomizedDialogs";
import SelectProductivityTest from "./SelectProductivityTest";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import "./ProductivityUserDashboard.css";

const ProductivityDashboard = ({ history }) => {
  const { domainReducer } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [technologyId, settechnologyId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTrueTechnologyList());
  }, []);

  const handleClick = (e, technology) => {
    setOpen(true);
    console.log(technology);
    settechnologyId(technology?._id);
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ paddingLeft: "10px", paddingRight: "-20px" }}
      >
        {domainReducer?.getTrueTechnologyList?.map((item) => {
          return (
            <Grid key={item?._id} item xs={12} md={4}>
              {console.log(item.name)}
              {/* <div > */}
              <Card className="prodcard" onClick={(e) => handleClick(e, item)}>
                <CardContent className="text-center">
                  <img
                    className={"cardImg3"}
                    src={
                    item.name==="RDBMS"?"https://ibridge.s3.us-east-2.amazonaws.com/ICapability/QuestionImages/1643977294223%2Brdb.png"
                    :item.name==="PYTHON"?"https://ibridge.s3.us-east-2.amazonaws.com/ICapability/QuestionImages/1643977394517%2Bpython.png"
                    :item.name==="JAVASCRIPT"?"https://ibridge.s3.us-east-2.amazonaws.com/ICapability/QuestionImages/1643977199880%2Bjs.png"
                    :item.name==="JAVA"?"https://ibridge.s3.us-east-2.amazonaws.com/ICapability/QuestionImages/1643977136114%2Bjava.jpg"
                    :item.name==="C"?"https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/C_Programming_Language.svg/926px-C_Programming_Language.svg.png"
                    :item.name==="HTML & CSS"?"https://ibridge.s3.us-east-2.amazonaws.com/ICapability/QuestionImages/1643958812215%2Bhtml.png"
                    :item.name==="C#"?"https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1200px-Logo_C_sharp.svg.png"
                    :item.name==="Linux"?"https://ibridge.s3.us-east-2.amazonaws.com/ICapability/QuestionImages/1643958246734%2Bunix.png"
                    :"https://static-00.iconduck.com/assets.00/no-image-icon-512x512-lfoanl0w.png"
                  }
                    width="80px"
                    height="82px"
                    alt=""
                  />
                  {item?.name}
                </CardContent>{" "}
              </Card>
              {/* </div> */}
            </Grid>
          );
        }) || (
          <Typography variant="h4" align="center" style={{ color: "red" }}>
            No Domains Found
          </Typography>
        )}
        <CustomizedDialogs
          title="Productivity"
          children={
            <SelectProductivityTest
              setOpenPopup={setOpen}
              technologyId={technologyId}
              history={history}
            />
          }
          openPopup={open}
          setOpenPopup={setOpen}
        />
      </Grid>
    </>
  );
};


export default ProductivityDashboard;


