import React from 'react';
import { AiAssistantProvider } from "../../contexts/ai_assistant_context"
import LearningAssistantAIComponent from './ai_learning_assistant_component';

const LearningAssistantAI = ({ history }) => {
  
 return (
    <AiAssistantProvider>
      <LearningAssistantAIComponent history={history} />
    </AiAssistantProvider>  
  )
}

export default LearningAssistantAI;