import {
  UPLOAD_IMAGE,
  UPLOAD_FILE_PROCESS,
  UPLOAD_AUDIO,
  UPLOAD_VIDEO,
  UPLOAD_FILE,
} from "./type";
import axios from "axios";
import { API_URL } from "../../Api_Request";
import Swal from "sweetalert2";

//Upload Question Image
export const uploadImage = (image) => (dispatch) => {
  dispatch(uploadFileProcess());
  axios
    .post(`${API_URL}/uploadFile/imageUpload`, image)
    .then((res) => {
      dispatch({
        type: UPLOAD_IMAGE,
        payload: res?.data?.fileInfo,
      });
    })
    .catch((err) => {
      console.log(err);
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

//Upload User file
export const uploadUserFile = (data) => (dispatch) => {
  dispatch(uploadFileProcess());
  axios
    .post(`${API_URL}/uploadFile/fileUpload`, data)
    .then((res) => {
      dispatch({
        type: UPLOAD_FILE,
        payload: res?.data?.fileInfo,
      });
    })
    .catch((err) => {
      console.log(err);
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

//Upload Audio
export const uploadAudio = (audio) => (dispatch) => {
  dispatch(uploadFileProcess());
  axios
    .post(`${API_URL}/uploadFile/audioUpload`, audio)
    .then((res) => {
      dispatch({
        type: UPLOAD_AUDIO,
        payload: res?.data?.fileInfo,
      });
    })
    .catch((err) => {
      console.log(err);
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
      dispatch({
        type: UPLOAD_AUDIO,
        payload: null,
      });
    });
};

//Upload Audio
export const uploadVideo = (video) => (dispatch) => {
  dispatch(uploadFileProcess());
  axios
    .post(`${API_URL}/uploadFile/videoUpload`, video)
    .then((res) => {
      dispatch({
        type: UPLOAD_VIDEO,
        payload: res?.data?.fileInfo,
      });
    })
    .catch((err) => {
      console.log(err);
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
      dispatch({
        type: UPLOAD_VIDEO,
        payload: null,
      });
    });
};

// Upload File process
export const uploadFileProcess = () => {
  return {
    type: UPLOAD_FILE_PROCESS,
  };
};

//Clear image data
export const clearImageData = () => {
  return {
    type: UPLOAD_IMAGE,
    payload: null,
  };
};

//Clear Audio data
export const clearAudioData = () => {
  return {
    type: UPLOAD_AUDIO,
    payload: null,
  };
};

//Clear Video data
export const clearVideoData = () => {
  return {
    type: UPLOAD_VIDEO,
    payload: null,
  };
};

//Clear file data
export const clearFileData = () => {
  return {
    type: UPLOAD_FILE,
    payload: null,
  };
};
