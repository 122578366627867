import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getDatabasesInfo ,editDataBase} from "../../../redux/actions/productivityActions/databaseActions";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";
import { dispatch } from "d3";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const EditDatabase = ({ setOpenPopup, dbId }) => {
  const classes = useStyles();
  const { productivityDatabases } = useSelector((state) => state);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const databaseInfo=productivityDatabases?.databaseInfo
  
  useEffect(()=>{
    dispatch(getDatabasesInfo(dbId))
  },[])

  useEffect(()=>{
    if(databaseInfo){
      formik.setFieldValue(
        "databaseType", databaseInfo ? {name: databaseInfo?.databaseType }: {}
      );
      
      formik.setFieldValue(
        "databaseName", databaseInfo ? databaseInfo?.name : ""
      );
  
      formik.setFieldValue(
        "hostName", databaseInfo ? databaseInfo?.databaseHost : ""
      );
  
      formik.setFieldValue(
        "portNumber", databaseInfo ? databaseInfo?.databasePort : ""
      );
  
      formik.setFieldValue(
        "schemaName", databaseInfo ? databaseInfo?.databaseSchema : ""
      );
    }
  },[databaseInfo])

  console.log("databaseInfo",productivityDatabases?.databaseInfo)

    console.log("dbId in editDB",dbId)
  const formik = useFormik({
    initialValues: {
      databaseType: "",
      databaseName: "",
      hostName: "",
      portNumber: "",
      // password: "",
      // databaseUserName: "",
      schemaName: "",
    },
    validationSchema: Yup.object({
      databaseType: Yup.object()
        .nullable()
        .required("Database is reqired field"),
      databaseName: Yup.string()
        .min(2).max(25)
        .trim()
        .required("Database Name is required field"),
      hostName: Yup.string()
        .min(4).max(80)
        .trim().required("Host Name is required field"),
      portNumber: Yup.number()
        .required("Port Number is required field")
        .typeError('Port Number should be a Number'),
      // databaseUserName: Yup.string()
      //   .min(4).max(15)
      //   .trim()
      //   .required("Database UserName is required field"),
      // password: Yup.string()
      //   .min(5)
      //   .max(18)
      //   .trim()
      //   .required("Password is required field"),
      schemaName: Yup.string()
        .min(3)
        .max(25)
        .trim()
        .required("Schema Name is required field"),
    }),

    onSubmit: async (values) => {
      // console.log("Values",values)
      const data = {
        _id:dbId,
        databaseType: values?.databaseType?.name,
        name: values?.databaseName,
        databaseHost: values?.hostName,
        databasePort: parseInt(values?.portNumber),
        databaseUsername: databaseInfo ? databaseInfo?.databaseUsername: "",
        databasePassword: databaseInfo ? databaseInfo?.databasePassword : "",
        databaseSchema: values?.schemaName,
      };
      // console.log("data",data)
      dispatch(editDataBase(data));
      setOpenPopup(false);
    },
  });

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Database Type"
            name="databaseType"
            values={[{ name: "MYSQL" }, { name: "ORACLE" }]}
            onSelect={(dbType) => formik.setFieldValue("databaseType", dbType)}
            selected={formik.values.databaseType}
            errors={
              formik.touched.databaseType && formik.errors.databaseType
                ? formik.errors.databaseType
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ marginTop: "-15px" }}>
          <TextFieldGroup
            variant="outlined"
            label="Database Name"
            name="databaseName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.databaseName}
            errors={
              formik.touched.databaseName && formik.errors.databaseName
                ? formik.errors.databaseName
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="HostName"
            name="hostName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.hostName}
            errors={
              formik.touched.hostName && formik.errors.hostName
                ? formik.errors.hostName
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Port Number"
            name="portNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.portNumber}
            errors={
              formik.touched.portNumber && formik.errors.portNumber
                ? formik.errors.portNumber
                : null
            }
          />
        </Grid>

        {/* <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Database UserName"
            name="databaseUserName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.databaseUserName}
            errors={
              formik.touched.databaseUserName && formik.errors.databaseUserName
                ? formik.errors.databaseUserName
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            passwordControl={() => setShowPassword(!showPassword)}
            showPassword={showPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            errors={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : null
            }
          // showPassword={()=>showPassword?setShowPassword(true):setShowPassword(false)}
          />
        </Grid> */}

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Schema Name"
            name="schemaName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.schemaName}
            errors={
              formik.touched.schemaName && formik.errors.schemaName
                ? formik.errors.schemaName
                : null
            }
          />
        </Grid>

        {/* <Grid item xs={12} md={6}>
                    <TextFieldGroup
                        label="Function Code"
                        name="functionCode"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.functionCode}
                        errors={
                            formik.touched.functionCode && formik.errors.functionCode
                                ? formik.errors.functionCode
                                : null
                        }
                    />
                </Grid> */}

        <Grid item xs={12} md={3} align="right">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="submit"
              name="save"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
            // onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </Grid>

        {/* <Grid item xs={12} md={3}>
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="reset"
              name="reset"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
            // onClick={submitForm}
            >
              Reset
            </Button>
          </div>
        </Grid> */}
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

export default EditDatabase;
