// // import React from "react";
// // import MaterialTable from "material-table";
// // import { forwardRef } from "react";
// // import ArrowDownward from "@material-ui/icons/ArrowDownward";
// // import ChevronLeft from "@material-ui/icons/ChevronLeft";
// // import ChevronRight from "@material-ui/icons/ChevronRight";
// // import Clear from "@material-ui/icons/Clear";
// // import FirstPage from "@material-ui/icons/FirstPage";
// // import LastPage from "@material-ui/icons/LastPage";
// // import Search from "@material-ui/icons/Search";
// // import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

// // const getMuiTheme = () =>
// //   createMuiTheme({
// //     overrides: {
// //       MUIDataTableHeadCell: {
// //         fixedHeader: {
// //           backgroundColor: "#003399",
// //         },
// //       },
// //       MuiTableCell: {
// //         /* color: rgba(0, 0, 0, 0.87); */
// //         head: {
// //           color: "black",
// //           fontWeight: "bold",
// //         },
// //       },
// //       MUIDataTableSelectCell: {
// //         root: {
// //           display: "none",
// //         },
// //         headerCell: {
// //           backgroundColor: "#003399",
// //         },
// //       },
// //       MUIDataTable: {
// //         root: {},
// //         paper: {
// //           boxShadow: "none",
// //         },
// //         responsiveScrollMaxHeight: {
// //           // margin : "0px 50px",
// //           border: "1px solid #dee2e6",
// //           borderCollapse: "collapse",
// //         },
// //       },
// //       MuiTypography: {
// //         h6: {
// //           marginLeft: "30px",
// //           fontWeight: "bold",
// //           color: "#003399",
// //         },
// //       },
// //       MUIDataTableBodyRow: {
// //         root: {
// //           "&:nth-child(odd)": {
// //             backgroundColor: "#dee2e6",
// //           },
// //         },
// //       },
// //       MUIDataTableBodyCell: {},
// //     },
// //   });

// // const tableIcons = {
// //   FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
// //   LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
// //   NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
// //   PreviousPage: forwardRef((props, ref) => (
// //     <ChevronLeft {...props} ref={ref} />
// //   )),
// //   ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
// //   Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
// //   SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
// // };

// // const Tables = ({ title, data, columns, options }) => {
// //   return (
// //     <MuiThemeProvider theme={getMuiTheme()}>
// //       <MaterialTable
// //         icons={tableIcons}
// //         title={title}
// //         data={data}
// //         columns={columns}
// //         options={options}
// //       />
// //     </MuiThemeProvider>
// //   );
// // };

// // export default Tables;




// import React, { useState, forwardRef } from "react";
// import MUITable, { MTableToolbar } from "material-table";
// import {
//   AddBox,
//   ArrowDownward,
//   Check,
//   ChevronLeft,
//   ChevronRight,
//   Clear,
//   DeleteOutline,
//   Edit,
//   FilterList,
//   FirstPage,
//   LastPage,
//   Remove,
//   SaveAlt,
//   Search,
//   ViewColumn,
// } from "@material-ui/icons";
// // import { $themeBlue } from "../../reusableComponents/Colors";

// const MaterialTable = ({ title, columns, data, pageSize, selectedRow,style }) => {
//   // const [selectedRow, setSelectedRow] = useState(null);

//   const tableIcons = {
//     Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
//     Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
//     Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//     Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
//     DetailPanel: forwardRef((props, ref) => (
//       <ChevronRight {...props} ref={ref} />
//     )),
//     Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
//     Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
//     Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
//     FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//     LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//     NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//     PreviousPage: forwardRef((props, ref) => (
//       <ChevronLeft {...props} ref={ref} />
//     )),
//     ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//     Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
//     SortArrow: forwardRef((props, ref) => (
//       <ArrowDownward {...props} ref={ref} />
//     )),
//     ThirdStateCheck: forwardRef((props, ref) => (
//       <Remove {...props} ref={ref} />
//     )),
//     ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
//   };

//   const options = {
//     searchable: true,
//     sorting: true,
//     pageSize: pageSize,
//     headerStyle: {
//       backgroundColor: "#003399",
//       // backgroundColor: $themeBlue,
//       color: "#F8F9F9",
//       fontWeight: 900,
//     },
    
//     // rowStyle: rowData => ({
//     //     backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
//     // })
//   };

//   return (
//     <MUITable
//       title={title}
//       columns={columns}
//       data={data || []}
//       options={options}
//       onRowClick={(evt, rowData) =>selectedRow? selectedRow(rowData):()=>console.log("")}
//       icons={tableIcons}
//       style={style}
//       components={{
//         Toolbar: (props) => (
//           <div style={{ fontWeight: 900 }}>
//             <MTableToolbar {...props} />
//           </div>
//         ),
//       }}
//     />
//   );
// };

// export default MaterialTable;

import React, { forwardRef } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import PropTypes from 'prop-types';
import './MaterialTable.css'; 

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const MaterialTableComponent = ({ title, columns, data, pageSize, onRowSelect, style }) => {
  const options = {
    search: true,
    sorting: true,
    pageSize: pageSize,
    headerStyle: {
      backgroundColor: "#003399",
      color: "#F8F9F9",
      fontWeight: 'bold',
    },
    rowStyle: {
      backgroundColor: '#FFF', // Set default background color
      cursor: "default", // Prevent pointer cursor on row hover
    },
  };

  return (
    <div className="tableWrapper"> 
    <MaterialTable
      title={title}
      columns={columns}
      data={data || []}
      options={options}
      onRowClick={(evt, rowData) => onRowSelect && onRowSelect(rowData)}
      icons={tableIcons}
      style={style}
      components={{
        Toolbar: props => (
          <div style={{ fontWeight: 'bold' }}>
            <MTableToolbar {...props} />
          </div>
        ),
      }}
    />
    </div>
  );
};

MaterialTableComponent.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  pageSize: PropTypes.number,
  onRowSelect: PropTypes.func,
  style: PropTypes.object,
};

MaterialTableComponent.defaultProps = {
  data: [],
  pageSize: 10,
  onRowSelect: null,
  style: {},
};

export default MaterialTableComponent;


