import {
  GET_TECHNOLOGY_LIST,
  GET_DOMAIN_LIST,
  GET_TECHNOLOGY_INFO,
  GET_TRUETECHNOLOGY_LIST,
  GET_DOMAIN_INFO,
  GET_ENABLED_DOMAIN_LIST,
} from "../type";
import axios from "axios";
import { API_URL } from "../../../Api_Request";
import Swal from "sweetalert2";

export const getTechnologyList = () => (dispatch) => {
  axios
    .get(`${API_URL}/technology/getAllTechnologies`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_TECHNOLOGY_LIST,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const getTechnologyInfo = (id) => (dispatch) => {
  axios
    .get(`${API_URL}/technology/getTechnologyInfo/${id}`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_TECHNOLOGY_INFO,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const getDomainList = () => (dispatch) => {
  axios
    .get(`${API_URL}/domain/getAllDomains`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_DOMAIN_LIST,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const getEnabledDomainList = () => (dispatch) => {
  axios
    .get(`${API_URL}/domain/getEnabledDomains`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_ENABLED_DOMAIN_LIST,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const getDomainInfo = (id) => (dispatch) => {
  axios
    .get(`${API_URL}/domain/getDomainInfo/${id}`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_DOMAIN_INFO,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const addDomainData = (params, history) => (dispatch) => {
  axios
    .post(`${API_URL}/domain/addDomain`, params)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: res?.data?.message || "Domain added successfully",
      });

      dispatch(getDomainList());

      // console.log(res.data)
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const addTechnologyData = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/technology/addTechnology`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: `Technology Added successfully !!!`,
      });
      dispatch(getTechnologyList());
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const editTechnologyData = (data) => (dispatch) => {
  axios
    .put(`${API_URL}/technology/updateTechnology`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: `Technology Updated successfully !!!`,
      });
      dispatch(getTechnologyList());
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const getTrueTechnologyList = () => (dispatch) => {
  axios
    .get(`${API_URL}/technology/getEnabledTechnologies`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_TRUETECHNOLOGY_LIST,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const editDomainData = (data) => (dispatch) => {
  axios
    .put(`${API_URL}/domain/updateDomain`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: res.data.message,
      });
      dispatch(getDomainList());
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};
