import React from 'react';

const HtmlViewer = ({ htmlUrl }) => {
  return (
    <div>
      <iframe
        title="HTML Viewer"
        src={htmlUrl}
        width="100%"
        height="600px"
      />
    </div>
  );
};

export default HtmlViewer;
