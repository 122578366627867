import {RESULTSHEET, RESULTFEEDBACK} from './type';
import axios from 'axios';
import { API_URL } from '../../Api_Request';



export const resultsheet = (params) => dispatch => {
    // console.log(params)
    axios.get(`${API_URL}/useranswer/?iduser=${params.iduser}&idtest=${params.idtest}`)
    .then (res => {
        dispatch({
            type : RESULTSHEET,
            payload : res.data
        })
        
        // console.log(res.data)
    })
    .catch(err => console.log(err))
}


export const resultfeedback = (id) => dispatch => {
      axios.get(`${API_URL}/userlists`)
      .then (res => {
          dispatch({
              type : RESULTFEEDBACK,
              payload : res.data
          })
          
        //   console.log(res.data)
      })
      .catch(err => console.log(err))
  }