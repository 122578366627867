import React, { Component } from "react";
import Menu from "../menu/menu";
import { Redirect } from "react-router-dom";
// import { API_URL } from '../../Api_Request';
// import axios from 'axios';
// import Select from 'react-select';
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { getQuestionpapers } from "../../redux/actions/questionPapersAction";
import { getQuestioncomplexity } from "../../redux/actions/questionComplexityAction";
import { getQuestionTopics } from "../../redux/actions/questionTopicsAction";
import {
  getassigntest,
  getassigntestnull,
} from "../../redux/actions/assignTestAction";
import { postassigntest } from "../../redux/actions/questionPapersAction";
import Tables from "../tables/tables";
import loader from ".././805.gif";

class AssignTests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.location.state.userId,
      userName: this.props.location.state.userName,
      userEmail: this.props.location.state.userEmail,
      userPhone: this.props.location.state.userPhone,
      isLoaded: false,
      topicData: [],
      complexData: [],
      selectedData: [],
      data: [],
      testdata: [],
      filterdata: [],
      topics: [],
      skill: [],
      selectedTopic: null,
      selectedComplex: null,
      selectedPaper: null,
      defaultTopic: null,
      defaultComplex: null,
      selectedRow: -1,
      editedERow: false,
      err: false,
      editSkillButton: false,
      acronym: "",
      add: false,
      qskill: false,
      qpaper: false,
    };
  }

  componentDidMount() {
    this.props.getassigntest(this.props.location.state.userId);
    this.props.getQuestionpapers();
    this.props.getQuestioncomplexity();
    this.props.getQuestionTopics();
  }

  componentWillReceiveProps(nextProps) {
    let { getquestioncomplexity } = nextProps.questioncomplexity;
    if (getquestioncomplexity) {
      this.setState({
        complexData: getquestioncomplexity,
      });
    }
    let { gettopics } = nextProps.questiontopics;
    if (gettopics) {
      this.setState({
        topicData: gettopics,
      });
    }
    if (!nextProps.assignedtests.getassignedtestsdata) {
      return null;
    } else if (nextProps.assignedtests.getassignedtestsdata.length < 0) {
      this.setState({
        testdata: nextProps.assignedtests.getassignedtestsdata,
        isLoaded: true,
      });
    } else {
      this.setState({
        testdata: nextProps.assignedtests.getassignedtestsdata,
        isLoaded: false,
      });
    }
    if (!nextProps.questionpapers.getquestionpaperlist) {
      return null;
    } else if (nextProps.questionpapers.getquestionpaperlist.length < 0) {
      this.setState({
        data: nextProps.questionpapers.getquestionpaperlist,
        isLoaded: true,
      });
    } else {
      this.setState({
        data: nextProps.questionpapers.getquestionpaperlist,
        isLoaded: false,
      });
    }
  }

  handleTopic = (e) => {
    let topicid = 0;
    let acronym = "";
    this.state.topicData.map((topic) => {
      if (e.target.value === topic.name) {
        topicid = topic.id;
        acronym = topic.acronym;
      }
    });
    let selectedTopic = {
      label: e.target.value,
      value: topicid,
    };
    this.setState({ selectedTopic, acronym, qskill: true });
    console.log(selectedTopic);
  };

  handleComplex = (e) => {
    let skillid = 0;
    this.state.complexData.map((skill) => {
      if (e.target.value === skill.name) {
        skillid = skill.id;
      }
    });
    let selectedComplex = {
      label: e.target.value,
      value: skillid,
    };
    let filterdata = this.state.data.filter(
      (paper) =>
        paper.topic_id === this.state.selectedTopic.value &&
        paper.skill_id === skillid
    );
    this.setState({
      selectedComplex,
      filterdata,
      editSkillButton: true,
      qpaper: true,
    });
    console.log(selectedComplex);
  };

  handlePaper = (e) => {
    let paperid = 0;
    this.state.data.map((paper) => {
      if (e.target.value === paper.name) {
        paperid = paper.id;
      }
    });
    let selectedPaper = {
      label: e.target.value,
      value: paperid,
    };
    let select_box = document.getElementById("selectskill");
    select_box.selectedIndex = 0;
    this.setState({ selectedPaper, editSkillButton: true, add: true });
    console.log(selectedPaper);
  };

  handleBack = (e) => {
    this.props.getassigntestnull();
    this.props.history.push("/menu/assigntestusers");
  };

  validate = () => {
    const { err, testdata, selectedTopic, selectedComplex, selectedPaper } =
      this.state;
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: true,
    });
    let isError = err;
    if (selectedTopic.value === undefined) {
      isError = true;
      Toast.fire({
        icon: "error",
        title: "Please select Topic name !!!",
      });
    }
    if (selectedComplex.value === undefined) {
      isError = true;
      Toast.fire({
        icon: "error",
        title: "Please select Skill Level !!!",
      });
    }
    if (selectedPaper.value === undefined) {
      isError = true;
      Toast.fire({
        icon: "error",
        title: "Please select Question Paper !!!",
      });
    }
    testdata.map((item) => {
      if (item.questionpaper === selectedPaper.label) {
        isError = true;
        Swal.fire({
          icon: "error",
          text: `Question paper is already assigned !!!`,
        });
      }
    });

    return isError;
  };

  handleSave = (e) => {
    const { selectedTopic, selectedComplex, selectedPaper, userId, acronym } =
      this.state;
    let { err } = this.state;
    const { user } = this.props.auth;
    const email = user && user.email;
    let topics = null;
    let skillid = null;
    let topicName = "";
    let skillname = null;
    err = this.validate();
    if (err) {
      this.setState({
        error: true,
      });
    } else {
      // console.log(topics,skills,userId)
      const usertest = {
        userId: userId,
        topicId: selectedTopic.value,
        skillId: selectedComplex.value,
        acronym: acronym,
        skill: selectedComplex.label,
        paperId: selectedPaper.value,
        date: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
      };
      console.log(usertest);
      this.props.postassigntest(usertest);
      let select_topic = document.getElementById("selecttopic");
      select_topic.selectedIndex = 0;
      let select_paper = document.getElementById("selectpaper");
      select_paper.selectedIndex = 0;
      this.setState({
        add: false,
        qskill: false,
        qpaper: false,
        selectedComplex: null,
        selectedTopic: null,
        isLoaded: false,
      });
    }
  };

  render() {
    const { userName, userEmail, userPhone } = this.state;
    //Table
    const columns = [
      {
        name: "user_id",
        label: "User Id",
        options: { filter: false, sort: true, display: false },
      },
      {
        name: "questionpaper",
        label: "Question Paper Name",
        options: { filter: false, sort: true },
      },
      { name: "topic", label: "Topic", options: { filter: true, sort: false } },
      {
        name: "skill",
        label: "Skill Level",
        options: { filter: true, sort: false },
      },
      {
        name: "test_id",
        label: "Test ID",
        options: { filter: true, sort: false },
      },
      {
        name: "date",
        label: "Assigned Date",
        options: { filter: false, sort: false },
      },
    ];

    const options = {
      filterType: "checkbox",
      serverSide: false,
      pagination: true,
      // searchText: true,
      download: false,
      selectableRows: "none",
      viewColumns: false,
      rowsPerPage: 6,
      selectableRowsOnClick: true,
      rowsPerPageOptions: [10],
      rowHover: true,
      search: true,
      print: false,
      responsive: "scrollMaxHeight",
    };
    const { getassignedtestsdata } = this.props.assignedtests;
    const { user, isAuthenticated } = this.props.auth;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div>
          {/* <h1>Dashboard Component</h1> */}
          <section>
            <div
              className="image-container"
              style={{
                display: this.state.isLoaded === true ? "block" : "none",
              }}
            >
              <p className="image-holder">
                <img src={loader} alt="" />
              </p>
            </div>
            <div className="container">
              <h2 className="text-center text-primary mt-5 ">Assign Tests</h2>
              {
                <div className="row mt-5 font-weight-bold">
                  <div className="col-sm-4">
                    Name : <span className="text-uppercase">{userName}</span>
                  </div>
                  <div className="col-sm-4">Email : {userEmail}</div>
                  <div className="col-sm-4">Phone : {userPhone}</div>
                </div>
              }
              <div className="row mt-5 ">
                <div className="col-sm-3">
                  <select
                    id="selecttopic"
                    className="form-control"
                    onChange={(e) => this.handleTopic(e)}
                  >
                    <option selected="true" disabled="disabled">
                      Select Question Topic
                    </option>
                    {this.state.topicData.map((topic) => (
                      <option key={topic.id} value={topic.name}>
                        {topic.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-3">
                  <select
                    id="selectskill"
                    disabled={this.state.qskill !== true}
                    className="form-control"
                    onChange={(e) => this.handleComplex(e)}
                  >
                    <option selected="true" disabled="disabled">
                      Select Skill Level
                    </option>
                    {this.state.complexData.map((complex) => (
                      <option key={complex.id} value={complex.name}>
                        {complex.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-3">
                  <select
                    id="selectpaper"
                    disabled={this.state.qpaper !== true}
                    className="form-control"
                    onChange={(e) => this.handlePaper(e)}
                  >
                    <option selected="true" disabled="disabled">
                      Select Question Paper
                    </option>
                    {this.state.filterdata.map((paper) => (
                      <option key={paper.id} value={paper.name}>
                        {paper.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-3">
                  <button
                    type="submit"
                    onClick={(e) => {
                      this.handleSave(e);
                    }}
                    disabled={this.state.add !== true}
                    className="btn btn-primary mr-2"
                    value="save"
                  >
                    Assign
                  </button>
                  <button
                    type="reset"
                    className="btn btn-danger"
                    value="clear"
                    onClick={(e) => {
                      this.handleBack(e);
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
              <div className="mt-5">
                {getassignedtestsdata && !this.state.isLoaded ? (
                  <Tables
                    title="Assigned Question Papers"
                    data={getassignedtestsdata}
                    columns={columns}
                    options={options}
                  />
                ) : null}
              </div>
              {/* <table className="table text-center table-bordered mt-5">
                          <thead>
                            <tr>
                              <th>Topics</th>
                              <th>Skill level</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              mappedData.length <= 0
                              ?
                              <tr>
                                <td>Test Topic</td>
                                <td>Assigned Question Paper</td>
                              </tr>
                              :
                              <React.Fragment>
                                {
                                  mappedData.map((item, index) => 
                                    (
                                      <tr key={item.topic_id}>
                                        <td>
                                          {item.topic_name}
                                        </td>
                                        <td>
                                          {
                                            this.state.editedERow === true && this.state.selectedRow === index
                                            ?
                                            <Select
                                          defaultValue={item.selectedskills}
                                          isMulti
                                          name="skill"
                                          options={skill}
                                          className="basic-multi-select"
                                          placeholder="Select Skill Level"
                                          onChange={this.handleComplex}
                                          />
                                          :
                                          item.skill_names
                                          }
                                        </td>
                                        <td>
                                        {
                                          this.state.editedERow === true && this.state.selectedRow === index
                                          ?
                                          <div>
                                          <button className="btn btn-primary  mr-2" disabled={this.state.editSkillButton !== true} onClick={(e)=>this.handleUpdate(e, item, index)}>Update</button>
                                          <button className="btn btn-danger" onClick={(e)=>this.handleCancel(e)}>Cancel</button>
                                          </div>
                                          :
                                          <div>
                                          <button className="btn btn-success  mr-2" onClick={(e)=>this.handleEdit(e,index,item)}>Edit</button>
                                          <button className="btn btn-danger" onClick={(e)=>this.handleDelete(e,item,index)}>Remove</button>
                                          </div>
                                        }
                                    </td>
                                    </tr>
                                    ))
                                }
                              </React.Fragment>
                            }
                          </tbody>
                        </table> */}
            </div>
          </section>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  questionpapers: state.questionpapers,
  questioncomplexity: state.questioncomplexity,
  questiontopics: state.questiontopics,
  assignedtests: state.assignedtests,
});

const mapDispatchToProps = {
  getQuestionTopics,
  getQuestioncomplexity,
  getQuestionpapers,
  getassigntest,
  postassigntest,
  getassigntestnull,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignTests);
