import React, { useState, useEffect } from 'react';
import Editor from './Editor'
import { useDispatch, useSelector } from "react-redux";
import { executeHTML, completeTest } from '../../../redux/actions/productivityActions/testAction';

import { Grid, Button } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import SelectSingleValues from '../SelectSingleValues';
import { useParams } from "react-router-dom";
import { getTestQuestions } from '../../../redux/actions/productivityActions/testAction';
// import useLocalStorage from '../hooks/useLocalStorage'

const HtmlEditorScreen = ({
  history,
}) => {
  const { testId } = useParams();
  console.log("testId",testId)
  const [html, setHtml] = useState("")
  const [css, setCss] = useState("")
  const [js, setJs] = useState("")
  const [srcDoc, setSrcDoc] = useState('')
  const [question, setQuestion] = useState("");
  // const { isAuthenticated, user } = auth;
  const dispatch = useDispatch();
  const { productivityTest, auth } = useSelector((state) => state);

  const { isAuthenticated, user } = auth;

  console.log("productivityTest",productivityTest)

  // const handleExecute = async () => {
  //   await setSrcDoc(`
  //                         <html>
  //                           <body>${html}</body>
  //                           <style>${css}</style>
  //                           <script>${js}</script>
  //                         </html>
  //                       `)
  //   const testData = {
  //     domain: question?.domain,
  //     functionId: question?.function,
  //     scenarioId: question?.scenario,
  //     question: question?._id,
  //     query: srcDoc,
  //     testId: testId,
  //   }
  //   console.log("testData",testData,srcDoc)
  // }

  useEffect(() => {
    if(srcDoc){
      const testData = {
        domain: question?.domain,
        functionId: question?.function,
        scenarioId: question?.scenario,
        question: question?._id,
        query: srcDoc,
        testId: testId,
      }
      // console.log("testData",testData,srcDoc)
      dispatch(executeHTML(testData))
    }
},[srcDoc])

  useEffect(() => {
      dispatch(getTestQuestions(testId));
  },[])

  const questionList = productivityTest?.testQuestions?.questionsData?.map(
    (item) => {
      item.name = item?.questionDescription;
      return item;
    }
  );

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
      // setSrcDoc(`
      //   <html>
      //     <body>${html}</body>
      //     <style>${css}</style>
      //     <script>${js}</script>
      //   </html>
      // `)
  //   }, 250)

  //   return () => clearTimeout(timeout)
  // }, [html, css, js])

  return (
    <>
    <Grid container>
    <Grid item xs={8} md={8} align="right" style={{marginTop:"1%",marginLeft:"1%",marginBottom:"1%"}} >
      <SelectSingleValues
              label="Select Question"
              name="question"
              values={questionList || []}
              // values={[]}
              onSelect={(questionData) => {
                // handleQuestion(questionData)
                setQuestion(questionData)
              }
              }
              selected={question}
              // errors={
              //   formik.touched.selectDomain && formik.errors.selectDomain
              //     ? formik.errors.selectDomain
              //     : null
              // }
            />
    </Grid>
      <Grid item xs={3} md={3} align="right">
          <Button
            style={{margin:"2%",marginTop:"5%"}}
            variant="contained"
            endIcon={<SendIcon/>}
            color="primary"
            type="submit"
            name="submit"
            disabled={question === "" ? true : false}
            onClick={() => {
              setSrcDoc(`
                          <html>
                            <body>\n${html}\n</body>
                            <style>\n\n${css}\n</style>
                            <script>\n${js}\n</script>
                          </html>
                        `)
              // handleExecute()
              // dispatch(executeHTML(srcDoc))
              // alert("am working")
              // setScheduleId(_id)
            }}
          >
            Execute
          </Button>

          <Button
            style={{margin:"2%",marginTop:"5%"}}
            variant="contained"
            color="primary"
            type="submit"
            name="submit"
            disabled={srcDoc === "" ? true : false}
            onClick={() => {
              dispatch(completeTest(testId,history))
            }}
          >
            Finish Code
          </Button>
      </Grid>
    </Grid>
      <div className="pane top-pane">
        <Editor
          language="xml"
          displayName="HTML"
          value={html}
          onChange={setHtml}
        />
        <Editor
          language="css"
          displayName="CSS"
          value={css}
          onChange={setCss}
        />
        <Editor
          language="javascript"
          displayName="JS"
          value={js}
          onChange={setJs}
        />
      </div>
      <div className="pane">
        <iframe
          srcDoc={srcDoc}
          title="output"
          sandbox="allow-scripts"
          frameBorder="0"
          width="100%"
          height="100%"
        />
      </div>
    </>
  )
}

export default HtmlEditorScreen;