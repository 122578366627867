import React, { useState, useEffect } from "react";
import { Grid, Button, TextField, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "../reusableComponents/is-empty";
import TextFieldGroup from "../reusableComponents/TextFieldGroup";
import Menu from "../menu/menu";
import { addCustomer } from "../../redux/actions/customerActions";

const initialCustomerForm = {
  customerFirstName: "",
  customerLastName: "",
  customerEmail: "",
  customerPassword: "",
  phoneNumber: "",
};


const AddCustomer = ({ auth, history, addCustomer }) => {
  const [formData, setFormData] = useState({ ...initialCustomerForm });
  const [validError, setValidError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { user } = auth;

  useEffect(() => {
    const { user } = auth;
    console.log(user);
    if (user && user.role !== "Super Admin") {
      history.push("/login");
    }
  }, [auth]);

  let exp = /^[a-zA-Z]+$/
  // let fn=formData.customerFirstName;

  const validate = () => {
    let isError = false;

    const validError = {
      validError: {},
    };

    if (!formData.customerFirstName.match(exp)) {
      isError = true;
      validError.validError.customerFirstName =
        "FIrst Name should be alphabet";
    }

    
    if (isEmpty(formData.customerFirstName)) {
      isError = true;
      validError.validError.customerFirstName =
        "Customer FIrst Name is required field";
    }

    if (isEmpty(formData.customerLastName)) {
      isError = true;
      validError.validError.customerLastName =
        "Customer Last Name is required field";
    }

    if (!formData.customerLastName.match(exp)) {
      isError = true;
      validError.validError.customerLastName =
        "Last Name should be alphabet";
    }

    if (isEmpty(formData.customerEmail)) {
      isError = true;
      validError.validError.customerEmail = "Customer Email is required field";
    }

    if (isEmpty(formData.phoneNumber )) {
      isError = true;
      validError.validError.phoneNumber = "Phonenumber is required field";
    }

    if (formData.phoneNumber.length < 10 || formData.phoneNumber.length > 10 ) {
      isError = true;
      validError.validError.phoneNumber = "Phonenumber should be 10 Digits";
    }

    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        formData.customerEmail
      )
    ) {
      isError = true;
      validError.validError.customerEmail = "Please enter valid email";
    }

    if (isEmpty(formData.customerPassword)) {
      isError = true;
      validError.validError.customerPassword =
        "Customer Password is required field";
    }

    setValidError({ ...validError.validError });

    return isError;
  };

  const handleChange = (e) => {
    let name = e.target.name;
    setFormData({ ...formData, [name]: e.target.value });
    setValidError({});
  };

  const onSubmit = (data) => {
    const err = validate();
    console.log(err);
    if (!err) {
      let customerData = JSON.parse(JSON.stringify(formData));
      customerData.addedBy = user && user.email;
      addCustomer(customerData, history);
    } else {
      console.log(validError, err);
    }
  };

  return (
    <>
      <Grid>
        <form onSubmit={() => onSubmit()} noValidate autoComplete="off">
          <h4
            style={{
              color: "black",
              fontWeight: "bolder",
              textAlign: "center",
              padding: 5,
              fontSize: "20px",
            }}
          >
            Add Customer
          </h4>
          <Grid container mad={6} spacing={2} justify="center">
            <Grid item md={7}>
              <TextFieldGroup
                heading="Customer First Name"
                showLable={true}
                // label="Client Name"
                varient="outlined"
                type="email"
                name="customerFirstName"
                onChange={(e) => handleChange(e)}
                value={formData.customerFirstName}
              />
              {validError.customerFirstName ? (
                <span style={{ color: "red" }}>
                  {validError.customerFirstName}
                </span>
              ) : null}
            </Grid>
            <Grid item md={7}>
              <TextFieldGroup
                heading="Customer Last Name"
                showLable={true}
                // label="Client Name"
                varient="outlined"
                type="email"
                name="customerLastName"
                onChange={(e) => handleChange(e)}
                value={formData.customerLastName}
              />
              {validError.customerLastName ? (
                <span style={{ color: "red" }}>
                  {validError.customerLastName}
                </span>
              ) : null}
            </Grid>
            <Grid item md={7}>
              <TextFieldGroup
                heading="Customer Phone number"
                showLable={true}
                varient="outlined"
                type="number"
                name="phoneNumber"
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, 10);
                }}
                onChange={(e) => handleChange(e)}
                value={formData.phoneNumber}
              />
              {validError.phoneNumber ? (
                <span style={{ color: "red" }}>{validError.phoneNumber}</span>
              ) : null}
            </Grid>
            <Grid item md={7}>
              <TextFieldGroup
                heading="Customer Email"
                showLable={true}
                varient="outlined"
                type="email"
                name="customerEmail"
                onChange={(e) => handleChange(e)}
                value={formData.customerEmail}
              />
              {validError.customerEmail ? (
                <span style={{ color: "red" }}>{validError.customerEmail}</span>
              ) : null}
            </Grid>
            <Grid item md={7}>
              <TextFieldGroup
                heading="Customer Password"
                varient="outlined"
                showLable={true}
                type={showPassword ? "text" : "password"}
                name="customerPassword"
                passwordControl={() => setShowPassword(!showPassword)}
                showPassword={showPassword}
                onChange={(e) => handleChange(e)}
                value={formData.customerPassword}
                errors={validError.customerPassword?.message}
              />
              {validError.customerPassword ? (
                <span style={{ color: "red" }}>
                  {validError.customerPassword}
                </span>
              ) : null}
            </Grid>
          </Grid>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              style={{ marginTop: "10px" }}
              onClick={() => onSubmit()}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="default"
              size="medium"
              style={{ marginTop: "10px", marginLeft: "5px" }}
              onClick={() => history.push("/admin/customerlist")}
            >
              Back
            </Button>
          </div>
        </form>
      </Grid>
    </>
  );
};

AddCustomer.propTypes = {
  addCustomer: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { addCustomer };

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
