import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "./components/reusableComponents/PrivateRoutes";

import AdminLayout from "./components/layout/adminLayout";

import Dashboard from "./components/dashboard/dashboard";
import AddQuestions from "./components/questionspage/AddQuestions";
import QuestionTopic from "./components/questions_topic/questionTopic";
import QuestionType from "./components/questions_type/questionType";
import QuestionComplexity from "./components/questions_complexity/questionComplexity";
import Questions from "./components/questions/questions";

import QuestionPapers from "./components/questions_set/questionPapers";

import CustomerList from "./components/Customers/CustomerList";
import UserGroups from "./components/Customers/UserGroups";
import Results from "./components/results/ResultViewTabs";

import CapabilityResults from "./components/results/results";
import ProductivityResults from "./components/results/ProductivityResults/queryResults";

import UserLists from "./components/visuals/userlists";
import AnimatedChart from "./components/visuals/animatedchart";
import performanceChart from "./components/visuals/performancechart";
import ResultGraph from "./components/visuals/resultgraph";
import NewResultGraph from "./components/visuals/NewResultGraph";

import Users from "./components/assign/users";

import UserManagement from "./components/usermanagement/userlists";

import Database from "./components/domainArena/database/database";
import Domain from "./components/domainArena/domain/Domain";
import TechnologyList from "./components/domainArena/Technology/TechnologyList";
import Function from "./components/domainArena/function/Function";
import Scenario from "./components/domainArena/scenario/Scenario";
import Question from "./components/domainArena/questions/Question";
import ViewProductivityTest from "./components/results/ProductivityResults/ViewProductivityTest";

import AssignedUsers from "./components/questions_set/assignedusers";
import QuestionLists from "./components/questions_set/questionlists";
import editQuestions from "./components/editQuestions/editQuestions";
import AssignTest from "./components/questions_set/assigntest";
import SelectQuestions from "./components/questions_set/selectQuestions";

import AddCustomer from "./components/Customers/AddCustomer";
import AddUserGroup from "./components/Customers/AddUserGroup";
import GroupUserList from "./components/Customers/UserList";

import ViewResult from "./components/viewresults/viewresults";
import Visuals from "./components/visuals/visuals";

import VisualsNew from ".//components/visuals/visualsNew";
import AddUsers from "./components/assign/adduser";
import AddRole from "./components/usermanagement/addrole";
import Assign from "./components/assign/assign";

import NewChapter from "./components/chapters/NewChapters";
import InterviewResult from "./components/results/interviewResult";
import DebuggingCode from "./components/domainArena/DeuggingCodes/DebuggingCode";
import DebuggingFeedbackResults from "./components/domainArena/DeuggingCodes/DebuggingFeedbackResults";
import ViewDebuggingSession from "./components/domainArena/DeuggingCodes/ViewDebuggingSession";
import NewPerformanceGraph from "./components/visuals/NewPerformanceGraph";
import NewAnimatedChart from "./components/visuals/NewAnimatedchart";

import ProductivityReports from "./components/iProductivityReportGenerator/ProductivityReports";

import CapabilityAnalysisApplication from "./components/capabilityAnalysisApplication/capabilityAnalysisApplication";

const AdminRoutes = ({ match }) => {
  return (
    <Switch>
      <AdminLayout>
        {/* <PrivateRoute path={`${match.url}`} component={Dashboard} /> */}
        <PrivateRoute path={`${match.url}/dashboard`} component={Dashboard} />
        <PrivateRoute
          path={`${match.url}/addQuestions/:topicId`}
          component={AddQuestions}
        />

        {/* Questions */}
        <PrivateRoute
          path={`${match.url}/questionTopic`}
          component={QuestionTopic}
        />
        <PrivateRoute
          path={`${match.url}/questionType`}
          component={QuestionType}
        />
        <PrivateRoute
          path={`${match.url}/questionComplexity`}
          component={QuestionComplexity}
        />
        <PrivateRoute path={`${match.url}/questions`} component={Questions} />

        <PrivateRoute path={`${match.url}/chapters`} component={NewChapter} />

        {/* manage tests */}
        <PrivateRoute
          path={`${match.url}/questionpapers`}
          component={QuestionPapers}
        />

        {/* customers */}
        <PrivateRoute
          exact
          path={`${match.url}/customerlist`}
          component={CustomerList}
        />
        <PrivateRoute
          exact
          path={`${match.url}/usergroups/:id`}
          component={UserGroups}
        />

        {/* Result */}
        <PrivateRoute
          path={`${match.url}/CapabilityResults`}
          component={CapabilityResults}
        />
        <PrivateRoute
          path={`${match.url}/ProductivityResults`}
          component={ProductivityResults}
        />

        <PrivateRoute
          path={`${match.url}/interviewResult`}
          component={InterviewResult}
        />

        <PrivateRoute
          path={`${match.url}/feedbackResults`}
          component={DebuggingFeedbackResults}
        />
        <PrivateRoute
          path={`${match.url}/view-debugging-session/:sessionId`}
          component={ViewDebuggingSession}
        />

        {/* Analysis */}
        <PrivateRoute path={`${match.url}/userlists`} component={UserLists} />
        {/* <PrivateRoute
          exact
          path={`${match.url}/animatedchart`}
          component={AnimatedChart}
        /> */}
        <PrivateRoute
          exact
          path={`${match.url}/animatedchart`}
          component={NewAnimatedChart}
        />

        <PrivateRoute
          exact
          path={`${match.url}/userperformance`}
          component={NewPerformanceGraph}
        />
        {/* <PrivateRoute
          exact
          path={`${match.url}/resultchart`}
          component={ResultGraph} */}
        {/* /> */}
        <PrivateRoute
          exact
          path={`${match.url}/resultchart`}
          component={NewResultGraph}
        />

        {/* Assign Topics */}
        <PrivateRoute path={`${match.url}/users`} component={Users} />

        {/* Manage Users */}
        <PrivateRoute
          path={`${match.url}/manageusers`}
          component={UserManagement}
        />

        {/* Domain Arena */}
        <PrivateRoute path={`${match.url}/database`} component={Database} />
        <PrivateRoute path={`${match.url}/domain`} component={Domain} />
        <PrivateRoute
          path={`${match.url}/debuggingCode`}
          component={DebuggingCode}
        />
        <PrivateRoute
          path={`${match.url}/technologies`}
          component={TechnologyList}
        />
        <PrivateRoute path={`${match.url}/function`} component={Function} />
        <PrivateRoute path={`${match.url}/scenario`} component={Scenario} />
        <PrivateRoute path={`${match.url}/question`} component={Question} />
        <PrivateRoute
          path={`${match.url}/viewqueryresult`}
          component={ViewProductivityTest}
        />

        {/*Sub Routes*/}
        <PrivateRoute
          path={`${match.url}/assignedusers`}
          component={AssignedUsers}
        />
        <PrivateRoute
          path={`${match.url}/questionlists`}
          component={QuestionLists}
        />
        <PrivateRoute
          path={`${match.url}/editQuestions`}
          component={editQuestions}
        />
        <PrivateRoute path={`${match.url}/assigntest`} component={AssignTest} />
        <PrivateRoute
          path={`${match.url}/selectquestions`}
          component={SelectQuestions}
        />
        <PrivateRoute
          exact
          path={`${match.url}/addcustomer`}
          component={AddCustomer}
        />
        <PrivateRoute
          exact
          path={`${match.url}/addusergroup`}
          component={AddUserGroup}
        />
        <PrivateRoute
          exact
          path={`${match.url}/grouplist/:group`}
          component={GroupUserList}
        />

        <PrivateRoute
          path={`${match.url}/viewresults`}
          component={ViewResult}
        />
        {/* <PrivateRoute path={`${match.url}/comparitive`} component={Visuals} /> */}

        <PrivateRoute
          path={`${match.url}/comparitive/:userId`}
          component={VisualsNew}
        />
        <PrivateRoute path={`${match.url}/addusers`} component={AddUsers} />
        <PrivateRoute path={`${match.url}/addrole`} component={AddRole} />
        <PrivateRoute path={`${match.url}/assigntopic`} component={Assign} />

        <PrivateRoute path={`${match.url}/iproductivityreportgenerator`} component={ProductivityReports} />
        <PrivateRoute path={`${match.url}/capabilityAnalysisApplication`} component={CapabilityAnalysisApplication} />
        
      </AdminLayout>
    </Switch>
  );
};

export default AdminRoutes;
