import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";

import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";

import { getTechnologyList } from "../../../redux/actions/productivityActions/domainAction";
import {
  getDomainInfo,
  editDomainData,
} from "../../../redux/actions/productivityActions/domainAction";
import { getDatabases } from "../../../redux/actions/productivityActions/databaseActions";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const EditDomain = ({
  auth,
  getTechnologyList,
  // addDomainData,
  setOpenPopup,
  domainId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { domainReducer, productivityDatabases } = useSelector(
    (state) => state
  );
  const domainInfo = domainReducer?.domainInfo;

  const dbValueList = productivityDatabases?.databaseList?.map((item) => {
    return {
      _id: item?._id,
      name: item?.name,
    };
  });

  useEffect(() => {
    if (auth.isAuthenticated) {
      getTechnologyList();
      dispatch(getDatabases());
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    dispatch(getDomainInfo(domainId));
  }, []);

  useEffect(() => {
    if (domainInfo) {
      if (domainInfo?.technology?.name === "RDBMS") {
        formik.setFieldValue(
          "selectDatabase",
          domainInfo
            ? {
                name: domainInfo?.database?.name,
                _id: domainInfo?.database?._id,
              }
            : {}
        );
      }
      formik.setFieldValue(
        "selectTechnology",
        domainInfo
          ? {
              name: domainInfo?.technology?.name,
              _id: domainInfo?.technology?._id,
            }
          : {}
      );

      formik.setFieldValue("domainName", domainInfo ? domainInfo?.name : "");

      formik.setFieldValue(
        "domainWebsite",
        domainInfo ? domainInfo?.domainWebsite : ""
      );

      formik.setFieldValue(
        "domainCode",
        domainInfo ? domainInfo?.domainCode : ""
      );

      formik.setFieldValue(
        "domainDescription",
        domainInfo ? domainInfo?.domainDescription : ""
      );
      formik.setFieldValue(
        "enableStatus",
        domainInfo?.status
          ? { name: "Enabled", _id: true }
          : { name: "Disbaled", _id: false }
      );
    }
  }, [domainInfo]);

  // console.log("doaminId", domainId);
  // console.log("domainInfo", domainInfo);

  const technologyList = domainReducer?.getTechnologyList?.map((item) => {
    console.log("techonoly list in domain", item);
    return {
      _id: item._id,
      name: item.name,
    };
  });

  const formik = useFormik({
    initialValues: {
      selectTechnology: "",
      domainName: "",
      domainWebsite: "",
      domainCode: "",
      domainDescription: "",
      selectDatabase: {},
      enableStatus: "",
    },
    validationSchema: Yup.object({
      // scheduleDescription: Yup.string()
      //   .trim()
      //   .required("Schedule Description is required field"),
      selectTechnology: Yup.object()
        .nullable()
        .required("Technology is reqired field"),
      domainName: Yup.string()
        .trim()
        .min(5)
        .max(25)
        .required("Domain Name is required field"),
      domainWebsite: Yup.string()
        .trim()
        .url()
        .required(
          "Domain Website is required field (ex: https://www.google.com/)"
        ),
      domainCode: Yup.string()
        .trim()
        .min(2)
        .max(4)
        .required("Domain Code is required field"),
      domainDescription: Yup.string()
        .trim()
        .required("Domain Description is required field"),
    }),

    onSubmit: async (values) => {
      let data = {};
      if (values.selectTechnology?.name === "RDBMS") {
        data = {
          _id: domainId,
          technology: values?.selectTechnology?._id,
          name: values?.domainName,
          database: values?.selectDatabase?._id,
          domainWebsite: values?.domainWebsite,
          domainCode: values?.domainCode,
          domainDescription: values?.domainDescription,
          status: values?.enableStatus?._id,
        };
      } else {
        data = {
          _id: domainId,
          technology: values?.selectTechnology?._id,
          name: values?.domainName,
          domainWebsite: values?.domainWebsite,
          domainCode: values?.domainCode,
          domainDescription: values?.domainDescription,
          status: values?.enableStatus?._id,
        };
      }
      // console.log("Edit Domain Data", data);
      dispatch(editDomainData(data));
      setOpenPopup(false);
    },
  });

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Technology"
            name="selectTechnology"
            values={technologyList || []}
            onSelect={(batch) => {
              formik.setFieldValue("selectTechnology", batch);
            }}
            selected={formik.values.selectTechnology}
            errors={
              formik.touched.selectTechnology && formik.errors.selectTechnology
                ? formik.errors.selectTechnology
                : null
            }
          />
        </Grid>
        {formik.values.selectTechnology?.name === "RDBMS" && (
          <Grid item xs={12} md={6}>
            <SelectSingleValues
              variant="outlined"
              label="Select Database"
              name="selectDatabase"
              values={dbValueList || []}
              onSelect={(selectDatabase) =>
                formik.setFieldValue("selectDatabase", selectDatabase)
              }
              selected={formik.values.selectDatabase}
              errors={
                formik.touched.selectDatabase && formik.errors.selectDatabase
                  ? formik.errors.selectDatabase
                  : null
              }
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} style={{ marginTop: "-15px" }}>
          <TextFieldGroup
            variant="outlined"
            label="Domain Name"
            name="domainName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.domainName}
            errors={
              formik.touched.domainName && formik.errors.domainName
                ? formik.errors.domainName
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Domain Website"
            name="domainWebsite"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.domainWebsite}
            errors={
              formik.touched.domainWebsite && formik.errors.domainWebsite
                ? formik.errors.domainWebsite
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Domain Code"
            name="domainCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.domainCode}
            errors={
              formik.touched.domainCode && formik.errors.domainCode
                ? formik.errors.domainCode
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <SelectSingleValues
            label="Enable/Disable Domain"
            name="enableStatus"
            values={
              [
                { name: "Enabled", _id: true },
                { name: "Disabled", _id: false },
              ] || []
            }
            onSelect={(status) => {
              formik.setFieldValue("enableStatus", status);
            }}
            selected={formik.values.enableStatus}
            errors={
              formik.touched.enableStatus && formik.errors.enableStatus
                ? formik.errors.enableStatus
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextFieldGroup
            variant="outlined"
            label="Domain Descripton"
            name="domainDescription"
            multiline="multiline"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.domainDescription}
            rows={4}
            errors={
              formik.touched.domainDescription &&
              formik.errors.domainDescription
                ? formik.errors.domainDescription
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6} align="right">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="save"
              name="save"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </Grid>

        {/* <Grid item xs={12} md={6} align="left">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="reset"
              name="reset"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Reset
            </Button>
          </div>
        </Grid> */}
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

EditDomain.propTypes = {
  auth: PropTypes.object.isRequired,
  getTechnologyList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  domainReducer: state.domainReducer,
});

const mapDispatchToProps = {
  getTechnologyList,
  // addDomainData,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDomain);
