import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from "../../Api_Request";
import { Button, Grid, TextField, Typography, Paper, Box } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { setReportDetails, reset, setErrorMessage } from '../../redux/actions/productivityActions/productivityReportsActions'

const DisplayUserTests = () => {
    const [userEmail, setUserEmail] = useState('');
    const [testDetails, setTestDetails] = useState(null);
    const [incorrectInput, setincorrectInput] = useState(false);
    const [name, setName] = useState(null);
    const dispatch = useDispatch();
    const { productivityReportsReducer } = useSelector((state)=>state);

    const isEmailValid = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const fetchDetails = async () => {
        
        let requestBody;
        if(userEmail){
            requestBody = {
                "userEmail": userEmail
            };
        }
        else if(productivityReportsReducer.userEmail)
        {
            requestBody = {
                "userEmail": productivityReportsReducer.userEmail
            };
        }
        if(requestBody){
            await axios.get(`${API_URL}/reportGenerator/testDetails`, { params: requestBody })
            .then(response => {
                console.log('recevied resp: ', response)
                setName(response.data.name)
                setincorrectInput(false)
                setTestDetails(response.data.testDetails);
            })
            .catch(err => {
                console.log('error in displayFunction', err);
                setincorrectInput(err);
            });
        }
    }; 

    const viewReport = (test) =>{
        console.log(test);
        test["userEmail"] = userEmail;
        dispatch(setReportDetails(test))
    }

    useEffect(()=>{
        console.log('useEffect: ', productivityReportsReducer.userEmail);
        if(productivityReportsReducer.userEmail)
        {
            setUserEmail(productivityReportsReducer.userEmail);
            fetchDetails();
        }
    },[])

    return (
        <Grid container spacing={1} 
        style={{ margin: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="Enter User's Email"
                    type="email"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    error={(!isEmailValid(userEmail) && userEmail) || incorrectInput}
                    helperText={userEmail && !isEmailValid(userEmail)
                        ? "Invalid Format": incorrectInput ? "email not found in database":""}
                />
            </Grid>
            <Grid item xs = {2}>
                <Button 
                    variant='outlined'
                    style={{
                        width: "150px",
                        textTransform: "none"
                      }}
                    onClick={fetchDetails}
                >
                    Retrieve Tests
                </Button>
            </Grid>
            {name && testDetails && <Grid item xs={12}>
                <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 20
                }}
                >
                <Typography variant='h6'>{name}</Typography>
                {testDetails.map((test)=>{
                    return (
                        <Paper
                        key={test.id}
                        style={{
                            margin: 20,
                            padding: 25,
                            width: 800,
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                        elevation={16}
                        >
                        <Typography variant='body1'>Test Name: {test.testName}</Typography>
                        <Typography variant='body1'>Date: {test.createdAt}</Typography>
                        {!test.generated ? 
                        (<Button 
                        variant='contained'
                        onClick={()=>viewReport(test)}
                        >
                            Generate
                            </Button>)
                        :
                        (
                        test.insufficientEntries == true ? (
                                <Button disabled>
                                Insufficient Entries
                            </Button>)
                        :
                        (<Button
                        variant='outlined'
                        onClick={()=>viewReport(test)}
                        >
                            View   
                        </Button>))}
                        </Paper>
                    )
                })}
                </Box>
            </Grid>}
        </Grid>
    );
}

export default DisplayUserTests;
