import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import Table from "../../reusableComponents/MaterialTable";
import { Button } from "@material-ui/core";
import ButtonField from "../../reusableComponents/ButtonField";
import { Add as AddIcon } from "@material-ui/icons";
import CustomizedDialogs from "../../reusableComponents/CustomizedDialogs";
import AddNewDebuggingCode from "./AddNewDebuggingCode";

import { getAllDebuggingCodes } from "../../../redux/actions/productivityActions/debuggingCodeActions";
import EditDebuggingCode from "./EditDebuggingCode";
// import EditTechnology from "./EditTechnology";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const DebuggingCode = ({}) => {
  const { auth, codeReducer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [codeList, setCodeList] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const classes = useStyles();
  const { isAuthenticated } = auth;

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getAllDebuggingCodes());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (codeReducer?.debuggingCodeList) {
      const list = codeReducer?.debuggingCodeList?.map((item) => {
        item.edit = (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            name="edit"
            onClick={() => {
              setSelectedCode(item);
              setOpenEdit(true);
            }}
          >
            Edit
          </Button>
        );
        return item;
      });
      setCodeList(list);
    }
  }, [codeReducer?.debuggingCodeList]);

  const columns = [
    {
      title: "Topic Name",
      field: "capabilityTopic.name",
    },
    {
      title: "Complexity",
      field: "capabilityComplexity.name",
    },
    {
      field: "debuggingCode",
      title: "Code",
    },
    {
      field: "expectedComments",
      title: "Expected Comments",
    },
    {
      field: "edit",
      title: "Edit",
    },
  ];

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            title={
              <ButtonField
                variant="outlined"
                name="Add New Code"
                icon={<AddIcon />}
                onClick={() => setOpen(true)}
              />
            }
            columns={columns}
            data={codeList || []}
            pageSize={5}
            // selectedRow={(row) => history.push(`/admin/coach/${row._id}`)}
          />
        </Grid>
      </Grid>
      <CustomizedDialogs
        title="Add New Code"
        children={<AddNewDebuggingCode setOpenPopup={setOpen} />}
        openPopup={open}
        setOpenPopup={setOpen}
      />
      <CustomizedDialogs
        title="Edit Code Details"
        children={
          <EditDebuggingCode
            codeData={selectedCode}
            setOpenPopup={setOpenEdit}
          />
        }
        openPopup={openEdit}
        setOpenPopup={setOpenEdit}
      />
    </Container>
  );
};

export default DebuggingCode;
