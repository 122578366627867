import React, { Component } from "react";
import "./dashboard.css";
// import Menu from "../menu/menu";
import { Redirect, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { adminTopics } from "../../redux/actions/TopicsAction";
import Spinner from "../reusableComponents/Spinner"; 
import loader from ".././805.gif";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicData: [],
      opt: false,
    };
  }

  componentDidMount() {
    const { user } = this.props.auth;

    this.props.adminTopics();
  }

  handleTile(tile) {
    this.props.history.push(`/admin/questions?id=${tile}`);
  }

  changeCardColor = (id) => {
    this.setState({ opt: true });
  };

  changeCardColor1 = (id) => {
    this.setState({ opt: false });
  };

  render() {
    // const {topicData}=this.state;
    const url = process.env.REACT_APP_FRONT_END_URL_PATH;

    const { user, isAuthenticated } = this.props.auth;
    const { adminTopics } = this.props.assignedTopics;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div>
          {/* <h1>Dashboard Component</h1> */}
          {/* <Menu /> */}
          <section className="dashboard">
            <div className="container">
              <div className="row">
                {adminTopics ? (
                  adminTopics.map((top, index) => {
                    return (
                      <div className="col-sm-3 mt-5" key={top.id}>
                        <div
                          className="card"
                          onClick={() => this.handleTile(top.id)}
                          onMouseEnter={() => this.changeCardColor(top.id)}
                          onMouseLeave={() => this.changeCardColor1(top.id)}
                        >
                          <div className="card-body d-flex p-0 card-content">
                            <div className="align-self-center icons">
                              <img
                                className={
                                  this.state.opt ? "cardImg2" : "cardImg"
                                }
                                src={top.image_url}
                                width="50px"
                                height="50px"
                              />
                            </div>
                            <div className="title align-self-center text-center">
                              <h5>{top.name}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  // <div className="image-container">
                  //   <p className="image-holder">
                  //     <img src={loader} alt="" />
                  <>
                    <Spinner />
                  </>
                  //   </p>
                  // </div>
                )}
              </div>
            </div>
          </section>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  assignedTopics: state.assignedTopics,
});

export default connect(mapStateToProps, { adminTopics })(Dashboard);
