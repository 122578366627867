import {
  GETQUESTIONPAPERS,
  GETFILTEREDQUESTIONS,
  POSTQUESTIONPAPER,
  GETQUESTIONPAPERLISTS,
  TESTASSIGNEDUSERS,
  ASSIGNTESTUSER,
  POSTASSIGNTESTS,
} from "./type";
import axios from "axios";
import { API_URL } from "../../Api_Request";
import Swal from "sweetalert2";

export const getQuestionpapers = () => (dispatch) => {
  axios
    .get(`${API_URL}/questionpapers`)
    .then((res) => {
      dispatch({
        type: GETQUESTIONPAPERS,
        payload: res.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const getquestionlists = (params) => (dispatch) => {
  axios
    .get(
      `${API_URL}/questionpapers/getquestions/?topicId=${params.topicId}&skillId=${params.skillId}`
    )
    .then((res) => {
      dispatch({
        type: GETFILTEREDQUESTIONS,
        payload: res.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const postquestionpaper = (params) => (dispatch) => {
  axios
    .post(`${API_URL}/questionpapers`, params)
    .then((res) => {
      dispatch({
        type: POSTQUESTIONPAPER,
        payload: { success: true },
      });
    })
    .catch((err) => console.log(err));
};

export const getquestionpaperlist = (params) => (dispatch) => {
  axios
    .get(`${API_URL}/questionpapers/id?id=${params}`)
    .then((res) => {
      dispatch({
        type: GETQUESTIONPAPERLISTS,
        payload: res.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const getassignedusers = (params) => (dispatch) => {
  axios
    .get(`${API_URL}/assigntests/setuser/id?id=${params}`)
    .then((res) => {
      dispatch({
        type: TESTASSIGNEDUSERS,
        payload: res.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const assignuser = (params) => (dispatch) => {
  axios
    .get(`${API_URL}/assigntests/assignuser/id?id=${params}`)
    .then((res) => {
      dispatch({
        type: ASSIGNTESTUSER,
        payload: res.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const postassigntest = (params) => (dispatch) => {
  // console.log(params)
  axios
    .post(`${API_URL}/assigntests/`, params)
    .then((res) => {
      const result = res.data;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
      Toast.fire({
        icon: "success",
        title: "Test assigned successfully!!!",
      });
      dispatch(assignuser(params.paperId));
      dispatch({
        type: POSTASSIGNTESTS,
        payload: res.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const postQuestionPaperStatus = (params) => (dispatch) => {
  axios.post(`${API_URL}/questionpapers/status`, params);
  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 3000,
  });
  Toast.fire({
    icon: "success",
    title: `Question paper ${
      params?.status === 0 ? "Disabled" : "Enabled"
    } successfully`,
  })
    .then((res) => {
      dispatch(getQuestionpapers());
    })
    .catch((err) => console.log(err));
};
