import React, { Component } from "react";
//import data from './data.json';
import * as d3 from "d3";
import "./GroupBarChart.css";
// import Menu from "../menu/menu";
// import UserMenu from '../users/usermenu/usermenu';
import { Redirect } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { allusers } from "../../redux/actions/visualsAction";
import Swal from "sweetalert2";
import loader from ".././805.gif";

class AnimatedChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.props.allusers();
  }

  componentWillReceiveProps(nextProps) {
    const { allusersvisualdata } = nextProps.visuals;
    if (allusersvisualdata) {
      let d = document.getElementById("groupBarChart");
      d.innerHTML = "";
      if (allusersvisualdata.length > 0) {
        this.GroupbarChart(allusersvisualdata);
        this.setState({
          isLoaded: true,
        });
      } else {
        this.setState({
          isLoaded: true,
        });
        Swal.fire({
          icon: "warning",
          text: "No Data found to display chart !!!",
        }).then(() => {
          this.props.history.push("/login");
        });
      }
    }
  }

  GroupbarChart = (data) => {
    //taking all the unique names
    var names = [];

    data.forEach(function (d) {
      names.push(d.First_Name);
    });

    var dis_names = [...new Set(names)];
    dis_names.unshift("-Users-");

    // data for default scatter plot
    var coll = [];

    for (var i = 0; i < dis_names.length; i++) {
      var person = new Object();
      person.name = dis_names[i];
      var te = [];
      var c = 1;
      for (var j = 0; j < data.length; j++) {
        if (dis_names[i] == data[j]["First_Name"]) {
          te.push({
            count: c++,
            Total_Marks: data[j]["Total_Marks"],
            Correct_Answers: data[j]["Correct_Answers"],
            per: (data[j]["Correct_Answers"] / data[j]["Total_Marks"]) * 100,
            Skill_Level: data[j]["Skill_Level"],
            Topic_Name: data[j]["Topic_Name"],
            date: data[j]["Test_Date"],
            Test_name: data[j]["test_id"],
          });
        }
      }
      // console.log("each test:", te)
      person.test = te;
      coll.push(person);
    }

    console.log("collection", coll);

    // calculating average percentage for all the students
    var per = [];

    coll.forEach(function (d) {
      var cal = new Object();
      cal.First_Name = d.name;
      var no_of_test = d["test"].length;
      var Total_marks_sum = 0;
      var Correct_Answers_sum = 0;
      var avg;
      d["test"].forEach(function (d) {
        Total_marks_sum += d.Total_Marks;
        Correct_Answers_sum += d.Correct_Answers;
        avg = (Correct_Answers_sum / Total_marks_sum) * 100;
      });
      cal.Total_Marks = Total_marks_sum;
      cal.Correct_Answers = Correct_Answers_sum;
      cal.avg = d3.format(".3n")(avg);
      per.push(cal);
    });

    console.log("per", per);

    // create the drop down menu of topic names
    var selector = d3
      .select("#groupBarChart")
      .append("select")
      .attr("id", "topicselector3")
      .selectAll("option")
      .data(dis_names)
      .enter()
      .append("option")
      .text(function (d) {
        return d;
      })
      .attr("value", function (d, i) {
        return d;
      });

    var name;
    d3.select("#topicselector3").on("change", function (d) {
      name = this.value;
      for (var i = 0; i < coll.length; i++) {
        if (coll[i].name == name) {
          d3.select("svg").remove();
          ScatterPlot(coll[i].test);
        }
      }
      // topic_update();
    });

    ScatterPlot(coll[1].test);

    function ScatterPlot(data) {
      d3.select("svg").remove();

      console.log("chart", data);
      var count = data.length;

      // var i=1;
      // data.forEach(function(d){
      //     d.count = i++;
      // })

      // set the dimensions and margins of the graph
      var margin = { top: 60, right: 30, bottom: 100, left: 50 },
        width = 1000 - margin.left - margin.right,
        height = 550 - margin.top - margin.bottom;

      var y = d3
        .scaleLinear()
        .domain(
          d3.extent(data, function (d) {
            return d.per;
          })
        )
        .range([height, 0])
        .nice();

      // Add X axis
      var x = d3.scaleLinear().domain([1, count]).range([0, width]);

      // append the svg object to the body of the page
      var svg = d3
        .select("#groupBarChart")
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", "10 0 1000 550")
        // .attr("width", width + margin.left + margin.right)
        // .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // Add the grey background that makes ggplot2 famous
      svg
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("height", height)
        .attr("width", width)
        .style("fill", "rgba(68, 170, 213, 0.1)")
        .style("stroke", "white");
      //console.log()

      var xaxis = svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        // .attr("transform", "translate(0," + height + ")")
        .call(
          d3
            .axisBottom(x)
            .tickSize(-height * 1.3, height * 1.3)
            .ticks(10)
        )

        // .select(".domain").remove()
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".25em")
        .attr("transform", "rotate(-10)");

      svg
        .append("text")
        .attr("x", width - 500)
        .attr("y", height + 30)
        .text("Tests");
      //  .attr("transform","rotate(-45)")

      //  xaxis.selectAll(".domain").remove()

      // Add Y axis
      var div = d3
        .select("#groupBarChart")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

      svg
        .append("g")
        .attr("transform", "translate(0, 0)")
        .call(
          d3
            .axisLeft(y)
            .tickSize(-width * 1.3, width * 1.3)
            .ticks(10)
        );
      svg
        .append("text")
        .attr("x", -250)
        .attr("y", -20)
        .text("Percentage")
        .attr("transform", "rotate(-90)");
      //.selectAll(".domain").remove()

      // Customization
      svg
        .selectAll(".tick line ")
        .attr("stroke", "gray")
        .attr("stroke-width", "1");

      svg.selectAll(".domain").remove();

      // Color scale: give me a specie name, I return a color
      var color = d3
        .scaleOrdinal()
        .domain(["setosa", "versicolor", "virginica"])
        .range(["#F8766D", "#00BA38", "#619CFF"]);

      // Add dots
      svg
        .append("g")
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", function (d) {
          return x(d.count);
        })
        .attr("cy", function (d) {
          return y(d.per);
        })
        .attr("r", 5)
        //.style("fill", function (d) { return d.avg <= 40 ? "red" : d.avg > 40 && d.avg <= 60 ? "green" : "orange" } )
        .style("fill", "rgb(124, 181, 236)")
        .attr("stroke", "black")
        .attr("stroke-width", "2px")
        .on("mouseover", function (d) {
          d3.select(this);
          // .attr("stroke","black")
          // .attr("stroke-width","4px")
          div.transition().duration(200).style("opacity", 0.9);
          div
            .html(
              "Test: " +
                d.Test_name +
                "<br/>" +
                "Total Mark: " +
                d.Total_Marks +
                "<br/>" +
                "Correct Answers: " +
                d.Correct_Answers +
                "<br/>" +
                "Test: " +
                d.count +
                "<br/>" +
                "Date: " +
                d.date +
                "<br/>"
            )
            .style("left", d3.event.pageX + "px")
            .style("top", d3.event.pageY - 28 + "px");
        })
        .on("mouseout", function (d) {
          d3.select(this);
          // .attr("stroke","none")
          // .attr("stroke-width","0px")
          div.transition().duration(500).style("opacity", 0);
        });

      // define the line
      var valueline = d3
        .line()
        .x(function (d) {
          return x(d.count);
        })
        .y(function (d) {
          return y(d.per);
        });

      var t = d3
        .transition()
        .duration(5000)
        .ease(d3.easeLinear)
        .on("start", function (d) {
          console.log("started");
        });

      // Add the valueline path.
      var line = svg.selectAll(".line").data([data]);
      line
        .enter()
        .append("path")
        .merge(line)
        .attr("class", "line")
        .attr("d", valueline)
        .attr("stroke-dasharray", function (d) {
          return this.getTotalLength();
        })
        .attr("stroke-dashoffset", function (d) {
          return this.getTotalLength();
        })
        .attr("fill", "none")
        .attr("stroke", "steelblue")
        .attr("stroke-width", "3px");

      svg.selectAll(".line").transition(t).attr("stroke-dashoffset", 0);
    }
  };

  componentDidUpdate() {
    d3.selectAll("svg").remove();
  }

  render() {
    const { user } = this.props.auth;
    const role = user && user.role;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div className="container centop">
          {/* {role === 'user' ? <UserMenu/> : <Menu /> } */}
          {/* <Menu /> */}
          <div
            className="image-container"
            style={{
              display: this.state.isLoaded === false ? "block" : "none",
            }}
          >
            <p className="image-holder">
              <img src={loader} alt="" />
            </p>
          </div>
          <center>
            <div className="fontSize badge badge-info text-center">
              Animated User Performance chart based on Percentage
            </div>
          </center>
          <div id="groupBarChart"></div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  visuals: state.visuals,
});

const mapDispatchToProps = {
  allusers,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnimatedChart);
