import React , { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";

import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";
import { editTechnologyData} from "../../../redux/actions/productivityActions/domainAction";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const EditTechnology = ({ setOpenPopup,selectedTechnology }) => {
  const classes = useStyles();
  const dispatch = useDispatch();


  const formik = useFormik({
    initialValues: {
      technologyName: "",
      technologyStatus:""
    },
    validationSchema: Yup.object({
    technologyName: Yup.string()
        .trim()
        .required("Technology Name is required field"),
        technologyStatus: Yup.object()
        .nullable()
        .required("Status is required field"),
    }),

    onSubmit: async (values) => {
      const data = {
        _id:selectedTechnology._id,
        name: values?.technologyName,
        status:values?.technologyStatus.value,
      };
        dispatch(editTechnologyData(data));
    // console.log(selectedTechnology._id)
    // console.log(data);
   setOpenPopup(false);
    },
  });


 

useEffect(() => {
  formik.setFieldValue(
    "technologyName", selectedTechnology.name
  );
  
  formik.setFieldValue(
    "technologyStatus", {name:selectedTechnology.status?"Enabled":"Disabled",value:selectedTechnology.status}
  );
}, []);

  

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        
        <Grid item xs={12} md={6} style={{ marginTop: "-15px" }}>
          <TextFieldGroup
            variant="outlined"
            label="Technology Name"
            name="technologyName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.technologyName}
            errors={
              formik.touched.technologyName && formik.errors.technologyName
                ? formik.errors.technologyName
                : null
            }
          />
        </Grid>
          <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Status"
            name="technologyStatus"
            values={[{name:"Enabled",value:true},{name:"Disabled",value:false}]}
            onSelect={(status) => formik.setFieldValue("technologyStatus",status)}
            selected={formik.values.technologyStatus}
            errors={
              formik.touched.technologyStatus && formik.errors.technologyStatus
                ? formik.errors.technologyStatus
                : null
            }
          />
        </Grid>
  

        <Grid item xs={12} md={12} align="center">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="save"
              name="save"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </Grid>

      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

export default EditTechnology;
