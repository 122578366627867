import React, { Component } from "react";
// import Menu from "../menu/menu";
import "./questions.css";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  singletopicquestions,
  alltopicquestions,
  changeQuestionStatus,
} from "../../redux/actions/questionsAction";
import Tables from "../tables/tables";
import loader from ".././805.gif";
import Spinner from "../reusableComponents/Spinner";

class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      id: null,
      questionsDatas: [],
      currentElement: -1,
      topicId: "",
      data: [],
      buttonColor: "green",
    };
  }

  handleQuestion = () => {
    let { id } = this.state;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    id = params.get("id");
    this.props.history.push(`/admin/addquestions/${id}`);
  };

  componentDidMount() {
    let { id } = this.state;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    id = params.get("id");
    // console.log(id);
    if (id === "null" || id === undefined || id === null) {
      this.props.alltopicquestions();
    } else {
      this.props.singletopicquestions(id);
      this.setState({
        topicId: id,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.questions)
    if (
      !nextProps.questions.singletopicquestionsdata ||
      !nextProps.questions.alltopicquestionsdata
    ) {
      return null;
    } else if (nextProps.questions.singletopicquestionsdata.length < 0) {
      this.setState({
        isLoaded: false,
        questionsDatas: nextProps.questions.singletopicquestionsdata,
      });
    } else if (nextProps.questions.alltopicquestionsdata.length < 0) {
      this.setState({
        isLoaded: false,
        questionsDatas: nextProps.questions.alltopicquestionsdata,
      });
    } else {
      this.setState({ isLoaded: true });
    }
  }

  handleEdit(rowData) {
    // console.log("rowdata",rowData.rowData)
    const userdata = rowData.rowData;
    this.props.history.push(`/admin/editQuestions?id=${userdata[0]}`);
  }

  handleStatus(rowData) {
    // const userdata = rowData;
    console.log("rowdata", rowData);
    const data = {
      topicId: this.state.topicId,
      quesionId: rowData[0],
      status: rowData[7] === 0 ? 1 : 0,
    };
    console.log(data);
    this.props.changeQuestionStatus(data);
    const newColor = this.state.buttonColor === "green" ? "red" : "green";
    this.setState({ buttonColor: newColor });
  }

  render() {
    const { user, isAuthenticated } = this.props.auth;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      const { topicId } = this.state;
      // console.log(topicId)

      // TABLE
      const columns = [
        {
          name: "id",
          label: "Question Id",
          options: { filter: false, sort: true, display: false },
        },
        {
          name: "description",
          label: "Question",
          options: { filter: false, sort: true },
        },
        {
          name: "name",
          label: "Topic",
          options: { filter: true, sort: false },
        },
        {
          name: "type_name",
          label: "Type",
          options: { filter: true, sort: false },
        },
        {
          name: "com_name",
          label: "Complexity",
          options: { filter: true, sort: true },
        },
        {
          name: "chapter_name",
          label: "Chapter",
          options: { filter: true, sort: true },
        },
        {
          name: "Edit Question",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <button
                  className="btn btn-primary"
                  onClick={(e) => this.handleEdit(tableMeta)}
                  style={{ backgroundColor: "#003399", color: "whitesmoke" }}
                >
                  Edit Question
                </button>
              );
            },
          },
        },
        {
          name: "status",
          label: "Change Question Status",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                //   <label className="switch" id="status">
                //     {console.log(tableMeta.rowData[7])}
                //           {tableMeta.rowData[7] === 0 ?
                //          <input
                //          type="checkbox"
                //          onChange={(e) => this.handleStatus(tableMeta.rowData)}
                //           /> :
                //            <input
                //           type="checkbox"
                //           onChange={(e) => this.handleStatus(tableMeta.rowData)}
                //            checked
                //            />}
                //          <span className="sliders round"></span>
                //  </label>
                <label className="switch" id="status">
                  <input
                    type="checkbox"
                    onChange={() => this.handleStatus(tableMeta.rowData)}
                    checked={tableMeta.rowData[7] === 0} // Assuming 1 is the "checked" state
                  />
                  <span className="sliders round"></span>
                </label>
              );
            },
          },
        },
      ];
      const {
        singletopicquestionsdata,
        alltopicquestionsdata,
        changeQuestionStatus,
      } = this.props.questions;
      const data = this.state.questionsDatas;

      const options = {
        filterType: "checkbox",
        serverSide: false,
        pagination: true,
        // searchText: true,
        download: false,
        selectableRows: "none",
        viewColumns: false,
        rowsPerPage: 6,
        selectableRowsOnClick: true,
        rowsPerPageOptions: [10],
        rowHover: true,
        search: true,
        print: false,
        // customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        //     <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />
        //   ),
        responsive: "scrollMaxHeight",
        // rowsDeleted: false
        // onTableChange: (action, tableState) => {
        //     console.log(action);
        //     console.log(tableState);

        // }
      };

      return (
        <div>
          <div className="text-center">
            <button
              type="button"
              id="btn"
              value="add question"
              className="btn btn-primary"
              style={{ backgroundColor: "#003399", color: "whitesmoke" }}
              onClick={() => this.handleQuestion()}
            >
              Add Question
            </button>
          </div>
          <div className="mutable">
            {singletopicquestionsdata ? (
              <Tables
                title="Questions"
                data={singletopicquestionsdata}
                columns={columns}
                options={options}
              />
            ) : alltopicquestionsdata ? (
              <Tables
                title="Questions"
                data={alltopicquestionsdata}
                columns={columns}
                options={options}
              />
            ) : (
              // <div className="image-container">
              //   <p className="image-holder">
              //     <img src={loader} alt="" />
              //   </p>
              // </div>
              <Spinner />
            )}
          </div>
        </div>
        // </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  questions: state.questions,
});

const mapDispatchToProps = {
  singletopicquestions,
  alltopicquestions,
  changeQuestionStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
