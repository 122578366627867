import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTrueTechnologyList } from "../../../redux/actions/productivityActions/domainAction";
import { userTopics } from "../../../redux/actions/TopicsAction";
import CustomizedDialogs from "../../reusableComponents/CustomizedDialogs";
// import SelectProductivityTest from "./SelectProductivityTest";
import { Grid, Card, CardContent, Typography,CardMedia ,Avatar} from "@mui/material";
// import "./ProductivityUserDashboard.css";
import "../../dashboard/dashboard.css";
import SelectDebuggingComplexity from "./SelectDebuggingComplexity";

const DebuggingCodeTopics = ({ history }) => {
  const { domainReducer, assignedTopics, auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState({});

  useEffect(() => {
    dispatch(getTrueTechnologyList());
    dispatch(userTopics(auth?.user?.id));
  }, [auth]);

  const handleClick = (e, data) => {
    setSelectedTopic(data);
    setOpen(true);
    // console.log(selectedTopic);
    // history.push(`/menu/debugFeedback/${data?.id}`);
    // console.log(technology);
    // settechnologyId(technology?._id);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ paddingLeft: "10px", paddingRight: "-20px" }}
      >
        {
          // assignedTopics?.assignedTopics && (
          //   <section className="dashboard">
          //     <div className="container">
          //       <div className="row">
          //         {assignedTopics ? (
          //           assignedTopics?.assignedTopics?.map((top, index) => {
          //             return (
          //               <div className="col-sm-3 mt-5" key={top.id}>
          //                 <div
          //                 // className="card"
          //                 // onClick={() => this.handleTile(top.id)}
          //                 // onMouseEnter={() => this.changeCardColor(top.id)}
          //                 // onMouseLeave={() => this.changeCardColor1(top.id)}
          //                 >
          //                   <div className="card-body d-flex p-0 card-content">
          //                     <div className="align-self-center icons">
          //                       <img
          //                         // className={
          //                         //   this.state.opt ? "cardImg2" : "cardImg"
          //                         // }
          //                         src={top.image_url}
          //                         width="80px"
          //                         height="82px"
          //                         alt=""
          //                       />
          //                     </div>
          //                     <div className="title align-self-center text-center">
          //                       <h5>{top.name}</h5>
          //                     </div>
          //                   </div>
          //                 </div>
          //               </div>
          //             );
          //           })
          //         ) : (
          //           <Typography
          //             variant="h4"
          //             align="center"
          //             style={{ color: "red" }}
          //           >
          //             No Domains Found
          //           </Typography>
          //         )}
          //       </div>
          //     </div>
          //   </section>
          // )
          assignedTopics?.assignedTopics?.map((item) => {
            console.log(item)
            console.log(item.name!=="Business 101")
           if(item.name!=="Business 101"){
                if(item.name!=="Developer Testing"){
                    if(item.name!==" Human Resources Recruitment"){
              
            return (
              <Grid key={item?._id} item xs={12} md={4}>
                {/* <div > */}
                <Card
                  className="prodcard"
                  onClick={(e) => handleClick(e, item)}
                >
                    {/* <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={item.image_url}
                    alt="Live from space album cover"
        /> */}
                           
                  <CardContent className="text-center">

                            <img
                                className="cardImg3"
                                src={item.image_url}
                                width="50px"
                                height="50px"
                              />

                    {item?.name}
                  </CardContent>{" "}
                </Card>
                {/* </div> */}
              </Grid>
            );}}}
            else{
             
            }
          }) || (
            <Typography variant="h4" align="center" style={{ color: "red" }}>
              No Domains Found
            </Typography>
          )
        }
        <CustomizedDialogs
          title="Select Complexity"
          children={
            <SelectDebuggingComplexity
              setOpenPopup={setOpen}
              selectedTopic={selectedTopic}
              history={history}
            />
          }
          openPopup={open}
          setOpenPopup={setOpen}
        />
      </Grid>
    </>
  );
};

export default DebuggingCodeTopics;
