import axios from "axios";
import { GET_USERDASHBOARD_DATA ,GET_INTERVIEW_QUESTION_PAPERS} from "./type";
import { API_URL } from "../../Api_Request";

export const getUserDashboardData=(data)=>(dispatch)=>{
    axios
    .get(`${API_URL}/dashboard/getUserDashboardData`)
    .then((res)=>{
      
        //console.log("in action",res?.data)
        dispatch({
            type:GET_USERDASHBOARD_DATA,
            payload:res?.data
        })
    })
    .catch((err)=>{
        const {message}=err?.response?.data;
        console.log(message);

    })

}

export const getInterviewQuestionPaper=(data)=>(dispatch)=>{
    axios
    .get(`${API_URL}/questionpapers/getInterviewPapers`)
    
    .then((res)=>{
        
        // console.log("in action",res?.data)
        dispatch({
            type:GET_INTERVIEW_QUESTION_PAPERS,
            payload:res?.data
        })
    })
    .catch((err)=>{
        const {message}=err?.response?.data;
        console.log(message);

    })

}