import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { Button, Paper } from "@material-ui/core";
import {
  getCodeHint,
  setHintData,
} from "../../redux/actions/productivityActions/openAIActions";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import PythonTerminal from "./PythonTerminal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    //width: 500,
    // marginLeft: "400px"
  },
  appBar: {
    //backgroundColor:"#17479e" ,
    color: "black",
  },
}));

export default function ProductivityResultTabs({
  output,
  exception,
  code,
  sqlCodeSubmissionOutput,
  question,
  language,
  terminal,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [disableButton, setDisableButton] = useState(true);
  const [hintTab, setHintTab] = useState(false);
  const [hintTabIndex, setHintTabIndex] = useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { openAIReducer, codeSubmissionReducer, productivityTest } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  const getHint = () => {
    setDisableButton(true);
    setHintTab(true);
    dispatch(setHintData({ question, code, exception, language }));
    dispatch(getCodeHint({ question, code, exception, language }));
    setValue(hintTabIndex);
  };

  useEffect(()=>{
    if(terminal)
      setValue(0)
  }, [productivityTest.clearTerminal])

  useEffect(() => {
    console.log(sqlCodeSubmissionOutput)
    console.log(exception);
    console.log(codeSubmissionReducer);
    if(terminal != null)
      setHintTabIndex(1)
    if (exception?.length > 0) {
      setDisableButton(false);
      setValue(1);
    } else {
      setValue(0);
    }

    if (
        !codeSubmissionReducer.sqlTestCaseEvaluate && (codeSubmissionReducer.allClear ||
      codeSubmissionReducer.errorMessage.length > 0)
    )
      setValue(2);
  }, [exception, output, codeSubmissionReducer]);

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const exceptionLabel =
    exception?.length > 0 ? (
      <Typography>
        Exception <ErrorOutlineIcon />
      </Typography>
    ) : (
      <Typography>Exception</Typography>
    );

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.appBar}>
          <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
      aria-label="full width tabs example"
    >
      {!terminal ? (
        [
          <Tab key={0} label={`Output`} {...a11yProps(0)} />,
          <Tab key={1} label={exceptionLabel} {...a11yProps(1)} />,
          <Tab key={2} label={"Hint"} {...a11yProps(hintTabIndex)} />,
        ]
      ) : (
        [
          <Tab key={0} label={"Terminal"} {...a11yProps(0)} />,
          <Tab key={1} label={"Hint"} {...a11yProps(hintTabIndex)} />,
          codeSubmissionReducer.totalCases && (
            <Tab key={2} label={'Submission Results'} {...a11yProps(2)} />
          ),
        ]
      )}
    </Tabs>

      </AppBar>
      {!terminal && (
        <>
          <TabPanel value={value} index={0}>
            {!sqlCodeSubmissionOutput ? (<pre
              style={{
                backgroundColor: "#f1f1f1",
                width: "620px",
                height: "400px",
                overflow: "scroll",
                whiteSpace: "pre-wrap",
                // textAlign: "justify",
              }}
            >
              {output}
            </pre>
            ):
            codeSubmissionReducer.allClear ?(
              <pre
              style={{
                backgroundColor: "#f1f1f1",
                width: "620px",
                height: "400px",
                overflow: "scroll",
                whiteSpace: "pre-wrap",
                // textAlign: "justify",
              }}
            >
              <Typography variant="h6" sx={{ color: "green" }}>Correct Output</Typography>
              {sqlCodeSubmissionOutput}
            </pre>
            ):
            (
              <pre
              style={{
                backgroundColor: "#f1f1f1",
                width: "620px",
                height: "400px",
                overflow: "scroll",
                whiteSpace: "pre-wrap",
                // textAlign: "justify",
              }}
            >
              <Typography variant="h6" color="error">Incorrect Output</Typography>
              {sqlCodeSubmissionOutput}
            </pre>
            )
          }
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Button
              onClick={getHint}
              disabled={disableButton}
              variant="outlined"
              color="primary"
            >
              Generate Hint
            </Button>
            <pre
              variant="h5"
              style={{
                backgroundColor: "#ffffff",
                color: "red",
                fontSize: "20px",
                width: "620px",
                height: "450px",
                overflow: "scroll",
                whiteSpace: "pre-wrap",
              }}
            >
              {exception}
            </pre>
          </TabPanel>
          <TabPanel value={value} index={hintTabIndex}>
          {openAIReducer?.generatedHint?.length > 0 ? (
            <Paper
            elevation={3}
            style={{
              backgroundColor: "#ffffff",
              fontSize: "20px",
              overflowY: "auto",
              padding: "10px",
            }}
          >
              <Button 
              onClick={getHint}
              // disabled = {disableButton}
              variant="outlined"
              color="primary" >
              Generate Hint
            </Button>
            <pre style={{ whiteSpace: "pre-wrap", margin: 0 }}>
              {openAIReducer?.generatedHint}
            </pre>
          </Paper>
      ) : hintTab ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
      ) : (
        <div>
          <Button 
        onClick={getHint}
        // disabled = {disableButton}
        variant="outlined"
        color="primary" >
        Generate Hint
        </Button>
          <Typography variant = 'h5' sx={{ color: 'blue' }}>Attempt the question</Typography> 
          </div>
      )}
          </TabPanel>
        </>
      )}

      {/* terminal */}
      {terminal && (
        <>
        <TabPanel value={value} index={0}>
          <PythonTerminal code={code} />
        </TabPanel>
      
      {/* {
        <Button
          style={{
            overflowY: "auto",
            padding: "10px",
          }}
          onClick={getHint}
          // disabled = {disableButton}
          variant="outlined"
          color="primary"
        >
          Generate Hint
        </Button>
      } */}
      <TabPanel value={value} index={hintTabIndex}>
          {openAIReducer?.generatedHint?.length > 0 ? (
            <Paper
            elevation={3}
            style={{
              backgroundColor: "#ffffff",
              fontSize: "20px",
              overflowY: "auto",
              padding: "10px",
            }}
              >
             <Button 
              onClick={getHint}
              // disabled = {disableButton}
              variant="outlined"
              color="primary" >
              Generate Hint
            </Button>
            <pre style={{ whiteSpace: "pre-wrap", margin: 0 }}>
              {openAIReducer?.generatedHint}
            </pre>
          </Paper>
      ) : hintTab ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
      ) : (
        <div>
          <Button 
        onClick={getHint}
        // disabled = {disableButton}
        variant="outlined"
        color="primary" >
        Generate Hint
        </Button>
          <Typography variant = 'h5' sx={{ color: 'blue' }}>Attempt the question</Typography> 
          </div>
      )}
          </TabPanel>

      {codeSubmissionReducer.allClear ? (
        <TabPanel value={value} index={2}>
          <Paper
            elevation={3}
            style={{
              backgroundColor: "#ffffff",
              fontSize: "20px",
              overflowY: "auto",
              padding: "10px",
            }}
          >
            <Typography variant="h5" sx={{ color: "green" }}>
              Correct Solution
            </Typography>
            <Typography variant="h3" sx={{ color: "green" }}>
              {" "}
              {codeSubmissionReducer.noOfCasesCleared}/
              {codeSubmissionReducer.totalCases}{" "}
              <Typography sx={{ color: "green", display: "inline" }}>
                {" "}
                test cases cleared
              </Typography>
            </Typography>
          </Paper>
        </TabPanel>
      ) : (
        <TabPanel value={value} index={2}>
          <Paper
            elevation={3}
            style={{
              backgroundColor: "#ffffff",
              fontSize: "20px",
              overflowY: "auto",
              padding: "10px",
            }}
          >
            <Typography variant="h5" sx={{ color: "red" }}>
              Incorrect Solution
            </Typography>
            <Typography variant="h5" sx={{ color: "red" }}>
              <pre>{codeSubmissionReducer.errorMessage}</pre>
            </Typography>
            <Typography variant="h3" sx={{ color: "red" }}>
              {" "}
              {codeSubmissionReducer.noOfCasesCleared}/
              {codeSubmissionReducer.totalCases}{" "}
              <Typography variant="h5" sx={{ color: "red", display: "inline" }}>
                test cases passed
              </Typography>
            </Typography>
          </Paper>
        </TabPanel>
      )}
      </>
      )}
    </div>
  );
}
