import React, { useEffect } from "react";
import * as d3 from "d3";
import { capitalize } from "@material-ui/core";

const AnimatedChartComponent = ({ quadrantData }) => {
  useEffect(() => {
    console.log("quadrantData", quadrantData);

    if (quadrantData) {
      let d = document.getElementById("quadrantchart");
      d.innerHTML = "";
      quadrantPlot(quadrantData);
    }
    return () => d3.selectAll("svg").select("#quadrantchart").remove();
  }, [quadrantData]);

  const quadrantPlot = (data) => {
    d3.select("svg").remove();

    console.log("chart", data);
    var count = data.length;

    // var i=1;
    // data.forEach(function(d){
    //     d.count = i++;
    // })

    // set the dimensions and margins of the graph
    var margin = { top: 60, right: 30, bottom: 100, left: 50 },
      width = 1000 - margin.left - margin.right,
      height = 550 - margin.top - margin.bottom;

    var y = d3
      .scaleLinear()
      .domain(
        d3.extent(data, function (d) {
          return d.per;
        })
      )
      .range([height, 0])
      .nice();

    // Add X axis
    var x = d3.scaleLinear().domain([1, count]).range([0, width]);

    // append the svg object to the body of the page
    var svg = d3
      .select("#quadrantchart")
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "10 0 1000 550")
      // .attr("width", width + margin.left + margin.right)
      // .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Add the grey background that makes ggplot2 famous
    svg
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("height", height)
      .attr("width", width)
      .style("fill", "rgba(68, 170, 213, 0.1)")
      .style("stroke", "white");
    //console.log()

    var xaxis = svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      // .attr("transform", "translate(0," + height + ")")
      .call(
        d3
          .axisBottom(x)
          .tickSize(-height * 1.3, height * 1.3)
          .ticks(10)
      )

      // .select(".domain").remove()
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".25em")
      .attr("transform", "rotate(-10)");

    svg
      .append("text")
      .attr("x", width - 500)
      .attr("y", height + 30)
      .text("Tests");
    //  .attr("transform","rotate(-45)")

    //  xaxis.selectAll(".domain").remove()

    // Add Y axis
    var div = d3
      .select("#quadrantchart")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    svg
      .append("g")
      .attr("transform", "translate(0, 0)")
      .call(
        d3
          .axisLeft(y)
          .tickSize(-width * 1.3, width * 1.3)
          .ticks(10)
      );
    svg
      .append("text")
      .attr("x", -250)
      .attr("y", -20)
      .text("Percentage")
      .attr("transform", "rotate(-90)");
    //.selectAll(".domain").remove()

    // Customization
    svg
      .selectAll(".tick line ")
      .attr("stroke", "gray")
      .attr("stroke-width", "1");

    svg.selectAll(".domain").remove();

    // Color scale: give me a specie name, I return a color
    var color = d3
      .scaleOrdinal()
      .domain(["setosa", "versicolor", "virginica"])
      .range(["#F8766D", "#00BA38", "#619CFF"]);

    // Add dots
    svg
      .append("g")
      .selectAll("dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return x(d.count);
      })
      .attr("cy", function (d) {
        return y(d.per);
      })
      .attr("r", 5)
      //.style("fill", function (d) { return d.avg <= 40 ? "red" : d.avg > 40 && d.avg <= 60 ? "green" : "orange" } )
      .style("fill", "rgb(124, 181, 236)")
      .attr("stroke", "black")
      .attr("stroke-width", "2px")
      .on("mouseover", function (d) {
        d3.select(this);
        // .attr("stroke","black")
        // .attr("stroke-width","4px")
        div.transition().duration(200).style("opacity", 0.9);
        div
          .html(
            "Test: " +
              d.Test_name +
              "<br/>" +
              "Total Mark: " +
              d.Total_Marks +
              "<br/>" +
              "Correct Answers: " +
              d.Correct_Answers +
              "<br/>" +
              "Test: " +
              d.count +
              "<br/>" +
              "Date: " +
              d.date +
              "<br/>"
          )
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("mouseout", function (d) {
        d3.select(this);
        // .attr("stroke","none")
        // .attr("stroke-width","0px")
        div.transition().duration(500).style("opacity", 0);
      });

    // define the line
    var valueline = d3
      .line()
      .x(function (d) {
        return x(d.count);
      })
      .y(function (d) {
        return y(d.per);
      });

    var t = d3
      .transition()
      .duration(5000)
      .ease(d3.easeLinear)
      .on("start", function (d) {
        console.log("started");
      });

    // Add the valueline path.
    var line = svg.selectAll(".line").data([data]);
    line
      .enter()
      .append("path")
      .merge(line)
      .attr("class", "line")
      .attr("d", valueline)
      .attr("stroke-dasharray", function (d) {
        return this.getTotalLength();
      })
      .attr("stroke-dashoffset", function (d) {
        return this.getTotalLength();
      })
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", "3px");

    svg.selectAll(".line").transition(t).attr("stroke-dashoffset", 0);
  };

  return <div id="quadrantchart"></div>;
};

export default AnimatedChartComponent;
