import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

class Tables extends Component {
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableHeadCell: {
          fixedHeaderCommon: {
            backgroundColor: "#003399",
          },
        },
        MuiTableCell: {
          /* color: rgba(0, 0, 0, 0.87); */
          head: {
            color: "#fff",
            fontWeight: "bold",
          },
        },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: "#003399",
          },
        },
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            // margin : "0px 50px",
            border: "1px solid #dee2e6",
            borderCollapse: "collapse",
          },
        },
        MuiTypography: {
          h6: {
            marginLeft: "30px",
            fontWeight: "bold",
            color: "#003399",
          },
        },
        MUIDataTableSelectCell: {
          root: {
            display: "none",
          },
        },
      },
    });

  render() {
    const { title, data, columns, options } = this.props;

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <MUIDataTable
          className=" table-mui"
          title={title}
          data={data}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    );
  }
}

export default Tables;
