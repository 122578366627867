import React, { Component } from "react";
import Menu from "../menu/menu";
import "../questions/questions.css";
import Tables from "../tables/tables";
import { connect } from "react-redux";
import { getassignedusers } from "../../redux/actions/questionPapersAction";
import { Redirect } from "react-router-dom";
import loader from ".././805.gif";

class AssignedUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      id: null,
      data: [],
      topic_name: "",
      skill_name: "",
      papername: "",
    };
  }

  componentDidMount() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      return this.props.history.push("/menu/questionpapers");
    } else {
      let id = this.props.location.state.id;
      this.props.getassignedusers(id);
      this.setState({
        topic_name: this.props.location.state.topicnames,
        skill_name: this.props.location.state.skillnames,
        papername: this.props.location.state.paperName,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.questionpapers.assignedusersdata) {
      return null;
    } else if (nextProps.questionpapers.assignedusersdata.length < 0) {
      this.setState({
        data: nextProps.questionpapers.assignedusersdata,
        isLoaded: true,
      });
    } else {
      this.setState({
        data: nextProps.questionpapers.assignedusersdata,
        isLoaded: false,
      });
    }
  }

  handleBack = () => {
    this.props.history.push(`/admin/questionpapers`);
  };

  render() {
    const { user } = this.props.auth;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      //Table
      const columns = [
        {
          name: "id",
          label: "User Id",
          options: { filter: false, sort: true, display: false },
        },
        {
          name: "first_name",
          label: "Name",
          options: { filter: false, sort: true },
        },
        {
          name: "email",
          label: "Email",
          options: { filter: true, sort: true },
        },
        {
          name: "test_id",
          label: "Test Id",
          options: { filter: true, sort: false },
        },
        {
          name: "completed",
          label: "Test Completed",
          options: { filter: true, sort: false },
        },
      ];
      const data = this.state.data;

      const options = {
        filterType: "checkbox",
        serverSide: false,
        pagination: true,
        // searchText: true,
        download: false,
        selectableRows: "none",
        viewColumns: false,
        rowsPerPage: 6,
        selectableRowsOnClick: true,
        rowsPerPageOptions: [10],
        rowHover: true,
        search: true,
        print: false,
        responsive: "scrollMaxHeight",
      };
      const { assignedusersdata } = this.props.questionpapers;

      return (
        <div>
          {/* <div className="mt-5">  */}
          {assignedusersdata && (
            <div className="row font-weight-bold pr-5 pl-5">
              <div className="col-sm-4">
                Question Paper Name : {this.state.papername}
              </div>
              <div className="col-sm-3">
                Question Topic : {this.state.topic_name}
              </div>
              <div className="col-sm-3">
                Question Skill Level : {this.state.skill_name}
              </div>
              <div className="col-sm-2">
                <button
                  className="btn btn-danger"
                  onClick={() => this.handleBack()}
                >
                  Back
                </button>
              </div>
            </div>
          )}
          <div className="mutable">
            {assignedusersdata ? (
              <Tables
                title="Assigned Users List"
                data={assignedusersdata}
                columns={columns}
                options={options}
              />
            ) : (
              <div className="image-container">
                <p className="image-holder">
                  <img src={loader} alt="" />
                </p>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  questionpapers: state.questionpapers,
});

const mapDispatchToProps = {
  getassignedusers,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignedUsers);
