import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Comparative Chart",
    },
  },
};

const BarChart = ({ barGraphData, barGraphLabels }) => {
  // const barGraphData =[
  //     {
  //         "label": "Recent Test",
  //         "data": [
  //             8,
  //             11
  //         ],
  //         "backgroundColor": "rgba(224, 17, 95, 0.5)"
  //     },
  //     {
  //         "label": "Second Recent Test",
  //         "data": [
  //             9,2
  //         ],
  //         "backgroundColor": "rgba(255, 99, 132, 0.5)"
  //     },
  //     {
  //         "label": "Third Recent Test",
  //         "data": [1,4,],
  //         "backgroundColor": "rgb(250, 250, 51, 0.5)"
  //     }
  // ]

  // const barGraphLabels =[
  //     "ReactJS",
  //     "Oracle SQL"
  // ]
  const labels = barGraphLabels ? barGraphLabels : [];

  const data = {
    labels,
    datasets: barGraphData.map((item) => {
      return {
        label: item.label,
        data: item.data,
        backgroundColor: item.backgroundColor,
      };
    }),
  };

  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
};

export default BarChart;
