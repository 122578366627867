import React from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";

import TextFieldGroup from "../../reusableComponents/TextFieldGroup";

import { addTechnologyData } from "../../../redux/actions/productivityActions/domainAction";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));


const AddNewTechnology = ({ setOpenPopup }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      technologyName: "",
    },
    validationSchema: Yup.object({
      technologyName: Yup.string()
        .trim()
        .required("Technology Name is required field"),
    }),

    onSubmit: async (values) => {
      const data = {
        name: values?.technologyName,
      };
       dispatch(addTechnologyData(data));
       setOpenPopup(false);
    },
  });

  const handleReset = () => {
    formik.resetForm();
  }

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextFieldGroup
            variant="outlined"
            label="Technology Name"
            name="technologyName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.technologyName}
            errors={
              formik.touched.technologyName && formik.errors.technologyName
                ? formik.errors.technologyName
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6} align="right">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="save"
              name="save"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="reset"
              name="reset"
              color="primary"
              onClick={handleReset}
              className={classes.inputButtonFields}
              variant="contained"
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

export default AddNewTechnology;
