import React, { useState, useEffect } from 'react';
import DisplayUserTests from './DisplayUserTests';
import DisplayReport from './DisplayReport';
import { Typography, Grid } from '@mui/material'
import { useSelector, useDispatch } from "react-redux";

const ProductivityReports = () => {


    const { productivityReportsReducer } = useSelector(
        (state) => state
      );

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} >
                <Typography
                variant='h4'
                sx={{
                    marginTop: 5,
                    marginLeft: 50,
                    marginRight: 20,
                    color: '#000',
                    textTransform: 'none'
                }}
                >iProductivity Report Generator
                </Typography>
            </Grid>
            <Grid item xs={12} >
                {!productivityReportsReducer.testSelected && <DisplayUserTests
                 style={{
                    marginTop: 5,
                    marginBottom: 9,
                    marginLeft: 50,
                    marginRight: 20,
                 }}
                 />}
            </Grid>
            <Grid item xs={12}>
                {productivityReportsReducer.testSelected && <DisplayReport />}
            </Grid>
        </Grid>
    )
     
}

export default ProductivityReports;