import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TabPanel, TabContext, TabList } from "@material-ui/lab";
import Typography from '@mui/material/Typography';
import Overall from './overall';
import Cluster from './cluster';
import TopicWise from './topicwise';


const CapabilityAnalysisApplication = () => {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ 
      width: '80%',
      ml: 'auto',
      mr: 'auto',
      mt: 7,
      border: '1px solid #ccc', 
      padding: 2, 
    }}>
      <Typography
       variant='h5'
       style={{
        marginLeft: 325,
        marginBottom: 14
        // marginRight: '0'
       }}
      >Capability Analysis Application</Typography>
      <TabContext value={value}>
          <Box
          style={{
            "display": "flex",
            "justify-content": "space-around"
          }}
          >
          <AppBar position='static'>
        <TabList onChange={handleChange}>
          <Tab 
          style={{flex:'1'}}
          label='Overall' value='1'/>
          <Tab 
          style={{flex:'1'}}
          label='Similar Performers' value='2'/>
          <Tab 
          style={{flex:'1'}}
          label='Topicwise' value='3'/>
        </TabList>
        </AppBar>
        </Box>
        <TabPanel value='1'><Overall /></TabPanel>
        <TabPanel value='2'><Cluster /></TabPanel>
        <TabPanel value='3'><TopicWise /></TabPanel>
        
      </TabContext>
    </Box>
  );
}

export default CapabilityAnalysisApplication;
