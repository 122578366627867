import {SELECTTEST, TESTQUESTIONS, SETPOSTDATANULL, PREDEFINEDTESTQUESTIONS} from './type';
import axios from 'axios';
import { API_URL } from '../../Api_Request';
import Swal from 'sweetalert2';



export const selecttest = (params) => dispatch => {
    // console.log(params)
    axios.all([
            axios.get(`${API_URL}/members`),
            axios.get(`${API_URL}/complexity/user?topicId=${params.topicId}&id=${params.uid}`),
            axios.get(`${API_URL}/userlists/${params.email}`)
          ])
          .then(axios.spread((topicData, complexData, userData) => {
            dispatch({
                type : SELECTTEST,
                payload : {topicData:topicData, complexData:complexData, userData:userData}
            })
        
    }))
    .catch(err => console.log(err))
}

export const testquestions = (params) => dispatch => {
      // console.log(params)
      axios.get(`${API_URL}/testquestions/?topicId=${params.topicId}&skillId=${params.skillId}`)
      .then (res => {
        if (res.data.length < 10){
            Swal.fire(
              {icon: 'warning',
              text: 'The selected topic and complexity does not have enough questions. Please select different topic or complexity !!!'}
            )
          }
          dispatch({
              type : TESTQUESTIONS,
              payload : res.data
          })
          dispatch({
            type : SETPOSTDATANULL,
            payload : null
          })
          // console.log(res.data)
      })
      .catch(err => console.log(err))
  }

  export const predefinedtestquestions = (params) => dispatch => {
    // console.log(params)
    axios.get(`${API_URL}/testquestions/predefined/?paperId=${params.paperId}`)
    .then (res => {
      if (res.data.length < 10){
          Swal.fire(
            {icon: 'warning',
            text: 'The selected topic and complexity does not have enough questions. Please select different topic or complexity !!!'}
          )
        }
        dispatch({
            type : PREDEFINEDTESTQUESTIONS,
            payload : res.data
        })
        dispatch({
          type : SETPOSTDATANULL,
          payload : null
        })
        // console.log(res.data)
    })
    .catch(err => console.log(err))
}


