import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../redux/actions/authActions";
import Grid from "@mui/material/Grid";
import "../login/login.css";

const Login = ({ loginUser, auth, history }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const { isAuthenticated, user } = auth;

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.role === "user") {
        history.push("/menu/userdashboard");
      } else if (user?.role === "admin" || user?.role === "mentor") {
        history.push("/admin/dashboard");
      }else if (user?.role === "Interview Admin") {
        history.push("/menu/interviewDashboard");
      }
    } else {
      history.push("/login");
    }
  }, [auth]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const user = {
      username: formData.username,
      password: formData.password,
    };
    loginUser(user);
  };

  const loginPage = (
    <div className="main">
      <form onSubmit={(e) => handleSubmit(e)}>
        <section className="login">
          <Grid container className="SigninIC">
            <Grid item className="LeftBox" xs={12} md={5}>
              <p className="LeftBoxHead">Welcome To Capability App</p>
              <ul >
              <li style={{margin:"25px"}}>
                  <p className="LeftBoxSubHead-1">
                    Consist group of courses related questions used to check
                    learner stages
                  </p>
                  </li>
                
                <li style={{margin:"25px"}}>
                  <p className="LeftBoxSubHead-2">
                    SignUp with iBridge360 for the credentials of Capability App
                    Sign In
                  </p>
                </li>
              </ul>
            </Grid>
            <Grid item className="RightBox" xs={12} md={7} align="center">
              <p className="RightHeader1">Sign In</p>
              <div className="form-group login-form-data1 mx-auto">
                <div className=" ">
                  <div className="input-group-append">
                    <input
                      className="form-control"
                      required="required"
                      onChange={(e) =>
                        setFormData({ ...formData, username: e.target.value })
                      }
                      placeholder="Enter Email"
                      type="text"
                      name="name"
                    />
                    <span className="input-group-text mail-box">
                      <i
                        className="fa fa-envelope mail-icon"
                        style={{ color: "#003399" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group login-form-data1 mx-auto">
                <div className=" ">
                  <div className="input-group-append">
                    <input
                      className="form-control"
                      style={{ height: "35px" }}
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                      required="required"
                      placeholder="Enter Password"
                      type={showPassword ? "text" : "password"}
                      name="name"
                    />
                    <span
                      className="input-group-text eye-box"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className="fa fa-eye eye-icon"
                        style={{ color: "#003399" }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="ForgotpassStyle">
                <NavLink to="/forgotPassword" style={{ color: "#003399" }}>
                  Forgot Password?
                </NavLink>
              </div>
              <div className=" col-sm-12 mt-4">
                <center>
                  <button
                    className="btn btn-primary login-button"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    style={{ width: "100px" }}
                  >
                    Login
                  </button>
                </center>
              </div>
              <div style={{ height: "16px" }}></div>
              <button className="btn  ml-auto  home-button" type="button" onClick={()=>history.push("/")}>
                {/* <NavLink to="/" style={{color:"#003399"}}>Home</NavLink> */}
                {/* <a
                  href="https://www.ibridge360.com/"
                  style={{ color: "#003399" }}
                > */}
                  Home
                {/* </a> */}
              </button>
            </Grid>
          </Grid>
        </section>

        <section className="login-form">
          <div className="container"></div>
        </section>
      </form>
    </div>
  );

  return (
    <div>
      {!isAuthenticated
        ? loginPage
        : user?.role === "admin" ||
          user?.role === "mentor" ||
          user?.role === "Super Admin" ||
          user?.role === "Customer Admin"
        ? history.push("/admin/dashboard")
        : history.push("/menu/userdashboard")}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { loginUser })(Login);
