import { ALLUSERS, COMPARITIVEUSERLISTS, CHANGEUSERSTATUS } from './type';
import axios from 'axios';
import { API_URL } from '../../Api_Request';
import Swal from 'sweetalert2';



export const userlists = () => dispatch => {
    axios.get(`${API_URL}/userlists`)
    .then (res => {
        dispatch({
            type : ALLUSERS, 
            payload : res.data
        })
        
        // console.log(res.data)
    })
    .catch(err => console.log(err))
}

export const comparitivechartlists = () => dispatch => {
    axios.get(`${API_URL}/visuals/comparitive/`)
    .then (res => {
        dispatch({
            type : COMPARITIVEUSERLISTS, 
            payload : res.data
        })
        
        // console.log(res.data)
    })
    .catch(err => console.log(err))
}

export const changeUserStatus = (data) => dispatch => {
    axios.post(`${API_URL}/userlists/status`, data)
    .then (res => {
        dispatch(userlists())
        const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            icon: 'success',
            title: res.data && res.data.message
          })
    })
    .catch(err => {
        Swal.fire({icon: 'error',
                text: `${err.response.data.message}`}
              );
        console.log(err)
    })
}