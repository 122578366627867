import React, { Component } from "react";
import "../../dashboard/dashboard.css";
import { Redirect, NavLink } from "react-router-dom";
import { API_URL } from "../../../Api_Request";
import { Grid, Box, Typography } from "@mui/material";
import axios from "axios";
import { connect } from "react-redux";
import { userResultsData } from "../../../redux/actions/resultsAction";
import { userTopics } from "../../../redux/actions/TopicsAction";
import { getincompletetest } from "../../../redux/actions/assignTestAction";
import Swal from "sweetalert2";
import loader from "../.././805.gif";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DebuggingCodeTopics from "../../domainArena/DeuggingCodes/DebuggingCodeTopics";
import ProductivityUserDashboard from "./ProductivityUserDashboard";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicData: [],
      assignedtests: null,
      tests: 0,
      noOfTests: 0,
      opt: false,
      value: 0,
    };
  }

  componentDidMount() {
    const { user } = this.props.auth;
    const id = user && user.id;
    this.props.userTopics(id);
    this.props.getincompletetest(id);
    this.props.userResultsData(user && user.email);
    this.setState({ value: this?.props?.history?.location?.state?.value || 1 });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      topicData: nextProps.assignedTopics.assignedTopics,
    });
    if (!nextProps.assignedtests.getincompletetestdata) {
      return null;
    } else if (nextProps.assignedtests.getincompletetestdata.length < 0) {
      this.setState({
        tests: nextProps.assignedtests.getincompletetestdata.length,
      });
    } else {
      this.setState({
        tests: nextProps.assignedtests.getincompletetestdata.length,
      });
    }
    if (!nextProps.resultsData) {
      return null;
    } else if (nextProps.resultsData) {
      if (nextProps.resultsData.resultsData) {
        if (nextProps.resultsData.resultsData.length < 0) {
          this.setState({
            noOfTests: nextProps.resultsData.resultsData.length,
          });
        } else {
          this.setState({
            noOfTests: nextProps.resultsData.resultsData.length,
          });
        }
      }
    }
  }

  handleTile(tile) {
    const { user } = this.props.auth;
    let role = user && user.role_id;
    let addedby = user && user.added_by;
    if (role === "3" && addedby === "AOS") {
      // console.log(this.state.noOfTests);
      if (this.state.noOfTests >= 3) {
        Swal.fire({
          title: `You have completed free tests. Please check products pricing for unlimited number of tests`,
          icon: "warning",
          confirmButtonText: "Yes",
          showCancelButton: true,
          // cancelButtonText: 'No'
        }).then((res) => {
          if (res.value) {
            this.props.history.push("/menu/subscribe");
          }
          // else if(res.dismiss == 'cancel'){
          //     console.log('cancel');
          // }
        });
      } else {
        this.props.history.push({
          pathname: `/menu/selecttest`,
          state: {
            userId: tile,
            tests: 0,
          },
        });
      }
    } else {
      this.props.history.push({
        pathname: `/menu/selecttest`,
        state: {
          userId: tile,
          tests: 0,
        },
      });
    }
  }

  changeCardColor = (id) => {
    this.setState({ opt: true });
  };

  changeCardColor1 = (id) => {
    this.setState({ opt: false });
  };

  handleTabChange = (e, data) => {
    this.setState({ value: data });
  };

  render() {
    // const {topicData, isLoaded}=this.state;
    const url = process.env.REACT_APP_FRONT_END_URL_PATH;
    const { user, isAuthenticated } = this.props.auth;
    const { assignedTopics } = this.props.assignedTopics;
    // console.log(assignedTopics && assignedTopics)
    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    } else if (
      (user && user.role === "admin") ||
      (user && user.role === "mentor")
    ) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div>
          {/* <h1>Dashboard Component</h1> */}
          {/* <Menu /> */}
          <Tabs
            value={this.state.value}
            onChange={this.handleTabChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            TabIndicatorProps={{
              style: { background: "#2a52be", height: "3px" },
            }}
          >
            <Tab label="Capability" {...a11yProps(0)} />
            <Tab label="Productivity" {...a11yProps(1)} />
            <Tab label="Debug Code" {...a11yProps(2)} />
          </Tabs>
          <TabPanel
            value={this.state.value}
            index={0}
            // dir={theme.direction}
          >
            <section className="dashboard">
              <div className="container">
                <div className="row">
                  {assignedTopics ? (
                    assignedTopics.map((top, index) => {
                      return (
                        <div className="col-sm-3 mt-5" key={top.id}>
                          <div
                            className="card"
                            onClick={() => this.handleTile(top.id)}
                            onMouseEnter={() => this.changeCardColor(top.id)}
                            onMouseLeave={() => this.changeCardColor1(top.id)}
                          >
                            <div className="card-body d-flex p-0 card-content">
                              <div className="align-self-center icons">
                                <img
                                  className={
                                    this.state.opt ? "cardImg2" : "cardImg"
                                  }
                                  src={top.image_url}
                                  width="80px"
                                  height="82px"
                                  alt=""
                                />
                              </div>
                              <div className="title align-self-center text-center">
                                <h5>{top.name}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="image-container">
                      <p className="image-holder">
                        <img src={loader} alt="" />
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </TabPanel>

          <TabPanel
            value={this.state.value}
            index={1}
            // dir={theme.direction}
          >
            <ProductivityUserDashboard history={this.props.history} />
          </TabPanel>
          <TabPanel
            value={this.state.value}
            index={2}
            // dir={theme.direction}
          >
            <DebuggingCodeTopics history={this.props.history} />
          </TabPanel>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  assignedTopics: state.assignedTopics,
  assignedtests: state.assignedtests,
  resultsData: state.resultsData,
});

const mapDispatchToProps = {
  getincompletetest,
  userTopics,
  userResultsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
