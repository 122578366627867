import { GETQUESTIONTYPES, ADDQUESTIONTYPE, EDITQUESTIONTYPE } from './type';
import axios from 'axios';
import { API_URL } from '../../Api_Request';
import Swal from 'sweetalert2';



export const getQuestionTypes = () => dispatch => {
    axios.get(`${API_URL}/type`)
        .then(res => {
          dispatch({
              type : GETQUESTIONTYPES, 
              payload : res.data
          })
          
          // console.log(res.data)
      })
      .catch(err => console.log(err))
  }

export const addnewtype = (params) => dispatch => {
    axios.post(`${API_URL}/type`, params)
    .then(res => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000
      })
      
      Toast.fire({
        icon: 'success',
        title: 'Question Type added successfully'
      })
      dispatch(getQuestionTypes())
      dispatch({
          type : ADDQUESTIONTYPE, 
          payload : "Success"
      })
      
      // console.log(res.data)
  })
  .catch(err => console.log(err))
}

export const editQuestionType = (params) => dispatch => {
    axios.put(`${API_URL}/type/${params.id}`,params)
    .then(res => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000
      })
      
      Toast.fire({
        icon: 'success',
        title: 'Topic updated successfully'
      })
      dispatch(getQuestionTypes())
      dispatch({
          type : EDITQUESTIONTYPE, 
          payload : res.data
      })
      
      // console.log(res.data)
  })
  .catch(err => console.log(err))
}