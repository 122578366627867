import {
  GET_SCENARIO_LIST,
  GET_SCENARIO_INFO,
  GET_ENABLED_SCENARIO_LIST,
} from "../type";
import axios from "axios";
import { API_URL } from "../../../Api_Request";
import Swal from "sweetalert2";

export const getScenarioList = () => (dispatch) => {
  axios
    .get(`${API_URL}/scenario/getAllScenarios`)
    .then((res) => {
      dispatch({
        type: GET_SCENARIO_LIST,
        payload: res.data?.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getEnabledScenarioList = () => (dispatch) => {
  axios
    .get(`${API_URL}/scenario/getEnabledScenarios`)
    .then((res) => {
      dispatch({
        type: GET_ENABLED_SCENARIO_LIST,
        payload: res.data?.data,
      });
    })
    .catch((err) => console.log(err));
};
export const getScenarioInfo = (id) => (dispatch) => {
  axios
    .get(`${API_URL}/scenario/getScenarioInfo/${id}`)
    .then((res) => {
      dispatch({
        type: GET_SCENARIO_INFO,
        payload: res.data?.data,
      });
    })
    .catch((err) => console.log(err));
};

export const addScenarioData = (params) => (dispatch) => {
  axios
    .post(`${API_URL}/scenario/addScenario`, params)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: res?.data?.message || "Scenario added successfully",
      });
      dispatch(getScenarioList());
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const editScenarioData = (data) => (dispatch) => {
  axios
    .put(`${API_URL}/scenario/updateScenario`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: res.data.message,
      });
      dispatch(getScenarioList());
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const editScenarioImage = (data) => (dispatch) => {
  axios
    .put(`${API_URL}/scenario/updateScenarioImage`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: res.data.message,
      });
      dispatch(getScenarioList());
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};
