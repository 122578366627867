import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { uploadAudio } from "../../redux/actions/uploadFileActions";

const AudioRecorder = ({ uploadAudio, auth }) => {
  const { user } = auth;
  const errorMsgElement = document.querySelector("span#errorMsg");
  const recordedVideo = document.querySelector("video#recorded");
  const recordButton = document.querySelector("button#record");
  const playButton = document.querySelector("button#play");
  const downloadButton = document.querySelector("button#download");
  const [disableRecord, setDisableRecord] = useState(true);
  const [disablePlay, setDisablePlay] = useState(true);
  const [disableDownload, setDisableDownload] = useState(true);

  const [showRecorded, setShowRecorded] = useState(false);
  const [record, setRecord] = useState("Start Recording");
  const [audioMic, setAudioMic] = useState("Start Mic");
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const [audioUrl, setaudioUrl] = useState("");
  const [webStream, setWebStream] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const videoRef = useRef(null);

  const startAudio = async (e) => {
    const constraints = {
      audio: true,
      video: {
        width: 1280,
        height: 720,
      },
    };
    if (audioMic === "Start Mic") {
      setAudioMic("Stop Mic");
      try {
        navigator.getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia;
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setWebStream(stream);
        handleSuccess(stream);
      } catch (e) {
        console.error("navigator.getUserMedia error:", e);
        // errorMsgElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
      }
    } else if (audioMic === "Stop Mic") {
      setAudioMic("Start Mic");
      handleStop(webStream);
    }
  };

  const handleSuccess = (stream) => {
    setDisableRecord(false);
    console.log("getUserMedia() got stream:", stream);
    window.stream = stream;
    let video = videoRef.current;
    video.srcObject = stream;
    video.play();
  };

  const handleStop = (stream) => {
    console.log(stream);
    const video = document.querySelector("#gum");
    for (const track of video.srcObject.getTracks()) {
      track.stop();
    }
    video.srcObject = null;
    stream.getTracks().forEach(function (track) {
      console.log(track, track.stop());
      let video = videoRef.current;
      video.pause();
      video.srcObject = null;
      track.stop();
      // if (track.readyState == 'live') {
      //     track.stop();
      // }
    });
    console.log(stream, stream.getTracks());
    setDisableRecord(true);
    setDisablePlay(true);
    setDisableDownload(true);
  };

  const recordAudio = () => {
    if (record === "Start Recording") {
      setRecord("Stop Recording");
      startRecording();
    } else {
      setRecord("Start Recording");
      setDisablePlay(false);
      setDisableDownload(false);
      stopRecording();
    }
  };

  const startRecording = () => {
    let mediaRecorder = null;
    let recordedBlobs = [];
    let options = { mimeType: "video/webm;codecs=vp9,opus" };
    try {
      mediaRecorder = new MediaRecorder(window.stream, options);
    } catch (e) {
      console.error("Exception while creating MediaRecorder:", e);
      errorMsgElement.innerHTML = `Exception while creating MediaRecorder: ${JSON.stringify(
        e
      )}`;
      return;
    }
    recordButton.textContent = "Stop Recording";
    setDisablePlay(true);
    setShowRecorded(false);
    setDisableDownload(true);
    mediaRecorder.onstop = (event) => {
      console.log("");
    };
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start();
    setRecorder(mediaRecorder);
  };

  const handleDataAvailable = (event) => {
    console.log("handleDataAvailable", event);
    if (event.data && event.data.size > 0) {
      console.log(recordedBlobs, "before");
      setRecordedBlobs([]);
      let blobs = recordedBlobs.slice(-1);
      console.log(blobs, "jjdfnjsnf", event.data);
      // blobs.push(event.data);
      setRecordedBlobs([event.data]);
      console.log([recordedBlobs[recordedBlobs.length - 1]], "after");
      // recordedBlobs.push(event.data);
    }
  };

  const stopRecording = () => {
    recorder.stop();
    setDisablePlay(false);
    setDisableDownload(false);
  };

  const playRecorded = () => {
    const superBuffer = new Blob(recordedBlobs, {
      type: "audio/ogg; codecs=opus",
    });
    setShowRecorded(true);
    setaudioUrl(window.URL.createObjectURL(superBuffer));
    // const audio = document.getElementById("playAudio");
    new Audio(superBuffer).play();
    // audio.play();
  };

  const downloadVideo = () => {
    const blob = new Blob(recordedBlobs, { type: "audio/ogg; codecs=opus" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    const formData = new FormData();
    formData.append("file", blob);

    uploadAudio(formData);
    setShowRecorded(true);
  };

  return (
    <>
      <div id="container">
        <div className="buttonContainer">
          <button
            id="start"
            type="button"
            className="btn btn-warning"
            onClick={startAudio}
          >
            {audioMic}
          </button>
          <button
            id="record"
            type="button"
            className="ml-2 btn btn-primary"
            disabled={disableRecord}
            onClick={recordAudio}
          >
            {record}
          </button>
          <button
            id="play"
            type="button"
            className="ml-2 btn btn-success"
            disabled={disablePlay}
            onClick={playRecorded}
          >
            Play
          </button>
          <button
            id="download"
            type="button"
            disabled={disableDownload}
            onClick={downloadVideo}
            className="ml-2 btn  btn-dark"
          >
            Submit
          </button>
        </div>
        <div>
          <audio
            style={{ display: showRecorded ? "block" : "none" }}
            className="mt-3"
            id="playAudio"
            src={audioUrl}
            controls="controls"
          />
        </div>
        <div>
          <span id="errorMsg"></span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { uploadAudio };

export default connect(mapStateToProps, mapDispatchToProps)(AudioRecorder);
