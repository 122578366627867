import {
  Menu as MenuIcon,
  Close as CloseIcon,
  AccountCircle,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  ExitToApp as ExitToAppIcon,
  Dashboard as DashboardIcon,
  ListAlt as ListAltIcon,
  AddBox as AddBoxIcon,
} from "@material-ui/icons";
import dashboard from "../../images/logo/dashboard-filled.png";
import result from "../../images/logo/result.png";
import results from "../../images/logo/results.png";
import graph from "../../images/logo/graph.png";
import assigned from "../../images/logo/assigned.png";
import managetest from "../../images/logo/managetest.png";
import questionpaper from "../../images/logo/questionpaper.png";
import user from "../../images/logo/user.png";
import group from "../../images/logo/group.png";
import usergroup from "../../images/logo/usergroup.png";
import manageuser from "../../images/logo/manage-users-fill.png";
import code from "../../images/logo/code.png";
import prod from "../../images/logo/pro.png";
import page from "../../images/logo/page.png";
import customers from "../../images/logo/customers.png";

export const DrawerList = [
  {
    id: 1,
    icon: <img src={dashboard} alt="" />,
    name: "Dashboard",
    route: "/admin/dashboard",
  },
  {
    id: 2,
    icon: <img src={questionpaper} alt="" />,
    name: "Questions",
    // route: "/admin/mvkLists",
    open: "questionOpen",
    handleClick: "setQuestionOpen",
    nested: [
      {
        id: 1,
        icon: <img src={page} alt="" />,
        name: "Questions Topic",
        route: "/admin/questionTopic",
      },
      {
        id: 2,
        icon: <img src={page} alt="" />,
        name: "Questions Chapter",
        route: "/admin/chapters",
      },
      {
        id: 3,
        icon: <img src={page} alt="" />,
        name: "Questions Type",
        route: "/admin/questionType",
      },
      {
        id: 4,
        icon: <img src={page} alt="" />,
        name: "Questions Complexity",
        route: "/admin/questionComplexity",
      },
      {
        id: 5,
        icon: <img src={page} alt="" />,
        name: "All Questions",
        route: "/admin/questions",
      },
    ],
  },
  {
    id: 3,
    icon: <img src={managetest} alt="" />,
    name: "Manage Tests",
    // route: "/admin/mvkLists",
    open: "testOpen",
    handleClick: "setTestOpen",
    nested: [
      {
        id: 1,
        icon: <img src={questionpaper} alt="" />,
        name: "Set Questions",
        route: "/admin/questionpapers",
      },
    ],
  },
  {
    id: 4,
    icon: <img src={group} alt="" />,
    name: "Customer",
    // route: "/admin/mvkLists",
    open: "customerOpen",
    handleClick: "setCustomerOpen",
    nested: [
      {
        id: 1,
        icon: <img src={customers} alt="" />,
        name: "Customers",
        route: "/admin/customerlist",
      },
      {
        id: 2,
        icon: <img src={usergroup} alt="" />,
        name: "User Groups",
        route: "/admin/usergroups/0",
      },
    ],
  },

  {
    id: 5,
    icon: <img src={results} alt="" />,
    name: "Results",
    // route: "/admin/mvkLists",
    open: "resultsOpen",
    handleClick: "setResultsOpen",
    nested: [
      {
        id: 1,
        icon: <img src={result} alt="" />,
        name: "Capability Results",
        route: "/admin/CapabilityResults",
      },
      {
        id: 2,
        icon: <img src={result} alt="" />,
        name: "Productivity Results",
        route: "/admin/ProductivityResults",
      },
      // {
      //   id: 3,
      //   icon: <img src={result} alt="" />,
      //   name: "Interview Results",
      //   route: "/admin/interviewResult",
      // },
      {
        id: 4,
        icon: <img src={result} alt="" />,
        name: "Feedback Results",
        route: "/admin/feedbackResults",
      },
      {
        id: 5,
        icon: <img src={result} alt=""/>,
        name: "Capability Analysis",
        route: "/admin/capabilityAnalysisApplication"
      }
    ],
  },

  // {
  //   id: 5,
  //   icon: <ListAltIcon style={{ color: "white" }} />,
  //   name: "Results",
  //   route: "/admin/results",
  // },
  {
    id: 6,
    icon: <img src={graph} alt="" />,
    name: "Analysis",
    // route: "/admin/mvkLists",
    open: "analysisOpen",
    handleClick: "setAnalysisOpen",
    nested: [
      {
        id: 1,
        icon: <img src={graph} alt="" />,
        name: "User Comparitive Chart",
        route: "/admin/userlists",
      },
      {
        id: 2,
        icon: <img src={graph} alt="" />,
        name: "Animated User Chart",
        route: "/admin/animatedchart",
      },
      {
        id: 3,
        icon: <img src={graph} alt="" />,
        name: "User Performance Chart",
        route: "/admin/userperformance",
      },
      {
        id: 4,
        icon: <img src={graph} alt="" />,
        name: "User Result Chart",
        route: "/admin/resultchart",
      },
    ],
  },
  {
    id: 7,
    icon: <img src={assigned} alt="" />,
    name: "Assign Topics",
    route: "/admin/users",
  },
  {
    id: 8,
    icon: <img src={manageuser} alt="" />,
    name: "Manage Users",
    route: "/admin/manageusers",
  },
  {
    id: 9,
    icon: <img src={code} alt="" />,
    name: "Productivity",
    open: "domainArenasOpen",
    handleClick: "setDomainArenaisOpen",
    nested: [
      {
        id: 0,
        icon: <img src={prod} alt="" />,
        name: "Technology",
        route: "/admin/technologies",
      },
      {
        id: 1,
        icon: <img src={prod} alt="" />,
        name: "Databases",
        route: "/admin/database",
      },
      {
        id: 2,
        icon: <img src={prod} alt="" />,
        name: "Domain",
        route: "/admin/domain",
      },
      {
        id: 3,
        icon: <img src={prod} alt="" />,
        name: "Function",
        route: "/admin/function",
      },
      {
        id: 4,
        icon: <img src={prod} alt="" />,
        name: "Scenario",
        route: "/admin/scenario",
      },
      {
        id: 5,
        icon: <img src={prod} alt="" />,
        name: "Questions",
        route: "/admin/question",
      },
      {
        id: 6,
        icon: <img src={prod} alt="" />,
        name: "Debugging Code",
        route: "/admin/debuggingCode",
      },
      {
        id: 7,
        icon: <img src={prod} alt="" />,
        name: "Productivity Reports",
        route: "/admin/iproductivityReportGenerator"
      }
    ],
  },
];
