import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { resetPassword } from "../../redux/actions/authActions";
import { NavLink } from "react-router-dom";
import "./resetPassword.css";
import Swal from "sweetalert2";

const ResetPassword = ({ history, resetPassword, match }) => {
  const initialValues = {
    newPassword: "",
    confirmNewPassword: "",
  };
  const [formData, setFormData] = useState({ ...initialValues });
  const [showPass, setShowPass] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const { token } = match.params;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.newPassword) {
      Swal.fire({
        icon: "error",
        text: `New password field is empty`,
      });
    } else if (!formData.confirmNewPassword) {
      Swal.fire({
        icon: "error",
        text: `Confirm password field is empty`,
      });
    } else if (formData.newPassword !== formData.confirmNewPassword) {
      Swal.fire({
        icon: "error",
        text: `Passwords does not match!!!`,
      });
    } else if (!token) {
      Swal.fire({
        icon: "error",
        text: `Something is wrong with the link. Please click on email link and try again`,
      });
    } else {
      const data = {
        newPassword: formData.newPassword,
        confirmNewPassword: formData.confirmNewPassword,
        token: token,
      };
      resetPassword(data, history);
    }
  };

  return (
    <div className="main">
      <section className="login">
        <Grid container className="SigninIC">
          <Grid item md={1}></Grid>
          <Grid item className="ResetBox" item md={9} align="center">
            <p
              className="RightHeaderReset"
              // style={{
              //   fontSize: "24px",
              //   fontFamily: "Verdana, Arial, Helvetica, sans-serif",
              //   marginTop: "-20px",
              // }}
            >
              Reset Password
            </p>

            <form onSubmit={handleSubmit}>
              <div className="form-group login-form-data-Reset mx-auto">
                <div className=" ">
                  <div class="input-group-append">
                    <input
                      className="form-control"
                      style={{ height: "35px" }}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          newPassword: e.target.value,
                        })
                      }
                      required="required"
                      placeholder="New Password"
                      type={showPass ? "text" : "password"}
                      name="newPassword"
                    />
                    <span
                      className="input-group-text eye-box"
                      onClick={() => setShowPass(!showPass)}
                    >
                      <i className="fa fa-eye eye-icon" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group login-form-data-Reset mx-auto">
                <div className=" ">
                  <div class="input-group-append">
                    <input
                      className="form-control"
                      style={{ height: "35px" }}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          confirmNewPassword: e.target.value,
                        })
                      }
                      required="required"
                      placeholder="Confirm Password"
                      type={showPass1 ? "text" : "password"}
                      name="confirmNewPassword"
                    />
                    <span
                      class="input-group-text eye-box"
                      onClick={() => setShowPass1(!showPass1)}
                    >
                      <i className="fa fa-eye eye-icon" />
                    </span>
                  </div>
                </div>
              </div>

              <div className=" col-sm-12 mt-4">
                <center>
                  <button
                    className="btn btn-primary  ml-auto submit-button"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Reset Password
                  </button>
                </center>
              </div>
            </form>
            <div style={{ height: "20px" }}></div>
          </Grid>
        </Grid>
      </section>

      <section className="login-form">
        <div className="container"></div>
      </section>
    </div>
  );
};

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { resetPassword };

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

// import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
// // import "../signup/signup.css";
// import { connect } from "react-redux";
// import { loginUser } from "../../redux/actions/authActions";
// import TextFieldGroup from "../reusableComponents/TextFieldGroup";
// import { Grid, Button, TextField, Typography } from "@material-ui/core";
// import "./resetPassword.css";

// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       showPass: false,
//       showPass1: false,
//       uName: "",
//       Password: "",
//       isLogin: "",
//       valueLogin: "",
//       data: [],
//       err: "",
//     };
//   }

//   handletoggle() {
//     this.setState({ showPass: !this.state.showPass });
//     console.log(this.state.showPass);
//   }

//   handletoggle1() {
//     this.setState({ showPass1: !this.state.showPass1 });
//     console.log(this.state.showPass1);
//   }

//   handleName(e) {
//     this.setState({
//       uName: e.target.value,
//     });
//   }
//   handlePassword(e) {
//     this.setState({
//       Password: e.target.value,
//     });
//   }
//   handleSubmit = (e) => {
//     e.preventDefault();
//     const user = {
//       username: this.state.uName,
//       password: this.state.Password,
//     };
//     this.props.loginUser(user);
//   };

//   componentWillReceiveProps(nextProps) {
//     // console.log(nextProps.auth.user)
//     const { role } = nextProps.auth.user;
//     const { isAuthenticated } = nextProps.auth;
//     if (isAuthenticated) {
//       if (role === "user") {
//         this.props.history.push("/menu/userdashboard");
//       } else if (role === "admin" || role === "mentor") {
//         this.props.history.push("/menu/dashboard");
//       }
//     } else {
//       this.props.history.push("/login");
//     }
//   }

//   render() {
//     const loginPage = (
//       <div className="main">
//         <section className="login">
//           <Grid container className="SigninIC">
//             <Grid item md={1}></Grid>
//             <Grid item className="ResetBox" item md={9} align="center">
//               <p
//                 className="RightHeader"
//                 style={{
//                   fontSize: "24px",
//                   fontFamily: "Verdana, Arial, Helvetica, sans-serif",
//                   marginTop: "-20px",
//                 }}
//               >
//                 Reset Password
//               </p>

//               <div className="form-group login-form-data mx-auto">
//                 <div className=" ">
//                   <div class="input-group-append">
//                     <input
//                       className="form-control"
//                       style={{ height: "35px" }}
//                       onChange={(e) => this.handlePassword(e)}
//                       required="required"
//                       placeholder="New Password"
//                       type={this.state.showPass ? "text" : "password"}
//                       name="name"
//                     />
//                     <span
//                       class="input-group-text eye-box"
//                       onClick={() => this.handletoggle()}
//                     >
//                       <i className="fa fa-eye eye-icon" />
//                     </span>
//                   </div>
//                 </div>
//               </div>
//               <div className="form-group login-form-data mx-auto">
//                 <div className=" ">
//                   <div class="input-group-append">
//                     <input
//                       className="form-control"
//                       style={{ height: "35px" }}
//                       onChange={(e) => this.handlePassword(e)}
//                       required="required"
//                       placeholder="Confirm Password"
//                       type={this.state.showPass1 ? "text" : "password"}
//                       name="name"
//                     />
//                     <span
//                       class="input-group-text eye-box"
//                       onClick={() => this.handletoggle1()}
//                     >
//                       <i className="fa fa-eye eye-icon" />
//                     </span>
//                   </div>
//                 </div>
//               </div>

//               <div className=" col-sm-12 mt-4">
//                 <center>
//                   <button
//                     className="btn btn-primary  ml-auto submit-button"
//                     type="submit"
//                     onClick={(e) => this.handleSubmit(e)}
//                   >
//                     Reset Password
//                   </button>
//                 </center>
//               </div>
//               <div style={{ height: "20px" }}></div>
//             </Grid>
//           </Grid>
//         </section>

//         <section className="login-form">
//           <div className="container"></div>
//         </section>
//       </div>
//     );

//     const { user, isAuthenticated } = this.props.auth;
//     const role = user && user.role;
//     return (
//       <div>
//         {!isAuthenticated
//           ? loginPage
//           : role === "admin" ||
//             role === "mentor" ||
//             role === "Super Admin" ||
//             role === "Customer Admin"
//           ? this.props.history.push("/menu/dashboard")
//           : this.props.history.push("/menu/userdashboard")}
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   auth: state.auth,
// });

// export default connect(mapStateToProps, { loginUser })(Login);
