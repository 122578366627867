import React, { useState } from "react";
import "../../menu/menu.css";
import { NavLink, withRouter, Link } from "react-router-dom";
import {
  Button,
  CssBaseline,
  AppBar,
  Toolbar,
  Container,
  Menu,
  MenuItem,
  useScrollTrigger,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { logoutUser } from "../../../redux/actions/authActions";
import Logo from "../../../images/logo/ibridgelogo.png";
import person from "../../../images/logo/profileb.png";
import down from "../../../images/logo/expandb.png";
import up from "../../../images/logo/collapseb.png";
import logout from "../../../images/logo/logout.png";
// /images/logo/profileb.png

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    marginLeft: theme.spacing(2),
  },
  menuCollapseContaier: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    textDecoration: "none",
    color: "#666666",
    cursor: "pointer",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    "&:hover": {
      color: "#003399",
    },
  },
  titleActive: {
    color: "#003399",
  },
  mobileTitle: {
    color: "#666666",
    textDecoration: "none",
    fontSize: 16,
    fontWeight: 600,
  },
  profileIconButton: {
    marginLeft: "10px",
    background: "#F8F9F9",
    border: "1px solid #005a86",
    borderRadius: 25,
    color: "#005a86",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 16,
    "&:hover": {
      border: "1px solid #006b9f",
      background: "#005a86",
      color: "#F8F9F9",
    },
  },
  profileIcon: {
    marginRight: theme.spacing(1),
  },
  userDetails: {
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    color: "#2B2B2B",
    cursor: "pointer",
    fontWeight: 600,
  },
  profileDrop: {
    marginTop: "45px",
    "& .MuiList-root": {
      // width: 130
      // background: '#233044',
    },
    "& .MuiButtonBase-root": {
      // color: '#F8F9F9',
      // '&:hover': {
      //   background: '#1e293a'
      // }
    },
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
}));
function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

const InterviewMenu = ({ props, auth, logoutUser, history }) => {
  console.log("history", history);

  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    logoutUser();
    history.push("/login");
  };

  const imageClick = () => {
    history.push("/menu/interviewDashboard");
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = auth;
  // console.log(this.state.tests)
  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar className="headerContainer">
          <Toolbar>
            <Container maxWidth="lg" className="headerContent">
              <img
                style={{
                  width: "200px",
                  height: "55px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                src={Logo}
                alt=""
                onClick={() => imageClick()}
              />

              <div className={classes.grow} />

              <div className={classes.userDetails}>
                <Button
                  disableRipple
                  className={classes.profileIconButton}
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  variant="outlined"
                  onClick={handleMenu}
                >
                  <img src={person} alt="" /> {user && user.first_name}{" "}
                  {Boolean(anchorEl) ? (
                    <img src={up} alt="" />
                  ) : (
                    <img src={down} alt="" />
                  )}
                </Button>
                <Menu
                  className={classes.profileDrop}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      logoutUser(history);
                      handleClose();
                      handleLogout();
                    }}
                  >
                    <img src={logout} alt="" /> Logout
                  </MenuItem>
                </Menu>
              </div>

              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarNav"
              >
                <ul className="nav">
                  <li className="dropdown">
                    <Button
                      disableRipple
                      //   className={classes.profileIconButton}
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      variant="outlined"
                      onClick={handleMenu}
                    >
                      <img src={person} alt="" /> {user && user.first_name}{" "}
                      {Boolean(anchorEl) ? (
                        <img src={up} alt="" />
                      ) : (
                        <img src={down} alt="" />
                      )}
                    </Button>
                    {/* <i className="fa fa-user"></i> {user && user.first_name}
                    </Button> */}
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link className="nav-link" to="/menu/interviewResult">
                          Result
                        </Link>
                      </li>

                      <li>
                        <NavLink
                          className="nav-link"
                          to="#"
                          onClick={() => {
                            handleLogout();
                          }}
                        >
                          Logout{" "}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InterviewMenu));
