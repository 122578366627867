import { Grid, Button, Typography } from "@mui/material";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  getFeedbackQuestions,
  getFeedbackSession,
  addUserFeedbacks,
} from "../../../redux/actions/productivityActions/debuggingCodeActions";
import { useParams } from "react-router-dom";


const DebugFeedback = ({ history }) => {
  const { codeReducer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const params = useParams();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userFeedbacks, setUserFeedbacks] = useState({});
  const [feedback, setFeedback] = useState("");
 

  useEffect(() => {
    dispatch(getFeedbackSession(params.sessionId));
  }, []);

  useEffect(() => {
    if (codeReducer?.debuggingSessionData?._id) {
      dispatch(getFeedbackQuestions(codeReducer.debuggingSessionData));
      setUserFeedbacks(codeReducer?.debuggingSessionData);
    }
  }, [codeReducer.debuggingSessionData]);

  const handleFinish=(data)=>{
    let newFeedback = userFeedbacks;
    const userData = {
      debuggingCode: data?._id,
      userFeedback: feedback,
    };
    newFeedback.userFeedbacks.push(userData);
    setUserFeedbacks(newFeedback);
    console.log(userFeedbacks, data);
    setFeedback("");
    dispatch(addUserFeedbacks(userFeedbacks, history));
  }

  const handleNext = (data) => {
    let newFeedback = userFeedbacks;
    if (selectedIndex === codeReducer?.debuggingQuestionCodes?.length - 1) {
      const userData = {
        debuggingCode: data?._id,
        userFeedback: feedback,
      };
      newFeedback.userFeedbacks.push(userData);
      setUserFeedbacks(newFeedback);
      console.log(userFeedbacks, data);
      setFeedback("");
      dispatch(addUserFeedbacks(userFeedbacks, history));
      // history.push("/menu/codeTopics");
    } else {
      console.log(userFeedbacks, data);
      if (
        userFeedbacks?.userFeedbacks?.find(
          (item) => item._id?.toString() === data?._id?.toString()
        )
      ) {
        let filteredFeedbacks = userFeedbacks?.userFeedbacks?.filter(
          (item) => item?._id?.toString() !== data?.Id?.toString()
        );
        const userData = {
          debuggingCode: data?._id,
          userFeedback: feedback,
        };
        filteredFeedbacks.push(userData);
        newFeedback.userFeedbacks = filteredFeedbacks;
        setUserFeedbacks(newFeedback);
        // newFeedback?.userFeedbacks
      } else {
        const userData = {
          debuggingCode: data?._id,
          userFeedback: feedback,
        };
        newFeedback.userFeedbacks.push(userData);
        setUserFeedbacks(newFeedback);
      }
      setSelectedIndex(selectedIndex + 1);
      setFeedback("");
    }
  };

  let totalLength=codeReducer?.debuggingQuestionCodes?.length;

  return codeReducer.debuggingQuestionCodes.length > 0 ? (
    <Grid container spacing={3} sx={{ padding: "20px" }}>
      <Grid item xs={12} md={4} style={{backgroundColor:"#1976d2"}}>
        <Typography color="white" variant="h5" fontWeight={500}>
          Session Id: {codeReducer?.debuggingSessionData?.sessionId}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} style={{backgroundColor:"#1976d2"}}>
        <Typography color="white" variant="h5" fontWeight={500}>
          Topic: {codeReducer?.debuggingSessionData?.capabilityTopic?.name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} style={{backgroundColor:"#1976d2"}}>
        <Typography color="white"  variant="h5" fontWeight={500}>
          Complexitiy:{" "}
          {codeReducer?.debuggingSessionData?.capabilityComplexity?.name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <pre style={{background:"#F5F5F5"}}>
          <code style={{color:"green"}}>
            <b>
              {" "}
              {
                codeReducer?.debuggingQuestionCodes[selectedIndex]
                  ?.debuggingCode
              }
            </b>
          </code>
        </pre>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextFieldGroup
          variant="outlined"
          label="consider the above code and explain  it briefly"
          name="code"
          multiline="multiline"
          onChange={(e) => setFeedback(e?.target?.value)}
          //   onBlur={formik.handleBlur}
          value={feedback}
          rows={5}
          //   errors={
          //     formik.touched.code && formik.errors.code
          //       ? formik.errors.code
          //       : null
          //   }
        />
      </Grid>
      <Grid item xs={12} md={4} align="center">
        {" "}
        <Typography color="black"  variant="h5" fontWeight={500}>
          QuestionNumber:{" "}
          {selectedIndex+1}
          /
       {totalLength}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} align="center">
        {" "}
        <Button
          buttonStyle="submit"
          type="save"
          name="save"
          color="primary"
          //   className={classes.inputButtonFields}
          variant="contained"
          onClick={() =>
            handleNext(codeReducer?.debuggingQuestionCodes[selectedIndex])
          }
        >
          Next
        </Button>
      </Grid>
      <Grid item xs={12} md={4} align="center">
        {" "}
        <Button
          buttonStyle="submit"
          type="save"
          name="save"
          color="primary"
          //   className={classes.inputButtonFields}
          variant="contained"
          onClick={() =>
            handleFinish(codeReducer?.debuggingQuestionCodes[selectedIndex])
          }
        >
          Finish Code
        </Button>
      </Grid>
    </Grid>
  ) : (
    <>
      <Typography variant="h4" color={"red"} align="center">
        No Codes Found.
        <Button
          align="center"
          buttonStyle="submit"
          type="save"
          name="save"
          color="primary"
          //   className={classes.inputButtonFields}
          variant="contained"
          onClick={() => history.push("/menu/codeTopics")}
        >
          Back
        </Button>
      </Typography>
    </>
  );
};

export default DebugFeedback;
