import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Tables from "../reusableComponents/MaterialTable";
import { getGroupUsers } from "../../redux/actions/customerActions";
import { Link, Redirect } from "react-router-dom";
import Menu from "../menu/menu";
import loader from "../805.gif";
import { Container, Grid, Breadcrumbs, Typography,  Avatar } from "@material-ui/core";
import Table from "../reusableComponents/MaterialTable";
import ButtonField from "../reusableComponents/ButtonField";


const CustomerList = ({ getGroupUsers, auth, history, customer, match }) => {
  const { user } = auth;

  useEffect(() => {
    getGroupUsers(match.params.group);
    document.title = "User List - Capability App";
  }, []);

  useEffect(() => {
    if (user && user.role === "user" && user && user.role === "testuser") {
      history.push("/login");
    }
  }, [auth]);

  const { groupUsers } = customer;
  //columns
  const columns = [
    { field: "id", title: "User Id", hidden: true },
    { field: "first_name", title: "First Name" },
    { field: "last_name", title: "Last Name" },
    { field: "email", title: "Email" },
    { field: "phone", title: "Phone" },
    { field: "user_group_name", title: "Sub Group" },
  ];

  const options = {
    headerStyle: {
      backgroundColor: "#003399",
      color: "#FFF",
    },
    scrollable: true,
    pageSize: 7,
  };

  const data = groupUsers && groupUsers;

  return (
  //   <div>
  //     <div>
  //       <div cclass="text-center"></div>
  //       <div className="mutable">
  //         {data && data ? (
  //           <Tables
              // title={
              //   <>
              //     <span
              //       style={{
              //         color: "#003399",
              //         fontSize: "1.25rem",
              //         fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
              //         fontWeight: "bold",
              //         lineHeight: "1.6",
              //         margin: "0 30px",
              //         letterSpacing: "0.0075em",
              //       }}
              //     >
              //       User List
              //     </span>
              //     <button
              //       className="btn btn-primary"
              //       style={{backgroundColor: "#003399",color:"white"}}
              //       onClick={() => history.goBack()}
              //     >
              //       Back
              //     </button>
              //   </>
              // }
  //             data={data}
  //             columns={columns}
  //             options={options}
  //           />
  //         ) : (
  //           <div className="image-container">
  //             <p className="image-holder">
  //               <img src={loader} alt="" />
  //             </p>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   </div>
  <Container maxWidth="lg" className="adminMvk">
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Table
    title={
          <>
            <span
              style={{
                color: "#003399",
                fontSize: "1.25rem",
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                fontWeight: "bold",
                lineHeight: "1.6",
                margin: "0 30px",
                letterSpacing: "0.0075em",
              }}
            >
              User List
            </span>
            <button
              className="btn btn-primary"
              style={{backgroundColor: "#003399",color:"white"}}
              onClick={() => history.goBack()}
            >
              Back
            </button>
          </>
        }
        columns={columns}
        data={data}
        pageSize={10}
        // selectedRow={(row) => history.push(`/admin/coach/${row._id}`)}
      />
    </Grid>
  </Grid>
</Container>
 );
};

CustomerList.propTypes = {
  getGroupUsers: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  customer: state.customer,
});

const mapDispatchToProps = { getGroupUsers };

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
