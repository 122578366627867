import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar, Button } from "@material-ui/core";
import Table from "../../reusableComponents/MaterialTable";
import ButtonField from "../../reusableComponents/ButtonField";
import {
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import CustomizedDialogs from "../../reusableComponents/CustomizedDialogs";
import AddNewQuestions from "./AddNewQuestions";
import EditQuestion from "./EditQuestion";

import { getQuestionList } from "../../../redux/actions/productivityActions/questionAction";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const Question = ({}) => {
  const [open, setOpen] = useState(false);
  const [questionId, setquestionId] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const classes = useStyles();
  //   const { isAuthenticated } = auth;

  const { auth, questionReducer } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getQuestionList());
    }
  }, [auth.isAuthenticated]);

  const columns = [
    {
      title: "Technology",
      field: "technology",
    },
    {
      title: "Domain",
      field: "domain",
    },
    {
      title: "Function",
      field: "function",
    },
    {
      title: "Scenario",
      field: "scenario",
    },
    {
      title: "Question Description",
      field: "questionDescription",
    },
    {
      title: "Question Status",
      field: "status",
    },
    {
      title: "Edit",
      field: "edit",
    },
  ];

  const data = questionReducer?.getQuestionList?.map((item) => {
    return {
      _id: item._id,
      technology: item.technology?.name,
      domain: item.domain?.name,
      function: item.function?.name,
      scenario: item.scenario?.name,
      questionDescription: item.questionDescription,
      status: item?.status ? "Enabled" : "Disabled",
      edit: (
        <Button
          variant="contained"
          color="primary"
          type="submit"
          name="edit"
          onClick={() => {
            setquestionId(item._id);
            setOpenEdit(true);
          }}
        >
          Edit
        </Button>
      ),
    };
  });

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            title={
              <ButtonField
                variant="outlined"
                name="Add New Question"
                icon={<AddIcon />}
                onClick={() => setOpen(true)}
              />
            }
            columns={columns}
            data={data}
            pageSize={10}
            // selectedRow={(row) => history.push(`/admin/coach/${row._id}`)}
          />
        </Grid>
      </Grid>
      <CustomizedDialogs
        title="Add New Question "
        children={<AddNewQuestions setOpenPopup={setOpen} />}
        openPopup={open}
        setOpenPopup={setOpen}
      />
      <CustomizedDialogs
        title="Edit Domain"
        children={
          <EditQuestion questionId={questionId} setOpenPopup={setOpenEdit} />
        }
        openPopup={openEdit}
        setOpenPopup={setOpenEdit}
      />
    </Container>
  );
};

Question.propTypes = {
  auth: PropTypes.object.isRequired,
  getCoachList: PropTypes.func.isRequired,
  clearCoachInfo: PropTypes.func.isRequired,
  adminCoach: PropTypes.object.isRequired,
};

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   adminCoach: state.adminCoach,
// });

// const mapDispatchToProps = { getCoachList, clearCoachInfo };

export default Question;
