import React, { Component } from "react";
import Menu from "../menu/menu";
import UserMenu from "../users/usermenu/usermenu";
import axios from "axios";
import { API_URL } from "../../Api_Request";
import $ from "jquery";
// import SearchField from 'react-search-field';
import Swal from "sweetalert2";
import "./viewresults.css";
import loader from ".././805.gif";

class GeneratePDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      data: [],
      userInfo: null,
      len: "",
      level: "",
      feedBack: "",
      saveFeedback: -1,
      secondfeed: -1,
      filterData: [],
      userName: "",
      userId: "",
      iduser: "",
      idtest: "",
      correct: "",
    };
  }

  componentDidMount() {
    if (!localStorage.getItem("capability")) {
      // alert("null")
      return null;
    } else {
      // if (localStorage.getItem('isLoggedIn') != null) {
      const query = new URLSearchParams(this.props.location.search);
      const iduser = query.get("iduser");
      const idtest = query.get("idtest");
      console.log("gsfhjf", iduser, idtest);
      const gettoken = JSON.parse(localStorage.getItem("capability"));
      const token = gettoken.token;
      axios
        .get(`${API_URL}/useranswer/?iduser=${iduser}&idtest=${idtest}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          const result = res.data;
          console.log(res.data.length);

          this.setState({
            data: result,
            filterData: result,
            userInfo: result[0],
            userName: `${result[0].first_name} ${result[0].last_name}`,
            userId: `${result[0].user_id}`,
            len: res.data.length,
            correct: result[result.length - 1].correct,
            saveFeedback: -1,
            secondfeed: -1,
            isLoaded: true,
            idtest: idtest,
            iduser: iduser,
          });
          console.log(this.state.data);
        })
        .catch((err) => {
          console.log(err);
        });
      // alert("not null")
    }
    // }
  }

  handleLevel = (e) => {
    this.state.level = e.target.value;

    this.setState({
      level: e.target.value,
    });
    console.log(this.state.level);
  };

  handleFeedback = (e, idx) => {
    this.state.feedBack = e.target.value;
    this.state.saveFeedback = idx;
    this.setState({
      feedBack: e.target.value,
      saveFeedback: idx,
      secondfeed: idx,
    });
    console.log(this.state.feedBack);
    console.log(this.state.saveFeedback);
  };

  handleClear = (e) => {
    this.setState({
      level: "",
      feedBack: "",
      saveFeedback: -1,
      secondfeed: -1,
    });
    // this.state.level = '',
    // this.state.feedBack = ''
  };

  //Add feedback
  handleSave = (e, id, uid, idx) => {
    e.preventDefault();
    const { level, feedBack } = this.state;
    if (level === "" || level === undefined) {
      Swal.fire({ icon: "error", text: `Select feedback level` });
      // alert("Select feedback level")
    } else if (feedBack === "" || feedBack === undefined) {
      Swal.fire({ icon: "error", text: `Enter feedback` });
      // alert("Enter feedback")
    } else {
      const feed = {
        level: level,
        feedBack: feedBack,
        userid: uid,
      };
      console.log(feed);

      const gettoken = JSON.parse(localStorage.getItem("capability"));
      const token = gettoken.token;
      axios
        .put(`${API_URL}/useranswer/${id}`, feed, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });

          Toast.fire({
            icon: "success",
            title: "Feedback added succesfully",
          });
          // alert("Feedback added succesfully")

          const gettoken = JSON.parse(localStorage.getItem("capability"));
          const token = gettoken.token;
          axios
            .get(`/api/useranswer/${feed.userid}`, {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then((res) => {
              const result = res.data;
              this.setState({
                data: result,
              });
            })
            .then((res) => {
              const feedindex = this.state.data;
              console.log(this.state.data[idx]);
              console.log(feedindex[idx], "sdf", feedindex);
              this.setState({
                feedBack: "",
                level: "",
                saveFeedback: -1,
                secondfeed: -1,
              });
            })
            .catch(function (err) {
              console.log("Error", err);
            });
        })
        .catch(function (err) {
          console.log("Error", err);
        });
    }
  };

  //Filter by correct/wrong answers
  handleFilter = (e) => {
    console.log(e.target.value);
    const userresult = e.target.value;
    if (userresult === "All Results") {
      let filterData = this.state.data;
      this.setState({
        filterData: filterData,
      });
    } else {
      let filterData = this.state.data.filter((result) => {
        return result.userResult === userresult;
      });
      console.log(this.state.filterData);
      this.setState({
        filterData: filterData,
      });
    }
  };

  //Save as Pdf

  savePDF = (e, id) => {
    const getPDF = (id) => {
      const gettoken = JSON.parse(localStorage.getItem("capability"));
      const token = gettoken.token;
      return axios.get(
        `${API_URL}/generatepdf/?iduser=${this.state.iduser}&idtest=${this.state.idtest}`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/pdf",
          },
        }
      );
    };

    return getPDF(id) // API call
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.state.userName}.pdf`;
        link.click();
      })
      .catch(function (err) {
        console.log("Error", err);
      });
  };

  render() {
    const { userInfo, saveFeedback } = this.state;
    const role = localStorage.getItem("role");

    return (
      <div>
        {/* {role === 'admin' ? <Menu /> : <UserMenu/>} */}
        <center>
          <img
            className="imcen mx-auto"
            src={require("../../images/arohaLogo.png")}
            alt="aroha"
          />
        </center>
        <div
          className="image-container"
          style={{ display: this.state.isLoaded === false ? "block" : "none" }}
        >
          <p className="image-holder">
            <img src={loader} alt="" />
          </p>
        </div>
        <div className="container mt-4">
          {userInfo && (
            <div
              id="details"
              className="row bg-white shadow-sm mt-2 font-weight-bold box"
            >
              <div className="col-sm-6 p-3">
                <div className="text-uppercase">
                  <label className="mr-1 ">Name : </label>

                  {" " +
                    userInfo.first_name.toUpperCase() +
                    " " +
                    userInfo.last_name.toUpperCase()}
                </div>
                <div>
                  <label className="mr-1">Email : </label>
                  {" " + userInfo.email}
                </div>
                <div>
                  <label className="mr-1">Date : </label>
                  {userInfo.insertedDate}
                </div>
              </div>
              <div className="col-sm-6 p-3">
                <div>
                  <label className="mr-1">Phone Number : </label>
                  {" " + userInfo.phone}
                </div>
                <div>
                  <label className="mr-1">Result :</label>
                  {this.state.correct} / {this.state.len}
                </div>
                <div>
                  <label className="mr-1">Test ID : </label>
                  {userInfo.test_id}
                </div>
              </div>
            </div>
          )}
        </div>

        <div id="filter" className="container">
          <div className="row">
            <div className="col-sm-6 p-3">
              <b>Filter by Answer result : </b>
              <select
                className="btn btn-primary ml-3"
                onChange={(e) => this.handleFilter(e)}
              >
                <option>All Results</option>
                <option>Correct</option>
                <option>Wrong</option>
              </select>
            </div>
            <div className="col-sm-6 p-3">
              <button
                id="print-button"
                className="btn btn-success mx-auto"
                onClick={(e) => this.savePDF(e, this.state.userId)}
              >
                Save this report as PDF
              </button>
            </div>
          </div>
        </div>

        <div>
          {this.state.filterData.map((results, idx) => {
            return (
              <div className="container" key={idx}>
                <div className="row box">
                  <div className="col-sm-12">
                    <div className="card pl-5 shadow-sm p-4 my-2 bg-white">
                      <p>
                        {idx + 1}.{results.description}
                        {/* {
                                           (results.actual_answer_id === results.user_answer_id) ?
                                            <span style={{color: "green", float:"right"}}><b>{this.state.check = "Correct"}</b> </span>
                                            :
                                            <span style={{color: "red", float:"right"}}> <b>{this.state.check = "Wrong"}</b>
                                            </span> 
                                        }  */}
                                        <span style={results.userResult === "Correct" ?{color: "green", float: "right"}:{color: "red", float: "right"}}>
                                        <b>{results.userResult}</b>
                                        </span>
                        {/* {results.userResult === "Correct" ? (
                          <span style={{ color: "green", float: "right" }}>
                            <b>{results.userResult}</b>{" "}
                          </span>
                        ) : (
                          <span style={{ color: "red", float: "right" }}>
                            {" "}
                            <b>{results.userResult}</b>
                          </span>
                        )} */}
                      </p>
                      <div className="row ml-2">
                        {results.choice_name.map((a, index) => {
                          return (
                            <div key={index} className="col-sm-6 p-2">
                              {index + 1}. {a}
                            </div>
                          );
                        })}
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <b>Actual Answer:</b>
                        </div>
                        {results.actual_answer.map((ans, index) => {
                          return (
                            <div
                              key={index}
                              className="col-sm-9 pl-5"
                              style={{ color: "green" }}
                            >
                              <b>{ans}</b>
                            </div>
                          );
                        })}
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-1">
                          <b>User Answer:</b>
                        </div>
                        {results.user_answer.map((uans, index) => {
                          return (
                            <div key={index} className="col-sm-9 pl-5">
                              {results.actual_answer_id ===
                              results.user_answer_id ? (
                                <span style={{ color: "green" }}>
                                  {" "}
                                  <b> {uans}</b>
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  {" "}
                                  <b> {uans}</b>
                                </span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className="row"
                        style={{
                          display:
                            results.userResult === "Wrong" ? "block" : "none",
                        }}
                      >
                        <div className="col-sm-12 mt-1">
                          <form
                            onSubmit={(e) =>
                              this.handleSave(
                                e,
                                results.question_id,
                                results.user_id,
                                idx
                              )
                            }
                          >
                            {results.feedback ? (
                              <React.Fragment>
                                <b>Mentor FeedBack:</b>
                                {role === "admin" ? (
                                  results.level !== "" ? (
                                    // <span><span className="pr-3 ok">
                                    // <input className="ml-3" type="radio" name="level" value="Ok"
                                    // onChange={(e) => this.handleLevel(e)} defaultChecked = {(results.level === "Ok") ? "Ok" : null}/> Ok</span><span className="pr-3">
                                    // <input className="ml-3"type="radio" name="level" value="Bad"
                                    // onChange={(e) => this.handleLevel(e)}  defaultChecked = {(results.level  === "Bad") ? "Bad" : null}/> Bad</span><span className="pr-3">
                                    // <input className="ml-3" type="radio" name="level" value="Conceptually/Logically"
                                    // onChange={(e) => this.handleLevel(e)} defaultChecked = {(results.level  === "Conceptually/Logically") ? "Conceptually/Logically" : null}/> Conceptually/Logically</span>
                                    // </span>
                                    // :
                                    <span>
                                      <span className="pr-3 ok">
                                        <input
                                          className="ml-3"
                                          type="radio"
                                          name="level"
                                          value="Ok"
                                          onChange={(e) =>
                                            this.handleLevel(e, idx)
                                          }
                                          checked={
                                            results.level === "Ok"
                                              ? (this.checked = true)
                                              : (this.checked = false)
                                          }
                                        />{" "}
                                        Ok
                                      </span>
                                      <span className="pr-3 bad">
                                        <input
                                          className="ml-3"
                                          type="radio"
                                          name="level"
                                          value="Bad"
                                          onChange={(e) =>
                                            this.handleLevel(e, idx)
                                          }
                                          checked={
                                            results.level === "Bad"
                                              ? (this.checked = true)
                                              : (this.checked = false)
                                          }
                                        />{" "}
                                        Bad
                                      </span>
                                      <span className="pr-3 clog">
                                        <input
                                          className="ml-3"
                                          type="radio"
                                          name="level"
                                          value="Conceptually/Logically"
                                          onChange={(e) =>
                                            this.handleLevel(e, idx)
                                          }
                                          checked={
                                            results.level ===
                                            "Conceptually/Logically"
                                              ? (this.checked = true)
                                              : (this.checked = false)
                                          }
                                        />{" "}
                                        Conceptually/Logically
                                      </span>
                                    </span>
                                  ) : null
                                ) : null}
                                <div className="mt-2">
                                  {role === "admin" ? (
                                    saveFeedback === idx ? (
                                      <textarea
                                        className="form-control"
                                        id="text"
                                        rows="2"
                                        defaultValue={results.feedback}
                                        value={this.state.feedBack}
                                        cols="100"
                                        onChange={(e) => {
                                          this.handleFeedback(e, idx);
                                        }}
                                      />
                                    ) : (
                                      <textarea
                                        className="form-control"
                                        id="text"
                                        rows="2"
                                        value={results.feedback}
                                        cols="100"
                                        onChange={(e) => {
                                          this.handleFeedback(e, idx);
                                        }}
                                      />
                                    )
                                  ) : (
                                    <textarea
                                      className="form-control"
                                      id="text"
                                      rows="2"
                                      value={results.feedback}
                                      cols="100"
                                      disabled
                                      onChange={(e) => {
                                        this.handleFeedback(e, idx);
                                      }}
                                    />
                                  )}
                                  {/* <textarea className="form-control" id="text" rows="2" placeholder="Enter Feedback"
                                            value={this.state.feedBack}  cols="100" 
                                            onChange={(e) => { this.handleFeedback(e, idx) }}  /> */}
                                </div>
                                {role === "admin" ? (
                                  <div
                                    className="mt-2"
                                    id="feed-button"
                                    // style = {{ display: (saveFeedback === {idx}) ? 'block' : 'none' }}
                                  >
                                    <div>
                                      <button
                                        type="submit"
                                        className="btn btn-primary mr-2"
                                        disabled={
                                          this.state.saveFeedback !== idx
                                        }
                                        value="save"
                                      >
                                        Save
                                      </button>
                                      <button
                                        type="reset"
                                        className="btn btn-danger"
                                        value="clear"
                                        onClick={(e) => {
                                          this.handleClear(e);
                                        }}
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                              </React.Fragment>
                            ) : null}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <center>
          <button
            id="print-button"
            className="btn btn-success mx-auto"
            onClick={(e) => this.savePDF(e, this.state.userId)}
          >
            Save this report as PDF
          </button>
        </center>
      </div>
    );
  }
}

export default GeneratePDF;
