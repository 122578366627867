import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import "./menu.css";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/authActions";
import { alltopicquestions } from "../../redux/actions/questionsAction";
import Logo from "../../images/logo/ibridgelogo.png";

class Menu extends Component {
  handleLogout() {
    localStorage.clear();
    this.props.logoutUser();
    this.props.history.push("/login");
    // document.body.style = 'background: ;'
  }

  handleQuestions() {
    this.props.alltopicquestions();
    this.props.history.push("/menu/questions");
  }

  render() {
    const { user } = this.props.auth;
    return (
      <div id="menu" className="bottomMargin">
        <nav className="navbar navbar-expand-lg fixed-top dashboard-menu">
          <div className="container">
            <Link className="navbar-brand" to="/login">
              <img
                style={{
                  width: "200px",
                  height: "55px",
                  objectFit: "cover",
                }}
                src={Logo}
              />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="nav-item">Menu</span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <ul className="nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/menu/dashboard">
                    Dashboard{" "}
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    // to=""
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Questions
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/menu/questionTopic" className="dropdown-item">
                        Question Topic
                      </Link>
                    </li>
                    <li>
                      <Link to="/menu/chapters" className="dropdown-item">
                        Question Chapter
                      </Link>
                    </li>
                    <li>
                      <Link to="/menu/questionType" className="dropdown-item">
                        Question Type
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/menu/questionComplexity"
                        className="dropdown-item"
                      >
                        Question Complexity
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => this.handleQuestions()}
                        className="dropdown-item"
                      >
                        All Questions
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <Link
                    // to=""
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Manage Tests
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/menu/questionpapers" className="dropdown-item">
                        Set Questions
                      </Link>
                    </li>
                    {/* <li ><Link to='/menu/assigntestusers' className="dropdown-item" >Assign Tests</Link></li> */}
                  </ul>
                </li>
                {(user && user.role === "Super Admin") ||
                (user && user.role === "Customer Admin") ? (
                  <li className="dropdown">
                    <Link
                      // to=""
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Customer
                    </Link>
                    <ul className="dropdown-menu">
                      {user && user.role === "Super Admin" ? (
                        <li>
                          <Link
                            to="/menu/customerlist"
                            className="dropdown-item"
                          >
                            Customers
                          </Link>
                        </li>
                      ) : null}
                      <li>
                        <Link to="/menu/usergroups/0" className="dropdown-item">
                          User Groups
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : null}
                {/* <li className="nav-item">
              <Link className="nav-link" to="/menu/users">Map Users</Link>
            </li> */}
                {/* <li className="nav-item">
              {
                role === 'admin' ?
                <Link className="nav-link" to="/menu/manageusers">Manage Users</Link>
                :
                null
              }
            </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/menu/results">
                    Results{" "}
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    // to=""
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Analysis
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/menu/userlists" className="dropdown-item">
                        User Comparitive Chart
                      </Link>
                    </li>
                    <li>
                      <Link to="/menu/animatedchart" className="dropdown-item">
                        Animated User Chart
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/menu/userperformance"
                        className="dropdown-item"
                      >
                        User Performance Chart
                      </Link>
                    </li>
                    <li>
                      <Link to="/menu/resultchart" className="dropdown-item">
                        User Result Chart
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li className="nav-item">
              <Link className="nav-link" to="/menu/visuals">Visuals</Link>
            </li> */}
                <li className="dropdown">
                  <Link
                    // to=""
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <i className="fa fa-user"></i> {user && user.first_name}
                  </Link>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <Link className="nav-link" to="/menu/users">
                        Assign Topics
                      </Link>
                    </li>
                    <li className="nav-item">
                      {(user && user.role === "admin") ||
                      (user && user.role === "Super Admin") ||
                      (user && user.role === "Customer Admin") ? (
                        <Link className="nav-link" to="/menu/manageusers">
                          Manage Users
                        </Link>
                      ) : null}
                    </li>
                    <li>
                      <NavLink
                        className="nav-link"
                        to="#"
                        onClick={() => {
                          this.handleLogout();
                        }}
                      >
                        Logout{" "}
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  logoutUser,
  alltopicquestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
