import React, { useEffect } from "react";
import * as d3 from "d3";
import { capitalize } from "@material-ui/core";

const PerformanceScatterChart = ({ scatterData }) => {
  useEffect(() => {
    if (scatterData) {
      let d = document.getElementById("performancescatterchart");
      d.innerHTML = "";
      scatterPlot(scatterData);
    }
    return () =>
      d3.selectAll("svg").select("#performancescatterchart").remove();
  }, [scatterData]);

  const scatterPlot = (per) => {
    d3.select("svg").remove();
    var div = d3
      .select("#performancescatterchart")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);
    // set the dimensions and margins of the graph
    var margin = { top: 10, right: 30, bottom: 0, left: 50 },
      width = 1000 - margin.left - margin.right,
      height = 450 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3
      .select("#performancescatterchart")
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 1000 550")
      // .attr("width", width + margin.left + margin.right)
      // .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Add the grey background that makes ggplot2 famous
    svg
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("height", height)
      .attr("width", width)
      .style("fill", "#EBEBEB")
      .style("stroke", "gray")
      .style("stroke-width", "2px");

    svg
      .append("line")
      .attr("x1", 0)
      .attr("y1", height / 2)
      .attr("x2", width)
      .attr("y2", height / 2)
      .style("fill", "gray")
      .style("stroke", "orange")
      .style("stroke-width", "2px");

    svg
      .append("line")
      .attr("x1", 0)
      .attr("y1", height / 4)
      .attr("x2", width)
      .attr("y2", height / 4)
      .style("fill", "gray")
      .style("stroke", "green")
      .style("stroke-width", "2px");

    var aspect = width / height,
      chart = d3.select("#groupBarChart");
    d3.select(window).on("resize", function () {
      var targetWidth = chart.node().getBoundingClientRect().width;
      chart.attr("width", targetWidth);
      chart.attr("height", targetWidth / aspect);
    });

    // Add X axis
    var x = d3
      .scaleBand()
      .domain(
        per.map(function (d) {
          return d.First_Name;
        })
      )
      .range([0, width]);

    var xaxis = svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(
        d3
          .axisBottom(x)
          .tickSize(-height * 1.3)
          .ticks(10)
      )
      // .select(".domain").remove()
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)");

    xaxis.select(".domain").remove();

    // Add Y axis
    var y = d3
      .scaleLinear()
      .domain(
        d3.extent(per, function (d) {
          return d.avg;
        })
      )
      .range([height, 0])
      .nice();

    var yaxis = svg
      .append("g")
      .call(
        d3
          .axisLeft(y)
          .tickSize(-width * 1.3)
          .ticks(7)
      )
      .select(".domain")
      .remove();

    console.log(y.ticks()[0]);
    // console.log("all the points", yaxis.ticks(yaxis.ticks()[0]))

    // Customization
    svg.selectAll(".tick line").attr("stroke", "white");

    // Add X axis label:
    // svg.append("text")
    //     .attr("text-anchor", "end")
    //     .attr("x", width/2 + margin.left)
    //     .attr("y", height + margin.top + 20)
    //     .text("Sepal Length");

    var number_format = d3.format(".3n");

    var mean = 0;
    per.forEach(function (d) {
      mean += d.avg;
    });
    mean = mean / per.length;
    console.log("mean", number_format(mean));

    var line_data = [
      { x: 0, y: y(mean) },
      { x: x.range()[1], y: y(mean) },
    ];

    var line = d3
      .line()
      .x(function (d) {
        return d.x;
      })
      .y(function (d) {
        return d.y;
      });

    // svg
    //   .append("path")
    //   .attr("d", line(line_data))
    //   .attr("stroke", "red")
    //   .attr("stroke-width", 2)
    //   .attr("fill", "none");

    // Y axis label:
    svg
      .append("text")
      .attr("text-anchor", "end")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left + 20)
      .attr("x", -margin.top - height / 2 + 20)
      .text("Average percentage");

    // Color scale: give me a specie name, I return a color
    var color = d3
      .scaleOrdinal()
      .domain(["setosa", "versicolor", "virginica"])
      .range(["#F8766D", "#00BA38", "#619CFF"]);

    // Add dots
    // svg.append('g')
    //     .selectAll("dot")
    svg
      .selectAll("dot")
      .data(per)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return x(d.First_Name);
      })
      .attr("cy", function (d) {
        return y(d.avg);
      })
      .attr("r", 5)
      .style("fill", function (d) {
        console.log(d, "slkd");
        if (d.avg <= 25) {
          return "red";
        } else if (d.avg > 25 && d.avg < 65) {
          return "orange";
        } else {
          return "green";
        }
      })
      .on("mouseover", function (d) {
        div.transition().duration(200).style("opacity", 0.9);
        div
          .html(d.First_Name + "<br/>" + number_format(d.avg))
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("mouseout", function (d) {
        div.transition().duration(500).style("opacity", 0);
      });
  };

  return <div id="performancescatterchart"></div>;
};

export default PerformanceScatterChart;
