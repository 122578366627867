import React, { useEffect } from "react";
import Menu from "../menu/menu";
// import '../questions/questions.css';
// import CustomToolbarSelect from './CustomToolbarSelect';
// import {Redirect} from 'react-router-dom';
// import MUIDataTable from "mui-datatables";
// import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from "react-redux";
import {
  redirectToTest,
  getIbridgeUser,
  getIbridgeTopics,
} from "../../redux/actions/iBridgeActions";
import { currentUser } from "../../redux/actions/authActions";
import Swal from "sweetalert2";
import setAuthToken from "../../redux/utils/setAuthToken";
import axios from "axios";
import { API_URL } from "../../Api_Request";
import loader from ".././805.gif";

const IbridgeLanding = ({
  redirectToTest,
  getIbridgeUser,
  getIbridgeTopics,
  iBridge,
  match,
  auth,
  currentUser,
  history,
}) => {
  const { user } = auth;

  const { redirectData, ibridgeUserData, ibridgeTestTopics } = iBridge;

  useEffect(() => {
    let skill = match.params.skill;
    let topicId = match.params.topicId;
    let topicName = match.params.topicName;
    let acronym = match.params.acronym;
    let user = match.params.user;
    console.log(topicName, acronym, user, topicId);
    if (user === "user") {
      getIbridgeUser(skill);
    }
    if (topicId && topicName && acronym) {
      console.log(userData);
      const userData = {
        topicId: topicId,
        topicName: topicName.replace("%20", " "),
        skill: skill,
        acronym: acronym.replace("%20", " "),
      };
      console.log(userData, "insideksjdf");
      getIbridgeTopics(userData);
    } else {
      getIbridgeUser(skill);
    }
  }, []);

  useEffect(() => {
    let skill = match.params.skill;
    let userParam = match.params.user;
    let programId = match.params.programId;
    if (userParam === "user") {
      getIbridgeUser(skill);
    } else {
      if (ibridgeTestTopics) {
        const { user } = auth;
        if (user && user?.id) {
          const test = {
            userId: user && user.id,
            topicId: ibridgeTestTopics.topicId,
            skillId: 1,
            acronym: ibridgeTestTopics.acronym,
            skill: "Beginner",
          };
          // console.log(test)
          axios
            .post(`${API_URL}/tests`, test)
            .then((res) => {
              console.log(res.data);
              history.push({
                pathname: "/ibridge/test",
                state: {
                  userId: user && user.id,
                  userName: user && user.email,
                  topicId: ibridgeTestTopics.topicId,
                  skillId: 1,
                  testid: res.data,
                  topic: ibridgeTestTopics.acronym,
                  skill: "Beginner",
                  programId: programId,
                },
              });
              // this.setState({
              //   testid : res.data
              // })
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  }, [auth]);

  useEffect(() => {
    if (ibridgeUserData) {
      const local = {
        token: ibridgeUserData.token,
        username: ibridgeUserData.username,
      };

      localStorage.setItem("capability", JSON.stringify(local));
      setAuthToken(local.token);
      currentUser(local);
      setTimeout(history.push("/menu/userdashboard"), 5000);
    }
  }, [ibridgeUserData]);

  useEffect(() => {
    if (ibridgeTestTopics) {
      const local = {
        token: ibridgeTestTopics.token,
        username: ibridgeTestTopics.username,
      };
      console.log(ibridgeTestTopics);
      localStorage.setItem("capability", JSON.stringify(local));
      setAuthToken(local.token);
      currentUser(local);
    }
  }, [ibridgeTestTopics]);

  useEffect(() => {
    if (redirectData) {
      const local = {
        token: redirectData.token,
        username: redirectData.username,
      };

      localStorage.setItem("capability", JSON.stringify(local));
      setAuthToken(local.token);
      currentUser(local);
      if (user && user?.id) {
        const test = {
          userId: user && user.id,
          topicId: 39,
          skillId: 1,
          acronym: `Business 101`,
          skill: "Beginner",
        };
        // console.log(test)
        axios
          .post(`${API_URL}/tests`, test)
          .then((res) => {
            console.log(res.data);
            history.push({
              pathname: "/ibridge/test",
              state: {
                userId: user && user.id,
                userName: user && user.email,
                topicId: 39,
                skillId: 1,
                testid: res.data,
                topic: "Business 101",
                skill: "Beginner",
              },
            });
            // this.setState({
            //   testid : res.data
            // })
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [redirectData]);

  return (
    <div>
      <div className="row quset font-weight-bold pr-5 pl-5">
        <div className="image-container">
          <p className="image-holder">
            <img src={loader} alt="" />
          </p>
        </div>
        {/* {redirectData || ibridgeTestTopics ? (
          <div className="image-container">
            <p className="image-holder">
              <img src={loader} alt="" />
            </p>
          </div>
        ) : (
          <div className="image-container">
            <p className="image-holder">
              <img src={loader} alt="" />
            </p>
          </div>
        )} */}
      </div>
    </div>
    // </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  iBridge: state.iBridge,
});

const mapDispatchToProps = {
  redirectToTest,
  currentUser,
  getIbridgeUser,
  getIbridgeTopics,
};

export default connect(mapStateToProps, mapDispatchToProps)(IbridgeLanding);
