import { GETQUESTIONCHAPTERS, 
  ADDQUESTIONCHAPTER, 
  EDITQUESTIONCHAPTER,
  GET_ILEARN_COUESES_VIDEOS,
} from './type';
import axios from 'axios';
import { API_URL } from '../../Api_Request';
import Swal from 'sweetalert2';



export const getQuestionChapters = () => dispatch => {
    axios.all([
        axios.get(`${API_URL}/chapters`),
        axios.get(`${API_URL}/members`)
    ])
      .then(axios.spread((chapterData, topicData) => {
          dispatch({
              type : GETQUESTIONCHAPTERS, 
              payload : {chapterData : chapterData.data, topicData : topicData.data}
          })
      }))
      .catch(err => console.log(err))
  }

export const addnewchapter = (params) => dispatch => {
  axios.post(`${API_URL}/chapters/addChapter`, params)
    .then(res => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000
      })
      
      Toast.fire({
        icon: 'success',
        title: 'Chapter added successfully'
      })
      dispatch(getQuestionChapters())
      dispatch({
          type : ADDQUESTIONCHAPTER, 
          payload : res.data
      })
      
      // console.log(res.data)
  })
  .catch(err => console.log(err))
}

export const editQuestionChapter = (params) => dispatch => {
  axios.put(`${API_URL}/chapters/editChapter/${params.id}`,params)
    .then(res => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000
      })
      
      Toast.fire({
        icon: 'success',
        title: 'Chapter updated successfully'
      })
      dispatch(getQuestionChapters())
      // dispatch({
      //     type : EDITQUESTIONCHAPTER, 
      //     payload : res.data
      // })
      
      // console.log(res.data)
  })
  .catch(err => console.log(err))
}

// export const getIlearnCourseVideo = () => dispatch => {
//   axios.all([
//       axios.get(`${API_URL}/chapters`),
//       axios.get(`${API_URL}/members`)
//   ])
//     .then(axios.spread((chapterData, topicData) => {
//         dispatch({
//             type : GETQUESTIONCHAPTERS, 
//             payload : {chapterData : chapterData.data, topicData : topicData.data}
//         })
//     }))
//     .catch(err => console.log(err))
// }

export const getIlearnCourseVideo = (data) => (dispatch) => {
  axios
    .get(`/api/ilearn/getIlearnCourses`)
    .then((res) =>
      dispatch({
        type: GET_ILEARN_COUESES_VIDEOS,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      // SnackBar.error(message);
      console.log(message)
    });
};

// export const addNewChapterQuestion = (data) => (dispatch) => {
//   axios
//     .post(`/api/programs/addPrograms`, data)
//     .then((res) => {
//       const { message } = res.data;
//       history.push("/admin/programsList");
//       SnackBar.success(message);
//     })
//     .catch((err) => {
//       const { message } = err?.response?.data;
//       SnackBar.error(message);
//       history.push("/admin/programsList");
//     });
// };