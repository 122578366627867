import React, { useContext } from 'react';
import { AiAssistantContext } from "../../contexts/ai_assistant_context"
import { Grid, Box, Typography } from '@material-ui/core';
import CustomizedProgressBars from './progress_status';
import Messages from './messages';
import TestList from './test_list';
import QuestionList from './question_list';

const LearningAssistantAIComponent = ({ history }) => {

  const { isLoadingTest, selectedTest, formatDate } = useContext(AiAssistantContext);

  return (
      <Grid container style={{ backgroundColor: '#f1f4fa', fontFamily: 'Inter, sans-serif' }}>
        <Grid item xs={12} md={2} style={{ height: "100vh" }}>

        <TestList history={history} />        
        </Grid>
        <Grid item xs={12} md={8} style={{ height: "100vh" }}>
          <Box style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: "100%",
            width: "100%",
            padding: 0,
            margin: 0
            // paddingLeft: 70
          }}>
            <Box style={{flex: 0.5, width: "100%", marginLeft:20, marginBottom: 50 }}>
              <Box style={{marginTop: 10}}>
                <Typography variant='h5' style={{fontWeight: 'bold', textAlign: 'center', marginBottom: 10}}>SQL AI Learning Assistant</Typography>
                {selectedTest && <Typography variant='h6' style={{ textAlign: 'center'}}>{`Domain: ${selectedTest.domain_name} | Function: ${selectedTest.function_name} | Scenario: ${selectedTest.scenario_name}`}</Typography>}
                {selectedTest && <Typography variant='h6' style={{ textAlign: 'center'}}>{`Session Date: ${formatDate(selectedTest.created_at)}`}</Typography>}
              </Box>
            </Box>
          
           {isLoadingTest ?
            <Box width="60%">
              <Typography variant='h5' style={{ textAlign: 'center'}}>Analysing Session</Typography>
              <CustomizedProgressBars />
            </Box>  
           :
            <Messages /> 
           }
          
          </Box> 
        </Grid>

        <Grid item xs={12} md={2} style={{ height: "100vh" }}>
          <QuestionList />
        </Grid>
      </Grid>  
      )
}

export default LearningAssistantAIComponent;