import React, { Component } from "react";
// import Menu from "../menu/menu";
import "../questions/questions.css";
import Tables from "../tables/tables";
import { connect } from "react-redux";
import {
  getQuestionpapers,
  postQuestionPaperStatus,
} from "../../redux/actions/questionPapersAction";
import { getstatusonskills } from "../../redux/actions/questionComplexityAction";
import { getstatusontopics } from "../../redux/actions/questionTopicsAction";
import Swal from "sweetalert2";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { Redirect } from "react-router-dom";
import loader from ".././805.gif";
import Spinner from "../reusableComponents/Spinner";
import "./questionPapers.css";
import { Grid } from "@mui/material";

class QuestionPapers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      id: null,
      questionpaperDatas: [],
      showadd: false,
      topics: [],
      skills: [],
      selectedTopic: null,
      selectedComplex: null,
      topicId: "",
      skillId: "",
      topic: "",
      skill: "",
      paperName: "",
      topicData: [],
      complexData: [],
      data: [],
      status2: false,
      questionPaperType: { label: "Learner", value: "Learner" },
    };
  }

  componentDidMount() {
    this.props.getQuestionpapers();
    this.props.getstatusonskills();
    this.props.getstatusontopics();
  }

  handleQuestionPaper = () => {
    let { showadd } = this.state;
    showadd = showadd ? false : true;
    this.setState({
      showadd,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.questionpapers.getquestionpaperlist) {
      return null;
    } else if (nextProps.questionpapers.getquestionpaperlist.length < 0) {
      this.setState({
        data: nextProps.questionpapers.getquestionpaperlist,
        isLoaded: true,
      });
    } else {
      this.setState({
        data: nextProps.questionpapers.getquestionpaperlist,
        isLoaded: false,
      });
    }
    let { statusontopics } = nextProps.questiontopics;
    if (statusontopics) {
      let topics = [];
      if (statusontopics.length > 0) {
        statusontopics.map((topic) => {
          let qtopic = { label: topic.name, value: topic.id };
          // console.log(topic)
          topics.push(qtopic);
        });
      }
      this.setState({
        topicData: statusontopics,
        topics,
      });
    }
    let { statusonskills } = nextProps.questioncomplexity;
    if (statusonskills) {
      let skills = [];
      if (statusonskills.length > 0) {
        statusonskills.map((complex) => {
          let skill = { label: complex.name, value: complex.id };
          // console.log("complexity")
          skills.push(skill);
        });
      }
      this.setState({
        complexData: statusonskills,
        skills,
      });
    }
  }

  handleTopic = (selectedTopic) => {
    this.setState({
      selectedTopic,
    });
    // console.log(selectedTopic);
  };

  handleComplex = (selectedComplex) => {
    this.setState({
      selectedComplex,
    });

    // console.log(selectedComplex);
  };

  handleQuestionPaperType = (questionPaperType) => {
    this.setState({
      questionPaperType,
    });

    // console.log(selectedComplex);
  };
  // handleTopic = (e) => {
  //   let tname = e.target.value
  //   let tid = this.state.topicData.filter(topic => topic.name === tname)
  //   console.log(tid[0].id)
  //   this.setState({
  //     topic : e.target.value,
  //     topicId: tid[0].id
  //   })
  // }

  // handleComplexity = (e) => {
  //   let cname = e.target.value
  //   let cid = this.state.complexData.filter(skill => skill.name === cname)
  //   console.log(cid[0].id)
  //   this.setState({
  //     skill : e.target.value,
  //     skillId : cid[0].id
  //   })
  // }

  handleName = (e) => {
    this.setState({
      paperName: e.target.value,
    });
  };

  handleQuestions = () => {
    let {
      topic,
      skill,
      paperName,
      topicId,
      skillId,
      selectedComplex,
      selectedTopic,
      questionPaperType,
    } = this.state;
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: true,
    });
    if (!selectedTopic || selectedTopic.length === 0) {
      Toast.fire({
        icon: "error",
        title: "Please Select Question Topic !!!",
      });
    } else if (!selectedComplex || selectedComplex.length === 0) {
      Toast.fire({
        icon: "error",
        title: "Please Select Question Skill Level !!!",
      });
    } else if (!paperName) {
      Toast.fire({
        icon: "error",
        title: "Please Select Question Paper Name !!!",
      });
    } else {
      const { getquestionpaperlist } = this.props.questionpapers;
      let duplicate = getquestionpaperlist.filter(
        (qset) => paperName.toLowerCase() === qset.name.toLowerCase()
      );
      if (selectedComplex !== null) {
        let skillids = selectedComplex.map((skill) => skill.value);
        skillId = skillids.join();
        let skillnames = selectedComplex.map((skill) => skill.label);
        skill = skillnames.join();
      }
      if (selectedTopic !== null) {
        let topicids = selectedTopic.map((topic) => topic.value);
        topicId = topicids.join();
        let topicnames = selectedTopic.map((topic) => topic.label);
        topic = topicnames.join();
      }
      if (duplicate.length > 0) {
        Toast.fire({
          icon: "error",
          title: "Question Paper Name is already added!!!",
        });
      } else {
        this.props.history.push({
          pathname: `/admin/selectquestions`,
          state: {
            paperName: paperName,
            topicName: topic,
            skillName: skill,
            topicId: topicId,
            skillId: skillId,
            questionPaperType: questionPaperType?.value,
          },
        });
      }
    }
  };

  handleView(rowData) {
    // console.log("rowdata",rowData.rowData)
    const userdata = rowData.rowData;
    this.props.history.push({
      pathname: `/admin/questionlists`,
      state: {
        id: userdata[0],
        topicnames: userdata[2],
        skillnames: userdata[3],
      },
    });
  }

  handleUsers(rowData) {
    const userdata = rowData.rowData;
    this.props.history.push({
      pathname: `/admin/assignedusers`,
      state: {
        id: userdata[0],
        paperName: userdata[1],
        topicnames: userdata[2],
        skillnames: userdata[3],
      },
    });
  }

  handleAssign(rowData) {
    const userdata = rowData.rowData;
    this.props.history.push({
      pathname: `/admin/assigntest`,
      state: {
        id: userdata[0],
        paperName: userdata[1],
        topicnames: userdata[2],
        skillnames: userdata[3],
        topicids: userdata[5],
        skillids: userdata[4],
      },
    });
  }

  handleStatus(rowData) {
    const questionPaperId = rowData?.rowData[0];
    if (rowData?.rowData[10] === 0) {
      const data = {
        questionPaperId: questionPaperId,
        status: 1,
      };
      this.props.postQuestionPaperStatus(data);
    } else {
      const data = {
        questionPaperId: questionPaperId,
        status: 0,
      };
      this.props.postQuestionPaperStatus(data);
    }
  }

  render() {
    const questionpaperType = [
      { label: "Learner", value: "Learner" },
      { label: "Interview", value: "Interview" },
    ];
    const { user } = this.props.auth;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      //Table
      const columns = [
        {
          name: "id",
          label: "Question Id",
          options: { filter: false, sort: true, display: false },
        },
        {
          name: "name",
          label: "Question Paper Name",
          options: { filter: false, sort: true },
        },
        {
          name: "topic_name",
          label: "Topic",
          options: { filter: true, sort: false },
        },
        {
          name: "skill_name",
          label: "Skill Level",
          options: { filter: true, sort: false },
        },
        {
          name: "question_paper_type",
          label: "Paper Type",
          options: { filter: true, sort: false },
        },
        {
          name: "skill_id",
          label: "Skill Id",
          options: { filter: true, sort: true, display: false },
        },
        {
          name: "topic_id",
          label: "Topic Id",
          options: { filter: true, sort: true, display: false },
        },
        {
          name: "View Questions",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <button
                  className="btn btn-primary"
                  style={{ backgroundColor: "#003399", color: "whitesmoke" }}
                  onClick={(e) => this.handleView(tableMeta)}
                >
                  View Questions
                </button>
              );
            },
          },
        },

        (user && user.role === "admin") ||
        (user && user.role === "mentor") ||
        (user && user.role === "Customer Admin") ||
        (user && user.role === "Super Admin")
          ? {
              name: "Assign Tests",
              options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                  console.log(tableMeta.rowData[10]);
                  // const tableMeta=tableMeta;
                  return tableMeta.rowData[10] === 1 ? (
                    <button
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#003399",
                        color: "whitesmoke",
                      }}
                      onClick={(e) => this.handleAssign(tableMeta)}
                    >
                      Assign Tests
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#003399",
                        color: "whitesmoke",
                      }}
                      disabled={true}
                    >
                      Assign Tests
                    </button>
                  );
                },
              },
            }
          : "",
        {
          name: "Assigned Users",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <button
                  className="btn btn-primary"
                  style={{ backgroundColor: "#003399", color: "whitesmoke" }}
                  onClick={(e) => this.handleUsers(tableMeta)}
                >
                  Assigned Users
                </button>
              );
            },
          },
        },
        {
          label: "QuestionPaperStatus",
          name: "status",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                // <label className="switch" id="status">
                //   {
                //     // this.state.status2=="Active"?
                //     tableMeta?.rowData[10] === 0 ? (
                //       <input
                //         type="checkbox"
                //         onChange={(e) => this.handleStatus(tableMeta)}
                //       />
                //     ) : (
                //       <input
                //         type="checkbox"
                //         onChange={(e) => this.handleStatus(tableMeta)}
                //         checked
                //       />
                //     )
                //   }
                //   <span className="slidersQP round"></span>
                // </label>
                <label className="switch" id="status">
                  <input
                    type="checkbox"
                    onChange={(e) => this.handleStatus(tableMeta)}
                    checked={tableMeta?.rowData[10] !== 0}
                  />
                  <span className="slidersQP round"></span>
                </label>
              );
            },
          },
        },
      ];
      const data = this.state.questionpaperDatas;

      const options = {
        filterType: "checkbox",
        serverSide: false,
        pagination: true,
        // searchText: true,
        download: false,
        selectableRows: "none",
        viewColumns: false,
        rowsPerPage: 6,
        selectableRowsOnClick: true,
        rowsPerPageOptions: [10],
        rowHover: true,
        search: true,
        print: false,
        responsive: "scrollMaxHeight",
      };
      const { getquestionpaperlist } = this.props.questionpapers;
      const { skills, topics, selectedComplex, selectedTopic } = this.state;
      return (
        <div>
          {/* <Menu /> */}
          {/* <div className="mt-5">  */}
          <div class="text-center">
            {(user && user.role === "admin") ||
            (user && user.role === "mentor") ||
            (user && user.role === "Super Admin") ||
            (user && user.role === "Customer Admin") ? (
              <button
                type="button"
                id="btn"
                value="add question"
                className="btn btn-primary"
                style={{ backgroundColor: "#003399", color: "whitesmoke" }}
                onClick={() => this.handleQuestionPaper()}
              >
                Add Question Paper
              </button>
            ) : null}
          </div>
          {this.state.showadd && (
            <div className="row mt-5 pr-5 pl-5">
              <div className="col-sm-3" style={{ zIndex: 1000 }}>
                {/* <Select
                  isMulti
                  name="topic"
                  options={topics}
                  className="basic-multi-select"
                  placeholder="Select Topic"
                  onChange={this.handleTopic}
                  defaultValue={selectedTopic}
                /> */}
                <Multiselect
                  options={topics}
                  onSelect={this.handleTopic}
                  selectedValues={selectedTopic}
                  displayValue="label"
                  placeholder="Select Topic"
                  showArrow={true}
                  hidePlaceholder={true}
                  showCheckbox
                />
                {/* <select className="form-control" onChange={(e) => this.handleTopic(e)}>
                        <option selected="true" disabled="disabled">Select Question Topic</option>
                        {
                            this.state.topicData.map(topic => (
                                <option key={topic.id} value={topic.name}>{topic.name}</option>
                            ))
                        }
                    </select> */}
              </div>
              <div className="col-sm-3" style={{ zIndex: 1000 }}>
                {/* <Select
                  isMulti
                  name="skill"
                  options={[...new Set(skills)]}
                  className="basic-multi-select"
                  placeholder="Select Skill Level"
                  onChange={this.handleComplex}
                  defaultValue={selectedComplex}
                /> */}

                <Multiselect
                  options={[...new Set(skills)]}
                  onSelect={this.handleComplex}
                  selectedValues={selectedComplex}
                  displayValue="label"
                  placeholder="Select Skill Level"
                  showArrow={true}
                  hidePlaceholder={true}
                  showCheckbox
                />

                {/* <select className="form-control" onChange={(e) => this.handleComplexity(e)}>
                        <option selected="true" disabled="disabled">Select Skill Level</option>
                        {this.state.complexData.map(complex =>
                            (
                                <option key={complex.id} value={complex.name}>{complex.name}</option>
                            )
                        )}
                    </select > */}
              </div>
              <div className="col-sm-3">
                <Select
                  name="type"
                  options={questionpaperType}
                  className="basic-multi-select"
                  placeholder="Select questionpaper type"
                  onChange={this.handleQuestionPaperType}
                  defaultValue={{ label: "Learner", value: "Learner" }}
                />
              </div>
              <div className="col-sm-3">
                <input
                  type="text"
                  placeholder="Enter Question Paper Name"
                  onChange={(e) => this.handleName(e)}
                />
              </div>
              <div className="col-sm-5 mt-3"></div>
              <div className="col-sm-3 my-3">
                <button
                  className="btn btn-primary"
                  onClick={() => this.handleQuestions()}
                >
                  Select Questions
                </button>
              </div>
            </div>
          )}
          <div className="mutable">
            {getquestionpaperlist ? (
              <Grid container>
                <Grid
                  item
                  xs="12"
                  md="12"
                  style={{ height: "100px", width: "100px" }}
                >
                  <Tables
                    style={{ height: "40%", width: "40%" }}
                    title="Question Paper Lists"
                    data={getquestionpaperlist}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              // // <div className="image-container">
              //   {/* <p className="image-holder">
              //     <img src={loader} alt="" />
              //   </p> */}
                
              // // </div>.
              <Spinner /> 
            )}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  questionpapers: state.questionpapers,
  questioncomplexity: state.questioncomplexity,
  questiontopics: state.questiontopics,
});

const mapDispatchToProps = {
  getstatusontopics,
  getstatusonskills,
  getQuestionpapers,
  postQuestionPaperStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPapers);
