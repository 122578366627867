import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getTechnologyList,
  getDomainList,
} from "../../../redux/actions/productivityActions/domainAction";
import { getFunctionList } from "../../../redux/actions/productivityActions/functionAction";
import { getScenarioList } from "../../../redux/actions/productivityActions/scenarioAction";
import {
  getQuestionInfo,
  editquestion,
} from "../../../redux/actions/productivityActions/questionAction";
import { getCorrectQuery } from "../../../redux/actions/productivityActions/codeSubmissionActions"
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";
import axios from 'axios';
import { API_URL } from "../../../Api_Request";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const EditQuestion = ({ setOpenPopup, questionId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { auth, domainReducer, functionReducer, scenarioReducer, codeSubmissionReducer } = useSelector(
    (state) => state
  );
  const [domainList, setDomainList] = useState([]);
  const [functionList, setFunctionList] = useState([]);
  const [scenarioList, setScenarioList] = useState([]);
  const [isRDBMS, setIsRDBMS] = useState(false);
  const { questionReducer } = useSelector((state) => state);

  useEffect(() => {
    if (auth?.isAuthenticated) {
      dispatch(getTechnologyList());
      dispatch(getDomainList());
      dispatch(getFunctionList());
      dispatch(getScenarioList());
      dispatch(getQuestionInfo(questionId));
      dispatch(getCorrectQuery(questionId));
    }
  }, [auth?.isAuthenticated]);

  const questionInfo = questionReducer.getQuestionInfo;

  useEffect(() => {
    if (domainReducer?.getDomainList.length > 0 && questionInfo) {
      const selectedDomain = domainReducer?.getDomainList?.find(
        (item) => item._id?.toString() === questionInfo?.domain?.toString()
      );
      formik.setFieldValue("selectDomain", selectedDomain || {});
    }
  }, [domainReducer?.getDomainList, questionInfo]);

  useEffect(() => {
    if (domainReducer?.getTechnologyList.length > 0 && questionInfo) {
      console.log('domainReducer?.getTechnologyList: ', domainReducer?.getTechnologyList);
      const selectedTechnology = domainReducer?.getTechnologyList?.find(
        (item) => item._id?.toString() === questionInfo?.technology?.toString()
      );
      if(selectedTechnology?.name == "RDBMS")
        setIsRDBMS(true);
      else
        setIsRDBMS(false);
      formik.setFieldValue("selectTechnology", selectedTechnology || {});
      const filteredList = domainReducer?.getDomainList?.filter(
        (item) => item?.technology?._id === selectedTechnology?._id?.toString()
      );
      setDomainList(filteredList);
    }
  }, [domainReducer?.getTechnologyList, questionInfo]);

  useEffect(() => {
    if (functionReducer?.getFunctionList?.length > 0 && questionInfo) {
      const selectedFunction = functionReducer?.getFunctionList?.find(
        (item) => item._id?.toString() === questionInfo?.function?.toString()
      );

      formik.setFieldValue("selectFunction", selectedFunction || {});
      const filteredList = functionReducer?.getFunctionList?.filter(
        (item) => item?.technology?._id === selectedFunction?._id?.toString()
      );
      setFunctionList(filteredList);
    }
  }, [functionReducer?.getFunctionList, questionInfo]);

  useEffect(() => {
    if (scenarioReducer?.getScenarioList?.length > 0 && questionInfo) {
      const selectedScenario = scenarioReducer?.getScenarioList?.find(
        (item) => item._id?.toString() === questionInfo?.scenario?.toString()
      );

      formik.setFieldValue("selectScenario", selectedScenario || {});
      const filteredList = scenarioReducer?.getScenarioList?.filter(
        (item) => item?.technology?._id === selectedScenario?._id?.toString()
      );
      setScenarioList(filteredList);
    }
  }, [functionReducer?.getFunctionList, questionInfo]);

  useEffect(()=>{
    if(codeSubmissionReducer?.correctQuery?.length>0)
      {
        formik.setFieldValue("correctQuery", codeSubmissionReducer?.correctQuery);
      }
  },[codeSubmissionReducer?.correctQuery])
  // console.log("functionreducer",functionReducer)

  useEffect(() => {
    if (questionInfo) {
      formik.setFieldValue(
        "questionDescription",
        questionInfo ? questionInfo?.questionDescription : ""
      );

      formik.setFieldValue(
        "enableStatus",
        questionInfo?.status
          ? { name: "Enabled", _id: true }
          : { name: "Disbaled", _id: false }
      );

      // formik.setFieldValue(
      //   "functionDescription", scenarioInfo ? scenarioInfo?.scenarioImage.link : ""
      // );
    }
  }, [questionInfo]);

  const technologyList = domainReducer?.getTechnologyList?.map((item) => {
    return {
      _id: item._id,
      name: item.name,
    };
  });

  const handleTechnology = (technology) => {
    formik.setFieldValue("selectTechnology", technology);
    const filteredList = domainReducer?.getDomainList?.filter(
      (item) => item?.technology?._id === technology?._id?.toString()
    );
    setDomainList(filteredList);
  };

  const handleDomain = (domain) => {
    formik.setFieldValue("selectDomain", domain);
    const filteredList = functionReducer?.getFunctionList?.filter(
      (item) => item?.domain?._id === domain?._id?.toString()
    );
    setFunctionList(filteredList);
  };

  const handleFunction = (functionData) => {
    formik.setFieldValue("selectFunction", functionData);
    const filteredList = scenarioReducer?.getScenarioList?.filter(
      (item) => item?.function?._id === functionData?._id?.toString()
    );
    setScenarioList(filteredList);
  };

  const formik = useFormik({
    initialValues: {
      selectTechnology: "",
      selectDomain: "",
      selectFunction: "",
      selectScenario: "",
      questionDescription: "",
      enableStatus: "",
      correctQuery: "",
    },
    validationSchema: Yup.object({
      questionDescription: Yup.string()
        .trim()
        .required("Question Description is a required field"),
      selectTechnology: Yup.object()
        .nullable()
        .required("Technology is reqired field"),
      selectDomain: Yup.object().nullable().required("Domain is a required field"),
      selectFunction: Yup.object()
        .nullable()
        .required("Function is a required field"),
      selectScenario: Yup.object()
        .nullable()
        .required("Scenario is a required field"),
    }),

    onSubmit: async (values) => {
      const data = {
        _id: questionId,
        technology: values?.selectTechnology?._id,
        domain: values?.selectDomain?._id,
        function: values?.selectFunction?._id,
        scenario: values?.selectScenario?._id,
        questionDescription: values?.questionDescription,
        status: values?.enableStatus?._id,
      };

      // add correct query (decoupled from prev logic)
      const correctQueryData = {
        questionId: questionId,
        domain: values?.selectDomain?._id,
        correctQuery: values?.correctQuery
      }
      
      const correctSQLQueryData = {...data, ...correctQueryData}
      axios.post(`${API_URL}/codeSubmission/addCorrectSqlQuery`, correctSQLQueryData)
      .then((res)=>{
        console.log('response ',res);
      })
      .catch((err)=>{
        Swal.fire({
          icon: "error",
          text: `Entered query not accepted. ${err.response.data.error}`,
        });
      })

      dispatch(editquestion(data));
      //console.log("question data",data)
      setOpenPopup(false);
    },
  });

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Technology"
            name="selectTechnology"
            values={technologyList || []}
            onSelect={(technology) => handleTechnology(technology)}
            selected={formik.values.selectTechnology}
            errors={
              formik.touched.selectTechnology && formik.errors.selectTechnology
                ? formik.errors.selectTechnology
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Domain"
            name="selectDomain"
            values={domainList || []}
            onSelect={(domain) => handleDomain(domain)}
            selected={formik.values.selectDomain}
            errors={
              formik.touched.selectDomain && formik.errors.selectDomain
                ? formik.errors.selectDomain
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Function"
            name="selectFunction"
            values={functionList || []}
            onSelect={(functionData) => handleFunction(functionData)}
            selected={formik.values.selectFunction}
            errors={
              formik.touched.selectFunction && formik.errors.selectFunction
                ? formik.errors.selectFunction
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Scenario"
            name="selectScenario"
            values={scenarioList || []}
            onSelect={(scenario) =>
              formik.setFieldValue("selectScenario", scenario)
            }
            selected={formik.values.selectScenario}
            errors={
              formik.touched.selectScenario && formik.errors.selectScenario
                ? formik.errors.selectScenario
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectSingleValues
            label="Enable/Disable Question"
            name="enableStatus"
            values={
              [
                { name: "Enabled", _id: true },
                { name: "Disabled", _id: false },
              ] || []
            }
            onSelect={(status) => {
              formik.setFieldValue("enableStatus", status);
            }}
            selected={formik.values.enableStatus}
            errors={
              formik.touched.enableStatus && formik.errors.enableStatus
                ? formik.errors.enableStatus
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextFieldGroup
            variant="outlined"
            multiline="multiline"
            rows={4}
            label="Question Description"
            name="questionDescription"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.questionDescription}
            errors={
              formik.touched.questionDescription &&
              formik.errors.questionDescription
                ? formik.errors.questionDescription
                : null
            }
          />
        </Grid>

        {isRDBMS && (
        <Grid item xs={12} md={12}>
          <TextFieldGroup
            variant="outlined"
            rows={4}
            label="Correct Query"
            name="correctQuery"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.correctQuery}
            errors={
              formik.touched.correctQuery &&
              formik.errors.correctQuery
                ? formik.errors.correctQuery
                : null
            }
          />
        </Grid>
        )}

        <Grid item xs={12} md={6} align="right">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="save"
              name="save"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </Grid>

        {/* <Grid item xs={12} md={6}>
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="reset"
              name="reset"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Reset
            </Button>
          </div>
        </Grid> */}
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

export default EditQuestion;
