import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar } from "@material-ui/core";
import Table from "../../reusableComponents/MaterialTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserSessions } from "../../../redux/actions/productivityActions/debuggingCodeActions";
import { Link } from "react-router-dom";
import ButtonField from "../../reusableComponents/ButtonField";
import loader from "../../805.gif";
// import ResultFilters from "../resultFilter";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const   DebuggingFeedbackResults = ({ history }) => {
  const { auth, codeReducer } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAuthenticated } = auth;

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAllUserSessions());
    }
  }, [isAuthenticated]);

  const data = codeReducer?.debuggingSessions?.map((item) => {
    return {
      _id: item._id,
      name: item?.capabilityUser.first_name,
      email: item?.capabilityUser?.email,
      topic: item?.capabilityTopic?.name,
      complexity: item?.capabilityComplexity?.name,
      createdAt: new Date(item?.createdAt).toDateString(),
      details: "View Details",
    };
  });

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Topic",
      field: "topic",
    },
    {
      title: "Complexity",
      field: "complexity",
    },
    {
      title: "Date&Time",
      field: "createdAt",
    },
    // {
    //   title: "QuestionsAttended",
    //   field: "questionsAttended",
    // },
    {
      title: "View Details",
      field: "details",
      render: (rowData) => (
        <Link to={`/admin/view-debugging-session/${rowData._id}`}>
        <ButtonField
          buttonStyle={classes.formSubmit}
          type="button"
          name="View Result"
          // onClick={() =>
          //   history.push({
          //     pathname: `/admin/viewresults`,
          //     state: {
          //       userId: row.id,
          //       testId: row.test_id,
          //       topics: row.topicname,
          //     },
          //   })
          // }
        > </ButtonField>
        
        </Link>
      ),
    },
  ];

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <>
            {/* <ResultFilters productivityResult={true} /> */}
            <Table
              title={<b>Feedback Results</b>}
              columns={columns}
              data={data}
              pageSize={10}
              selectedRow={(row) => console.log("")}
            />
          </>
        </Grid>
      </Grid>
    </Container>
  );
};

DebuggingFeedbackResults.propTypes = {
  auth: PropTypes.object.isRequired,
  getCoachList: PropTypes.func.isRequired,
  clearCoachInfo: PropTypes.func.isRequired,
  adminCoach: PropTypes.object.isRequired,
};

export default DebuggingFeedbackResults;
