import { GETASSIGNTOPICS, POSTASSIGNTOPICS, EDITASSIGNTOPICS, DELETEASSIGNTOPICS } from './type';
import axios from 'axios';
import { API_URL } from '../../Api_Request';
import Swal from 'sweetalert2';



export const getTopics = (id) => dispatch => {
    axios.all([
        axios.get(`${API_URL}/members/status`),
        axios.get(`${API_URL}/complexity/statuson`),
        axios.get(`${API_URL}/mapusers/${id}`)])
        .then(axios.spread((topicData, complexData, mappedData) => {
        dispatch({
            type : GETASSIGNTOPICS, 
            payload : {topicData:topicData, complexData:complexData, mappedData:mappedData}
        })
    }))
    .catch(err => console.log(err))
}

export const assignTopics = (params) => dispatch => {
      axios.post(`${API_URL}/mapusers/`,params)
        .then(res => {
          const result = res.data;
          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            icon: 'success',
            title: 'Added successfully'
          })
          dispatch(getTopics(params.userId))
          dispatch({
              type : POSTASSIGNTOPICS, 
              payload : res.data
          })
          
          // console.log(res.data)
      })
      .catch(err => console.log(err))
  }

  export const editTopics = (params) => dispatch => {
      axios.put(`${API_URL}/mapusers/`,params)
        .then(res => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                icon: 'success',
                title: 'Updated successfully'
              })
          dispatch(getTopics(params.userId))
          dispatch({
              type : EDITASSIGNTOPICS, 
              payload : res.data
          })
          
          // console.log(res.data)
      })
      .catch(err => console.log(err))
  }


  export const deleteTopics = (params) => dispatch => {
      axios.post(`${API_URL}/mapusers/delete`,params)
        .then(res => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                icon: 'success',
                title: 'Deleted successfully'
              })
          dispatch(getTopics(params.userId))
          dispatch({
              type : DELETEASSIGNTOPICS, 
              payload : res.data
          })
          
          // console.log(res.data)
      })
      .catch(err => console.log(err))
  }