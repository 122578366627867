import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

// const options = {
//     responsive: true,
//     plugins: {
//         legend: {
//             position: "top",
//         },
//         title: {
//             display: true,
//             text: "Performance Chart",
//         },
//     },
// };
const options = {
  legend: {
    display: true,
    position: "left",
  },
};

const DonutChart = ({ donutGraphData, donutGraphLabels }) => {
  // const labels = donutGraphLabels ? donutGraphLabels : [];

  // const data = {
  //     labels,
  //     datasets: donutGraphData.map((item) => {
  //         return {
  //             label: item.label,
  //             data: item.data,
  //             backgroundColor: item.backgroundColor,
  //         };
  //     }),
  // };

  const data = {
    labels: ["WrongAnswers", "CorrectAswers", "NotAnswered", "sdf"],
    datasets: [
      {
        label: "# of Votes",
        data: [40, 30, 20, 90],
        backgroundColor: [
          "rgba(60, 74, 62,0.8)",
          "rgb(68, 209, 107,0.5)",
          "rgba(159, 175, 161,0.5)",
          "rgb(68, 209, 107,0.5)",
        ],
      },
    ],
  };

  return (
    <>
      {/* <Doughnut options={options} data={data} />
            <Doughnut options={options} data={data} /> */}
      <div style={{ width: "400px", height: "400px", position: "relative" }}>
        <Doughnut data={data} width={400} height={400} />
        <div
          style={{
            height: "100px",
            width: "100px",
            backgroundColor: "",
            borderRadius: "50%",
            top: "60%",
            left: "38%",
            textAlign: "center",
            marginTop: "-65px",
            lineHeight: "20px",
            position: "absolute",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: "60%",
              left: 0,
              textAlign: "center",
              marginTop: "-35px",
              lineHeight: "20px",
            }}
          >
            <span>
              <h6 style={{ color: "#000000" }}>Total Questions:1000</h6>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DonutChart;
