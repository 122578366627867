import { TEST_SELECTED, REPORT_LOADED, BACK_ARROW, EMPTY_REPORT, REPORT_GEN_ERROR } from "../type";
import axios from 'axios';

export const setReportDetails = (data) => (dispatch)=>{
    dispatch({type: TEST_SELECTED, payload: data});
    axios.get("/api/reportGenerator/report", {params: data} )
    .then((res) => {
        if(res.status == 200)
            dispatch({ type: REPORT_LOADED, payload: res })
        else if(res.status == 204){
            dispatch({ type: EMPTY_REPORT })
            setTimeout(()=>{
                dispatch({type: BACK_ARROW});
            }, 4000)
        }       
    })
    .catch(err=>{
        dispatch({ type: REPORT_GEN_ERROR, payload: err })
        setTimeout(()=>{
            dispatch({ type: BACK_ARROW });
        }, 4000)
    })
}

export const setErrorMessage = (data) => (dispatch) =>{
    dispatch({type: REPORT_GEN_ERROR, payload: data})
    setTimeout(()=>{
        dispatch({type: BACK_ARROW});
    })
}
export const reset = () => (dispatch) => {
    dispatch({ type: BACK_ARROW });
};