import React,{useState,useEffect}from "react";
import "../../components/questions/questions.css";
// import Menu from "../users/usermenu/usermenu";
import { Grid } from "@material-ui/core";
import DonutChart from "../reusableComponents/DonutChart";
import { connect } from "react-redux";
import {getUserDashboardData}from "../../redux/actions/userDashboarAction";
import DashBoardCard from "../reusableComponents/DashBoardCard";
import { ShimmerThumbnail } from "react-shimmer-effects";

// class U[serInfo extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

const UserInfo =({
    auth,
    getUserDashboardData,
    userDashboard
}) =>{
     

    useEffect(()=>{
        getUserDashboardData()  
    },[])
    
    const {userDashboardData} = userDashboard
    // console.log("user info",userDashboard[0],userDashboard,userDashboard[0]?.topicsAlloted)
    return (
        <div className="mutable">
          {/* <Menu /> */}
        
          <Grid container spacing={8} className="mt-5">
            <Grid item xs={12} md={4} sm={6}>
              {
                userDashboardData?.length > 0 ? 
                <DashBoardCard title="Number of Subjects Alloted" count={userDashboardData && userDashboardData[0]?.topicsAlloted|| 0 } />
                :
                <ShimmerThumbnail height={90} width={440} rounded />
              }
             

            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              {
                 userDashboardData?.length > 0 ? 
              <DashBoardCard title="Number of Tests taken" count={userDashboardData && userDashboardData[0]?.totalTestsTaken|| 0} />
              :
              <ShimmerThumbnail height={90} width={440} rounded />  
            }
              </Grid>
  
            <Grid item xs={12} md={4} sm={6}>
              {
                 userDashboardData?.length > 0 ? 
              <DashBoardCard title="Total Questions Attended " count={userDashboardData && userDashboardData[0]?.totalQuestionsAttended|| 0} />
              :
              <ShimmerThumbnail height={90} width={440} rounded />
            }
              </Grid>
          </Grid>
          
          {/* <Grid
            item
            align="center"
            justify="center"
            direction="column"
            className="mt-5"
          >
            <DonutChart />
          </Grid> */}
          
        </div>
      );
}
 

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   assignedtests: state.assignedtests,
//   resultsData: state.resultsData,
// });

// const mapDispatchToProps = {
//   getincompletetest,
//   userResultsData,
// };

const mapStateToProps = (state) => ({
    auth: state.auth,
    userDashboard:state.userDashboard,
    });
  
   const mapDispatchToProps = {
    getUserDashboardData,
   };
  
  export default connect(mapStateToProps , mapDispatchToProps) (UserInfo);
