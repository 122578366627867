import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { addnewchapter } from "../../redux/actions/questionChapterAction";
import * as Yup from "yup";

import TextFieldGroup from "../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../reusableComponents/SelectSingleValues";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const AddNewQuestionChapter = ({ iLearnVideos, topicsList, addnewchapter,setOpenPopup }) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      chapterName: "",
      selectCapabilityTopic: "",
      selectIlearnCourse: "",
      selectedCourseVideo: "",
    },
    validationSchema: Yup.object({
      chapterName: Yup.string()
        .trim()
        .required("Schedule Title is required field"),
      // scheduleDescription: Yup.string()
      //   .trim()
      //   .required("Schedule Description is required field"),
      selectCapabilityTopic: Yup.object()
        .nullable()
        .required("Program Duration is reqired field"),
      // selectIlearnCourse: Yup.object()
      //   .nullable()
      //   .required("Program Duration is reqired field"),
      // selectedCourseVideo: Yup.object()
      //   .nullable()
      //   .required("Program Duration is reqired field"),
    }),

    onSubmit: async (values) => {
      // console.log("submit value", values);
      const data = {
        chapterName: values?.chapterName,
        iLearnCourse: values?.selectIlearnCourse?.id,
        iLearnVideo: values?.selectedCourseVideo?.id,
        topicId: values?.selectCapabilityTopic?.id,
      };
      addnewchapter(data);
      // console.log(data)
      setOpenPopup(false);
    },
  });

  const [courseVideoLink, setCourseVideoLink] = useState([]);

  const setVideosLink = (batch) => {
    const batchVideoLink = batch?.videos?.map((item) => {
      return {
        id: item._id,
        name: item.title,
      };
    });
    setCourseVideoLink(batchVideoLink);
  };

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
    
        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Capability Topic"
            name="selectCapabilityTopic"
            values={topicsList || []}
            onSelect={(batch) =>
              formik.setFieldValue("selectCapabilityTopic", batch)
            }
            selected={formik.values.selectCapabilityTopic}
            errors={
              formik.touched.selectCapabilityTopic &&
              formik.errors.selectCapabilityTopic
                ? formik.errors.selectCapabilityTopic
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ marginTop: "-15px" }}>
          <TextFieldGroup
            label="Chapter Name"
            name="chapterName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.chapterName}
            errors={
              formik.touched.chapterName && formik.errors.chapterName
                ? formik.errors.chapterName
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Ilearn course"
            name="selectIlearnCourse"
            values={iLearnVideos || []}
            onSelect={(batch) => {
              formik.setFieldValue("selectIlearnCourse", batch);
              setVideosLink(batch);
            }}
            selected={formik.values.selectIlearnCourse}
            errors={
              formik.touched.selectIlearnCourse &&
              formik.errors.selectIlearnCourse
                ? formik.errors.selectIlearnCourse
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select selected course video"
            name="selectedCourseVideo"
            values={courseVideoLink || []}
            onSelect={(batch) =>
              formik.setFieldValue("selectedCourseVideo", batch)
            }
            selected={formik.values.selectedCourseVideo}
            errors={
              formik.touched.selectedCourseVideo &&
              formik.errors.selectedCourseVideo
                ? formik.errors.selectedCourseVideo
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={12} align="center">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="submit"
              name="submit"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

AddNewQuestionChapter.propTypes = {
  auth: PropTypes.object.isRequired,
  addnewchapter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  addnewchapter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewQuestionChapter);
