import {
  SINGLETOPICQUESTIONS,
  ALLTOPICQUESTIONS,
  GETQUESTIONSRELATEDDATA,
  ADDNEWQUESTION,
  GETEDITQUESTION,
  UPDATEQUESTION,
} from "./type";
import axios from "axios";
import { API_URL } from "../../Api_Request";
import Swal from "sweetalert2";

//List of questions based on a single topic
export const singletopicquestions = (id) => (dispatch) => {
  // console.log(id);
  // console.log(token.token)
  axios
    .get(`${API_URL}/members/${id}`)
    .then((res) => {
      dispatch({
        type: SINGLETOPICQUESTIONS,
        payload: res.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

//List of all questions
export const alltopicquestions = () => (dispatch) => {
  axios
    .get(`${API_URL}/questions/`)
    .then((res) => {
      dispatch({
        type: ALLTOPICQUESTIONS,
        payload: res.data,
      });

      //   console.log(res.data)
    })
    .catch((err) => console.log(err));
};

//Get questions related topics and chapters
export const getTopicsAndChapters = () => (dispatch) => {
  axios
    .all([
      axios.get(`${API_URL}/members/status`),
      axios.get(`${API_URL}/complexity/statuson`),
      axios.get(`${API_URL}/type`),
      axios.get(`${API_URL}/chapters`),
    ])
    .then(
      axios.spread((topicData, complexData, typeData, chapterdata) => {
        dispatch({
          type: GETQUESTIONSRELATEDDATA,
          payload: {
            topicData: topicData,
            complexData: complexData,
            typeData: typeData,
            chapterdata: chapterdata,
          },
        });

        // console.log(res.data)
      })
    )
    .catch((err) => console.log(err));
};

//Add a question
export const addquestion = (params, navigationParams) => (dispatch) => {
  axios
    .post(`${API_URL}/choices/addQuestions`, params)
    .then((res) => {
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });

      Toast.fire({
        icon: "success",
        title: "Question added successfully",
      }).then(() => {
        navigationParams.history.push(
          `/admin/questions?id=${navigationParams.tid}`
        );
      });
    })
    .catch((err) => {
      console.log(err);
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

//Add FreeText/Voice type question
export const addFreeTextVoiceQuestion =
  (data, navigationParams) => (dispatch) => {
    axios
      .post(`${API_URL}/query/addFreeText`, data)
      .then((res) => {
        // console.log(res);
        const Toast = Swal.mixin({
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });

        Toast.fire({
          icon: "success",
          title: "Question added successfully",
        }).then(() => {
          navigationParams.history.push(
            `/admin/questions?id=${navigationParams.tid}`
          );
        });
        dispatch({
          type: ADDNEWQUESTION,
          payload: "Success",
        });

        //   console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
        const { message } = err && err.response.data;
        Swal.fire({ icon: "error", text: message });
        navigationParams.history.push(
          `/admin/questions?id=${navigationParams.tid}`
        );
        dispatch({
          type: ADDNEWQUESTION,
          payload: "Success",
        });
      });
  };

//Get questions related topics and chapters for Edit questions Component
export const geteditquestion = (id) => (dispatch) => {
  axios
    .all([
      axios.get(`${API_URL}/members/status`),
      axios.get(`${API_URL}/complexity/statuson`),
      axios.get(`${API_URL}/type`),
      axios.get(`${API_URL}/chapters`),
      axios.get(`${API_URL}/questions/${id}`),
    ])
    .then(
      axios.spread(
        (topicData, complexData, typeData, chapterdata, editQuesData) => {
          dispatch({
            type: GETEDITQUESTION,
            payload: {
              topicData: topicData?.data,
              complexData: complexData?.data,
              typeData: typeData?.data,
              chapterdata: chapterdata?.data,
              editQuesData: editQuesData?.data,
            },
          });

          // console.log(res.data)
        }
      )
    )
    .catch((err) => console.log(err));
};

//CLear Question data
export const clearQuestionData = () => (dispatch) => {
  dispatch({
    type: GETEDITQUESTION,
    payload: null,
  });
};

//Edit a question
export const updatequestion = (data, navigationParams) => (dispatch) => {
  // console.log(params)
  axios
    .put(`${API_URL}/questions/${data.id}`, data)
    .then((res) => {
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });

      Toast.fire({
        icon: "success",
        title: "Question Updated successfully",
      }).then(() => {
        navigationParams.history.push(
          `/admin/questions?id=${navigationParams.tid}`
        );
      });
      dispatch({
        type: UPDATEQUESTION,
        payload: "Success",
      });

      //   console.log(res.data)
    })
    .catch((err) => {
      console.log(err);
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
      navigationParams.history.push(
        `/admin/questions?id=${navigationParams.tid}`
      );
      dispatch({
        type: UPDATEQUESTION,
        payload: "Success",
      });
    });
};

export const changeQuestionStatus = (data) => dispatch => {
  console.log(data);
    axios.put(`${API_URL}/questions/editQuestionStatus/${data?.quesionId}`, data)
    .then (res => {
      if ( 
        data.topicId=== "null" ||  
        data.topicId=== undefined || 
        data.topicId === null) {
        dispatch(alltopicquestions())
      } else {
        // dispatch(singletopicquestions(data?.quesionId))
        dispatch(alltopicquestions())
      }
        
        const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            icon: 'success',
            title: res.data && res.data.message
          })
    })
    .catch(err => {
        Swal.fire({icon: 'error',
                text: `${err.response.data.message}`}
              );
        console.log(err)
    })
}
