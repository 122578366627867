import React from 'react';
import Swal from 'sweetalert2'
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { postquestionpaper } from '../../redux/actions/questionPapersAction';

const defaultToolbarSelectStyles = {
  iconButton: {},
  iconContainer: {
    marginRight: '24px',
  },
  inverseIcon: {
    transform: 'rotate(90deg)',
  },
};

class CustomToolbarSelect extends React.Component {
  state = {
    dataid: [],
    userId: '',
  };


  handleClickBlockSelected = (e) => {
    const Toast = Swal.mixin({toast: true,position: 'center',showConfirmButton: true,})
    let data = []
    let questionids = []
    this.props.selectedRows.data.map(row => {
      this.props.displayData.map(item => {
        if(row.dataIndex === item.dataIndex){
            data.push(item.data)
        }
      })
    })
    data.map(item => {
      questionids.push(item[0])
    })
    console.log("hehe",questionids)
    if(this.props.topicId.length <= 2 && this.props.skillId.length <= 2){
      if(data.length < 10){
        Toast.fire({
          icon: 'error',
          title: 'Please select at least 10 questions !!!'
      })
      }else if(data.length > 40){
        Toast.fire({
          icon: 'error',
          title: 'You can select only 20 questions !!!'
      })
    }else{
      const qpaper = {
        paperName : this.props.paperName,
        topicId : this.props.topicId,
        skillId : this.props.skillId,
        questionid : questionids,
        questionPaperType:this.props.questionPaperType,
      }
      // console.log(this.props.questionPaperType)
      this.props.postquestionpaper(qpaper)
    }
    }else if(this.props.topicId.length > 2 || this.props.skillId.length > 2){
      if(data.length < 10){
        Toast.fire({
          icon: 'error',
          title: 'Please select at least 10 questions !!!'
      })
      }else if(data.length > 40){
        Toast.fire({
          icon: 'error',
          title: 'You can select only 40 questions !!!'
      })
    }else{
      const qpaper = {
        paperName : this.props.paperName,
        topicId : this.props.topicId,
        skillId : this.props.skillId,
        questionid : questionids,
        questionPaperType:this.props.questionPaperType,
      }
      // console.log(this.props.questionPaperType)
      this.props.postquestionpaper(qpaper)
    }
    }
  };



  render () {
    const {classes} = this.props;

    return (
      <button onClick={(e) => this.handleClickBlockSelected(e)}
      className="btn tbn-primary mx-auto" 
      style={{backgroundColor:"blue",color:"white"}}>Add Questions</button> 
    );
  }
}

const mapStateToProps = state => ({
  auth : state.auth,
  questionpapers : state.questionpapers
})

const mapDispatchToProps = {
  postquestionpaper
}

export default (withRouter (connect(mapStateToProps,mapDispatchToProps)(withStyles (defaultToolbarSelectStyles, {name: 'CustomToolbarSelect'}) (CustomToolbarSelect))));
