import React from "react";
import { InputAdornment, IconButton, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { BsExclamationDiamond } from "react-icons/bs";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LocalPhoneOutlinedIcon from "@material-ui/icons/LocalPhoneOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import CategoryIcon from "@material-ui/icons/Category";
import DeckIcon from "@material-ui/icons/Deck";
import DomainIcon from "@material-ui/icons/Domain";

const TextFieldGroup = ({
  id,
  label,
  heading,
  type,
  className,
  name,
  size,
  value,
  multiline,
  rows,
  variant,
  disabled,
  onChange,
  onInput,
  onBlur,
  onKeyPress,
  onKeyDown,
  autoFocus,
  labelShink,
  refs,
  showLable,
  errors,
  showPassword,
  passwordControl,
  placeholder,
}) => {
  let icon = null;
  if (name === "password") {
    icon = (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={passwordControl}
          onMouseDown={(text) => text}
          size="small"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  }

  const errorText = (
    <p>
      <BsExclamationDiamond style={{ width: "12px" }} /> {errors}
    </p>
  );
  return (
    <div>
      {showLable && (
        <div className="fieldHeading" style={{ fontWeight: "bold" }}>
          {heading}
        </div>
      )}
      <TextField
        label={label}
        type={type}
        variant={variant || "standard"}
        margin="normal"
        required
        placeholder={placeholder}
        inputRef={refs}
        id={name}
        size={size || "small"}
        name={name}
        disabled={disabled || false}
        onChange={onChange}
        onInput={onInput}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus || null}
        fullWidth
        value={value}
        multiline={multiline}
        rows={rows}
        InputLabelProps={{ shrink: labelShink }}
        error={errors ? true : false}
        helperText={errors ? errorText : null}
        InputProps={{
          endAdornment: icon,
          className: className,
        }}
      />
    </div>
  );
};

export default TextFieldGroup;
