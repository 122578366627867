import React, { Component } from "react";
//import data from './data.json';
import * as d3 from "d3";
import "./GroupBarChart.css";
// import Menu from "../menu/menu";
// import UserMenu from '../users/usermenu/usermenu';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { allusers } from "../../redux/actions/visualsAction";
import Swal from "sweetalert2";
import loader from ".././805.gif";

class ResultGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.props.allusers();
  }

  componentWillReceiveProps(nextProps) {
    const { allusersvisualdata } = nextProps.visuals;
    if (allusersvisualdata) {
      if (allusersvisualdata.length > 0) {
        let d = document.getElementById("groupBarChart");
        d.innerHTML = "";
        this.GroupbarChart(allusersvisualdata);
        this.setState({
          isLoaded: true,
        });
      } else {
        this.setState({
          isLoaded: true,
        });
        Swal.fire({
          icon: "warning",
          text: "No Data found to display chart !!!",
        }).then(() => {
          this.props.history.push("/login");
        });
      }
    }
  }

  GroupbarChart = (data) => {
    //taking all the topic names
    var Topic_Name = [];

    data.forEach(function (d) {
      Topic_Name.push(d.Topic_Name);
    });

    var Topic_Name = [...new Set(Topic_Name)];
    Topic_Name.unshift("--Topics--");
    console.log(Topic_Name);

    // filtering all the skill
    var skill_Name = [];

    data.forEach(function (d) {
      skill_Name.push(d.Skill_Level);
    });

    var skill_Name = [...new Set(skill_Name)];
    skill_Name.unshift("--Skill Levels--");
    console.log(skill_Name);

    //taking all the unique names
    var names = [];

    data.forEach(function (d) {
      names.push(d.First_Name);
    });

    var dis_names = [...new Set(names)];

    // data for default sctter plot
    var coll = [];

    for (var i = 0; i < dis_names.length; i++) {
      var person = new Object();
      person.name = dis_names[i];
      var te = [];
      for (var j = 0; j < data.length; j++) {
        if (dis_names[i] == data[j]["First_Name"]) {
          te.push({
            Total_Marks: data[j]["Total_Marks"],
            Correct_Answers: data[j]["Correct_Answers"],
            Skill_Level: data[j]["Skill_Level"],
            Topic_Name: data[j]["Topic_Name"],
          });
        }
      }
      // console.log("each test:", te)
      person.test = te;
      coll.push(person);
    }

    console.log("collection", coll);

    // calculating average percentage for all the students
    var per = [];

    coll.forEach(function (d) {
      var cal = new Object();
      cal.First_Name = d.name;
      var no_of_test = d["test"].length;
      var Total_marks_sum = 0;
      var Correct_Answers_sum = 0;
      var avg;
      d["test"].forEach(function (d) {
        Total_marks_sum += d.Total_Marks;
        Correct_Answers_sum += d.Correct_Answers;
        avg = (Correct_Answers_sum / Total_marks_sum) * 100;
      });
      cal.Total_Marks = Total_marks_sum;
      cal.Correct_Answers = Correct_Answers_sum;
      cal.avg = avg;
      per.push(cal);
    });

    console.log("per", per);

    // create the drop down menu of topic names
    var selector = d3
      .select("#groupBarChart")
      .append("select")
      .attr("id", "topicselector1")
      .selectAll("option")
      .data(Topic_Name)
      .enter()
      .append("option")
      .text(function (d) {
        return d;
      })
      .attr("value", function (d, i) {
        return d;
      });

    var topic;
    d3.select("#topicselector1").on("change", function (d) {
      topic = this.value;
      topic_update();
    });

    // create the drop down for skills
    var selector = d3
      .select("#groupBarChart")
      .append("select")
      .attr("id", "skillselector1")
      .selectAll("option")
      .data(skill_Name)
      .enter()
      .append("option")
      .text(function (d) {
        return d;
      })
      .attr("value", function (d, i) {
        return d;
      });

    var skill;
    d3.select("#skillselector1").on("change", function (d) {
      skill = this.value;
      skill_update();
    });

    // chart for topic filter
    function topic_update() {
      console.log(topic);
      var per = [];

      coll.forEach(function (d) {
        var cal = new Object();
        cal.First_Name = d.name;
        var no_of_test = 0;
        var Total_marks_sum = 0;
        var Correct_Answers_sum = 0;
        var avg;
        d["test"].forEach(function (d) {
          if (d.Topic_Name == topic) {
            Total_marks_sum += d.Total_Marks;
            Correct_Answers_sum += d.Correct_Answers;
            avg = (Correct_Answers_sum / Total_marks_sum) * 100;
          }
        });
        cal.avg = avg;
        cal.Total_Marks = Total_marks_sum;
        cal.Correct_Answers = Correct_Answers_sum;
        per.push(cal);
      });

      per = per.filter(function (d) {
        return d.Total_Marks > 0 && d.Correct_Answers > 0;
      });
      console.log("topic per", per);

      d3.select("svg").remove();
      ScatterPlot(per);
    }

    // chart for skill filter
    function skill_update() {
      console.log(skill);
      var per = [];

      coll.forEach(function (d) {
        var sum = 0;
        var cal = new Object();
        cal.First_Name = d.name;
        var no_of_test = 0;
        var Total_marks_sum = 0;
        var Correct_Answers_sum = 0;
        var avg;
        d["test"].forEach(function (d) {
          if (d.Topic_Name == topic && d.Skill_Level == skill) {
            Total_marks_sum += d.Total_Marks;
            Correct_Answers_sum += d.Correct_Answers;
            avg = (Correct_Answers_sum / Total_marks_sum) * 100;
          }
        });
        cal.Total_Marks = Total_marks_sum;
        cal.Correct_Answers = Correct_Answers_sum;
        cal.avg = avg;
        per.push(cal);
      });
      per = per.filter(function (d) {
        return d.Total_Marks > 0 && d.Correct_Answers > 0;
      });

      console.log("skill per", per);

      d3.select("svg").remove();
      ScatterPlot(per);
    }

    ScatterPlot(per);

    function ScatterPlot(data) {
      d3.select("svg").remove();
      // set the dimensions and margins of the graph
      var margin = { top: 10, right: 30, bottom: 0, left: 50 },
        width = 1000 - margin.left - margin.right,
        height = 450 - margin.top - margin.bottom;

      // append the svg object to the body of the page
      var svg = d3
        .select("#groupBarChart")
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", "0 0 1000 550")
        // .attr("width", width + margin.left + margin.right)
        // .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // Add the grey background that makes ggplot2 famous
      svg
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("height", height)
        .attr("width", width)
        .style("fill", "#EBEBEB")
        .style("stroke", "gray")
        .style("stroke-width", "2px");

      svg
        .append("line")
        .attr("x1", 0)
        .attr("y1", height / 2)
        .attr("x2", width)
        .attr("y2", height / 2)
        .style("fill", "gray")
        .style("stroke", "gray")
        .style("stroke-width", "1px");

      svg
        .append("line")
        .attr("x1", width / 2)
        .attr("y1", 0)
        .attr("x2", width / 2)
        .attr("y2", height)
        .style("fill", "gray")
        .style("stroke", "gray")
        .style("stroke-width", "1px");

      //Read the data
      // d3.csv("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/iris.csv", function(data) {

      // Add X axis
      var x = d3
        .scaleLinear()
        .domain(
          d3.extent(data, function (d) {
            return d.Total_Marks;
          })
        )
        .range([0, width]);

      //console.log()

      var y = d3
        .scaleLinear()
        .domain(
          d3.extent(data, function (d) {
            return d.Correct_Answers;
          })
        )
        .range([height, 0])
        .nice();

      var xaxis = svg
        .append("g")
        .attr("transform", "translate(0," + y.range()[0] / 2 + ")")
        // .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).ticks(30))
        // .select(".domain").remove()
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");
      // .style("font-weight","bold")

      //  xaxis.selectAll(".domain").remove()

      // Add Y axis
      var div = d3
        .select("#groupBarChart")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

      svg
        .append("g")
        .attr("transform", "translate(" + x.range()[1] / 2 + ", 0)")
        .call(d3.axisLeft(y).ticks(20));
      //.selectAll(".domain").remove()

      // Customization
      svg.selectAll(".tick line ").attr("stroke-width", "1");
      //   svg.selectAll(".tick text").attr("fill","red").attr("font-weight","bold")
      svg.selectAll(".domain").remove();

      // Color scale: give me a specie name, I return a color
      var color = d3
        .scaleOrdinal()
        .domain(["setosa", "versicolor", "virginica"]);
      // .range([ "#F8766D", "#00BA38", "#619CFF"])

      // Add dots
      svg
        .append("g")
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", function (d) {
          return x(d.Total_Marks);
        })
        .attr("cy", function (d) {
          return y(d.Correct_Answers);
        })
        .attr("r", 5)
        .style("fill", function (d) {
          return d.avg <= 40
            ? "red"
            : d.avg > 40 && d.avg <= 60
            ? "green"
            : "orange";
        })
        .on("mouseover", function (d) {
          div.transition().duration(200).style("opacity", 0.9);
          div
            .html(
              "Name: " +
                d.First_Name +
                "<br/>" +
                "Total Mark: " +
                d.Total_Marks +
                "<br/>" +
                "Correct Answers: " +
                d.Correct_Answers +
                "<br/>" +
                "Percentage: " +
                d3.format(".3n")(d.avg) +
                "%"
            )
            .style("left", d3.event.pageX + "px")
            .style("top", d3.event.pageY - 28 + "px");
        })
        .on("mouseout", function (d) {
          div.transition().duration(500).style("opacity", 0);
        });

      // svg.append("text")
      // .attr("x",width/3)
      // .attr("y",-50)
      // .text("User Performance")
      // .attr("class","titlecap")
    }
  };
  // groupbarChart(data)

  componentWillUnmount() {
    d3.selectAll("svg").remove();
  }

  render() {
    const { user } = this.props.auth;
    const role = user && user.role;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div className="container centop">
          {/* {role === 'user' ? <UserMenu/> : <Menu /> } */}
          {/* <Menu /> */}
          <div
            className="image-container"
            style={{
              display: this.state.isLoaded === false ? "block" : "none",
            }}
          >
            <p className="image-holder">
              <img src={loader} alt="" />
            </p>
          </div>
          <center>
            <div className="fontSize badge badge-info text-center">
              User Performance chart based on Results
            </div>
          </center>
          <div id="groupBarChart" className="mt-5"></div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  visuals: state.visuals,
});

const mapDispatchToProps = {
  allusers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultGraph);
