import React, { Component } from "react";
import Menu from "../menu/menu";
import { Redirect } from "react-router-dom";
import { API_URL } from "../../Api_Request";
import axios from "axios";
import "./editQuestions.css";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  geteditquestion,
  updatequestion,
  clearQuestionData,
} from "../../redux/actions/questionsAction";
import {
  uploadImage,
  clearImageData,
} from "../../redux/actions/uploadFileActions";
import FileUpload from "../reusableComponents/FileUpload";
import {
  Box,
  Paper,
  Grid,
  styled,
  CircularProgress,
  Card,
  CardMedia,
} from "@mui/material";
import loader from ".././805.gif";
import Mic3Recorder from "../reusableComponents/audioRecorder";
import MediaRecorder from "../reusableComponents/MediaRecorder";

class editQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      image_key: "",
      image_name: "",
      editQuesData: "",
      topicData: [],
      chapterdata: [],
      dummydata: [],
      typeData: [],
      complexData: [],
      questionsqueryData: "",
      qid: "",
      oldchapter: "",
      chapterDropdownValue: "",
      topicDropdownValue: "",
      typeDropdownValue: "",
      complexityDropdownValue: "",
      isLoaded: false,
      isEnabled: "",
      check1: "",
      check2: "",
      check3: "",
      check4: "",
      error: false,
      selectedRadio: "",
      showAudioButton: false,
      showAudioControls: false,
      audioSourceChanged: false,
      videoSourceChanged: false,
      showVideoControls: false,
      showVideoButton: false,
      RecordData: {
        selectedRadio: "",
        option1: null,
        option2: null,
        option3: null,
        option4: null,
        questionData: "",
        mName: "",
        questionsqueryData: "",
      },

      getInitialState: function () {
        return {
          check_me1: true,
          check_me2: true,
          check_me3: true,
          check_me4: true,
        };
      },
    };
  }

  componentDidMount() {
    const idData = this.props.location.search;
    var idvalue = idData.split("?id=");
    var id = idvalue.slice(1, 3);
    this.props.clearQuestionData();
    this.props.geteditquestion(id[0]);
    this.props.clearImageData();
  }

  componentWillReceiveProps(nextProps) {
    let { editquestiondata, updatequestiondata } = nextProps.questions;
    if (editquestiondata) {
      this.setState({
        topicData: editquestiondata.topicData,
        complexData: editquestiondata.complexData,
        typeData: editquestiondata.typeData,
        chapterdata: editquestiondata.chapterdata.map((chapter) => {
          chapter.chapter_name = chapter.chapterName;
          return chapter;
        }),
        editQuesData: editquestiondata.editQuesData,
      });

      const editQuesData = editquestiondata && editquestiondata.editQuesData;
      if (editquestiondata.editQuesData) {
        this.setState({
          editQuesData: editquestiondata.editQuesData,
          isEnabled: editquestiondata.editQuesData.questiontype,
          file: editquestiondata.editQuesData.image
            ? editquestiondata.editQuesData.image
            : "",
          audio: editquestiondata.editQuesData.audio
            ? editquestiondata.editQuesData.audio
            : "",
          video: editquestiondata.editQuesData.video
            ? editquestiondata.editQuesData.video
            : "",
          showAudioButton: editquestiondata.editQuesData.audio ? true : false,
          showVideoButton: editquestiondata.editQuesData.video ? true : false,
          image_key: editquestiondata.editQuesData.image_key
            ? editquestiondata.editQuesData.image_key
            : "",
          image_name: editquestiondata.editQuesData.image_name
            ? editquestiondata.editQuesData.image_name
            : "",
        });
        // console.log(this.state.editQuesData.questiontopic);

        if (
          editquestiondata.editQuesData.choices &&
          editquestiondata.editQuesData.choices.length > 0
        ) {
          if (!editquestiondata.editQuesData.choices[0]) {
            this.setState({
              option1: "",
            });
          } else {
            this.setState({
              option1: editquestiondata.editQuesData.choices[0].name,
            });
            this.state.option1 = editquestiondata.editQuesData.choices[0].name;
          }
          if (!editquestiondata.editQuesData.choices[1]) {
            this.setState({
              option2: "",
            });
          } else {
            this.state.option2 = editquestiondata.editQuesData.choices[1].name;
            this.setState({
              option1: editquestiondata.editQuesData.choices[1].name,
            });
          }
          if (!editquestiondata.editQuesData.choices[2]) {
            this.setState({
              option3: "",
            });
          } else {
            this.state.option3 = editquestiondata.editQuesData.choices[2].name;
            this.setState({
              option1: editquestiondata.editQuesData.choices[2].name,
            });
          }
          if (!editquestiondata.editQuesData.choices[3]) {
            this.setState({
              option4: null,
            });
          } else {
            this.state.option4 = editquestiondata.editQuesData.choices[3].name;
            this.setState({
              option1: editquestiondata.editQuesData.choices[3].name,
            });
          }
          this.setState({
            option1: this.state.option1,
            option2: this.state.option2,
            option3: this.state.option3,
            option4: this.state.option4,
          });
        }

        editquestiondata.topicData.map((topic, i) => {
          const tname = topic.name;
          const tid = topic.id;
          if (tname === editQuesData.questiontopic) {
            this.state.dummydata = editquestiondata.chapterdata.filter(
              (chapter) => {
                chapter.chapter_name = chapter.chapterName;
                return chapter.topicId === tid;
              }
            );
            // console.log(this.state.dummydata)
            this.setState({ qid: tid });
            // this.state.qid = tid
            // console.log(this.state.qid)
          }
        });
      }

      this.setState({
        questionData: editQuesData.description,
        oldchapter: editQuesData.questionchapter,
        topicDropdownValue: editQuesData.questiontopic,
        typeDropdownValue: editQuesData.questiontype,
        complexityDropdownValue: editQuesData.questioncomplexity,
        chapterDropdownValue: editQuesData.questionchapter,
        isEnabled: editQuesData.questiontype,
      });

      if (editquestiondata.editQuesData.answer) {
        editquestiondata.editQuesData.answer.map((result, i) => {
          // console.log(result)
          if (result.name === this.state.option1) {
            this.setState({
              check1: result.name,
            });
          }
          if (result.name === this.state.option2) {
            this.setState({
              check2: result.name,
            });
          }
          if (result.name === this.state.option3) {
            this.setState({
              check3: result.name,
            });
          }
          if (result.name === this.state.option4) {
            this.setState({
              check4: result.name,
            });
          }
        });
      }

      this.setState({
        isLoaded: true,
      });
    }
    if (updatequestiondata === "Success") {
      this.setState({
        isLoaded: true,
      });
    }
    let { imageData, audioData, videoData } = nextProps.fileData;
    if (imageData && this.state.isLoaded) {
      this.setState({
        file: imageData.link,
        image_key: imageData.key,
        image_name: imageData.name,
      });
    }
    if (audioData && this.state.isLoaded) {
      if (this.state.audio !== audioData?.link) {
        this.setState({
          audioSourceChanged: true,
          audio: audioData?.link,
          showAudioControls: false,
          showAudioButton: true,
        });
      }
    }
    if (videoData && this.state.isLoaded) {
      if (this.state.video !== videoData?.link) {
        this.setState({
          videoSourceChanged: true,
          video: videoData?.link,
          showVideoControls: false,
          showVideoButton: true,
        });
      }
    }
  }

  handleQuestionsqueryData(e) {
    this.setState({
      questionsqueryData: e.target.value,
    });
  }

  handleTypeDropdown = (e) => {
    this.setState({
      typeDropdownValue: e.target.value,
      isEnabled: e.target.value,
      check1: "",
      check2: "",
      check3: "",
      check4: "",
    });
    //    console.log(this.state.typeDropdownValue);
  };

  handleTopicDropdown = (e) => {
    let tpid = "";
    this.state.topicData.map((topic) => {
      if (e.target.value === topic.name) {
        tpid = topic.id;
      }
      this.state.dummydata = this.state.chapterdata.filter((chapter) => {
        return chapter.topicId === tpid;
      });
    });
    this.setState({
      topicDropdownValue: e.target.value,
      qid: e.target.value,
    });
  };

  handleChapterDropdown = (e) => {
    this.setState({
      chapterDropdownValue: e.target.value,
    });
  };

  handleComplexityDropdown = (e) => {
    this.setState({
      complexityDropdownValue: e.target.value,
    });
  };
  handleQuestionsData(e) {
    this.setState({
      questionData: e.target.value,
    });
  }

  handleOption1(e) {
    // console.log(e.target.value);
    this.setState({
      option1: e.target.value,
    });
    if (this.state.check1 != "") {
      this.setState({
        check1: e.target.value,
      });
    }
  }
  handleOption2(e) {
    this.setState({
      option2: e.target.value,
    });
    if (this.state.check2 != "") {
      this.setState({
        check2: e.target.value,
      });
    }
  }
  handleOption3(e) {
    this.setState({
      option3: e.target.value,
    });
    if (this.state.check3 != "") {
      this.setState({
        check3: e.target.value,
      });
    }
  }

  handleOption4(e) {
    this.setState({
      option4: e.target.value,
    });
    if (this.state.check4 != "") {
      this.setState({
        check4: e.target.value,
      });
    }
  }

  onChangeRadio1(e) {
    console.log(e);
    this.setState({
      check1: e,
      check2: "",
      check3: "",
      check4: "",
    });
  }

  onChangeRadio2(e) {
    this.setState({
      check1: "",
      check2: e,
      check3: "",
      check4: "",
    });
  }

  onChangeRadio3(e) {
    this.setState({
      check1: "",
      check2: "",
      check3: e,
      check4: "",
    });
  }

  onChangeRadio4(e) {
    this.setState({
      check1: "",
      check2: "",
      check3: "",
      check4: e,
    });
  }

  onChangeCheck1 = (e) => {
    if (this.state.check1) {
      this.setState({
        check1: "",
      });
    } else {
      this.setState({
        check1: e.target.value,
      });
    }
  };

  onChangeCheck2 = (e) => {
    if (this.state.check2) {
      this.setState({
        check2: "",
      });
    } else {
      this.setState({
        check2: e.target.value,
      });
    }
  };

  onChangeCheck3 = (e) => {
    if (this.state.check3) {
      this.setState({
        check3: "",
      });
    } else {
      this.setState({
        check3: e.target.value,
      });
    }
  };

  onChangeCheck4 = (e) => {
    if (this.state.check4) {
      this.setState({
        check4: "",
      });
    } else {
      this.setState({
        check4: e.target.value,
      });
    }
  };

  validate = () => {
    const {
      questionData,
      option1,
      option2,
      option3,
      option4,
      chapterDropdownValue,
      check1,
      check2,
      check3,
      check4,
    } = this.state;
    // console.log(this.state.topicDropdownValue,this.state.typeDropdownValue,this.state.complexityDropdownValue)
    let isError = this.state.error;
    const regex = /^[a-zA-Z0-9!@#$%^&*?()_+\-=[\]{};':"\\|,(\n).<>/`[↵]~|| ]*$/;
    const textarea = this.state.questionData.match(regex);
    const roption1 = this.state.option1?.match(regex);
    const roption2 = this.state.option2?.match(regex);
    const roption3 = this.state.option3?.match(regex);
    const roption4 = this.state.option4?.match(regex);
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: true,
    });
    if (questionData.trim() === "" || questionData.trim() === undefined) {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Please enter Question Description !!!",
      });
    }
    if (questionData.trim().length < 10) {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Question Description should have at least 10 characters !!!",
      });
    }
    // if (!textarea || !roption1 || !roption2 || !roption3) {
    //   isError = true;

    //   Toast.fire({
    //     icon: "error",
    //     title:
    //       "Special Characters are not allowed(If copy paste from browser) !!!",
    //   });
    // }
    if (option1?.trim() === "" || option1?.trim() === undefined) {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Please enter Option 1 !!!",
      });
    }
    if (option2?.trim() === "" || option2?.trim() === undefined) {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Please enter Option 2 !!!",
      });
    }
    // if (option3?.trim() === "" || option3?.trim() === undefined) {
    //   isError = true;

    //   Toast.fire({
    //     icon: "error",
    //     title: "Please enter Option 3 !!!",
    //   });
    // }
    // if (option4?.trim() === "" || option4?.trim() === undefined) {
    //   isError = true;

    //   Toast.fire({
    //     icon: "error",
    //     title: "Please enter Option 4 !!!",
    //   });
    // }
    if (chapterDropdownValue === "" || chapterDropdownValue === undefined) {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Please select a Chapter !!!",
      });
    }
    if (check1 === "" && check2 === "" && check3 === "" && check4 === "") {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Please select an answer !!!",
      });
    }

    return isError;
  };

  handleSave() {
    const { user } = this.props.auth;
    const { audioData, videoData } = this.props.fileData;
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: true,
    });
    const idData = this.props.location.search;
    let idvalue = idData.split("?id=");
    let id = idvalue.slice(1, 3);
    if (
      this.state.isEnabled === "Query" ||
      this.state.isEnabled === "Free Text" ||
      this.state.isEnabled === "Voice" ||
      this.state.isEnabled === "Video" ||
      this.state.isEnabled === "File Upload"
    ) {
      const userQuestion = {
        description: this.state.questionData.trim(),
        questiontype: this.state.typeDropdownValue,
        questioncomplexity: this.state.complexityDropdownValue,
        questiontopic: this.state.topicDropdownValue,
        questionchapter: this.state.chapterDropdownValue,
        oldchapter: this.state.oldchapter,
        id: id[0],
        image: this.state.file || undefined,
        image_key: this.state.image_key || undefined,
        image_name: this.state.image_name || undefined,
        insertedBy: user?.id,
        status: 0,
        type: "Query",
        audioSourceChanged: this.state.audioSourceChanged,
        changedAudio:
          this.state.audioSourceChanged && audioData ? audioData : {},
        videoSourceChanged: this.state.videoSourceChanged,
        changedVideo:
          this.state.videoSourceChanged && videoData ? videoData : {},
      };
      const navigationParams = {
        history: this.props.history,
        tid: this.state.qid,
      };
      this.props.updatequestion(userQuestion, navigationParams);
      this.setState({
        isLoaded: false,
      });
    } else {
      let err = this.validate();
      if (!err) {
        const f = [];
        if (this.state.check1 !== "") {
          f.push({ name: this.state.check1 });
        }
        if (this.state.check2 !== "") {
          f.push({ name: this.state.check2 });
        }
        if (this.state.check3 !== "") {
          f.push({ name: this.state.check3 });
        }
        if (this.state.check4 !== "") {
          f.push({ name: this.state.check4 });
        }
        // console.log(f);
        // if (this.state.selectedRadio === "" || this.state.selectedRadio === null) {
        const userQuestion = {
          description: this.state.questionData.trim(),
          questiontype: this.state.typeDropdownValue,
          questioncomplexity: this.state.complexityDropdownValue,
          questiontopic: this.state.topicDropdownValue,
          questionchapter: this.state.chapterDropdownValue,
          oldchapter: this.state.oldchapter,
          insertedBy: user?.id,
          id: id[0],
          //image:'None',
          status: 0,
          choices: [
            { name: this.state.option1?.trim() },
            { name: this.state.option2?.trim() },
            { name: this.state.option3?.trim() },
            { name: this.state.option4?.trim() },
          ],
          answer: f,
        };
        // console.log("mul",userQuestion)
        const navigationParams = {
          history: this.props.history,
          tid: this.state.tid,
        };
        this.props.updatequestion(userQuestion, navigationParams);
        this.setState({
          isLoaded: false,
        });
      } else {
        console.log(err);
      }
    }
  }

  uploadQuestionImage = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    this.props.uploadImage(formData);
  };

  handleClose(tid) {
    this.props.history.push(`/admin/questions?id=${tid}`);
  }

  handleAudio = () => {
    this.setState({ showAudioControls: true, showAudioButton: false });
  };

  handleVideo = () => {
    this.setState({ showVideoControls: true, showVideoButton: false });
  };

  handleCancelAudio = () => {
    this.setState({
      showAudioControls: false,
      showAudioButton: true,
      audioSourceChanged: false,
    });
  };

  handleCancelVideo = () => {
    this.setState({
      showVideoControls: false,
      showVideoButton: true,
      videoSourceChanged: false,
    });
  };

  render() {
    const tid = this.state.qid;

    const { user, isAuthenticated } = this.props.auth;
    const { imageData, uploadFileProcess } = this.props.fileData;
    const {
      file,
      complexData,
      typeData,
      dummydata,
      audio,
      showAudioButton,
      showAudioControls,
      showVideoControls,
      showVideoButton,
      video,
    } = this.state;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div className="container">
          <div
            className="image-container"
            style={{
              display: this.state.isLoaded === false ? "block" : "none",
            }}
          >
            <p className="image-holder">
              <img src={loader} alt="" />
            </p>
          </div>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <table
                id="tblPage"
                className="container mt-5"
                style={{
                  display:
                    this.state.isLoaded === true ? "inline-table" : "none",
                }}
              >
                <tbody>
                  <tr className="row">
                    <td className="col-sm-4">
                      <label>Select Topic</label>
                      <select
                        className="form-control"
                        onChange={this.handleTopicDropdown}
                      >
                        <option selected="true" disabled="disabled" value="">
                          --select--
                        </option>
                        {this.state.topicData.map((topic) => {
                          return (
                            <option
                              key={topic.id}
                              value={topic.name}
                              selected={
                                topic.name ===
                                this.state.editQuesData.questiontopic
                              }
                            >
                              {topic.name}
                            </option>
                          );
                        })}
                      </select>
                    </td>

                    <td className="col-sm-4">
                      <label>Select Complexity</label>
                      <select
                        className="form-control"
                        onChange={this.handleComplexityDropdown}
                      >
                        <option selected="true" disabled="disabled" value="">
                          --select--
                        </option>
                        {complexData.map((complex) => (
                          <option
                            key={complex.id}
                            value={complex.name}
                            selected={
                              complex.name ===
                              this.state.editQuesData.questioncomplexity
                            }
                          >
                            {complex.name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="col-sm-4">
                      <label>Select Type</label>
                      <select
                        className="form-control"
                        id="drpType"
                        onChange={this.handleTypeDropdown}
                      >
                        <option selected="true" disabled="disabled" value="">
                          --select--
                        </option>
                        {typeData.map((type) => (
                          <option
                            key={type.id}
                            value={type.name}
                            selected={
                              type.name === this.state.editQuesData.questiontype
                            }
                          >
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr className="row mt-3">
                    <td className="col-sm-12">
                      <label>Select Chapters</label>
                      <select
                        className="form-control"
                        required="required"
                        onChange={this.handleChapterDropdown}
                      >
                        <option selected="true" disabled="disabled" value="">
                          --Select--
                        </option>
                        {dummydata.map((chapter) => (
                          <option
                            key={chapter.id}
                            value={chapter.chapter_name}
                            selected={
                              chapter.chapter_name ===
                              this.state.editQuesData.questionchapter
                            }
                          >
                            {chapter.chapter_name}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Box>

          <div
            id="sType"
            className="form-group"
            style={{
              display:
                this.state.editQuesData.questiontype ===
                  "Single Choice Question" ||
                this.state.editQuesData.questiontype ===
                  "Multiple Choice Question" ||
                this.state.isEnabled === "Single Choice Question" ||
                this.state.typeDropdownValue === "Single Choice Question" ||
                this.state.isEnabled === "Multiple Choice Question" ||
                this.state.typeDropdownValue === "Multiple Choice Question" ||
                this.state.isEnabled === "Boolean" ||
                this.state.typeDropdownValue === "Boolean"
                  ? "block"
                  : "none",
            }}
          >
            <div className="row container">
              {this.state.isEnabled === "Single Choice Question" ||
              this.state.typeDropdownValue === "Single Choice Question" ||
              this.state.isEnabled === "Multiple Choice Question" ||
              this.state.typeDropdownValue === "Multiple Choice Question" ||
              this.state.isEnabled === "Boolean" ||
              this.state.typeDropdownValue === "Boolean" ? (
                <div className="col-sm-12">
                  <textarea
                    className="form-control ml-3"
                    id="text"
                    rows="3"
                    cols="100"
                    defaultValue={this.state.editQuesData.description}
                    onChange={(e) => {
                      this.handleQuestionsData(e);
                    }}
                  />
                </div>
              ) : null}
            </div>
            {/* <table id="tblQpage" text-align="center" >
                        <tbody>
                        <tr><td>
                            <input type='text' className="form-control question" rows="3" cols="100"  defaultValue={this.state.editQuesData.description} onChange={(e)=>{this.handleQuestionsData(e)}}/></td></tr>
                   </tbody></table> */}
            <div className="align">
              <table
                className="optionTbl"
                text-align="center"
                style={{
                  display:
                    this.state.isEnabled === "Single Choice Question" ||
                    this.state.typeDropdownValue === "Single Choice Question" ||
                    this.state.isEnabled === "Multiple Choice Question" ||
                    this.state.typeDropdownValue ===
                      "Multiple Choice Question" ||
                    this.state.isEnabled === "Boolean" ||
                    this.state.typeDropdownValue === "Boolean"
                      ? "block"
                      : "none",
                }}
              >
                <tbody>
                  <tr>
                    <td className="options">
                      <label className="optLbl">a.</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          this.handleOption1(e);
                        }}
                        value={this.state.option1}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="options">
                      <label className="optLbl">b.</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          this.handleOption2(e);
                        }}
                        value={this.state.option2}
                      />
                    </td>
                  </tr>
                  {(this.state.isEnabled === "Single Choice Question" ||
                    this.state.typeDropdownValue === "Single Choice Question" ||
                    this.state.isEnabled === "Multiple Choice Question" ||
                    this.state.typeDropdownValue ===
                      "Multiple Choice Question") && (
                    <>
                      <tr>
                        <td className="options">
                          <label className="optLbl">c.</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              this.handleOption3(e);
                            }}
                            value={this.state.option3}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="options">
                          <label className="optLbl">d.</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              this.handleOption4(e);
                            }}
                            value={this.state.option4}
                          />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              <table
                className="ans"
                style={{
                  display:
                    this.state.isEnabled === "Single Choice Question" ||
                    this.state.typeDropdownValue === "Single Choice Question" ||
                    this.state.isEnabled === "Boolean" ||
                    this.state.typeDropdownValue === "Boolean"
                      ? "block"
                      : "none",
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="radio"
                        className="ans1"
                        name="selectedRadio"
                        onChange={(e) =>
                          this.onChangeRadio1(this.state.option1)
                        }
                        value={this.state.option1}
                        checked={this.state.check1}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="radio"
                        className="ans1"
                        name="selectedRadio"
                        onChange={(e) =>
                          this.onChangeRadio2(this.state.option2)
                        }
                        value={this.state.option2}
                        checked={this.state.check2}
                      />
                    </td>
                  </tr>
                  {this.state.isEnabled === "Single Choice Question" &&
                    this.state.typeDropdownValue ===
                      "Single Choice Question" && (
                      <>
                        {" "}
                        <tr>
                          <td>
                            <input
                              type="radio"
                              className="ans1"
                              name="selectedRadio"
                              onChange={(e) =>
                                this.onChangeRadio3(this.state.option3)
                              }
                              value={this.state.option3}
                              checked={this.state.check3}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              type="radio"
                              className="ans2"
                              name="selectedRadio"
                              onChange={(e) =>
                                this.onChangeRadio4(this.state.option4)
                              }
                              value={this.state.option4}
                              checked={this.state.check4}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  <tr>
                    <td>
                      <div
                        id="divbtn"
                        className="mt-3"
                        style={{
                          display:
                            (this.state.isEnabled ===
                              "Single Choice Question" &&
                              this.state.typeDropdownValue ===
                                "Single Choice Question") ||
                            (this.state.isEnabled === "Boolean" &&
                              this.state.typeDropdownValue === "Boolean")
                              ? "block"
                              : "none",
                        }}
                      >
                        <button
                          type="button"
                          value="save"
                          className="btn btn-success"
                          disabled={
                            !this.state.questionData ||
                            !this.state.option1 ||
                            !this.state.option2
                            // || !this.state.option3
                            // !this.state.option4
                          }
                          onClick={() => {
                            this.handleSave();
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          value="Cancel"
                          className="btn btn-danger"
                          onClick={() => {
                            this.handleClose(tid);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                className="ans"
                style={{
                  display:
                    this.state.isEnabled === "Multiple Choice Question" ||
                    this.state.typeDropdownValue === "Multiple Choice Question"
                      ? "block"
                      : "none",
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        className="ans1"
                        name="check1"
                        checked={this.state.check1}
                        onChange={(e) => this.onChangeCheck1(e)}
                        value={this.state.option1}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        className="ans1"
                        name="check2"
                        checked={this.state.check2}
                        onChange={(e) => this.onChangeCheck2(e)}
                        value={this.state.option2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        className="ans1"
                        name="check3"
                        checked={this.state.check3}
                        onChange={(e) => this.onChangeCheck3(e)}
                        value={this.state.option3}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        className="ans1"
                        name="check4"
                        checked={this.state.check4}
                        onChange={(e) => this.onChangeCheck4(e)}
                        value={this.state.option4}
                      />
                    </td>
                  </tr>{" "}
                  <tr>
                    <td>
                      <div
                        id="divbtn"
                        className="mt-3"
                        style={{
                          display:
                            this.state.isEnabled ===
                              "Multiple Choice Question" &&
                            this.state.typeDropdownValue ===
                              "Multiple Choice Question"
                              ? "block"
                              : "none",
                        }}
                      >
                        <button
                          type="button"
                          value="save"
                          disabled={
                            !this.state.questionData ||
                            !this.state.option1 ||
                            !this.state.option2 ||
                            !this.state.option3
                            // !this.state.option4
                          }
                          className="btn btn-success"
                          onClick={() => {
                            this.handleSave();
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          value="Cancel"
                          className="btn btn-danger"
                          onClick={() => {
                            this.handleClose(tid);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            id="qType"
            style={{
              display:
                this.state.isEnabled === "Query" ||
                this.state.isEnabled === "Free Text" ||
                this.state.isEnabled === "Voice" ||
                this.state.typeDropdownValue === "Query" ||
                this.state.typeDropdownValue === "Free Text" ||
                this.state.typeDropdownValue === "Voice" ||
                this.state.typeDropdownValue === "Video" ||
                this.state.typeDropdownValue === "File Upload"
                  ? "block"
                  : "none",
            }}
          >
            <Grid container>
              <Grid item md={6} xs={12}>
                <FileUpload
                  onChange={(e) => this.uploadQuestionImage(e)}
                  changeLabel={"Edit"}
                />
                {uploadFileProcess ? (
                  <Grid sx={{ maxWidth: 345 }} align="center">
                    <CircularProgress />
                  </Grid>
                ) : (
                  file && (
                    <Card sx={{ maxWidth: 345 }}>
                      <CardMedia
                        component="img"
                        alt="questionImage"
                        height="140"
                        image={file && file}
                      />
                    </Card>
                  )
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                {
                  !uploadFileProcess && showAudioButton ? (
                    <>
                      <audio src={audio} controls />{" "}
                      <button
                        className="btn btn-primary mb-5 ml-3"
                        onClick={this.handleAudio}
                      >
                        Change Audio
                      </button>
                    </>
                  ) : null
                  // && <MicRecorder existingAudio={audio} />
                }
                {showAudioControls ? (
                  <>
                    <Mic3Recorder />{" "}
                    {!this.state.audioSourceChanged && (
                      <button
                        className="btn btn-danger mt-1"
                        onClick={this.handleCancelAudio}
                      >
                        Cancel Audio Edit
                      </button>
                    )}
                  </>
                ) : null}
                {
                  !uploadFileProcess && showVideoButton ? (
                    <div>
                      <video src={video} style={{ width: "40%" }} controls />{" "}
                      <button
                        className="btn btn-primary mb-5 ml-3"
                        onClick={this.handleVideo}
                      >
                        Change Video
                      </button>
                    </div>
                  ) : null
                  // && <MicRecorder existingAudio={audio} />
                }
                {showVideoControls ? (
                  <>
                    <MediaRecorder />{" "}
                    {!this.state.videoSourceChanged && (
                      <button
                        className="btn btn-danger mt-1"
                        onClick={this.handleCancelVideo}
                      >
                        Cancel Video Edit
                      </button>
                    )}
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Grid item id="tblQpage" xs={12}>
              <textarea
                className="questionBox"
                ro-ws="5"
                cols="100"
                id="txArea"
                placeholder="Enter Questions"
                defaultValue={this.state.editQuesData.description}
                onChange={(e) => {
                  this.handleQuestionsData(e);
                }}
                // onChange={(e) => {
                //   this.handleQuestionsqueryData(e);
                // }}
              />
            </Grid>
            <Grid item xs={12} align="right" className="mt-3">
              <button
                type="button"
                value="save"
                className="btn btn-primary"
                onClick={() => {
                  this.handleSave();
                }}
              >
                Save
              </button>
              <button
                type="button"
                value="Cancel"
                className="btn btn-danger"
                onClick={() => {
                  this.handleClose(tid);
                }}
              >
                Cancel
              </button>
            </Grid>
            {/* <table className="a" textalign="center">
              {file && file ? (
                <div className="imgUp">
                  <span>
                    <b>Upload Image:</b>
                  </span>
                  <label htmlFor="file" className="qtbl">
                    Upload
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className="inputfile"
                    onChange={this.handleChange}
                  />
                  <img
                    src={"/public/images/" + file}
                    className="uploadImg"
                    alt=""
                  />
                </div>
              ) : null}
              <table id="tblQpage" className="ml-3" text-align="center">
                <tbody>
                  <tr>
                    <td>
                      <div className="col-sm-12">
                        <textarea
                          className="form-control ml-3"
                          id="text"
                          rows="3"
                          cols="100"
                          defaultValue={this.state.editQuesData.description}
                          onChange={(e) => {
                            this.handleQuestionsData(e);
                          }}
                        />
                      </div>
                      
                    </td>
                  </tr>
                </tbody>
              </table>

              <div id="divbtn" className="mt-3">
                <button
                  type="button"
                  value="save"
                  className="btn btn-success"
                  onClick={() => {
                    this.handleSave();
                  }}
                >
                  Save
                </button>
                <button
                  type="button"
                  value="Cancel"
                  className="btn btn-danger"
                  onClick={() => {
                    this.handleClose(tid);
                  }}
                >
                  Cancel
                </button>
              </div>
            </table> */}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  questions: state.questions,
  fileData: state.fileData,
});

const mapDispatchToProps = {
  geteditquestion,
  updatequestion,
  uploadImage,
  clearImageData,
  clearQuestionData,
};

export default connect(mapStateToProps, mapDispatchToProps)(editQuestions);
