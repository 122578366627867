import React from "react";
import { makeStyles, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const FileUpload = ({ onChange, changeLabel }) => {
  return (
    <>
      <label htmlFor="upload-photo">
        <input
          style={{ display: "none" }}
          id="upload-photo"
          name="upload-photo"
          type="file"
          onChange={onChange}
        />

        <Fab
          color="primary"
          size="small"
          component="span"
          aria-label="add"
          variant="extended"
        >
          <AddIcon /> {changeLabel}
        </Fab>
      </label>
    </>
  );
};

export default FileUpload;
