import React, { Component, useEffect } from "react";

import loader from ".././805.gif";
import { currentUser } from "../../redux/actions/authActions";
import setAuthToken from "../../redux/utils/setAuthToken";
import { connect } from "react-redux";

import { getIbridgeUser } from "../../redux/actions/iBridgeActions";

const ResultIbridge = ({
  match,
  auth,
  currentUser,
  getIbridgeUser,
  iBridge,
  history,
}) => {
  const { user } = match.params;
  const { ibridgeUserData } = iBridge;

  useEffect(() => {
    getIbridgeUser(user);
  }, []);

  useEffect(() => {
    if (ibridgeUserData) {
      const local = {
        token: ibridgeUserData.token,
        username: ibridgeUserData.username,
      };
      localStorage.setItem("capability", JSON.stringify(local));
      setAuthToken(local.token);
      currentUser(local);
      setTimeout(history.push("/menu/userdashboard"), 5000);
    }
  }, [ibridgeUserData]);

  return (
    <>
      <div className="row quset font-weight-bold pr-5 pl-5">
        <div className="image-container">
          <p className="image-holder">
            <img src={loader} alt="" />
          </p>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  iBridge: state.iBridge,
});

const mapDispatchToProps = {
  getIbridgeUser,
  currentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultIbridge);
