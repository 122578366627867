import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getTechnologyList,
  getDomainList,
} from "../../../redux/actions/productivityActions/domainAction";
import { getFunctionList } from "../../../redux/actions/productivityActions/functionAction";
import { getScenarioList } from "../../../redux/actions/productivityActions/scenarioAction";
import { addQustionData } from "../../../redux/actions/productivityActions/questionAction";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const AddNewQuestion = ({ setOpenPopup }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { auth, domainReducer, functionReducer, scenarioReducer } = useSelector(
    (state) => state
  );
  const [domainList, setDomainList] = useState([]);
  const [functionList, setFunctionList] = useState([]);
  const [scenarioList, setScenarioList] = useState([]);

  useEffect(() => {
    if (auth?.isAuthenticated) {
      dispatch(getTechnologyList());
      dispatch(getDomainList());
      dispatch(getFunctionList());
      dispatch(getScenarioList());
    }
  }, [auth?.isAuthenticated]);

  const technologyList = domainReducer?.getTechnologyList?.map((item) => {
    return {
      _id: item._id,
      name: item.name,
    };
  });

  const handleTechnology = (technology) => {
    formik.setFieldValue("selectTechnology", technology);
    const filteredList = domainReducer?.getDomainList?.filter(
      (item) => item?.technology?._id === technology?._id?.toString()
    );
    setDomainList(filteredList);
  };

  const handleDomain = (domain) => {
    formik.setFieldValue("selectDomain", domain);
    const filteredList = functionReducer?.getFunctionList?.filter(
      (item) => item?.domain?._id === domain?._id?.toString()
    );
    setFunctionList(filteredList);
  };

  const handleFunction = (functionData) => {
    formik.setFieldValue("selectFunction", functionData);
    const filteredList = scenarioReducer?.getScenarioList?.filter(
      (item) => item?.function?._id === functionData?._id?.toString()
    );
    setScenarioList(filteredList);
  };

  const formik = useFormik({
    initialValues: {
      selectTechnology: "",
      selectDomain: "",
      selectFunction: "",
      selectScenario: "",
      questionDescription: "",
    },
    validationSchema: Yup.object({
      questionDescription: Yup.string()
        .trim()
        .required("Question Descriptionis required field"),
      selectTechnology: Yup.object()
        .nullable()
        .required("Technology is reqired field"),
      selectDomain: Yup.object().nullable().required("Domain is reqired field"),
      selectFunction: Yup.object()
        .nullable()
        .required("Function is reqired field"),
      selectScenario: Yup.object()
        .nullable()
        .required("Scenario is reqired field"),
    }),

    onSubmit: async (values) => {
      const data = {
        technology: values?.selectTechnology?._id,
        domain: values?.selectDomain?._id,
        function: values?.selectFunction?._id,
        scenario: values?.selectScenario?._id,
        questionDescription: values?.questionDescription,
      };

      dispatch(addQustionData(data));
      // console.log(data)
      setOpenPopup(false);
    },
  });

  const handleReset = () => {
    formik.resetForm();
  }

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Technology"
            name="selectTechnology"
            values={technologyList || []}
            onSelect={(technology) => handleTechnology(technology)}
            selected={formik.values.selectTechnology}
            errors={
              formik.touched.selectTechnology && formik.errors.selectTechnology
                ? formik.errors.selectTechnology
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Domain"
            name="selectDomain"
            values={domainList || []}
            onSelect={(domain) => handleDomain(domain)}
            selected={formik.values.selectDomain}
            errors={
              formik.touched.selectDomain && formik.errors.selectDomain
                ? formik.errors.selectDomain
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Function"
            name="selectFunction"
            values={functionList || []}
            onSelect={(functionData) => handleFunction(functionData)}
            selected={formik.values.selectFunction}
            errors={
              formik.touched.selectFunction && formik.errors.selectFunction
                ? formik.errors.selectFunction
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Scenario"
            name="selectScenario"
            values={scenarioList || []}
            onSelect={(scenario) =>
              formik.setFieldValue("selectScenario", scenario)
            }
            selected={formik.values.selectScenario}
            errors={
              formik.touched.selectScenario && formik.errors.selectScenario
                ? formik.errors.selectScenario
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextFieldGroup
            variant="outlined"
            multiline="multiline"
            rows={4}
            label="Question Description"
            name="questionDescription"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.questionDescription}
            errors={
              formik.touched.questionDescription &&
              formik.errors.questionDescription
                ? formik.errors.questionDescription
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6} align="right">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="save"
              name="save"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="reset"
              name="reset"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

export default AddNewQuestion;
