import React, {useState, useEffect, useRef, useContext} from 'react';
import { AiAssistantContext } from '../../contexts/ai_assistant_context';
import { TextField, Box, Paper, Button, Typography, CircularProgress } from "@material-ui/core"
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useSelector } from "react-redux";
import { styled } from '@mui/system';


const CustomButton = styled(Button)(({ theme }) => ({
  border: '1px solid #18479f',
  // color: 'black',
  textTransform: 'none', 
  '&:hover': {
    border: '1px solid #18479f', 
  },
}));


const Messages = () => {

    const chatbotMessagesRef = useRef(null);
    const [userMessage, setUserMessage] = useState("");
    const handleButtonClick = (message) => {
      handleSendMessage(message);
    };

    const { currentConversation, converse, testHistory, isMessageLoading, selectedTest, selectedQuestion, overallTestAnalysis, userId } = useContext(AiAssistantContext);

    function formatString(input) {
      console.log("question message ", input);
      if(input){
        console.log("question message ", input);
      const lines = input.split('\n');
      const elements = [];
    
      lines.forEach((line, index) => {
        const headerMatch = line.match(/^(#{1,6})\s+(.*)/);
        if (headerMatch) {
          const level = (headerMatch[1].length < 2) ? (headerMatch[1].length + 2):(headerMatch[1].length);
          elements.push(
            <Typography variant={`h${level}`} key={index} component={`h${level}`} color='textPrimary'>
              {headerMatch[2]}
            </Typography>
          );
        } else {
          const parts = line.split(/(\*\*.*?\*\*)/);
          parts.forEach((part, i) => {
            if (part.startsWith('**') && part.endsWith('**')) {
              console.log("part ", part, part.slice(2, -2));
              elements.push(
                <Typography variant='h6' color='textPrimary' display='inline'>
                  {part.slice(2, -2)}
                </Typography>
              );
            } else {
              elements.push(
                <Typography component="span" key={`${index}-${i}`}>
                  {part}
                </Typography>
              );
            }
          });
          elements.push(<br key={`br-${index}`} />);
        }
      });
    
      return elements;
    }
    }
    

    useEffect(()=>{
    if(chatbotMessagesRef.current && !overallTestAnalysis && currentConversation?.length> 1 ){
        chatbotMessagesRef.current.scrollTop = chatbotMessagesRef.current.scrollHeight;
    }
    },[currentConversation])

    const { auth } = useSelector(state => state);

    const handleSendMessage = (readyToAskQuestion) => {

      console.log("readyToAskQuestion ", readyToAskQuestion)
      chatbotMessagesRef.current.scrollTop = chatbotMessagesRef.current.scrollHeight;

      if(userMessage?.length > 0)
      {
        const userQuestion = {role: 'user', content: userMessage};
        
        let conversationType;
        if(selectedTest == null)
          conversationType = 'userKnowledge'
        else if(selectedTest != null && selectedQuestion != null)
          conversationType = 'question'
  
        setUserMessage("");
        converse(conversationType, userId, userQuestion);
      }
      else if(readyToAskQuestion != null)
      {
        // setUserMessage(readyToAskQuestion);
        const userQuestion = {role: 'user', content: readyToAskQuestion};
        
        let conversationType;
        if(selectedTest == null)
          conversationType = 'userKnowledge'
        else if(selectedTest != null && selectedQuestion != null)
          conversationType = 'question'
        // setUserMessage("")
        converse(conversationType, userId, userQuestion); 
      }
    };

    return(
        <>
            <Box 
          ref={chatbotMessagesRef}
          style={{
            flex: 8, 
            display:'flex',
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center',
            width: "100%",
            overflow: 'auto',
            overflowY: 'scroll',
          }}
          className='chat-messages'
          >

          <Box
              style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  height: "100%",
                  width: "90%",
              }}
          >

              {currentConversation?.length> 0 ? currentConversation.map((message, index) => {
                  return message.role === "user" ? (
                      <Paper key={index} style={{ padding: 20, alignSelf: 'flex-end', backgroundColor: "#18479f", color: 'white', marginTop: 5, marginBottom: 10 }} elevation={6}>
                          {message?.content?.split('\n').map((line, i) => (
                              <div key={i}>{line}</div>
                          ))}
                      </Paper>
                  ) : message.role === "assistant" && (
                      <Paper key={index} style={{ padding: 20, alignSelf: 'flex-start', marginTop: 5, marginBottom: 10 }} elevation={10}>
                          {formatString(message?.content)}
                      </Paper>
                  );
              }) 
              : !overallTestAnalysis  ? 
              (<Typography style={{textAlign: 'center'}}variant='h6'>Select one of your tests on the left</Typography>)
              :
                (
                  <Paper style={{ padding: 20, alignSelf: 'flex-start', marginTop: 5, marginBottom: 10 }} elevation={10}>
                    {formatString(overallTestAnalysis?.content)}
                </Paper>
                )
              }
              <Box><br /></Box>
          </Box>
  
          { testHistory?.length>0 && !currentConversation && !userMessage && !overallTestAnalysis &&
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
              <Box sx={{display: 'flex', marginBottom: 10}}>
                <Box sx={{marginRight: 10}}>
                  <CustomButton
                  variant='outlined'
                  color="primary"
                  onClick={(e)=>handleButtonClick("How is my progress so far?")}
                  >
                    How is my progress so far?
                  </CustomButton>
                </Box>
                <Box>
                  <CustomButton
                  variant='outlined'
                  color="primary"
                  onClick={()=>handleButtonClick("What are the concepts that I need to work on?")}
                  >
                    What are the concepts that I need to work on?
                  </CustomButton>
                </Box>
              </Box>
                <CustomButton
                  variant='outlined' 
                  color='primary'
                  onClick={()=>handleButtonClick("How many of my sessions have been analysed so far?")}
                  >
                    How many of my sessions have been analysed so far?
                </CustomButton>
            </Box>
          }
          </Box>
          {!overallTestAnalysis && <Box style={{ flex: 2, display: 'flex', width: "75%" }}>
            <TextField
              label="Your message"
              variant="outlined"
              fullWidth
              margin="normal"
              value={userMessage}
              onChange={(e)=>{setUserMessage(e.target.value)}}
              onKeyDown={(e)=>{
                if(e.key == 'Enter')
                    handleSendMessage();
              }}
              disabled={isMessageLoading}
              InputProps={{
                endAdornment: (
                  <Button
                  style={{ paddingLeft: 1, width: 1, marginLeft: -12, marginRight: 8, backgroundColor: "#1847a0" }} 
                  variant="contained"
                  color="primary"
                  onClick={handleSendMessage}
                  endIcon={isMessageLoading ? 
                    <CircularProgress size={24} color={isMessageLoading == 'light'? 'inherit':'secondary'} /> 
                    :
                    <SendRoundedIcon />}
                  disabled={isMessageLoading || userMessage?.length === 0}
                  />
                ),
              }}
            />
          </Box>}
        </>
    )
}

export default Messages;