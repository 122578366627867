
import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  CardMedia,
  TextField,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const ProductivitySchema = ({ schemaList }) => {
  const [tableList, setTableList] = useState([]);
  const [filteredTableList, setFilteredTableList] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [selectedTable, setSelectedTable] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const tableCellStyle = {
    backgroundColor: "#007bff",
    color: "white",
  };

  useEffect(() => {
    let tableData = new Set();
    schemaList?.map((item) => tableData.add(item?.TABLE_NAME));
    const tableArray = [...tableData];
    setTableList(tableArray);
    setFilteredTableList(tableArray);
  
    // Automatically select the first table
    if (tableArray.length > 0) {
      setSelectedTable(tableArray[0]);
      handleTables(tableArray[0]);
    }
  }, [schemaList]);
  
  const handleTables = (column) => {
    setSelectedTable(column);
    let tableColumns = schemaList?.filter((item) => {
      return item?.TABLE_NAME === column;
    });
    setColumnList(tableColumns);
    console.log("tableColumns",tableColumns);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredTables = tableList.filter((table) =>
      table.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTableList(filteredTables);
  };

  return (
    <>
      <Typography variant="h6">
        Click on table name to view the column description
      </Typography>
      <TextField
       InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
        label="Search Tables"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearch}
        // fullWidth
        margin="normal"
              size="small"
              sx={{width:"47%"}}
      />
      <Grid container spacing={12}>
        <Grid item md={6} className="text-center" justifyContent="flex-end">
          <Typography variant="h6">Table List</Typography>
          <TableContainer component={Paper} style={{ maxHeight: "400px" }}>
            <Table sx={{ minWidth: 150 }} aria-label="simple table">
              <TableBody>
                {filteredTableList?.map((item, idx) => {
                  return (
                    <TableRow key={idx} onClick={() => handleTables(item)}>
                      <TableCell
                        style={
                          item === selectedTable ? tableCellStyle : null
                        }
                        component="th"
                        scope="row"
                      >
                        {item}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item md={6} justifyContent="flex-start">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 150 }} aria-label="simple table">
              <TableBody>
                {columnList?.map((item, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell
                        style={tableCellStyle}
                        component="th"
                        scope="row"
                      >
                        {item?.COLUMN_NAME} :{" "}
                        {item?.COLUMN_TYPE
                          ? item?.COLUMN_TYPE
                          : `${item?.DATA_TYPE} (${item?.DATA_LENGTH})`}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductivitySchema;
