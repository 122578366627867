export const SETCURRENTUSER = "SETCURRENTUSER";
export const USERTOPICS = "USERTOPICS";
export const USERRESULTS = "USERRESULTS";
export const USERLOGOUT = "USERLOGOUT";
export const RESULTSHEET = "RESULTSHEET";
export const RESULTFEEDBACK = "RESULTFEEDBACK";
export const SELECTTEST = "SELECTTEST";
export const TESTQUESTIONS = "TESTQUESTIONS";
export const POSTTEST = "POSTTEST";
export const DELETETEST = "DELETETEST";
export const ADMINTOPICS = "ADMINTOPICS";
export const SINGLETOPICQUESTIONS = "SINGLETOPICQUESTIONS";
export const ALLTOPICQUESTIONS = "ALLTOPICQUESTIONS";
export const ALLRESULTS = "ALLRESULTS";
export const ALLUSERS = "ALLUSERS";
export const GETASSIGNTOPICS = "GETASSIGNTOPICS";
export const POSTASSIGNTOPICS = "POSTASSIGNTOPICS";
export const EDITASSIGNTOPICS = "EDITASSIGNTOPICS";
export const DELETEASSIGNTOPICS = "DELETEASSIGNTOPICS";
export const GETUSERROLES = "GETUSERROLES";
export const EDITUSERROLE = "EDITUSERROLE";
export const ADDNEWUSER = "ADDNEWUSER";
export const GETQUESTIONTOPICS = "GETQUESTIONTOPICS";
export const ADDQUESTIONTOPIC = "ADDQUESTIONTOPIC";
export const EDITQUESTIONTOPIC = "EDITQUESTIONTOPIC";
export const GETQUESTIONCHAPTERS = "GETQUESTIONCHAPTERS";
export const ADDQUESTIONCHAPTER = "ADDQUESTIONCHAPTER";
export const EDITQUESTIONCHAPTER = "EDITQUESTIONCHAPTER";
export const GETQUESTIONTYPES = "GETQUESTIONTYPES";
export const ADDQUESTIONTYPE = "ADDQUESTIONTYPE";
export const EDITQUESTIONTYPE = "EDITQUESTIONTYPE";
export const GETQUESTIONCOMPLEXITY = "GETQUESTIONCOMPLEXITY";
export const ADDQUESTIONCOMPLEXITY = "ADDQUESTIONCOMPLEXITY";
export const EDITQUESTIONCOMPLEXITY = "EDITQUESTIONCOMPLEXITY";
export const GETQUESTIONSRELATEDDATA = "GETQUESTIONSRELATEDDATA";
export const ADDNEWQUESTION = "ADDNEWQUESTION";
export const GETEDITQUESTION = "GETEDITQUESTION";
export const UPDATEQUESTION = "UPDATEQUESTION";
export const SETPOSTDATANULL = "SETPOSTDATANULL";
export const SINGLEUSERVISUALS = "SINGLEUSERVISUALS";
export const ALLUSERSVISUAL = "ALLUSERSVISUAL";
export const COMPARITIVEUSERLISTS = "COMPARITIVEUSERLISTS";
export const GETQUESTIONPAPERS = "GETQUESTIONPAPERS";
export const GETFILTEREDQUESTIONS = "GETFILTEREDQUESTIONS";
export const POSTQUESTIONPAPER = "POSTQUESTIONPAPER";
export const GETQUESTIONPAPERLISTS = "GETQUESTIONPAPERLISTS";
export const GETASSIGNEDTESTS = "GETASSIGNEDTESTS";
export const POSTASSIGNTESTS = "POSTASSIGNTESTS";
export const GETINCOMPLETETESTS = "GETINCOMPLETETESTS";
export const PREDEFINEDTESTQUESTIONS = "PREDEFINEDTESTQUESTIONS";
export const QUESTIONSNULL = "QUESTIONSNULL";
export const SINGLEUSERTESTS = "SINGLEUSERTESTS";
export const TESTASSIGNEDUSERS = "TESTASSIGNEDUSERS";
export const ASSIGNTESTUSER = "ASSIGNTESTUSER";
export const GETSTATUSTOPICS = "GETSTATUSTOPICS";
export const GETSTATUSCOMPLEXITY = "GETSTATUSCOMPLEXITY";
export const ADDFEEDBACKTOTABLE = "ADDFEEDBACKTOTABLE";
export const CHANGEUSERSTATUS = "CHANGEUSERSTATUS";

//Visuals
export const BAR_COMPARITIVE_CHART = "BAR_COMPARITIVE_CHART";
export const SCATTER_GRAPH_DATA = "SCATTER_GRAPH_DATA";
export const SPECIFIC_USER_GRAPH_DATA = "SPECIFIC_USER_GRAPH_DATA";

//Upload image
export const UPLOAD_FILE_PROCESS = "UPLOAD_FILE_PROCESS";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_AUDIO = "UPLOAD_AUDIO";
export const UPLOAD_VIDEO = "UPLOAD_VIDEO";
export const UPLOAD_FILE = "UPLOAD_FILE";

//Ibridge
export const IBRIDGETEST = "IBRIDGETEST";
export const IBRIDGEUSER = "IBRIDGEUSER";
export const GET_IBRIDGE_ICAP_TEST = "GET_IBRIDGE_ICAP_TEST";

// Customers
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_ALL_USER_SUBGROUPS = "GET_ALL_USER_SUBGROUPS";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const GET_GROUP_USERS = "GET_GROUP_USERS";

// Question Chapter
export const GET_ILEARN_COUESES_VIDEOS = "GET_ILEARN_COUESES_VIDEOS";

//User Dashboard Data
export const GET_USERDASHBOARD_DATA = "GET_USERDASHBOARD_DATA";
export const GET_INTERVIEW_QUESTION_PAPERS = "GET_INTERVIEW_QUESTION_PAPERS";

// Interview Result
export const GET_INTERVIEW_RESULTS_LIST = "GET_INTERVIEW_RESULTS_LIST";
export const GET_INTERVIEW_RESULT_SHEET = "GET_INTERVIEW_RESULT_SHEET";

// capabilityAnalysisApplication
export const FETCH_OVERALL = 'FETCH_OVERALL'
export const FETCH_CLUSTER = 'FETCH_CLUSTER'
export const FETCH_TOPICWISE = 'FETCH_TOPICWISE'
export const SET_CAPABILITY_ANALYSIS_ERROR = 'SET_CAPABILITY_ANALYSIS_ERROR'
export const LOADING_CAPABILITY_ANALYSIS_DATA = 'LOADING_CAPABILITY_ANALYSIS_DATA'

//Productivity domain Arean
//test
export const SET_PRODUCTIVITY_LOADING = "SET_PRODUCTIVITY_LOADING";
export const GET_TEST_ID = "GET_TEST_ID";
export const GET_TEST_QUESTIONS = "GET_TEST_QUESTIONS";
export const GET_ALL_PRODUCTIVITY_TESTS = "GET_ALL_PRODUCTIVITY_TESTS";
export const EXECUTE_SQL = "EXECUTE_SQL";
export const GET_SQL_SCHEMA = "GET_SQL_SCHEMA";
export const GET_TEST_DATA = "GET_TEST_DATA";
export const GET_ALL_USER_TESTS = "GET_ALL_USER_TESTS";

//database
export const GET_PRODUCTIVITY_DATABASES = "GET_PRODUCTIVITY_DATABASES";
export const GET_PRODUCTIVITY_DATABASES_INFO =
  "GET_PRODUCTIVITY_DATABASES_INFO";
//domain and technology
export const GET_TECHNOLOGY_LIST = "GET_TECHNOLOGY_LIST";
export const GET_TECHNOLOGY_INFO = "GET_TECHNOLOGY_INFO";
export const GET_DOMAIN_LIST = "GET_DOMAIN_LIST";
export const GET_DOMAIN_INFO = "GET_DOMAIN_INFO";
export const GET_TRUETECHNOLOGY_LIST = "GET_TRUETECHNOLOGY_LIST";
export const GET_ENABLED_DOMAIN_LIST = "GET_ENABLED_DOMAIN_LIST";
export const GET_ENABLED_FUNCTION_LIST = "GET_ENABLED_FUNCTION_LIST";
export const GET_ENABLED_SCENARIO_LIST = "GET_ENABLED_SCENARIO_LIST";

//function
export const GET_FUNCTION_LIST = "GET_FUNCTION_LIST";
export const GET_FUNCTION_INFO = "GET_FUNCTION_INFO";

//question
export const GET_QUESTION_LIST = "GET_QUESTION_LIST";
export const GET_QUESTION_INFO = "GET_QUESTION_INFO";

//scenario
export const GET_SCENARIO_LIST = "GET_SCENARIO_LIST";
export const GET_SCENARIO_INFO = "GET_SCENARIO_INFO";

//query Result
export const GET_QUERYRESULT_LIST = "GET_QUERYRESULT_LIST";

//Debugging Code
export const GET_ALL_DEBUGGING_CODES = "GET_ALL_DEBUGGING_CODES";
export const GET_DEBUGGING_CODES_BY_TECHNOLOGY =
  "GET_DEBUGGING_CODES_BY_TECHNOLOGY";
export const GET_DEBUGGING_SESSION = "GET_DEBUGGING_SESSION";
export const GET_DEBUGGING_CODES_FOR_FEEDBACK =
  "GET_DEBUGGING_CODES_FOR_FEEDBACK";
export const GET_ALL_USER_SESSIONS = "GET_ALL_USER_SESSIONS";

//OpenAI
export const GET_CODE_HINT = "GET_CODE_HINT";
export const SET_HINT_DATA = "SET_HINT_DATA";
export const CLEAR_HINT_DATA = "CLEAR_HINT_DATA";

// codeSubmissions
export const GET_BOILER_PLATE_CODE = "GET_BOILER_PLATE_CODE";
export const PYTHON_CODE_SUBMISSION = "PYTHON_CODE_SUBMISSION";
export const PYTHON_CODE_INI = "PYTHON_CODE_INI";

// terminal
export const CLEAR_TERMINAL = "CLEAR_TERMINAL";


export const ESTABLISH_SOCKET_CONNECTION = 'ESTABLISH_SOCKET_CONNECTION'
export const  DISCONNECT_SOCKET = "DISCONNECT_SOCKET"

// Productivity Reports
export const TEST_SELECTED = "TEST_SELECTED"
export const REPORT_LOADED = "REPORT_LOADED"
export const BACK_ARROW = "BACK_ARROW"
export const EMPTY_REPORT = "EMPTY_REPORT"
export const REPORT_GEN_ERROR = "REPORT_GEN_ERROR"

// sql productivity test case evaluation
export const SQL_TEST_CASE_EVALUATION_ENABLED = "SQL_TEST_CASE_EVALUATION_ENABLED"
export const SQL_CODE_SUBMISSION = "SQL_CODE_SUBMISSION"
export const CLEAR_CODE_SUBMISSION_REDUCER = "CLEAR_CODE_SUBMISSION_REDUCER"
export const GET_CORRECT_SQL_QUERY = "GET_CORRECT_SQL_QUERY"

// learning assistant AI 
export const RETRIEVE_PRODUCTIVITY_TESTS = "RETRIEVE_PRODUCTIVITY_TESTS" 
export const RETRIEVE_LEARNING_ASSISTANT_CHAT_HISTORY = "RETRIEVE_LEARNING_ASSISTANT_CHAT_HISTORY"
// export const RETRIEVE_CHAT_MESSAGES = "RETRIEVE_CHAT_MESSAGES"
// export const INITIATE_CHAT = "INITIATE_CHAT"
export const SET_CHAT_MESSAGES = "SET_CHAT_MESSAGES"
export const RETRIEVE_QUESTION_LIST = "RETRIEVE_QUESTION_LIST"
export const SET_TEST_ID = "SET_TEST_ID"
export const LOAD_AI_MESSAGE = "LOAD_AI_MESSAGE"