import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getScatterGraphData,
  getspecificUserGraphData,
} from "../../redux/actions/visualsAction";
import { adminTopics } from "../../redux/actions/TopicsAction";
import { Grid, Button } from "@mui/material";

import SelectSingleValues from "../reusableComponents/SelectSingleValues";

import AnimatedChartComponent from "./animatedChartComponent";

import { userlists } from "../../redux/actions/userlistsAction";

const NewAnimatedChart = () => {
  const { visuals, allUsers } = useSelector((state) => state);
  console.log("visuals?.SpecificUserGraphData", visuals?.SpecificUserGraphData);
  const dispatch = useDispatch();
  const { scatterGraphData } = visuals;
  const [userSelected, setUserSelected] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const skills = [
    { name: "Beginner", value: 1 },
    { name: "Intermediate", value: 2 },
    { name: "Expert", value: 3 },
  ];
  // console.log(assignedTopics);

  useEffect(() => {
    dispatch(adminTopics());
    dispatch(getScatterGraphData());
    dispatch(userlists());
  }, []);

  const handleUserSelect = (userData) => {
    console.log("userData.id", userData?.id);
    dispatch(getspecificUserGraphData(userData?.id));
    setUserSelected(true);
  };

  const userList = allUsers?.allUsers?.map((item) => {
    const { first_name, last_name, id } = item;
    return {
      name: `${first_name} ${last_name}`,
      id,
    };
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12} align="center">
        <SelectSingleValues
          style={{ width: "40%" }}
          label="Select User"
          name="User"
          values={userList ? userList : []}
          onSelect={(userData) => {
            setSelectedUser(userData);
            handleUserSelect(userData);
          }}
          selected={selectedUser}
          // errors={
          //   formik.touched.selectDomain && formik.errors.selectDomain
          //     ? formik.errors.selectDomain
          //     : null
          // }
        />
      </Grid>
      <Grid item xs={12} md={12}>
        {userSelected ? (
          <AnimatedChartComponent
            quadrantData={visuals?.SpecificUserGraphData}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default NewAnimatedChart;
