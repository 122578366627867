import React, { Component } from "react";
import "./questionPage.css";
import Menu from "../menu/menu";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  getTopicsAndChapters,
  addquestion,
  addFreeTextVoiceQuestion,
} from "../../redux/actions/questionsAction";
import {
  uploadImage,
  clearImageData,
  clearAudioData,
  clearVideoData,
} from "../../redux/actions/uploadFileActions";
import loader from ".././805.gif";
import FileUpload from "../reusableComponents/FileUpload";
import {
  Box,
  Paper,
  Grid,
  styled,
  CircularProgress,
  Card,
  CardMedia,
} from "@mui/material";
import Mic3Recorder from "../reusableComponents/audioRecorder";
import MediaRecorder from "../reusableComponents/MediaRecorder";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

class QuestionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckbox: "",
      isCheckboxSelected: "",
      selectedRadio: "",
      questionsqueryData: "",
      files: [],
      newProduct: "",
      uploadedFile: [],
      maxFilesErr: false,
      topicData: [],
      complexData: [],
      typeData: [],
      chapterdata: [],
      chckBoxAnswer: [],
      dummydata: [],
      selectChapter: false,
      topicDropdownValue: "Select Question Topic",
      complexityDropdownValue: "Select Question Chapter",
      typeDropdownValue: "Select Question Type",
      chapterDropdownValue: "Select Question Chapter",
      option1: null,
      option2: null,
      option3: null,
      option4: null,
      isRadioSelected: "",
      questionData: "",
      choice1url: "",
      choice1ur2: "",
      choice1ur3: "",
      choice1ur4: "",
      RecordData: {
        selectedRadio: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        questionData: "",
        mName: "",
        questionsqueryData: "",
      },
      a: [],
      responseUrl: "",
      check1: "",
      check2: "",
      check3: "",
      check4: "",
      getInitialState: function () {
        return {
          check_me1: true,
          check_me2: true,
          check_me3: true,
          check_me4: true,
        };
      },
      saveanswers: [],
      id: "upload-photo",
      imageArray: [],
      isLoaded: false,
      error: false,
      tid: null,
      tname: "",
    };
  }

  componentDidMount() {
    this.props.getTopicsAndChapters();
    this.props.clearImageData();
    this.props.clearAudioData();
    this.props.clearVideoData();
  }

  componentWillReceiveProps(nextProps) {
    let { questionrelateddata, addquestion } = nextProps.questions;
    if (questionrelateddata) {
      let { tid, isLoaded } = this.state;
      let search = window.location.search;
      let params = new URLSearchParams(search);
      tid = params.get("id");
      let dummydata = [];
      if (tid !== null && !isLoaded) {
        questionrelateddata.topicData.data.map((topic) => {
          if (topic.id == tid) {
            dummydata = questionrelateddata.chapterdata.data.filter(
              (chapter) => {
                if (chapter.topicId === topic.id) {
                  chapter.chapter_name = chapter.chapterName;
                  return chapter;
                }
              }
            );
            // console.log(dummydata)
            this.setState({
              tname: topic.name,
              topicDropdownValue: topic.name,
              tid: topic.id,
              dummydata: dummydata,
              selectChapter: true,
              chapterDropdownValue: "",
            });
          }
        });
      }

      this.setState({
        topicData: questionrelateddata.topicData.data,
        complexData: questionrelateddata.complexData.data,
        typeData: questionrelateddata.typeData.data,
        chapterdata: questionrelateddata.chapterdata.data,
        isLoaded: true,
      });
    }
    if (addquestion === "Success") {
      this.setState({
        isLoaded: true,
      });
    }
  }

  handleOption1(e) {
    this.setState({
      option1: e.target.value,
    });
  }
  handleOption2(e) {
    this.setState({
      option2: e.target.value,
    });
  }
  handleOption3(e) {
    this.setState({
      option3: e.target.value,
    });
  }
  handleOption4(e) {
    this.setState({
      option4: e.target.value,
    });
  }
  handleTypeDropdown = (e) => {
    this.setState({
      typeDropdownValue: e.target.value,
      selectedRadio: "",
      isRadioSelected: "",
    });
  };

  onSelect(selected) {
    // check format of selected param here
    const newProduct = { ...this.state.newProduct };
    newProduct.product_categories = selected;
    console.log(newProduct);
    this.setState({ newProduct });
  }

  handleTopicDropdown = (e) => {
    let tid = "";
    this.state.topicData.map((topic) => {
      if (e.target.value === topic.name) {
        tid = topic.id;
      }
      this.state.dummydata = this.state.chapterdata.filter((chapter) => {
        if (chapter.topicId === tid) {
          chapter.chapter_name = chapter.chapterName;
          return chapter;
        }
      });
    });
    this.setState({
      chapterDropdownValue: e.target.value,
      topicDropdownValue: e.target.value,
      selectChapter: true,
      tid: tid,
    });
  };

  handleChapterDropdown = (e) => {
    this.setState({
      chapterDropdownValue: e.target.value,
    });
  };

  handleComplexityDropdown = (e) => {
    this.setState({
      complexityDropdownValue: e.target.value,
    });
  };
  handleQuestionsData(e) {
    this.setState({
      questionData: e.target.value,
    });
  }
  handleQuestionsqueryData(e) {
    this.setState({
      questionsqueryData: e.target.value,
    });
  }
  handleAnswer1(e) {
    this.setState({
      selectedRadio: e,
      isRadioSelected: 1,
    });
    console.log(this.state.selectedRadio);
  }

  handleAnswer2(e) {
    this.setState({
      selectedRadio: e,
      isRadioSelected: 2,
    });
  }

  handleAnswer3(e) {
    this.setState({
      selectedRadio: e,
      isRadioSelected: 3,
    });
  }

  handleAnswer4(e) {
    this.setState({
      selectedRadio: e,
      isRadioSelected: 4,
    });
  }

  uploadQuestionImage = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    this.props.uploadImage(formData);
  };

  checkChange = (e) => {
    if (e.target.checked === false) {
      this.setState({
        [e.target.name]: "",
        chckBoxAnswer: this.state.chckBoxAnswer.filter(function (val) {
          return val !== e.target.value;
        }),
      });
      console.log("before else" + this.state.chckBoxAnswer);
    } else if (e.target.checked === true) {
      var joined = e.target.value;

      var newState = this.state.chckBoxAnswer.slice();
      newState.push(joined);

      this.setState({
        chckBoxAnswer: this.state.chckBoxAnswer.concat([newState]),
        [e.target.name]: e.target.value,
        isCheckboxSelected: 1,
        // chckBoxAnswer:joined
      });
    }
    console.log("after else" + this.state.chckBoxAnswer);
  };

  handleClose() {
    let { tid } = this.state;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    tid = params.get("id");
    this.props.history.push(`/menu/questions?id=${tid}`);
  }

  validate = () => {
    const { singletopicquestionsdata, alltopicquestionsdata } =
      this.props.questions;
    const {
      questionData,
      option1,
      option2,
      option3,
      option4,
      chapterDropdownValue,
    } = this.state;
    let isError = this.state.error;
    const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,(\n).<>/?`[↵]~|| ]*$/;
    const textarea = this.state.questionData.match(regex);
    const roption1 = this.state.option1?.match(regex);
    const roption2 = this.state.option2?.match(regex);
    const roption3 = this.state.option3?.match(regex);
    const roption4 = this.state.option4?.match(regex);
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: true,
    });
    // if (!singletopicquestionsdata && !alltopicquestionsdata) {
    //   isError = true;
    //   const Toast = Swal.mixin({
    //     toast: true,
    //     position: "center",
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });

    //   Toast.fire({
    //     icon: "Warning",
    //     title: "Something went wrong !!!",
    //   })
    //   // .then(() => {
    //   //   this.props.history.push(`/menu/dashboard`);
    //   // });
    // }
    if (questionData.trim() === "" || questionData.trim() === undefined) {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Please enter Question Description !!!",
      });
    }
    if (questionData.trim().length < 10) {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Question Description should have at least 10 characters !!!",
      });
    }
    if (!textarea || !roption1 || !roption2 || !roption3) {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Please enter all the fields ",
      });
    }
    if (option1.trim() === "" || option1.trim() === undefined) {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Please enter Option 1 !!!",
      });
    }
    if (option2.trim() === "" || option2.trim() === undefined) {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Please enter Option 2 !!!",
      });
    }
    if (option3.trim() === "" || option3.trim() === undefined) {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Please enter Option 3 !!!",
      });
    }
    // if (option4.trim() === "" || option4.trim() === undefined) {
    //   isError = true;

    //   Toast.fire({
    //     icon: "error",
    //     title: "Please enter Option 4 !!!",
    //   });
    // }
    if (chapterDropdownValue === "" || chapterDropdownValue === undefined) {
      isError = true;

      Toast.fire({
        icon: "error",
        title: "Please select a Chapter !!!",
      });
    }

    return isError;
  };

  handleSave() {
    let err = this.validate();
    // alert(err)
    if (!err) {
      let choices = [];
      if (this.state.option4) {
        choices = [
          { name: this.state.option1?.trim() },
          { name: this.state.option2?.trim() },
          { name: this.state.option3?.trim() },
          { name: this.state.option4?.trim() },
        ];
      } else {
        choices = [
          { name: this.state.option1?.trim() },
          { name: this.state.option2?.trim() },
          { name: this.state.option3?.trim() },
        ];
      }

      if (
        this.state.selectedRadio === "" ||
        this.state.selectedRadio === null
      ) {
        const f = [];
        if (this.state.check1 !== "") {
          f.push({ name: this.state.check1 });
        }
        if (this.state.check2 !== "") {
          f.push({ name: this.state.check2 });
        }
        if (this.state.check3 !== "") {
          f.push({ name: this.state.check3 });
        }
        if (this.state.check4 !== "") {
          f.push({ name: this.state.check4 });
        }
        console.log(f);

        if (f.length === 0) {
          const Toast = Swal.mixin({
            toast: true,
            position: "center",
            showConfirmButton: true,
          });

          Toast.fire({
            icon: "error",
            title: "Please Select an answer !!!",
          });
          this.setState({
            error: true,
          });
        } else {
          const userQuestion = {
            description: this.state.questionData.trim(),
            questiontype: this.state.typeDropdownValue,
            questioncomplexity: this.state.complexityDropdownValue,
            questiontopic: this.state.topicDropdownValue,
            questionchapter: this.state.chapterDropdownValue,
            //image: 'None',
            choices: choices,
            answer: f,
          };
          const navigationParams = {
            history: this.props.history,
            tid: this.state.tid,
          };
          this.props.addquestion(userQuestion, navigationParams);
        }
      } else {
        const userQuestion = {
          description: this.state.questionData.trim(),
          questiontype: this.state.typeDropdownValue,
          questioncomplexity: this.state.complexityDropdownValue,
          questiontopic: this.state.topicDropdownValue,
          questionchapter: this.state.chapterDropdownValue,
          // image: 'None',
          choices: choices,
          answer: [
            {
              name: this.state.selectedRadio,
            },
          ],
        };
        const navigationParams = {
          history: this.props.history,
          tid: this.state.tid,
        };
        this.props.addquestion(userQuestion, navigationParams);
        this.setState({
          isLoaded: false,
        });
      }
    }
  }

  handleFreeTextVoice() {
    const { imageData, audioData, videoData } = this.props.fileData;
    const { user } = this.props.auth;
    const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,(\n).<>/?`[↵]~|| ]*$/;
    const textarea = this.state.questionsqueryData.match(regex);
    const {
      questionsqueryData,
      chapterDropdownValue,
      topicData,
      complexData,
      typeData,
      chapterdata,
      typeDropdownValue,
      complexityDropdownValue,
      topicDropdownValue,
    } = this.state;
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: true,
    });
    if (
      questionsqueryData.trim() === "" ||
      questionsqueryData.trim() === undefined
    ) {
      Toast.fire({
        icon: "error",
        title: "Please enter Question Description !!!",
      });
    } else if (questionsqueryData.trim().length < 10) {
      Toast.fire({
        icon: "error",
        title: "Question Description should have at least 10 characters !!!",
      });
    } else if (
      chapterDropdownValue === "" ||
      chapterDropdownValue === undefined
    ) {
      Toast.fire({
        icon: "error",
        title: "Please select a Chapter !!!",
      });
    } else if (textarea) {
      const userQuestion = {
        description: questionsqueryData,
        questionType: typeData.find((item) => item.name === typeDropdownValue)
          .id,
        questionComplexity: complexData.find(
          (item) => item.name === complexityDropdownValue
        ).id,
        questionTopic: topicData.find(
          (topic) => topic.name === topicDropdownValue
        ).id,
        questionChapter: chapterdata.find(
          (chapter) => chapter.chapter_name === chapterDropdownValue
        ).id,
        imageFlag: imageData ? 1 : 0,
        audioFlag: audioData ? 1 : 0,
        videoFlag: videoData ? 1 : 0,
        imageInfo: imageData ? imageData : {},
        audioInfo: audioData ? audioData : {},
        videoInfo: videoData ? videoData : {},
        insertedBy: user?.id,
      };
      const navigationParams = {
        history: this.props.history,
        tid: this.state.tid,
      };
      this.props.addFreeTextVoiceQuestion(userQuestion, navigationParams);
    } else {
      Swal.fire({
        icon: "error",
        text: "Special Characters are not allowed(If copy paste from browser)",
      });
    }
  }

  render() {
    const { user } = this.props.auth;
    const { files, maxFilesErr } = this.state;
    const { imageData, uploadFileProcess, audioData } = this.props.fileData;
    const renderFiles = () => {
      let newFiles = [];
      let i = 0;
      for (let f of files) {
        let img = (
          <img src={URL.createObjectURL(f)} key={i++} className="uploadImg" />
        );
        newFiles.push(img);
      }
      return newFiles;
    };
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div className="container QPmenu">
          <Menu />

          <div
            className="image-container"
            style={{
              display: this.state.isLoaded === false ? "block" : "none",
            }}
          >
            <p className="image-holder">
              <img src={loader} alt="" />
            </p>
          </div>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {/* 1st grid 1st item */}
              <Grid item md={4} xs={12}>
                <label>Select Question Topic</label>
                <select
                  className="form-control"
                  onChange={this.handleTopicDropdown}
                >
                  && {this.state.selectedCheckbox}
                  {/* <option selected="true" disabled="disabled">--select--</option> */}
                  {this.state.topicData.map((topic) => (
                    <option
                      key={topic.id}
                      value={topic.name}
                      selected={topic.name === this.state.tname}
                    >
                      {topic.name}
                    </option>
                  ))}
                </select>
              </Grid>
              {/* 1st grid 2nd item */}
              <Grid item md={4} xs={12}>
                <label>Select Question Complexity</label>
                <select
                  className="form-control"
                  disabled={
                    this.state.topicDropdownValue === "Select Question Chapter"
                  }
                  onChange={this.handleComplexityDropdown}
                >
                  <option selected="true" disabled="disabled" value="">
                    Select Question Chapter
                  </option>
                  {this.state.complexData.map((complex) => (
                    <option key={complex.id} value={complex.name}>
                      {complex.name}
                    </option>
                  ))}
                </select>
              </Grid>
              {/* 1st grid 3rd item */}
              <Grid item md={4} xs={12}>
                <label>Select Question Type</label>
                <select
                  className="form-control"
                  id="drpType"
                  disabled={
                    this.state.complexityDropdownValue ===
                    "Select Question Type"
                  }
                  onChange={this.handleTypeDropdown}
                >
                  <option selected="true" disabled="disabled" value="">
                    Select Question Type
                  </option>
                  {this.state.typeData.map((type) => (
                    <option key={type.id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </Grid>

              <Grid item align="left" xs={12}>
                <label>Select Question Chapter</label>
                <select
                  className="form-control"
                  required="required"
                  disabled={
                    this.state.chapterDropdownValue ===
                    "Select Question Chapter"
                  }
                  onChange={this.handleChapterDropdown}
                >
                  <option selected="true" disabled="disabled" value="">
                    Select Question Chapter
                  </option>
                  {console.log(this.state.dummydata)}
                  {this.state.dummydata.map((chapter) => (
                    <option key={chapter.id} value={chapter.chapter_name}>
                      {chapter.chapter_name}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item align="center" xs={12}></Grid>
            </Grid>
          </Box>
          <div>
            <button
              type="button"
              value="Cancel"
              className="btn btn-danger"
              id="qCancel"
              style={{
                display:
                  this.state.typeDropdownValue === "Single Choice Question" ||
                  this.state.typeDropdownValue === "Multiple Choice Question" ||
                  this.state.typeDropdownValue === "Query" ||
                  this.state.typeDropdownValue === "Free Text" ||
                  this.state.typeDropdownValue === "Voice" ||
                  this.state.typeDropdownValue === "Video" ||
                  this.state.typeDropdownValue === "Boolean"
                    ? "none"
                    : "block",
              }}
              onClick={() => {
                this.handleClose();
              }}
            >
              Cancel
            </button>
          </div>

          <div
            id="sType"
            className="form-group"
            style={{
              display:
                this.state.typeDropdownValue === "Single Choice Question" ||
                this.state.typeDropdownValue === "Multiple Choice Question" ||
                this.state.typeDropdownValue === "Boolean"
                  ? "block"
                  : "none",
            }}
          >
            <div className="row">
              <Grid item id="tblQpage" xs={12}>
                <textarea
                  className="form-control ml-3"
                  id="text"
                  rows="3"
                  cols="100"
                  placeholder="Enter Questions"
                  value={this.state.questionData}
                  onChange={(e) => {
                    this.handleQuestionsData(e);
                  }}
                />
              </Grid>
            </div>
            <div className="align">
              <Grid item md={8} xs={12}>
                <Grid className="options">
                  <label className="optLbl">a.</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.handleOption1(e);
                    }}
                    value={this.state.option1}
                  />
                </Grid>
                <Grid className="options">
                  <label className="optLbl">b.</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.handleOption2(e);
                    }}
                    value={this.state.option2}
                  />
                </Grid>
                <Grid
                  className="options"
                  style={{
                    display:
                      this.state.typeDropdownValue !== "Boolean"
                        ? "block"
                        : "none",
                  }}
                >
                  <label className="optLbl">c.</label>
                  <input
                    type="text"
                    className="form-control"
                    style={{
                      display:
                        this.state.typeDropdownValue !== "Boolean"
                          ? "block"
                          : "none",
                    }}
                    onChange={(e) => {
                      this.handleOption3(e);
                    }}
                    value={this.state.option3}
                  />
                </Grid>
                <Grid
                  className="options"
                  style={{
                    display:
                      this.state.typeDropdownValue !== "Boolean"
                        ? "block"
                        : "none",
                  }}
                >
                  <label className="optLbl">d.</label>
                  <input
                    type="text"
                    className="form-control"
                    style={{
                      display:
                        this.state.typeDropdownValue !== "Boolean"
                          ? "block"
                          : "none",
                    }}
                    onChange={(e) => {
                      this.handleOption4(e);
                    }}
                    value={this.state.option4}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                md={6}
                xs={12}
                className="ans"
                style={{
                  display:
                    this.state.typeDropdownValue === "Single Choice Question" ||
                    this.state.typeDropdownValue === "Boolean"
                      ? "block"
                      : "none",
                }}
              >
                <Grid>
                  <input
                    type="radio"
                    className="ans1"
                    selected={this.state.isRadioSelected === 1}
                    name="selectedRadio"
                    onChange={(e) => {
                      this.handleAnswer1(this.state.option1);
                    }}
                  />
                </Grid>
                <Grid>
                  <input
                    type="radio"
                    className="ans1"
                    selected={this.state.isRadioSelected === 2}
                    onChange={(e) => {
                      this.handleAnswer2(this.state.option2);
                    }}
                    name="selectedRadio"
                  />
                </Grid>
                <Grid>
                  <input
                    type="radio"
                    className="ans1"
                    style={{
                      display:
                        this.state.typeDropdownValue !== "Boolean"
                          ? "block"
                          : "none",
                    }}
                    selected={this.state.isRadioSelected === 3}
                    name="selectedRadio"
                    onChange={(e) => {
                      this.handleAnswer3(this.state.option3);
                    }}
                  />
                </Grid>
                <Grid>
                  <input
                    type="radio"
                    className="ans2"
                    style={{
                      display:
                        this.state.typeDropdownValue !== "Boolean"
                          ? "block"
                          : "none",
                    }}
                    selected={this.state.isRadioSelected === 4}
                    name="selectedRadio"
                    onChange={(e) => {
                      this.handleAnswer4(this.state.option4);
                    }}
                  />
                </Grid>

                <div
                  id="divbtn"
                  className="mt-5"
                  style={{
                    display:
                      this.state.typeDropdownValue === "Single Choice Question"
                        ? "block"
                        : "none",
                  }}
                >
                  <button
                    type="button"
                    value="save"
                    className="btn btn-primary mr-1"
                    onClick={() => {
                      this.handleSave();
                    }}
                    disabled={
                      !this.state.questionData ||
                      !this.state.option1 ||
                      !this.state.option2 ||
                      // !this.state.option3 ||
                      // !this.state.option4 ||
                      !this.state.isRadioSelected
                    }
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    value="Cancel"
                    className="btn btn-danger"
                    onClick={() => {
                      this.handleClose();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                className="ans"
                style={{
                  display:
                    this.state.typeDropdownValue === "Multiple Choice Question"
                      ? "block"
                      : "none",
                }}
              >
                <Grid>
                  <input
                    type="checkbox"
                    className="ans1"
                    name="check1"
                    checked={this.state.check_me1}
                    onChange={(e) => this.checkChange(e)}
                    value={this.state.option1}
                  />
                </Grid>
                <Grid>
                  <input
                    type="checkbox"
                    className="ans1"
                    name="check2"
                    checked={this.state.check_me2}
                    onChange={(e) => this.checkChange(e)}
                    value={this.state.option2}
                  />
                </Grid>
                <Grid>
                  <input
                    type="checkbox"
                    className="ans1"
                    name="check3"
                    checked={this.state.check_me3}
                    onChange={(e) => this.checkChange(e)}
                    value={this.state.option3}
                  />
                </Grid>
                <Grid>
                  <input
                    type="checkbox"
                    className="ans1"
                    name="check4"
                    checked={this.state.check_me4}
                    onChange={(e) => this.checkChange(e)}
                    value={this.state.option4}
                  />
                </Grid>
                <div
                  id="divbtn"
                  className="mt-3"
                  style={{
                    display:
                      this.state.typeDropdownValue ===
                      "Multiple Choice Question"
                        ? "block"
                        : "none",
                  }}
                >
                  <button
                    type="button"
                    value="save"
                    className="btn btn-primary mr-1"
                    onClick={() => {
                      this.handleSave();
                    }}
                    disabled={
                      !this.state.questionData ||
                      !this.state.option1 ||
                      !this.state.option2 ||
                      !this.state.option3 ||
                      // !this.state.option4 ||
                      !this.state.isCheckboxSelected
                    }
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    value="Cancel"
                    className="btn btn-danger"
                    onClick={() => {
                      this.handleClose();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Grid>
            </div>
          </div>
          <div
            id="qType"
            style={{
              display:
                this.state.typeDropdownValue === "Query" ||
                this.state.typeDropdownValue === "Free Text" ||
                this.state.typeDropdownValue === "Voice" ||
                this.state.typeDropdownValue === "Video"
                  ? "block"
                  : "none",
            }}
          >
            <Grid item md={12} xs={12}>
              <FileUpload
                onChange={(e) => this.uploadQuestionImage(e)}
                changeLabel={"Upload"}
              />
              {uploadFileProcess ? (
                <Grid sx={{ maxWidth: 345 }} align="center">
                  <CircularProgress />
                </Grid>
              ) : (
                imageData &&
                imageData.link && (
                  <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                      component="img"
                      alt="questionImage"
                      height="140"
                      image={imageData?.link}
                    />
                  </Card>
                )
              )}
            </Grid>
            <Grid container>
              <Grid item md={6} xs={12}>
                {/* {this.state.typeDropdownValue === "Voice" && <AudioRecorder />}
              {this.state.typeDropdownValue === "Voice" && <MicRecorder />} */}
                {(this.state.typeDropdownValue === "Voice" ||
                  this.state.typeDropdownValue === "Video") && <Mic3Recorder />}
              </Grid>
              <Grid item md={6} xs={12}>
                {this.state.typeDropdownValue === "Video" && <MediaRecorder />}
              </Grid>
            </Grid>
            <Grid item id="tblQpage" xs={12}>
              <textarea
                className="questionBox"
                ro-ws="5"
                cols="100"
                id="txArea"
                placeholder="Enter Questions"
                onChange={(e) => {
                  this.handleQuestionsqueryData(e);
                }}
              />
            </Grid>
            <Grid item xs={12} align="right" className="mt-3">
              <button
                type="button"
                value="save"
                className="btn btn-primary mr-1"
                onClick={() => {
                  this.handleFreeTextVoice();
                }}
                disabled={!this.state.questionsqueryData}
              >
                Save
              </button>
              <button
                type="button"
                value="Cancel"
                className="btn btn-danger"
                onClick={() => {
                  this.handleClose();
                }}
              >
                Cancel
              </button>
            </Grid>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  questions: state.questions,
  fileData: state.fileData,
});

const mapDispatchToProps = {
  getTopicsAndChapters,
  addquestion,
  uploadImage,
  clearImageData,
  addFreeTextVoiceQuestion,
  clearAudioData,
  clearVideoData,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsPage);
