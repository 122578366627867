import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";

import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(4, 0),
        padding: theme.spacing(4),
    },
    submitButton: {
        margin: "10px 0",
        mentorForm: {
            width: "100%",
        },
    },
    mForm: {
        margin: "8px",
    },
}));

const Query = ( ) => {
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            dataBaseEndPoint: "",
            selectDoamin: "",
            selectFunction: "",
            selectScenario: "",
            selectQuestion: "",
        },
        validationSchema: Yup.object({

            // scheduleDescription: Yup.string()
            //   .trim()
            //   .required("Schedule Description is required field"),
            dataBaseEndPoint: Yup.object()
                .nullable()
                .required(" DataBaseEndPoint is reqired field"),
            selectDomain: Yup.object()
                .nullable()
                .required("Domain is reqired field"),
            selectFunction: Yup.object()
                .nullable()
                .required("Function is reqired field"),
            selectScenario: Yup.string()
                .trim()
                .required("Scenario is required field"),
            selectQuestion: Yup.string()
                .trim()
                .required("Question is required field"),


        }),

        onSubmit: async (values) => {
            // console.log("submit value", values);
            // const data = {
            //     chapterName: values?.chapterName,
            //     iLearnCourse: values?.selectIlearnCourse?.id,
            //     iLearnVideo: values?.selectedCourseVideo?.id,
            //     topicId: values?.selectCapabilityTopic?.id,
            // };
            // addnewchapter(data);
            // console.log(data)
            // setOpenPopup(false);
        },
    });


    const renderForm = (
        <form
            onSubmit={formik.handleSubmit}
            enableReinitialize={true}
            noValidate
            autoComplete="off"
        >
            <Grid container spacing={3}>

                <Grid item xs={12} md={3}>
                    <SelectSingleValues
                        label="DataBaseEndPoint"
                        name="dataBaseEndPoint"
                        values={[]}
                        onSelect={() =>
                            formik.setFieldValue("dataBaseEndPoint",)
                        }
                        selected={formik.values.dataBaseEndPoint}
                        errors={
                            formik.touched.dataBaseEndPoint &&
                                formik.errors.dataBaseEndPoint
                                ? formik.errors.dataBaseEndPoint
                                : null
                        }
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <SelectSingleValues
                        label="Select Domain"
                        name="selectDoamin"
                        values={[]}
                        onSelect={() =>
                            formik.setFieldValue("selectDoamin",)
                        }
                        selected={formik.values.selectDoamin}
                        errors={
                            formik.touched.selectDoamin &&
                                formik.errors.selectDoamin
                                ? formik.errors.selectDoamin
                                : null
                        }
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <SelectSingleValues
                        label="Select Function"
                        name="selectFunction"
                       values={[]}
                        onSelect={() =>
                            formik.setFieldValue("selectFunction",)
                        }
                        selected={formik.values.selectFunction}
                        errors={
                            formik.touched.selectFunction &&
                                formik.errors.selectFunction
                                ? formik.errors.selectFunction
                                : null
                        }
                    />
                </Grid>

            
                <Grid item xs={12} md={3}>
                    <SelectSingleValues
                        label="Select Scenario"
                        name="selectScenario"
                       values={[]}
                        onSelect={() =>
                            formik.setFieldValue("selectScenario",)
                        }
                        selected={formik.values.selectScenario}
                        errors={
                            formik.touched.selectScenario &&
                                formik.errors.selectScenario
                                ? formik.errors.selectScenario
                                : null
                        }
                    />
                </Grid>
               
                <Grid item xs={12} md={10}>
                    <SelectSingleValues
                        label="Select Question"
                        name="selectQuestion"
                       values={[]}
                        onSelect={() =>
                            formik.setFieldValue("selectQuestion",)
                        }
                        selected={formik.values.selectQuestion}
                        errors={
                            formik.touched.selectQuestion &&
                                formik.errors.selectQuestion
                                ? formik.errors.selectQuestion
                                : null
                        }
                    />
                </Grid>
               
            


                {/* <Grid item xs={12} md={6}>
                    <TextFieldGroup
                        label="Function Code"
                        name="functionCode"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.functionCode}
                        errors={
                            formik.touched.functionCode && formik.errors.functionCode
                                ? formik.errors.functionCode
                                : null
                        }
                    />
                </Grid> */}


                <Grid item xs={12} md={2} align="right">
                    <div className={classes.submitButton}>
                        <Button
                            buttonStyle="submit"
                            type="reset"
                            name="reset"
                            color="primary"
                            className={classes.inputButtonFields}
                            variant="contained"
                        // onClick={submitForm}
                        >
                          CODE NOW
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );

    return (
        <Container maxWidth="lg" className="adminAddNewEducator">
            {renderForm}
        </Container>
    );
};

// AddNewScenario.propTypes = {
//   auth: PropTypes.object.isRequired,
//   addnewchapter: PropTypes.func.isRequired,
// };

// const mapStateToProps = (state) => ({
//   auth: state.auth,
// });

// const mapDispatchToProps = {

// };

export default connect(
    //   mapStateToProps,
    //   mapDispatchToProps
)(Query);
