import React, { Component } from "react";
// import axios from 'axios';
import Menu from "../menu/menu";
import "./questionComplexity.css";
// import { API_URL } from '../../Api_Request';
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import Spinner from "../reusableComponents/Spinner";
import loader from ".././805.gif";
import {
  getQuestioncomplexity,
  addnewtype,
  editQuestionComplexity,
} from "../../redux/actions/questionComplexityAction";

class QuestionComplexity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: false,
      addComplex: false,
      complexData: [],
      editIdValue: "",
      editElement: -1,
      name: "",
      acronym: "",
      status: 1,
      RecordsData: {
        name: "",
        acronym: "",
        status: "",
      },
    };
  }

  componentDidMount() {
    this.props.getQuestioncomplexity();
  }

  componentWillReceiveProps(nextProps) {
    let { getquestioncomplexity } = nextProps.questioncomplexity;
    if (getquestioncomplexity) {
      this.setState({
        complexData: getquestioncomplexity,
        isLoaded: true,
      });
    }
  }

  handleChoice = () => {
    if (this.state.addComplex === false) {
      this.setState({
        editElement: -1,
        name: "",
        acronym: "",
        addComplex: true,
      });
    }
  };

  handleCancelData = () => {
    if (this.state.addComplex === true) {
      this.setState({
        addComplex: false,
      });
    }
  };

  handleEdit(data, id) {
    if (this.state.addComplex === false) {
      this.setState({
        editIdValue: data.id,
        name: data.name,
        acronym: data.acronym,
        status: data.status,
        editElement: id,
      });
    } else {
      Swal.fire({ icon: "error", text: `please enter the details` });
      // alert("please enter the details");
    }
    console.log(this.state.editElement + 2);
  }

  validate = () => {
    let isError = this.state.error;
    if (this.state.name === "" || this.state.name === undefined || this.state.name.trim() === "") {
      isError = true;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: true,
      });

      Toast.fire({
        icon: "error",
        title: "Please enter Question Complexity name !!!",
      });
      this.setState({
        error: true,
      });
    } else if (this.state.acronym === "" || this.state.acronym === undefined | this.state.acronym.trim() ==="") {
      isError = true;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: true,
      });

      Toast.fire({
        icon: "error",
        title: "Please enter Question Complexity Acronym !!!",
      });
      this.setState({
        error: true,
      });
    } else {
      this.setState({
        error: false,
      });
    }
    return isError;
  };

  handleName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  handleAcronym(e) {
    this.setState({
      acronym: e.target.value,
    });
  }

  handleClick() {
    this.setState({
      error: false,
    });
  }

  handleSaveStatus() {
    this.setState({
      status: this.state.status === 0 ? 1 : 0,
    });
    console.log(this.state.status);
  }

  handleSaveData() {
    let err = this.validate();
    if (!err) {
      const userQComplexity = {
        name: this.state.name,
        acronym: this.state.acronym,
        status: this.state.status === 0 ? 0 : 1,
      };
      this.props.addnewtype(userQComplexity);
      this.setState({
        editElement: -1,
        addComplex: false,
        name: "",
        acronym: "",
        isLoaded: false,
      });
    }
  }

  handleStatus(data, status, index) {
    const dummyRecord = this.state.RecordsData;
    dummyRecord.name = data.name;
    dummyRecord.acronym = data.acronym;
    dummyRecord.status = status === 0 ? 1 : 0;
    const dummycomplexData = this.state.complexData;
    dummycomplexData[index] = dummyRecord;
    this.setState({
      complexData: dummycomplexData,
      status: status === 0 ? 1 : 0,
      RecordsData: {
        name: "",
        acronym: "",
        status: "",
      },
    });
    // alert(JSON.stringify(this.state.complexData));
  }

  handleUpdate(index) {
    let err = this.validate();
    if (!err) {
      const userComplexityEdit = {
        name: this.state.name,
        acronym: this.state.acronym,
        status: this.state.status === 0 ? 0 : 1,
        cid: this.state.editIdValue,
        date: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
      };
      this.props.editQuestionComplexity(userComplexityEdit);
      this.setState({
        editElement: -1,
        name: "",
        acronym: "",
        isLoaded: false,
      });
    }
  }

  render() {
    const { addComplex } = this.state;

    const { user, isAuthenticated } = this.props.auth;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      const complexData = this.state.complexData.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              {this.state.editElement === index ? (
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    this.handleName(e);
                  }}
                  defaultValue={item.name}
                />
              ) : (
                item.name
              )}
            </td>

            <td>
              {this.state.editElement === index ? (
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    this.handleAcronym(e);
                  }}
                  defaultValue={item.acronym}
                />
              ) : (
                item.acronym
              )}
            </td>
            <td>
              <label className="switch" id="status">
                <input
                  type="checkbox"
                  checked={item.status === 0 ? false : true}
                  disabled={this.state.editElement === index ? false : true}
                  onChange={() => this.handleStatus(item, item.status, index)}
                />
                <span className="slider round" />
              </label>
            </td>
            {/* <td>
            <div>
              {
                this.state.editElement === index ? 
                <button className="btn btn-primary" onClick={(e) => this.handleUpdate(index)}>Save</button>
                :
                <button className="btn btn-success" onClick={(e) => this.handleEdit(item, index)}>Edit</button>
              }
            </div>
          </td> */}
          </tr>
        );
      });

      return (
        <div>
          <div>
            <div>
              <br />
              <center>
                <button
                  type="button"
                  id="add-row"
                  className="btn btn-primary"
                  style={{backgroundColor:"#003399",color:"whitesmoke"}}
                  onClick={this.handleChoice}
                >
                  {" "}
                  Add Question Complex
                </button>
              </center>
            </div>
            {/* <div className="alert" style={{display: this.state.error === true ? 'block':'none'}}>
                    <span className="closebtn" onClick={()=>{this.handleClick()}}>&times;</span>
                    <strong>Please Enter Details....</strong>
                  </div> */}
            <br />
            <div
              className="image-container"
              style={{
                display: this.state.isLoaded === false ? "block" : "none",
              }}
            >
              <p className="image-holder">
                <Spinner />
                {/* <img src={loader} alt="" /> */}
              </p>
            </div>
            <div className="table-responsive p-5">
              <table
                className="table table-bordered table-hover"
                style={{
                  display:
                    this.state.isLoaded === true ? "inline-table" : "none",
                }}
              >
                <thead className="thead-bg headers">
                  <tr>
                    <th>Name</th>
                    <th>Acronym</th>
                    <th>Status</th>
                    {addComplex && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {addComplex && (
                    <tr>
                      <td>
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          value={this.state.name}
                          onChange={(e) => {
                            this.handleName(e);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          id="acronym"
                          className="form-control"
                          value={this.state.acronym}
                          onChange={(e) => {
                            this.handleAcronym(e);
                          }}
                        />
                      </td>
                      <td>
                        <label className="switch" id="status">
                          <input
                            type="checkbox"
                            onChange={() => this.handleSaveStatus()}
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>
                        <button
                          type="button"
                          id="qtopicbutton"
                          className="btn btn-primary"
                          style={{backgroundColor:"#003399",color:"whitesmoke"}}
                          value="save"
                          onClick={(e) => this.handleSaveData(e)}
                        >
                          {" "}
                          Save{" "}
                        </button>
                        <button
                          type="button"
                          id="qtopicbutton"
                          className="btn btn-danger"
                          value="cancel"
                          onClick={(e) => this.handleCancelData(e)}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                      </td>
                    </tr>
                  )}
                  {complexData}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  questioncomplexity: state.questioncomplexity,
});

const mapDispatchToProps = {
  getQuestioncomplexity,
  addnewtype,
  editQuestionComplexity,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionComplexity);
