import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

const RunTerminalButton = (props) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState(props.children);

  const enableButton = () => {
    setIsButtonDisabled(false);
    setButtonText(props.children);
  };

  const handleClick = (e) => {
    setIsButtonDisabled(true);
    setButtonText('Running...');
    props.onClick(e);

    setTimeout(enableButton, 5000); 
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
      backgroundColor: '#c1d0ed',
      '&:hover': {
        backgroundColor: '#c1d0ed',
      },
      color: 'black',
    },
  }));

  const classes = useStyles();

  return (
    <Button
      variant={props.variant}
      color={props.color}
      size={props.size}
      className={classes.button}
      endIcon={<IntegrationInstructionsIcon />}
      onClick={(e) => handleClick(e)}
      disabled={isButtonDisabled}
    >
      {buttonText}
    </Button>
  );
};

export default RunTerminalButton;
