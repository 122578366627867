import { GETUSERROLES, EDITUSERROLE, ADDNEWUSER } from "./type";
import axios from "axios";
import { API_URL } from "../../Api_Request";
import Swal from "sweetalert2";

export const getuserrole = (id) => (dispatch) => {
  axios
    .get(`${API_URL}/signup/user/${id}`)
    .then((res) => {
      const result = res.data;
      dispatch({
        type: GETUSERROLES,
        payload: res.data[0],
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const edituserrole = (params) => (dispatch) => {
  axios
    .put(`${API_URL}/userlists/role`, params)
    .then((res) => {
      dispatch({
        type: EDITUSERROLE,
        payload: "Success",
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const addnewuser = (params, history) => (dispatch) => {
  axios
    .post(`${API_URL}/signup`, params)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: `User Added successfully !!!`,
      }).then(() => {
        history.push("/admin/users");
      });

      // dispatch({
      //     type : ADDNEWUSER,
      //     payload : "Success"
      // })

      // console.log(res.data)
    })
    .catch((err) => {
      // dispatch({
      //   type: ADDNEWUSER,
      //   payload: err.response.data.message,
      // });
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};
