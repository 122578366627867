import React, { createContext, useEffect, useState } from 'react';
import { fetchTestHistoryAPI, fetchSqlTestQuestionsAnalysisAPI, converseAPI, fetchDoubtConversationHistoryAPI } from '../services/ai_assistant_api';
import { useSelector } from "react-redux";

export const AiAssistantContext = createContext();

export const AiAssistantProvider = ({ children }) => {

  const [testHistory, setTestHistory] = useState([]);
  const [selectedTestQuestions, setSelectedTestQuestions] = useState([]);
  const [currentConversation, setCurrentConversation] = useState([]);
  const [userId, setUserId] = useState(null);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [analysisProgressValue, setAnalysisProgressValue] = useState(0);
  const [isLoadingTest, setIsLoadingTest] = useState(false);
  const [questionAnalysisArray, setQuestionAnalysisArray] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [overallTestAnalysis, setOverallTestAnalysis] = useState(null);
  const [isUserKnowledgeEnabled, setIsUserKnowledgeEnabled] = useState(false);
  const [isMessageLoading, setIsMessageLoading] = useState(false);

  // useEffect(()=>{
    // if(currentConversationView)
    // setCurrentConversation(currentQuestionView.conversation)
  // }, [currentQuestionView])

  const { auth } = useSelector(state => state);
  useEffect(()=>{
    if(auth?.user?.id){
      setUserId(auth?.user?.id);
    }
  }, [auth]) 


  useEffect(async ()=>{
    setIsLoadingTest(true);
    setOverallTestAnalysis(null);
    const doubtConversationHistory = await fetchDoubtConversationHistory(selectedTest?.id, selectedQuestion?.questionId);
    setCurrentConversation(selectedQuestion?.conversation);
    if(doubtConversationHistory != null && doubtConversationHistory?.length > 0)
      setCurrentConversation((prev)=>[...prev, ...doubtConversationHistory]);
    setIsLoadingTest(false);
  }, [selectedQuestion])
  

  const fetchSQLTestHistory = async (userId) => {
    const {testHistory, userKnowledgeExists} = await fetchTestHistoryAPI(userId, 'RDBMS');
    setIsUserKnowledgeEnabled(userKnowledgeExists);
    if(testHistory == false)
      setIsExistingUser(false);
    else
      setTestHistory(testHistory);
  };


  const fetchDoubtConversationHistory = async (sessionId, questionId) =>{
    const { doubtConversationHistory } = await fetchDoubtConversationHistoryAPI(sessionId, questionId);
    return doubtConversationHistory;
  }


  const selectTest = async (test, userId) => {
    setSelectedTest(test);
    setCurrentConversation([]);
    setSelectedQuestion(null);
    setQuestionAnalysisArray([]);
    setIsLoadingTest(true); 
    
    setAnalysisProgressValue(20);
    await new Promise(resolve => setTimeout(resolve, 100)); 
  
    try {
      setAnalysisProgressValue(40);
      await new Promise(resolve => setTimeout(resolve, 100)); 
  
      const { analysedQuestionsPayload: questionsAnalysis, overallAnalysis } = await fetchSqlTestQuestionsAnalysisAPI(test.id, userId);
      
      setQuestionAnalysisArray(questionsAnalysis);
      console.log(questionsAnalysis);
      console.log(overallAnalysis);
      setOverallTestAnalysis(overallAnalysis);
  
      setAnalysisProgressValue(75);
      await new Promise(resolve => setTimeout(resolve, 100)); 
    } catch (error) {
      console.log(error);
    }
  
    setAnalysisProgressValue(100);
    setIsLoadingTest(false);
  };


  const converse = async (type, userId, userMessage) => {
    setIsMessageLoading(true);
    const sessionId = selectedTest?.id;
    const questionId = selectedQuestion?.questionId;
    setCurrentConversation((prevConversation) => {
      const updatedConversation = prevConversation?.length > 0 ? [...prevConversation, userMessage] : [userMessage];
      converseAPI(type, userId, updatedConversation, sessionId, questionId).then((response) => {
        setCurrentConversation(response);
        setIsMessageLoading(false);
      });
      return updatedConversation;
    });
  };
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth()+1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
  }

  return (
    <AiAssistantContext.Provider value={{ testHistory, fetchSQLTestHistory, isLoadingTest,
       setSelectedTest, selectedTest, isMessageLoading, analysisProgressValue, questionAnalysisArray, selectedQuestion, userId, setSelectedQuestion, selectTest,
    setSelectedTestQuestions, currentConversation, setCurrentConversation, overallTestAnalysis, isUserKnowledgeEnabled, formatDate,
     converse }}>
      {children}
    </AiAssistantContext.Provider>
  );
};
