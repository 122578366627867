import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { dateFilter, allResults } from "../../redux/actions/resultsAction";
import { getScatterGraphData } from "../../redux/actions/visualsAction";
import {
  getFilteredTests,
  getAllTests,
} from "../../redux/actions/productivityActions/testAction";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import ButtonField from "../reusableComponents/ButtonField";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useEffect } from "react";
import Calender from "../../images/logo/Calnder.png";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    margin: theme.spacing(1, 0),
  },
  formSubmit: {
    textTransform: "capitalize",
    backgroundColor: "#003299",
    marginRight: "10px",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#F8F9F9",
    "&:hover": {
      background: "#004499",
    },
  },
  multiselect: {
    marginTop: "10px",
  },
}));

const ResultFilters = ({ scatterFilter, productivityResult }) => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 61))
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      startDate: startDate,
      endDate: endDate,
    };

    if (scatterFilter) {
      dispatch(getScatterGraphData(data));
    } else if (productivityResult) {
      dispatch(getFilteredTests(data));
    } else {
      dispatch(dateFilter(data));
    }

    // console.log("data");
    // searchAccessLog(data);
  };

  const handleClearFilter = (e) => {
    setStartDate(new Date());
    setEndDate(new Date());
    // setCourses([]);
    e.preventDefault();
    if (scatterFilter) {
      dispatch(getScatterGraphData());
    } else if (productivityResult) {
      dispatch(getAllTests());
    } else {
      dispatch(allResults());
    }
    // getAccessLog();
  };

  const classes = useStyles();
  return (
    <form onSubmit={(e) => handleSubmit(e)} noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={4} style={{ marginLeft: "10px" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              // margin="normal"
              name="programStartDate"
              label="Select Start Date"
              format="MM/dd/yyyy"
              inputVariant="standard"
              value={startDate}
              className={classes.inputFields}
              onChange={(date) => {
                if (!date) {
                  setStartDate(1);
                } else {
                  setStartDate(
                    date.getFullYear() +
                      "-" +
                      (date.getMonth() + 1) +
                      "-" +
                      date.getDate()
                  );
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              keyboardIcon={<img src={Calender} alt="calendar" />}
            />
            {/* {
              console.log()
              
            } */}
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              // margin="normal"
              name="programStartDate"
              label="Select End Date"
              format="MM/dd/yyyy"
              inputVariant="standard"
              value={endDate}
              className={classes.inputFields}
              onChange={(date1) => {
                if (!date1) {
                  setEndDate(1);
                } else {
                  setEndDate(
                    date1.getFullYear() +
                      "-" +
                      (date1.getMonth() + 1) +
                      "-" +
                      date1.getDate()
                  );
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              keyboardIcon={<img src={Calender} alt="calendar" />}
            />
            {/* {
              console.log(endDate.getFullYear()+"-"+(endDate.getMonth()+1)+"-"+endDate.getDate())
              
            } */}
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={3} >
          <div className={classes.buttonContainer}>
            <ButtonField
              buttonStyle={classes.formSubmit}
              type="submit"
              name="Filter"
            />
            <ButtonField
              buttonStyle={classes.formSubmit}
              onClick={(e) => handleClearFilter(e)}
              type="button"
              name="Clear Filter"
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

ResultFilters.propTypes = {
  //   getAllCourses: PropTypes.func.isRequired,
  //   searchAccessLog: PropTypes.func.isRequired,
  //   getAccessLog: PropTypes.func.isRequired,
  //   auth: PropTypes.object.isRequired,
  //   courses: PropTypes.object.isRequired,
};

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   courses: state.courses,
// });

export default ResultFilters;
