import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { API_URL } from "../../Api_Request";
import axios from "axios";
import "./adduser.css";
import Swal from "sweetalert2";
import Menu from "../menu/menu";
import { connect } from "react-redux";
import { addnewuser } from "../../redux/actions/addRoleAction";
import Select from "react-select";
import {
  getUserSubGroupList,
  getCustomerList,
} from "../../redux/actions/customerActions";
// import { getQuestionTopics } from "../../redux/actions/questionTopicsAction";
import { adminTopics } from "../../redux/actions/TopicsAction";
import { Container, Grid, Typography } from "@material-ui/core";
import TextFieldGroup from "../reusableComponents/TextFieldGroup";

class Adduser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      subGroup: "",
      selectedSubGroup: null,
      filteredSubGroup: [],
      subGroupError: "",
      customer: "",
      customerError: "",
      data: [],
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneError: "",
      err: false,
      subGroupData: [],
      topics: [],
      selectedTopic: null,
    };
  }
  componentDidMount() {
    this.props.getUserSubGroupList();
    this.props.adminTopics();
    const { user } = this.props.auth;
    if (user?.role === "Super Admin") {
      this.props.getCustomerList();
    }
  }

  componentWillReceiveProps(nextProps) {
    // let { newuser } = nextProps.userRoles;
    // if (newuser) {
    //   if (newuser === "Success") {
    //     Swal.fire({
    //       icon: "success",
    //       text: `User Added successfully !!!`,
    //     }).then(() => {
    //       this.props.history.push("/menu/users");
    //     });
    //   } else {
    //     Swal.fire({ icon: "error", text: `${newuser}` });
    //   }
    // }
    const { user } = nextProps.auth;
    const { customerList } = nextProps.customer;
    if (customerList) {
      const name = customerList.map((item) => {
        item.label = item.customer_name;
        item.value = item.customer_id;
        return item;
      });
      this.setState({
        customerData: name,
      });
    }
    const { userSubGroupList } = nextProps.customer;
    if (userSubGroupList) {
      const name = userSubGroupList.map((item) => {
        item.label = item.user_group_name;
        item.value = item.user_group_id;
        return item;
      });
      this.setState({
        subGroupData: name,
      });
      if (user?.role !== "Super Admin") {
        const filteredGroup = userSubGroupList.map((item) => {
          if (item.customer_id === user?.customer_id) {
            item.label = item.user_group_name;
            item.value = item.user_group_id;
            return item;
          }
        });
        this.setState({
          filteredSubGroup: filteredGroup,
        });
      }
    }
    let { adminTopics } = nextProps.assignedTopics;
    if (adminTopics?.length > 0) {
      if (!this.state.topics || this.state.topics.length === 0) {
        adminTopics.map((topics) => {
          let topic = { label: topics.name, value: topics.id };
          // console.log(topic)
          this.state.topics.push(topic);
        });
      }
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneError: "",
      err: false,
    });
  }

  handleSubGroup = (selectedSubGroup) => {
    console.log(selectedSubGroup, this.state.selectedSubGroup);
    this.setState({
      subGroup: selectedSubGroup.value,
      selectedSubGroup: selectedSubGroup,
    });
  };

  handleCustomer = (selectedCustomer) => {
    const filteredSubGroup = this.state.subGroupData.filter(
      (item) => item.customer_id === selectedCustomer.value
    );
    console.log(
      this.state.subGroupData,
      filteredSubGroup,
      this.state.selectedSubGroup
    );

    this.setState({
      customer: selectedCustomer.value,
      filteredSubGroup,
      selectedSubGroup: filteredSubGroup[0],
    });
  };

  emailValidation = (e) => {
    // console.log(e.target.value);

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
      this.setState({
        emailError: "",
        err: false,
        emailvalid: true,
      });
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)
    ) {
      this.setState({
        emailError: "Please enter valid email !!!",
        err: true,
        emailvalid: false,
      });
    } else if (this.state.email.includes("@")) {
      const Email_Id = e.target.value;
      axios
        .get(`${API_URL}/signup/${Email_Id}`)
        .then((res) => {
          if (res.data.length > 0) {
            this.setState({
              emailError: "Email_Id is already registered !!!",
              err: true,
              emailvalid: false,
            });
          } else {
            this.setState({
              emailError: "",
              err: false,
              emailvalid: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  number = (e) => {
    const num = String.fromCharCode(e.which);

    if (!/[0-9]/.test(num)) {
      e.preventDefault();
      this.setState({
        phoneError: "Enter only numbers",
        err: true,
      });
    }
  };

  character = (e) => {
    const char = String.fromCharCode(e.which);

    if (!/^[A-Za-z]+$/.test(char)) {
      e.preventDefault();
      this.setState({
        firstNameError: "Enter only Characters",
        err: true,
      });
    }
  };

  character1 = (e) => {
    const char = String.fromCharCode(e.which);

    if (!/^[A-Za-z]+$/.test(char)) {
      e.preventDefault();
      this.setState({
        lastNameError: "Enter only Characters",
        err: true,
      });
    }
  };

  handleTopics = (selectedTopic) => {
    this.setState({ selectedTopic });
    console.log(selectedTopic);
  };

  validate = () => {
    let isError = this.state.err;
    const { user } = this.props.auth;

    let firstNameError = "";
    let lastNameError = "";
    let emailError = "";
    let phoneError = "";
    let subGroupError = "";
    let customerError = "";

    if (this.state.firstName === "") {
      isError = true;
      firstNameError = "First name should not be empty";
    }
    if (this.state.firstName.length < 4) {
      isError = true;
      firstNameError = "First name should contain at least 4 characters";
    }

    if (this.state.lastName === "") {
      isError = true;
      lastNameError = "Last name should not be empty";
    }

    if (this.state.email === "") {
      isError = true;
      emailError = "Email_Id should not be empty";
    }

    if (this.state.phone === "") {
      isError = true;
      phoneError = "Phone number should not be empty";
    }

    if (!this.state.subGroup) {
      isError = true;
      subGroupError = "SubGroup should not be empty";
    }

    if (!this.state.subGroup) {
      isError = true;
      subGroupError = "SubGroup should not be empty";
    }

    if (user?.role === "Super Admin") {
      if (!this.state.customer) {
        isError = true;
        customerError = "Customer should not be empty";
      }
    }

    this.setState({
      ...this.state,
      firstNameError: firstNameError,
      lastNameError: lastNameError,
      emailError: emailError,
      phoneError: phoneError,
      subGroupError,
      customerError,
    });

    return isError;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let {
      err,
      emailError,
      firstNameError,
      lastNameError,
      phoneError,
      subGroupError,
      selectedTopic,
    } = this.state;
    err = this.validate();
    const username = JSON.parse(localStorage.getItem("capability"));
    const addedby = username.username;

    if (!err) {
      let topics = null;
      let topicName = null;
      const { user } = this.props.auth;
      if (selectedTopic !== null) {
        let topic = selectedTopic.map((skill) => skill.value);
        topics = topic.join();
        let topicNames = selectedTopic.map((skill) => skill.label);
        topicName = topicNames.join();
      }
      const userData = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        addedby: addedby,
        password: this.state.phone,
        cpassword: this.state.phone,
        user_group_id: this.state.subGroup,
        customer:
          user?.role === "Super Admin"
            ? this.state.customer
            : user?.customer_id,
        topics: topics,
        topicName: topicName,
        selectedTopic,
      };
      // console.log(userData);
      this.props.addnewuser(userData, this.props.history);
    } else {
      Swal.fire({
        icon: "error",
        text: `${emailError}
                 ${firstNameError}
                 ${lastNameError}
                 ${phoneError}
                 ${subGroupError}`,
      });
    }
  };
  render() {
    const { user, isAuthenticated } = this.props.auth;
    const { userSubGroupList } = this.props.customer;
    const { adminTopics } = this.props.assignedTopics;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      const addUser = (
        <div>
          <section className="addlogin">
            <Grid className="mt-5 pt-5">
              <form onSubmit={(e) => this.handleSubmit(e)} autoComplete="off">
                <h6 className="text-center">
                  Add <span>U</span>ser
                </h6>
                <Grid
                  container
                  md={12}
                  spacing={5}
                  justify="center"
                  style={{ padding: "20px" }}
                >
                  <Grid item md={6}>
                    <div className="text-left">
                      <label>First Name</label>
                    </div>
                    <input
                      className="form-control"
                      required="required"
                      onChange={(e) => this.handleChange(e)}
                      minLength="4"
                      onKeyPress={(e) => this.character(e)}
                      placeholder="Enter First Name"
                      type="text"
                      name="firstName"
                    />
                    {this.state.firstNameError ? (
                      <span style={{ color: "red" }}>
                        {this.state.firstNameError}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item md={6}>
                    <div className="text-left">
                      <label>Last Name</label>
                    </div>
                    <input
                      className="form-control"
                      required="required"
                      onChange={(e) => this.handleChange(e)}
                      onKeyPress={(e) => this.character1(e)}
                      placeholder="Enter Last Name"
                      type="text"
                      name="lastName"
                    />
                    {this.state.lastNameError ? (
                      <span style={{ color: "red" }}>
                        {this.state.lastNameError}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item md={6}>
                    <div className="text-left">
                      <label>Email</label>
                    </div>
                    <input
                      className="form-control"
                      onBlur={(e) => this.emailValidation(e)}
                      required="required"
                      onChange={(e) => this.handleChange(e)}
                      placeholder="Enter Email"
                      type="text"
                      name="email"
                    />
                    {this.state.emailError ? (
                      <span style={{ color: "red" }}>
                        {this.state.emailError}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item md={6}>
                    <div className="text-left">
                      <label>Phone Number</label>
                    </div>
                    <input
                      className="form-control"
                      onChange={(e) => this.handleChange(e)}
                      required="required"
                      placeholder="Enter Phone number"
                      type="text"
                      name="phone"
                      onKeyPress={(e) => this.number(e)}
                      maxLength="10"
                      minLength="10"
                    />
                    {this.state.phoneError ? (
                      <span style={{ color: "red" }}>
                        {this.state.phoneError}
                      </span>
                    ) : null}
                  </Grid>
                  {user?.role === "Super Admin" ? (
                    <Grid item md={6}>
                      {/* <div className="error">{this.state.subGroupError}</div> */}
                      <div className="">
                        <div className="text-left">
                          <label>Select Customer</label>
                        </div>
                        {this.state.customerData && (
                          <Select
                            name="customer"
                            options={this.state.customerData}
                            onChange={this.handleCustomer}
                          />
                        )}
                      </div>
                      {this.state.customerError ? (
                        <span style={{ color: "red" }}>
                          {this.state.customerError}
                        </span>
                      ) : null}
                    </Grid>
                  ) : null}
                  <Grid item md={6}>
                    {/* <div className="error">{this.state.subGroupError}</div> */}
                    <div className="">
                      <div className="text-left">
                        <label>Select User-Sub-Group</label>
                      </div>
                      {this.state.filteredSubGroup && (
                        <Select
                          name="subGroup"
                          value={this.state.selectedSubGroup}
                          options={this.state.filteredSubGroup}
                          onChange={this.handleSubGroup}
                        />
                      )}
                    </div>
                    {this.state.subGroupError ? (
                      <span style={{ color: "red" }}>
                        {this.state.subGroupError}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item md={6}>
                    {/* <div className="error">{this.state.subGroupError}</div> */}
                    <div className="">
                      <div className="text-left">
                        <label>Assign Topics</label>
                      </div>
                      {adminTopics && (
                        <Select
                          isMulti
                          name="topics"
                          options={this.state.topics}
                          className="basic-multi-select"
                          placeholder="Assign Topics"
                          onChange={this.handleTopics}
                          defaultValue={this.state.selectedTopic}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item md={6}></Grid>
                </Grid>
                <div className="form-group col-sm-12 mt-4">
                  <center>
                    <button
                      className="btn btn-primary w-25 ml-auto login-button"
                      type="submit"
                    >
                      Add User
                    </button>
                  </center>
                </div>
              </form>
            </Grid>
            {/* <div className="container pt-5"> */}
            {/* <h2 className="pt-5">Capability <span>A</span>pp</h2> */}
            {/* <form
                className="demo mt-3 pt-3 box"
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <h6 className="text-center">
                  Add <span>U</span>ser
                </h6>
                <div className="form-group login-form-data mx-auto">
                  <div className=" ">
                    <div className="text-left">
                      <label>First Name</label>
                    </div>
                    <div className="error">{this.state.firstNameError}</div>
                    <div className="">
                      <input
                        className="form-control"
                        required="required"
                        onChange={(e) => this.handleChange(e)}
                        minLength="4"
                        onKeyPress={(e) => this.character(e)}
                        placeholder="Enter First Name"
                        type="text"
                        name="firstName"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group login-form-data mx-auto">
                  <div className=" ">
                    <div className="text-left">
                      <label>Last Name</label>
                    </div>
                    <div className="error">{this.state.lastNameError}</div>
                    <div className="">
                      <input
                        className="form-control"
                        required="required"
                        onChange={(e) => this.handleChange(e)}
                        onKeyPress={(e) => this.character1(e)}
                        placeholder="Enter Last Name"
                        type="text"
                        name="lastName"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group login-form-data mx-auto">
                  <div className=" ">
                    <div className="text-left">
                      <label>Email</label>
                    </div>
                    <div className="error">{this.state.emailError}</div>
                    <div className="">
                      <input
                        className="form-control"
                        onBlur={(e) => this.emailValidation(e)}
                        required="required"
                        onChange={(e) => this.handleChange(e)}
                        placeholder="Enter Email or Username"
                        type="email"
                        name="email"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group login-form-data mx-auto">
                  <div className=" ">
                    <div className="text-left">
                      <label>Phone</label>
                    </div>
                    <div className="error">{this.state.phoneError}</div>
                    <div className="">
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChange(e)}
                        required="required"
                        placeholder="Enter Phone number"
                        type="text"
                        name="phone"
                        onKeyPress={(e) => this.number(e)}
                        maxLength="10"
                        minLength="10"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group login-form-data mx-auto">
                  <div className=" ">
                    <div className="text-left">
                      <label>Assign Sub-Group</label>
                    </div>
                    <div className="error">{this.state.subGroupError}</div>
                    <div className="">
                      {this.state.subGroupData && (
                        <Select
                          name="subGroup"
                          options={this.state.subGroupData}
                          onChange={this.handleSubGroup}
                        />
                      )}
                    </div> */}
            {/* <div className="text-left">
                      <label>Select User Group</label>
                    </div>
                    <div className="error">{this.state.phoneError}</div>
                    <div className="">
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChange(e)}
                        required="required"
                        placeholder="Enter Phone number"
                        type="text"
                        name="phone"
                        onKeyPress={(e) => this.number(e)}
                        maxLength="10"
                        minLength="10"
                      />
                    </div>
                  </div> */}
            {/* </div>
                </div> */}
            {/* <div className="form-group login-form-data mx-auto">
                  <div className=" ">
                    <div className="text-left">
                      <label>Assign Topics</label>
                    </div>
                    <div className="error">{this.state.topicsError}</div>
                    <div className="">
                      {gettopics && (
                        <Select
                          isMulti
                          name="topics"
                          options={this.state.topics}
                          className="basic-multi-select"
                          placeholder="Assign Topics"
                          onChange={this.handleTopics}
                          defaultValue={this.state.selectedTopic}
                        />
                      )}
                    </div>
                  </div>
                </div> */}
            {/* <div className="form-group col-sm-12 mt-4">
                  <center>
                    <button
                      className="btn btn-primary w-25 ml-auto login-button"
                      type="submit"
                    >
                      Add User
                    </button>
                  </center>
                </div>
              </form>
            </div> */}
          </section>
        </div>
      );

      return (
        <div>
          <Menu />
          {addUser}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userRoles: state.userRoles,
  customer: state.customer,
  assignedTopics: state.assignedTopics,
});

const mapDispatchToProps = {
  addnewuser,
  getUserSubGroupList,
  getCustomerList,
  adminTopics,
};

export default connect(mapStateToProps, mapDispatchToProps)(Adduser);
