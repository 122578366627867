import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";

import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";

import {
  getTechnologyList,
  getDomainList,
} from "../../../redux/actions/productivityActions/domainAction";
import {
  getFunctionInfo,
  editfunction,
} from "../../../redux/actions/productivityActions/functionAction";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const EditFunction = ({ setOpenPopup, functionId }) => {
  const classes = useStyles();
  const { auth, domainReducer } = useSelector((state) => state);
  const { functionReducer } = useSelector((state) => state);
  const [domainList, setDomainList] = useState([]);
  const dispatch = useDispatch();

  const functionInfo = functionReducer?.getFunctionInfo;

  useEffect(() => {
    if (auth.isAuthenticated) {
      formik.setFieldValue("selectTechnology", {});
      formik.setFieldValue("selectedDomain", {});
      dispatch(getTechnologyList());
      dispatch(getDomainList());
      dispatch(getFunctionInfo(functionId));
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (domainReducer?.getDomainList.length > 0 && functionInfo) {
      const selecetedDoamin = domainReducer?.getDomainList?.find(
        (item) => item._id?.toString() === functionInfo?.domain?.toString()
      );
      formik.setFieldValue("selectDomain", selecetedDoamin || {});
    }
  }, [domainReducer?.getDomainList, functionInfo]);

  useEffect(() => {
    if (domainReducer?.getTechnologyList.length > 0 && functionInfo) {
      const selecetedTechnology = domainReducer?.getTechnologyList?.find(
        (item) => item._id?.toString() === functionInfo?.technology?.toString()
      );

      formik.setFieldValue("selectTechnology", selecetedTechnology || {});
      const filteredList = domainReducer?.getDomainList?.filter(
        (item) => item?.technology?._id === selecetedTechnology?._id?.toString()
      );
      setDomainList(filteredList);
    }
  }, [domainReducer?.getTechnologyList, functionInfo]);
  useEffect(() => {
    if (functionInfo) {
      formik.setFieldValue(
        "functionName",
        functionInfo ? functionInfo?.name : ""
      );

      formik.setFieldValue(
        "functionBusinessValue",
        functionInfo ? functionInfo?.functionBusinessValue : ""
      );

      formik.setFieldValue(
        "functionBusinessSize",
        functionInfo ? functionInfo?.functionBusinessSize : ""
      );

      formik.setFieldValue(
        "functionCode",
        functionInfo ? functionInfo?.functionCode : ""
      );

      formik.setFieldValue(
        "functionDescription",
        functionInfo ? functionInfo?.functionDescription : ""
      );
      formik.setFieldValue(
        "enableStatus",
        functionInfo?.status
          ? { name: "Enabled", _id: true }
          : { name: "Disbaled", _id: false }
      );
    }
  }, [functionInfo]);

  // console.log("functionId",functionId  );
  //  console.log("functionInfo",functionInfo );

  const technologyList = domainReducer?.getTechnologyList?.map((item) => {
    return {
      _id: item._id,
      name: item.name,
    };
  });

  const handleTechnology = (technology) => {
    formik.setFieldValue("selectTechnology", technology);
    const filteredList = domainReducer?.getDomainList?.filter(
      (item) => item?.technology?._id === technology?._id?.toString()
    );
    setDomainList(filteredList);
  };

  //   console.log("domainReducer in function",domainReducer)

  const formik = useFormik({
    initialValues: {
      selectTechnology: "",
      selectDomain: "",
      functionName: "",
      functionBusinessValue: "",
      functionBusinessSize: "",
      functionCode: "",
      functionDescription: "",
      enableStatus: "",
    },
    validationSchema: Yup.object({
      // scheduleDescription: Yup.string()
      //   .trim()
      //   .required("Schedule Description is required field"),
      selectTechnology: Yup.object()
        .nullable()
        .required("Technology is reqired field"),
      selectDomain: Yup.object().nullable().required("Domain is reqired field"),
      functionName: Yup.string()
        .min(5)
        .max(25)
        .trim()
        .required("Function Name is required field"),
      functionBusinessValue: Yup.number()
        .min(1)
        .required("Function Business Value is required field")
        .typeError("Bussiness value be a number"),
      functionBusinessSize: Yup.number()
        .min(1)
        .required("Function Business Size is required field")
        .typeError("Bussiness Size should be a number"),
      functionCode: Yup.string()
        .trim()
        .min(2)
        .max(4)
        .required("Function Code is required field"),
      functionDescription: Yup.string()
        .trim()
        .required("Function Description is required field"),
    }),

    onSubmit: async (values) => {
      // console.log("submit value", values);
      const data = {
        _id: functionId,
        technology: values?.selectTechnology?._id,
        domain: values?.selectDomain?._id,
        name: values?.functionName,
        functionCode: values?.functionCode,
        functionBusinessValue: values?.functionBusinessValue,
        functionBusinessSize: values?.functionBusinessSize,
        functionDescription: values?.functionDescription,
        status: values?.enableStatus?._id,
      };
      dispatch(editfunction(data));
      //console.log("function data",data)
      setOpenPopup(false);
    },
  });

  const renderForm = (
    <form
      // onSubmit={()=>alert("am in form Submit")}
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Technology"
            name="selectTechnology"
            values={technologyList || []}
            onSelect={(technology) => handleTechnology(technology)}
            selected={formik.values.selectTechnology}
            errors={
              formik.touched.selectTechnology && formik.errors.selectTechnology
                ? formik.errors.selectTechnology
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            variant="outlined"
            label="Select Domain"
            name="selectDomain"
            values={domainList || {}}
            onSelect={(domain) => formik.setFieldValue("selectDomain", domain)}
            selected={formik.values.selectDomain}
            errors={
              formik.touched.selectDomain && formik.errors.selectDomain
                ? formik.errors.selectDomain
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Function Name"
            name="functionName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.functionName}
            errors={
              formik.touched.functionName && formik.errors.functionName
                ? formik.errors.functionName
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Function Business Value"
            name="functionBusinessValue"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.functionBusinessValue}
            errors={
              formik.touched.functionBusinessValue &&
              formik.errors.functionBusinessValue
                ? formik.errors.functionBusinessValue
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Function Business Size"
            name="functionBusinessSize"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.functionBusinessSize}
            errors={
              formik.touched.functionBusinessSize &&
              formik.errors.functionBusinessSize
                ? formik.errors.functionBusinessSize
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Function Code"
            name="functionCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.functionCode}
            errors={
              formik.touched.functionCode && formik.errors.functionCode
                ? formik.errors.functionCode
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectSingleValues
            label="Enable/Disable Domain"
            name="enableStatus"
            values={
              [
                { name: "Enabled", _id: true },
                { name: "Disabled", _id: false },
              ] || []
            }
            onSelect={(status) => {
              formik.setFieldValue("enableStatus", status);
            }}
            selected={formik.values.enableStatus}
            errors={
              formik.touched.enableStatus && formik.errors.enableStatus
                ? formik.errors.enableStatus
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextFieldGroup
            variant="outlined"
            label="Function Description"
            name="functionDescription"
            multiline="multiline"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={4}
            value={formik.values.functionDescription}
            errors={
              formik.touched.functionDescription &&
              formik.errors.functionDescription
                ? formik.errors.functionDescription
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6} align="right">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="submit"
              name="save"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </Grid>

        {/* <Grid item xs={12} md={6} align="left">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="reset"
              name="reset"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
            // onClick={submitForm}
            >
              Reset
            </Button>
          </div>
        </Grid> */}
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

export default EditFunction;
