import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import CustomizedDialogs from "../reusableComponents/CustomizedDialogs";
import Tables from "../tables/tables";
// import Menu from "../menu/menu";
import { Grid, Button } from "@material-ui/core";
import { getQuestionTopics } from "../../redux/actions/questionTopicsAction";
import {
  getQuestionChapters,
  getIlearnCourseVideo,
} from "../../redux/actions/questionChapterAction";

import AddNewQuestionChapter from "./AddNewQuestionChapter";
import EditNewQuestionChapter from "./EditNewChapter";

const NewChapter = ({
  getQuestionChapters,
  getIlearnCourseVideo,
  getchapters,
  questiontopics,
  getQuestionTopics,
}) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedChapter, setselectedChapter] = useState({});

  const topicsList = questiontopics?.gettopics?.map((item) => {
    const { name, id } = item;
    return {
      name,
      id,
    };
  });
  const iLearnVideos = getchapters?.coursesVideoLink?.courses?.map((item) => {
    return {
      id: item._id,
      name: item.title,
      videos: item.videos,
    };
  });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: { filter: false, sort: false, display: false },
    },
    {
      name: "topicName",
      label: "Topic Name",
      options: { filter: false, sort: true },
    },
    {
      name: "chapterName",
      label: "Chapter Name",
      options: { filter: false, sort: false },
    },
    {
      name: "ilearnCourseName",
      label: "Chapter Course",
      options: { filter: false, sort: false },
    },
    {
      name: "ilearnVideoName",
      label: "Chapter Video",
      options: { filter: false, sort: false },
    },
    {
      name: "action",
      label: "Assign Tests",
    },
  ];

  useEffect(() => {
    getQuestionChapters();
    getIlearnCourseVideo();
    getQuestionTopics();
  }, []);

  const getchapters1 = getchapters?.getchapters?.chapterData;
  console.log("getchapters1", getchapters1);

  const options = {
    filterType: "checkbox",
    serverSide: false,
    pagination: true,
    // searchText: true,
    download: false,
    selectableRows: "none",
    viewColumns: false,
    rowsPerPage: 6,
    selectableRowsOnClick: true,
    rowsPerPageOptions: [10],
    rowHover: true,
    search: true,
    print: false,
    filter: false,
    responsive: "scrollMaxHeight",
  };

  const data = getchapters1?.map((item) => {
    const { id, chapterName, IlearnCourse, ilearnVideo, topicName, topicId } =
      item;
    const chapterData = {
      _id: id,
      topic: { name: topicName, id: topicId },
      chapterName,
      IlearnCourse,
      ilearnVideo,
    };

    return {
      _id: id,
      topicName,
      chapterName,
      ilearnCourseName: IlearnCourse?.name,
      ilearnVideoName: ilearnVideo?.name,
      action: (
        <Button
          variant="contained"
          color="primary"
          type="submit"
          name="submit"
          onClick={() => {
            setOpenEdit(true);
            setselectedChapter(chapterData);
            // console.log("New Chapter ID", id);
          }}
        >
          Edit
        </Button>
      ),
    };
  });

  console.log("data", data);

  return (
    <>
      {/* <Menu /> */}
      <Grid container style={{ padding: "2%" }}>
        <Grid item xs={12} md={12} align="center">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            name="submit"
            onClick={() => {
              setOpen(true);
              // setScheduleId(_id)
            }}
          >
            Add Question Chapter
          </Button>
        </Grid>
        <Grid item xs={12} md={12} style={{ paddingTop: "2%" }}>
          <Tables
            title="Question Chapter"
            data={data}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      <CustomizedDialogs
        title="Add Question Chapter "
        children={
          <AddNewQuestionChapter
            iLearnVideos={iLearnVideos}
            topicsList={topicsList}
            setOpenPopup={setOpen}
          />
        }
        openPopup={open}
        setOpenPopup={setOpen}
      />
      <CustomizedDialogs
        title="Edit Question Chapter"
        children={
          <EditNewQuestionChapter
            selectedChapter={selectedChapter}
            iLearnVideos={iLearnVideos}
            topicsList={topicsList}
            setOpenPopup={setOpenEdit}
          />
        }
        openPopup={openEdit}
        setOpenPopup={setOpenEdit}
      />
    </>
  );
};

NewChapter.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  getchapters: state.getchapters,
  questiontopics: state.questiontopics,
});

const mapDispatchToProps = {
  getQuestionChapters,
  getQuestionTopics,
  getIlearnCourseVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewChapter);

// export default  NewChapter;
