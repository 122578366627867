import React, { Component } from "react";
import "./landing.css";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Logo from "../../images/logo/ibridgelogo.png";
import Logo1 from "../../images/logo/ibi.png"
import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import getintouch from "../../images/getintouch.png";
import { makeStyles } from "@material-ui/core/styles";



// const useStyles = makeStyles(theme => ({
//   mainViewContainer: {
//       margin:"0%",
//       maxWidth: "100%",
//       paddingLeft: "0%",
//       paddingTop:"0%",
//       paddingRight:"0%",
//       "& .MuiContainer-root": {
//           paddingLeft: "0%",
//       }, 
//       "& .MuiContainer-maxWidthLg": {
//           maxWidth: "100%",
//       }
//   }}))
  



class Landing extends Component {
  
  render() {
    
    return (
      <div>
        <nav className="navbar navbar-expand-sm fixed-top">
          <div className="container">
            {/* <Link className="navbar-brand"> */}
              <div
                className="d-flex justify-content-center logostyle"
                // style={{ paddingRight: "50px", marginLeft: "-20px" }}
              >
                <a href="https://www.ibridge360.com/">
                <img 
                src={Logo1} 
                style={{
                  width: "200px",
                  height: "55px",
                  objectFit: "cover",
                
                }}
                />
                </a>
              </div>
            {/* </Link> */}
            {/* <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">Menu</span>
            </button> */}
            <div
            // className="collapse navbar-collapse justify-content-end"
            // id="navbarNav"
            >
              {/* <ul> */}
              {/* <li className="nav-item">
              <Link className="nav-link btn btn-primary" to="/signup">Register </Link>
            </li> */}
              {/* <li> */}
              {/* <Link className=" ml-2 nav-link btn btn-primary" to="/login">
                    Login{" "}
                  </Link> */}

              <div className=" ml-auto">
                <Link
                  className="btn-2 btn btn-primary "
                  //  style={{ backgroundColor:"#003399"}}
                  to="/login"
                >
                  Login
                </Link>
              </div>
              {/* </li>
              </ul> */}
            </div>
          </div>
        </nav>
        <Container maxWidth="lg" className="mt-4 ">
          <Grid container>
            <section className="section-one">
              <div className="ibanner-section"></div>
              {/* <img src={landingimage} className="startImage" /> */}
              <div className="row">
                <div
                  className="col-sm-12 banner-content"
                  // style={{ paddingTop: "10px" }}
                >
                  <div className=" banner-cont mx-auto">
                    {/* <h6>Online Test</h6> */}
                    <p
                      className=" headingImageText"
                      //style={{ backgroundColor: "#003399"}}
                    >
                      Why Capability App?
                    </p>
                    <p
                      className="p-5 borderCSS"
                      varient="h2"
                      // style={{ backgroundColor: "#007bff", fontSize: "20px" }}
                    >
                      Every one of us need to know how good we are in the
                      subject we deal with. Most of the companies are struggling
                      with productivity issues, one of the way to correct the
                      issue is to make sure employees are good enough to do the
                      job. If you see the gap, invest in mentoring and
                      monitoring to make sure they become productive. Capability
                      application is the first step you should take towards the
                      goal of acceptable productivity in the organization. This
                      platform helps to achieve the same. Give it a try.
                    </p>
                    <div>
                      <br />
                    </div>
                    {/* <div className="row">
                      <div className="col-sm-4 line text-right">
                        <i className="fas fa-cart-plus fa-2x float-center cartIcon"></i>
                        <p className="float-center cartText">Domain</p>
                      </div>
                      <div className="col-sm-4 line text-center ">
                        <i className="fas fa-outdent fa-2x techIcon"></i>
                        <p className="techText" >Technology</p>
                      </div>

                      <div className="col-sm-4 text-left">
                        <i className="fas fa-users fa-2x processIcon"></i>
                        <p className="processText">Process</p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>

            <section className="section-two mt-4">
              {/* <h2 className="pt-3">Our Services</h2> */}
              {/* <div className="container py-4 colo"> */}
              <div className="row">
                <div className="col-sm-6">
                  <div className="section-two-1 text-center p-5 text-muted">
                    <p className="boxText">More than 1000 Questions in 4 different technologies</p>
                    {/* <h4>Wide Range Of Products</h4> */}
                    <p className="boxText">
                      RDBMS Concepts, Oracle SQL, Java Programming, SQL Server
                      SQL, Oracle PL/SQL, SQL Server TSQL, DW Concepts are some
                      of the subjects on which you can check your capability.
                    </p>
                    {/* <button>Learn More</button> */}
                  </div>
                </div>

                <div className="col-sm-6 box2" style={{ paddingRight: "15px" }}>
                  <div className="section-two-2 text-center p-5 text-muted">
                    <p className="boxText1">
                      We have Beginner, Intermediate and Expert level Questions.
                      Where do you stand from your perspective? If you think you
                      are an expert, choose expert level and check the skill
                      level.
                    </p>
                    {/* <h4>Wide Range Of Products</h4> */}
                    <p className="boxText1">
                      After finishing the test, resource can take a look at the
                      actual result and their own results. This allows resources
                      to see gaps and question their intuition or knowledge and
                      seek help in right places.
                    </p>
                    {/* <button>Learn More</button> */}
                  </div>
                </div>
                {/* </div> */}
              </div>
            </section>

            <div className="container mt-3">
              <div className="row">
                <div className="col-sm-3">
                  <img
                  className="imageStyle"
                    src={getintouch}
                    // style={{ height: "350px", marginLeft: "-50px" }}
                  />
                </div>
                <div className="col-sm-9">
                  <div className="row">
                    <div
                      className="col-sm-12 text-center"
                      // style={{ paddingTop: "30px", paddingBottom: "20px" }}
                    >
                      <h1
                        className="section-title"
                      >
                        Get In Touch
                      </h1>
                      {/* <div className="section-title-border margin-t-20"></div> */}
                      <div
                        className="section-subtitle "
                        // style={{
                        //   color: "#007bff",
                        //   paddingRight: "230px",
                        //   paddingBottom: "30px",
                        // }}
                      >
                        We thrive when coming up with innovative ideas but also
                        understand that a smart
                        <br />
                        concept should be supported with measurable results.
                      </div>
                    </div>
                    <div className="col-sm-5" style={{ paddingRight: "30px" }}>
                      <div className="col-sm-4 mt-4 text-center">
                        <div class="flip-card">
                          <div class="flip-card-inner">
                            <div class="flip-card-front">
                              <p>
                                Corporate Office: <br />
                                <b class="cname">iBridge360</b>
                              </p>{" "}
                              <br />
                            </div>
                            <div class="flip-card-back">
                              <p>
                                14, 5th Main, 38th Cross Rd, <br /> 5th Block,
                                Jayanagar, <br />
                                Bengaluru - 560041
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-7" style={{ paddingRight: "20px" }}>
                      <p className="col-sm-6 mt-4 text-center">
                        <div className="flip-card">
                          <div className="flip-card-inner">
                            <div className="flip-card-front">
                              <p>
                                Digital Services: <br />
                                <b className="cname">iBridge360</b>
                              </p>
                              <br />
                            </div>
                            <div className="flip-card-back">
                              <p>
                                #70, 2nd Floor, 45th Cross, <br />
                                8th Block, Jayanagar, <br /> Bengaluru - 560082
                              </p>
                            </div>
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-copyright text-center pt-3">
              Copyright ©2021 iBridge360.
            </div>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default Landing;
