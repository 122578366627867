import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";

import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";

import {
  getTechnologyList,
  getDomainList,
} from "../../../redux/actions/productivityActions/domainAction";
import { addFunctionData } from "../../../redux/actions/productivityActions/functionAction";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const AddNewFunction = ({ setOpenPopup }) => {
  const classes = useStyles();

  const { auth, domainReducer } = useSelector((state) => state);
  const [domainList, setDomainList] = useState([]);
  const dispatch = useDispatch();

  const technologyList = domainReducer?.getTechnologyList?.map((item) => {
    return {
      _id: item._id,
      name: item.name,
    };
  });

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getTechnologyList());
      dispatch(getDomainList());
    }
  }, [auth.isAuthenticated]);

  const handleTechnology = (technology) => {
    formik.setFieldValue("selectTechnology", technology);
    const filteredList = domainReducer?.getDomainList?.filter(
      (item) => item?.technology?._id === technology?._id?.toString()
    );
    setDomainList(filteredList);
  };

  //   console.log("domainReducer in function",domainReducer)

  const formik = useFormik({
    initialValues: {
      selectTechnology: {},
      selectDomain: {},
      functionName: "",
      functionBusinessValue: "",
      functionBusinessSize: "",
      functionCode: "",
      functionDescription: "",
    },
    validationSchema: Yup.object({
      // scheduleDescription: Yup.string()
      //   .trim()
      //   .required("Schedule Description is required field"),
      selectTechnology: Yup.object()
        .nullable()
        .required("Technology is reqired field"),
      selectDomain: Yup.object().nullable().required("Domain is reqired field"),
      functionName: Yup.string()
        .min(5)
        .max(25)
        .trim()
        .required("Function Name is required field"),
      functionBusinessValue: Yup.number()
        .min(1)
        .required("Function Business Value is required field")
        .typeError('Bussiness value be a number'),
      functionBusinessSize: Yup.number()
        .min(1)
        .required("Function Business Size is required field")
        .typeError('Bussiness Size should be a number'),
      functionCode: Yup.string()
        .trim()
        .min(2)
        .max(4)
        .required("Function Code is required field"),
      functionDescription: Yup.string()
        .trim()
        .required("Function Description is required field"),
    }),

    onSubmit: async (values) => {
      // console.log("submit value", values);
      const data = {
        technology: values?.selectTechnology?._id,
        domain: values?.selectDomain?._id,
        name: values?.functionName,
        functionCode: values?.functionCode,
        functionBusinessValue: values?.functionBusinessValue,
        functionBusinessSize: values?.functionBusinessSize,
        functionDescription: values?.functionDescription,
      };
      dispatch(addFunctionData(data));
      // console.log("submit data",data)
      setOpenPopup(false);
    },
  });

  const handleReset = () => {
    formik.resetForm();
  }

  const renderForm = (
    <form
      // onSubmit={()=>alert("am in form Submit")}
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Technology"
            name="selectTechnology"
            values={technologyList || []}
            onSelect={(technology) => handleTechnology(technology)}
            selected={formik.values.selectTechnology}
            errors={
              formik.touched.selectTechnology && formik.errors.selectTechnology
                ? formik.errors.selectTechnology
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            variant="outlined"
            label="Select Domain"
            name="selectDomain"
            values={domainList || {}}
            onSelect={(domain) => formik.setFieldValue("selectDomain", domain)}
            selected={formik.values.selectDomain}
            errors={
              formik.touched.selectDomain && formik.errors.selectDomain
                ? formik.errors.selectDomain
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Function Name"
            name="functionName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.functionName}
            errors={
              formik.touched.functionName && formik.errors.functionName
                ? formik.errors.functionName
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Function Business Value"
            name="functionBusinessValue"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.functionBusinessValue}
            errors={
              formik.touched.functionBusinessValue &&
                formik.errors.functionBusinessValue
                ? formik.errors.functionBusinessValue
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Function Business Size"
            name="functionBusinessSize"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.functionBusinessSize}
            errors={
              formik.touched.functionBusinessSize &&
                formik.errors.functionBusinessSize
                ? formik.errors.functionBusinessSize
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Function Code"
            name="functionCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.functionCode}
            errors={
              formik.touched.functionCode && formik.errors.functionCode
                ? formik.errors.functionCode
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextFieldGroup
            variant="outlined"
            label="Function Description"
            name="functionDescription"
            multiline="multiline"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={4}
            value={formik.values.functionDescription}
            errors={
              formik.touched.functionDescription &&
                formik.errors.functionDescription
                ? formik.errors.functionDescription
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6} align="right">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="submit"
              name="save"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
            // onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </Grid>

        <Grid item xs={12} md={6} align="left">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="reset"
              name="reset"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

export default AddNewFunction;
