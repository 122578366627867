import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Grid } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";

import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";

const MultipleChoiceQuestions = ({
  optionValues,
  checkedStates,
  handleCheckbox,
  handleMultiOptionValues,
}) => {
  return (
    <>
      <FormControl fullWidth>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          style={{ marginLeft: "20px" }}
        >
          <FormGroup>
            <FormControlLabel
              value={optionValues?.option1}
              control={
                <Checkbox
                  name="option1"
                  value={optionValues?.option1}
                  checked={checkedStates?.option1}
                  onChange={(e) => handleCheckbox(e)}
                />
              }
              label={
                <TextField
                  name="option1"
                  variant="filled"
                  value={optionValues?.option1 || ""}
                  style={{ width: "50vw" }}
                  onChange={(e) => handleMultiOptionValues(e)}
                  size="small"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              }
            />
            <FormControlLabel
              value={optionValues?.option2}
              control={
                <Checkbox
                  name="option2"
                  value={optionValues?.option2}
                  checked={checkedStates?.option2 || ""}
                  onChange={(e) => handleCheckbox(e)}
                />
              }
              label={
                <TextField
                  name="option2"
                  variant="filled"
                  style={{ width: "50vw" }}
                  value={optionValues?.option2 || ""}
                  onChange={(e) => handleMultiOptionValues(e)}
                  size="small"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              }
            />
            <FormControlLabel
              value={optionValues?.option3}
              control={
                <Checkbox
                  name="option3"
                  value={optionValues?.option3}
                  checked={checkedStates?.option3 || ""}
                  onChange={(e) => handleCheckbox(e)}
                />
              }
              label={
                <TextField
                  name="option3"
                  variant="filled"
                  style={{ width: "50vw" }}
                  value={optionValues?.option3 || ""}
                  onChange={(e) => handleMultiOptionValues(e)}
                  size="small"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              }
            />
            <FormControlLabel
              value={optionValues?.option4}
              control={
                <Checkbox
                  name="option4"
                  value={optionValues?.option4}
                  checked={checkedStates?.option4 || ""}
                  onChange={(e) => handleCheckbox(e)}
                />
              }
              label={
                <TextField
                  name="option4"
                  variant="filled"
                  style={{ width: "50vw" }}
                  value={optionValues?.option4 || ""}
                  onChange={(e) => handleMultiOptionValues(e)}
                  size="small"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              }
            />
          </FormGroup>
        </Grid>
      </FormControl>
    </>
  );
};

export default MultipleChoiceQuestions;
