import {
  GET_FUNCTION_LIST,
  GET_FUNCTION_INFO,
  GET_ENABLED_FUNCTION_LIST,
} from "../type";
import axios from "axios";
import { API_URL } from "../../../Api_Request";
import Swal from "sweetalert2";

export const getFunctionList = () => (dispatch) => {
  axios
    .get(`${API_URL}/function/getAllFunctions`)
    .then((res) => {
      dispatch({
        type: GET_FUNCTION_LIST,
        payload: res.data?.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getEnabledFunctionList = () => (dispatch) => {
  axios
    .get(`${API_URL}/function/getEnabledFunctions`)
    .then((res) => {
      dispatch({
        type: GET_ENABLED_FUNCTION_LIST,
        payload: res.data?.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getFunctionInfo = (id) => (dispatch) => {
  axios
    .get(`${API_URL}/function/getFunctionInfo/${id}`)
    .then((res) => {
      dispatch({
        type: GET_FUNCTION_INFO,
        payload: res.data?.data,
      });
    })
    .catch((err) => console.log(err));
};

export const addFunctionData = (params, history) => (dispatch) => {
  axios
    .post(`${API_URL}/function/addFunction`, params)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: res?.data?.message || "Function added successfully",
      });
      dispatch(getFunctionList());
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const editfunction = (data) => (dispatch) => {
  axios
    .put(`${API_URL}/function/updateFunction`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: res.data.message,
      });
      dispatch(getFunctionList());
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};
