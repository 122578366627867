import React,{Component} from 'react';
import Menu from '../users/usermenu/usermenu';
import axios from 'axios';
import { API_URL } from '../../Api_Request';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

class Success extends Component {
    constructor(props) {
        super(props);
        this.state = {
          user : {}
        }
      }

    render() { 
      const {user} = this.props.auth;
        if(user && user.role === "admin" || user && user.role === "mentor"){
            return <Redirect to='/login' />
          }
          else{
        return ( 
            <div>
                <Menu/>
                <span className="mt-5"></span>
                <div className="mt-5"></div>
                <center><h1 className="mt-5 text-danger">Payment Success</h1></center>
            </div>
         );
        }
    }
}
 
const mapStateToProps = state => ({
  auth : state.auth,
})


export default connect(mapStateToProps,)(Success);