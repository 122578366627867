import React, { Component } from "react";
import axios from "axios";
// import Menu from '../users/usermenu/usermenu';
import { Redirect, Link } from "react-router-dom";
// import { API_URL } from '../../../Api_Request';
import Swal from "sweetalert2";
// import '../users/userdashboard/userdashboard.css';
import { connect } from "react-redux";
import { getQuestioncomplexity } from "../../../redux/actions/questionComplexityAction";
import { getQuestionTopics } from "../../../redux/actions/questionTopicsAction";
import { predefinedtestquestions } from "../../../redux/actions/selectTestAction";

class Rules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      paperId: "",
      userId: "",
      topicName: "",
      skill: "",
      testId: "",
      topicData: [],
      complexData: [],
      userData: [],
      userName: "",
      date: new Date()
        .toISOString()
        .replace(/T/, " ")
        .replace(/\..+/, "")
        .slice(0, 10),
    };
  }

  componentDidMount() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      return this.props.history.push("/login");
    } else {
      this.props.getQuestioncomplexity();
      this.props.getQuestionTopics();
      const params = {
        paperId: this.props.location.state.paperId,
      };
      this.props.predefinedtestquestions(params);
      this.setState({
        userName: this.props.location.state.uname,
        paperId: this.props.location.state.paperId,
        userId: this.props.location.state.userId,
        topicName: this.props.location.state.topicName,
        skill: this.props.location.state.skill,
        testId: this.props.location.state.testId,
        interview: this.props.location.state.interview || false,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let { getquestioncomplexity } = nextProps.questioncomplexity;
    if (getquestioncomplexity) {
      this.setState({
        complexData: getquestioncomplexity,
      });
    }
    let { gettopics } = nextProps.questiontopics;
    if (gettopics) {
      this.setState({
        topicData: gettopics,
      });
    }
  }

  //redirecting to test component
  handleStart = (e) => {
    let topicid = 0;
    this.state.topicData.map((topic) => {
      if (this.state.topicName === topic.name) {
        topicid = topic.id;
      }
    });
    let skillid = 0;
    this.state.complexData.map((skill) => {
      if (this.state.skill === skill.name) {
        skillid = skill.id;
      }
    });
    this.props.history.push({
      pathname: "/menu/test",
      state: {
        userId: this.state.userId,
        userName: this.state.userName,
        topicId: topicid,
        skillId: skillid,
        testid: this.state.testId,
        topic: this.state.topicName,
        skill: this.state.skill,
        paperId: this.state.paperId,
        interview: this.state.interview,
      },
    });
  };

  handleCancel = (e) => {
    this.props.history.push("/menu/testsassigned");
  };

  render() {
    const { user, isAuthenticated } = this.props.auth;
    if ((user && user.role === "admin") || (user && user.role === "mentor")) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div className="container mt-5">
          <span className="mt-5"></span>
          <div
            id="details"
            className="row bg-white shadow-sm mt-5 font-weight-bold"
          >
            <div className="col-sm-6 p-3">
              <div>
                <label className="mr-1">Name : </label>
                <span className="text-uppercase">{this.state.userName}</span>
              </div>
              <div>
                <label className="mr-1">Topic Name : </label>
                {this.state.topicName}
              </div>
              <div>
                <label className="mr-1">Date : </label>
                {this.state.date}
              </div>
            </div>
            <div className="col-sm-6 p-3">
              <div>
                <label className="mr-1">Test ID : </label>
                {this.state.testId}
              </div>
              <div>
                <label className="mr-1">Skill Level : </label>
                {this.state.skill}
              </div>
            </div>
            <div className="col-sm-12 p-3 text-center">
              <p className="text-light bg-dark font-weight-bold">Test rules</p>
              <p>Test will contain 20 questions with two sections</p>
              <p>1st Section will be single choiced questions</p>
              <p>2nd Section will be multiple choiced questions</p>
              <p>
                You can skip questions, which will be considered as not
                attempted
              </p>
              <p>You can not go back once moved to another question</p>
              <p>Results will apear instantly once the test is completed</p>
              <div className="mt-5 text-center">
                <button
                  className="btn btn-primary"
                  onClick={(e) => this.handleStart(e)}
                  type="submit"
                >
                  Start Test
                </button>
                <button
                  className="btn btn-danger ml-3"
                  onClick={(e) => this.handleCancel(e)}
                  type="submit"
                >
                  Cancel Test
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  getQuestioncomplexity,
  getQuestionTopics,
  predefinedtestquestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Rules);
