import { SET_HINT_DATA, GET_CODE_HINT, CLEAR_HINT_DATA} from "../type";
import axios from 'axios'

export const getCodeHint = (data) => (dispatch) => {
    axios.post("/api/openai//getCodeHint", data)
    .then((res)=>{
        // console.log(1)
        // console.log(res.data.hints)
        dispatch({type: GET_CODE_HINT, payload: res.data.hints})})
    .catch((error)=>{
        console.log("Try again after some time")
    })
    }


export const setHintData = (data) => (dispatch)=>{
    dispatch({type: SET_HINT_DATA, payload: data})
}

export const clearHintData = () => (dispatch) => {
    dispatch({type: CLEAR_HINT_DATA, payload: ""})
}