import React from 'react';
import Swal from 'sweetalert2'
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { postassigntest, assignuser } from '../../redux/actions/questionPapersAction';

const defaultToolbarSelectStyles = {
  iconButton: {},
  iconContainer: {
    marginRight: '24px',
  },
  inverseIcon: {
    transform: 'rotate(90deg)',
  },
};

class AssignToolbarSelect extends React.Component {
  state = {
    dataid: [],
    userId: '',
  };


  handleClickBlockSelected = (e) => {
    const Toast = Swal.mixin({toast: true,position: 'center',showConfirmButton: true,})
    const {paperName, topicId, skillId, selectedRows, displayData, setSelectedRows, paperId} = this.props
    let data = []
    let userids = []
    this.props.selectedRows.data.map(row => {
      this.props.displayData.map(item => {
        if(row.dataIndex === item.dataIndex){
            data.push(item.data)
        }
      })
    })
    data.map(item => {
        userids.push(item[0])
    })
    console.log("hehe",userids)
    if(topicId === "" || topicId === undefined || skillId === "" || skillId === undefined || paperName === "" || paperName === undefined || paperId === "" || paperId === undefined){
        Toast.fire({icon: 'error',title: 'Something went worng !!!'})
        return this.props.history.push('/menu/questionpapers')
    }
    if(this.props.topicId.length <= 2 && this.props.skillId.length <= 2){
        const usertest ={
            userId: userids,
            topicId: topicId,
            skillId: skillId,
            paperName: paperName,
            paperId : paperId,
            date : new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
          }
        //   console.log("sin",usertest)
          this.props.postassigntest(usertest)
          this.props.assignuser(paperId)
    }else if(this.props.topicId.length > 2 || this.props.skillId.length > 2){
        const usertest ={
            userId: userids,
            topicId: 0,
            skillId: 0,
            paperName: paperName,
            paperId : paperId,
            date : new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
          }
        //   console.log("con",usertest)
          this.props.postassigntest(usertest)
          this.props.assignuser(paperId)
    }
  };



  render () {
    const {classes} = this.props;

    return (
      <button onClick={(e) => this.handleClickBlockSelected(e)}
      className="btn tbn-primary mx-auto" 
      style={{backgroundColor:"blue",color:"white"}}>Assign Test</button> 
    );
  }
}

const mapStateToProps = state => ({
  auth : state.auth,
  questionpapers : state.questionpapers
})

const mapDispatchToProps = {
    postassigntest,
    assignuser
}

export default (withRouter (connect(mapStateToProps,mapDispatchToProps)(withStyles (defaultToolbarSelectStyles, {name: 'AssignToolbarSelect'}) (AssignToolbarSelect))));
