import React, { useEffect } from "react";
import * as d3 from "d3";
import { capitalize } from "@material-ui/core";

const QuadrantChart = ({ quadrantData }) => {
  useEffect(() => {
    if (quadrantData) {
      let d = document.getElementById("quadrantchart");
      d.innerHTML = "";
      quadrantPlot(quadrantData);
    }
    return () => d3.selectAll("svg").select("#quadrantchart").remove();
  }, [quadrantData]);

  const quadrantPlot = (data) => {
    d3.select("svg").remove();
    // set the dimensions and margins of the graph
    var margin = { top: 10, right: 30, bottom: 0, left: 50 },
      width = 1000 - margin.left - margin.right,
      height = 450 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3
      .select("#quadrantchart")
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 1000 550")
      // .attr("width", width + margin.left + margin.right)
      // .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Add the grey background that makes ggplot2 famous
    svg
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("height", height)
      .attr("width", width)
      .style("fill", "#EBEBEB")
      .style("stroke", "gray")
      .style("stroke-width", "2px");

    svg
      .append("line")
      .attr("x1", 0)
      .attr("y1", height / 2)
      .attr("x2", width)
      .attr("y2", height / 2)
      .style("fill", "gray")
      .style("stroke", "gray")
      .style("stroke-width", "1px");

    svg
      .append("line")
      .attr("x1", width / 2)
      .attr("y1", 0)
      .attr("x2", width / 2)
      .attr("y2", height)
      .style("fill", "gray")
      .style("stroke", "gray")
      .style("stroke-width", "1px");

    //Read the data
    // d3.csv("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/iris.csv", function(data) {

    // Add X axis
    var x = d3
      .scaleLinear()
      .domain(
        d3.extent(data, function (d) {
          return d.Total_Marks;
        })
      )
      .range([0, width]);

    //console.log()

    var y = d3
      .scaleLinear()
      .domain(
        d3.extent(data, function (d) {
          return d.Correct_Answers;
        })
      )
      .range([height, 0])
      .nice();

    var xaxis = svg
      .append("g")
      .attr("transform", "translate(0," + y.range()[0] / 2 + ")")
      // .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).ticks(30))
      // .select(".domain").remove()
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)");
    // .style("font-weight","bold")

    //  xaxis.selectAll(".domain").remove()

    // Add Y axis
    var div = d3
      .select("#quadrantchart")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    svg
      .append("g")
      .attr("transform", "translate(" + x.range()[1] / 2 + ", 0)")
      .call(d3.axisLeft(y).ticks(20));
    //.selectAll(".domain").remove()

    // Customization
    svg.selectAll(".tick line ").attr("stroke-width", "1");
    //   svg.selectAll(".tick text").attr("fill","red").attr("font-weight","bold")
    svg.selectAll(".domain").remove();

    // Color scale: give me a specie name, I return a color
    var color = d3.scaleOrdinal().domain(["setosa", "versicolor", "virginica"]);
    // .range([ "#F8766D", "#00BA38", "#619CFF"])

    // Add dots
    svg
      .append("g")
      .selectAll("dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return x(d.Total_Marks);
      })
      .attr("cy", function (d) {
        return y(d.Correct_Answers);
      })
      .attr("r", 5)
      .style("fill", function (d) {
        return d.avg <= 40
          ? "red"
          : d.avg > 40 && d.avg <= 60
          ? "orange"
          : "green";
      })
      .on("mouseover", function (d) {
        div.transition().duration(200).style("opacity", 0.9);
        div
          .html(
            "Name: " +
              d.First_Name +
              "<br/>" +
              "Total Mark: " +
              d.Total_Marks +
              "<br/>" +
              "Correct Answers: " +
              d.Correct_Answers +
              "<br/>" +
              "Percentage: " +
              d3.format(".3n")(d.avg) +
              "%" +
              "<br/>" +
              "Topic: " +
              d.topicName +
              "<br/>" +
              "Skill: " +
              d.skillLevel
          )
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("mouseout", function (d) {
        div.transition().duration(500).style("opacity", 0);
      });
  };

  return <div id="quadrantchart"></div>;
};

export default QuadrantChart;
