import React, { useEffect } from "react";
import { Link, Redirect,useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Tables from "../reusableComponents/MaterialTable";
import { getUserSubGroupList } from "../../redux/actions/customerActions";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar } from "@material-ui/core";
import Table from "../reusableComponents/MaterialTable";
import ButtonField from "../reusableComponents/ButtonField";
import { getCustomerList } from "../../redux/actions/customerActions";
import {
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

// const SubGroups = ({ getUserSubGroupList, auth, history, customer }) => {
//   const { user } = auth;
//   const { userSubGroupList } = customer;
//   let { id } = useParams();

//   useEffect(() => {
//     getUserSubGroupList(id);
//     document.title = "Customer List - Capability App";
//   }, []);

//   useEffect(() => {
//     console.log(user);
//     if (
//       user &&
//       user.role !== "Super Admin" &&
//       user &&
//       user.role !== "Customer Admin"
//     ) {
//       history.push("/login");
//     }
//   }, [auth]);

//   //columns
//   const columns = [
//     { field: "user_group_id", title: "User Group Id", hidden: true },
//     { field: "user_group_name", title: "Sub Group Name" },
//     {
//       title: "Action",
//       field: "details",
//       render: (rowData) => (
//         // <Link to={`/editprofile/${rowData.id}`}>{rowData.details}</Link>
//         <Link to={`/admin/grouplist/${rowData.user_group_id}`}>
//           {rowData.details}
//         </Link>
//       ),
//     },
//   ];

//   const options = {
//     headerStyle: {
//       backgroundColor: "#003399",
//       color: "#FFF",
//     },
//     scrollable: true,
//     pageSize: 7,
//   };

//   const data =
//     userSubGroupList &&
//     userSubGroupList.map((item) => {
//       return {
//         user_group_id: item.user_group_id,
//         user_group_name: item.user_group_name,
//         details: "View Users",
//       };
//     });

//   const handleCustomer = () => {
//     history.push("/admin/addusergroup");
//   };

//   return (
//     <div>
//       {/* <Menu /> */}
//       <div>
//         <div class="text-center">
//           {(user && user.role === "Customer Admin") ||
//           user?.role === "Super Admin" ? (
//             <button
//               type="button"
//               id="btn"
//               value="add new user"
//               className="btn btn-primary"
//               style={{backgroundColor:"#003399",color:"whitesmoke"}}
//               onClick={handleCustomer}
//             >
//               Add New User-Group
//             </button>
//           ) : null}
//         </div>
//         <div className="mutable">
//           {data && data ? (
//             <Tables
//               title={
//                 <>
//                   <span
//                     style={{
//                       color: "#003399",
//                       fontSize: "1.25rem",
//                       fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
//                       fontWeight: "bold",
//                       lineHeight: "1.6",
//                       margin: "0 30px",
//                       letterSpacing: "0.0075em",
//                     }}
//                   >
//                     User Group List
//                   </span>
//                   {/* <button
//                     className="btn btn-primary"
//                     onClick={() => history.push("/menu/customerlist")}
//                   >
//                     Back
//                   </button> */}
//                 </>
//               }
//               data={data}
//               columns={columns}
//               options={options}
//             />
//           ) : (
//             <div className="image-container">
//               <p className="image-holder">
//                 <img src={loader} alt="" />
//               </p>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };


const SubGroups = ({ getUserSubGroupList, auth, history, customer }) => {
  const { user } = auth;
  const { userSubGroupList } = customer;
  let { id } = useParams();

  useEffect(() => {
    getUserSubGroupList(id);
    document.title = "Customer List - Capability App";
  }, []);

  useEffect(() => {
    console.log(user);
    if (
      user &&
      user.role !== "Super Admin" &&
      user &&
      user.role !== "Customer Admin"
    ) {
      history.push("/login");
    }
  }, [auth]);

  //columns
  const columns = [
    { field: "user_group_id", title: "User Group Id", hidden: true },
    { field: "user_group_name", title: "Sub Group Name" },
    {
      title: "Action",
      field: "details",
      render: (rowData) => (
        // <Link to={`/editprofile/${rowData.id}`}>{rowData.details}</Link>
        <Link to={`/admin/grouplist/${rowData.user_group_id}`}>
          {rowData.details}
        </Link>
      ),
    },
  ];

  const options = {
    headerStyle: {
      backgroundColor: "#003399",
      color: "#FFF",
    },
    scrollable: true,
    pageSize: 7,
  };

  const data =
    userSubGroupList &&
    userSubGroupList.map((item) => {
      return {
        user_group_id: item.user_group_id,
        user_group_name: item.user_group_name,
        details: "View Users",
      };
    });

  const handleCustomer = () => {
    history.push("/admin/addusergroup");
  };

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            title={
              <ButtonField
                variant="outlined"
                name="Add New UserGroup"
                icon={<AddIcon />}
                onClick={() => handleCustomer()}
              />
            }
            columns={columns}
            data={data}
            pageSize={10}
            // selectedRow={(row) => history.push(`/admin/coach/${row._id}`)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

SubGroups.propTypes = {
  getUserSubGroupList: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  customer: state.customer,
});

const mapDispatchToProps = { getUserSubGroupList };

export default connect(mapStateToProps, mapDispatchToProps)(SubGroups);
