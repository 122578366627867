import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Avatar, Paper } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { getTestData } from "../../../redux/actions/productivityActions/testAction";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import "./ViewProductivityResult.css";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0, 0),
    padding: theme.spacing(0),
  },
}));

const theme = createTheme({
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: [16, "!important"],
      },
    },
  },
});

const ViewProductivitytest = ({ location }) => {
  const { testId } = useParams();
  const classes = useStyles();
  const { auth, productivityTest } = useSelector((state) => state);
  const { isAuthenticated } = auth;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTestData(location?.state));
  }, [isAuthenticated]);

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Card sx={{ minWidth: 1000 }} variant="outlined">
          <CardContent>
            <ThemeProvider theme={theme}>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography variant="h6 ">
                    <b>Name:</b>
                    {`${
                      productivityTest?.testData &&
                      productivityTest?.testData[0]?.user?.first_name
                    } ${
                      (productivityTest?.testData &&
                        productivityTest?.testData[0]?.user?.last_name) ||
                      ""
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography></Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6 ">
                    <b>Date:</b>
                    {new Date(
                      productivityTest?.testData &&
                        productivityTest?.testData[0]?.test?.createdAt
                    ).toDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography gutterBottom variant="h6  ">
                    <b>Domain:</b>{" "}
                    {(productivityTest?.testData &&
                      productivityTest?.testData[0]?.test?.domain?.name) ||
                      location?.state?.domainName ||
                      ""}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography gutterBottom variant="h6  ">
                    <b>Function:</b>
                    {(productivityTest?.testData &&
                      productivityTest?.testData[0]?.test?.function?.name) ||
                      location?.state?.functionName ||
                      ""}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography gutterBottom variant="h6  ">
                    <b>Scenario:</b>
                    {(productivityTest?.testData &&
                      productivityTest?.testData[0]?.test?.scenario?.name) ||
                      location?.state?.scenarioName ||
                      ""}
                  </Typography>
                </Grid>
              </Grid>
            </ThemeProvider>
          </CardContent>
        </Card>
      </Grid>
      <br />
      <br />
      <Grid container spacing={2}>
        <Card sx={{ minWidth: 1000 }} variant="outlined">
          <CardContent>
            <Grid container spacing={3}>
              {productivityTest?.testData?.map((item, idx) => {
                return (
                  <>
                    <Grid item xs={12} md={12}>
                      <Paper className={classes.pageContent} elevation={6}>
                        <Box p={3}>
                          <Grid item xs={12}>
                            <Typography component="div" variant="h6">
                              <b>Question: </b>{" "}
                              {item?.question?.questionDescription}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography component="div" variant="h6">
                              <b>Answer:</b>
                              <pre className="prgm">
                                <code>{item?.query}</code>
                              </pre>
                            </Typography>
                          </Grid>
                          {item?.exceptionString && (
                            <Grid item xs={12}>
                              <Typography
                                component="div"
                                variant="h6"
                                style={{ color: "red" }}
                              >
                                <pre className="error">
                                  {" "}
                                  <b>Error:</b> {item?.exceptionString}
                                </pre>
                              </Typography>
                            </Grid>
                          )}
                          {productivityTest?.testData[0]?.test?.technology
                            ?.name === "SQL" ||
                          productivityTest?.testData[0]?.test?.technology
                            ?.name === "MYSQL" ||
                          productivityTest?.testData[0]?.test?.technology
                            ?.name === "RDBMS" ? (
                            item?.resultString && (
                              <Grid item xs={12}>
                                <Typography component="div" variant="h6">
                                  <b>Result:</b>
                                  <div
                                    className={`${classes.pageContent} text-center`}
                                    elevation={6}
                                  >
                                    <table className="text-center">
                                      {JSON.parse(item?.resultString)?.length >
                                        0 &&
                                        Object.keys(
                                          JSON.parse(item?.resultString)?.[0]
                                        )?.map((key, index) => {
                                          return (
                                            <th
                                              key={index}
                                              style={{
                                                border: "1px solid #000000",
                                                "text-align": "center",
                                                padding: "8px",
                                              }}
                                            >
                                              {key.toUpperCase()}
                                            </th>
                                          );
                                        })}
                                      {JSON.parse(item?.resultString)?.length >
                                        0 &&
                                        JSON.parse(item?.resultString)?.map(
                                          (res, index) => {
                                            return (
                                              <tr>
                                                {JSON.parse(item?.resultString)
                                                  ?.length > 0 &&
                                                  Object.keys(
                                                    JSON.parse(
                                                      item?.resultString
                                                    )?.[0]
                                                  )?.map((k, i) => {
                                                    return (
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid #000000",
                                                        }}
                                                      >
                                                        {res[k]}
                                                      </td>
                                                    );
                                                  })}
                                              </tr>
                                            );
                                          }
                                        )}
                                      {/* { JSON.parse(item?.resultString)?.} */}
                                    </table>
                                  </div>
                                </Typography>
                              </Grid>
                            )
                          ) : item?.exceptionString ? null : (
                            <Grid item xs={12}>
                              <Typography component="div" variant="h6">
                                <b>Result:</b>
                                <div
                                  className={`${classes.pageContent} text-center`}
                                  elevation={6}
                                >
                                  {productivityTest?.testData?.technology?.name
                                    .toUpperCase()
                                    .includes("HTML") ? (
                                    <div className="pane">
                                      <iframe
                                        srcDoc={item?.query}
                                        title="output"
                                        sandbox="allow-scripts"
                                        frameBorder="0"
                                        width="100%"
                                        height="100%"
                                      />
                                    </div>
                                  ) : null}
                                  <pre className="result">
                                    <code className="result">
                                      <b> {item?.resultString}</b>
                                    </code>
                                  </pre>
                                </div>{" "}
                              </Typography>
                            </Grid>
                          )}
                        </Box>
                      </Paper>{" "}
                    </Grid>
                  </>
                );
              }) || <Typography>NO Data Found</Typography>}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
};

export default ViewProductivitytest;
