

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';



const useStyles = makeStyles(theme => ({
    imageView: {
        // display: 'flex',
        // justifyContent: 'center'
    },
    profileImageUploadFile: {
        display: 'none'
    },
    largeAvatar: {
        // cursor: 'pointer',
        textTransform: 'capitalize',
        fontSize: 20,
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
}));

const AvatarReusable = ({
    imageName,
    imageUrl,
    onChange,
    disabled
}) => {

    const classes = useStyles();

    return (
        <div className={classes.imageView} >
        
                <Avatar 
                    alt={imageName}
                    src={imageUrl}
                    className={classes.largeAvatar}
                />

           
        </div>
    );
};

export default AvatarReusable ;