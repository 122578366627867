import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button, Paper, Container } from "@material-ui/core";
import { GroupAdd as GroupAddIcon, YouTube } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { editQuestionChapter } from "../../redux/actions/questionChapterAction";

import * as Yup from "yup";

// import { getQuestionChapters } from "../../redux/actions/questionChapterAction";

import TextFieldGroup from "../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../reusableComponents/SelectSingleValues";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const EditNewQuestionChapter = ({
  selectedChapter,
  topicsList,
  iLearnVideos,
  editQuestionChapter,
  setOpenPopup,
}) => {
  const classes = useStyles();

  const [courseVideoLink, setCourseVideoLink] = useState();

  const setVideosLink = (batch) => {

    formik.setFieldValue(
      "selectedCourseVideo", null
    );
    const batchVideoLink = batch?.videos?.map((item) => {
      return {
        id: item._id,
        name: item.title,
      };
    });
    setCourseVideoLink(batchVideoLink);
  };

  useEffect(() => {
    // console.log(selectedChapter, topicsList, iLearnVideos);
  }, [selectedChapter]);

  // console.log("vishwas iLearnVideos", iLearnVideos)

  const formik = useFormik({
    initialValues: {
      chapterName: "",
      selectCapabilityTopic: "",
      selectIlearnCourse: "",
      selectedCourseVideo: "",
    },
    validationSchema: Yup.object({
      chapterName: Yup.string()
        .trim()
        .required("Schedule Title is required field"),
      // scheduleDescription: Yup.string()
      //   .trim()
      //   .required("Schedule Description is required field"),
      selectCapabilityTopic: Yup.object()
        .nullable()
        .required("Program Duration is reqired field"),
      selectIlearnCourse: Yup.object()
        .nullable()
        .required("Program Duration is reqired field"),
      selectedCourseVideo: Yup.object()
        .nullable()
        .required("Program Duration is reqired field"),
    }),

    onSubmit: async (values) => {
      // console.log("submit value", values);
      const data = {
        id:selectedChapter?._id,
        chapterName: values?.chapterName,
        iLearnCourse: values?.selectIlearnCourse?.id || values?.selectIlearnCourse?._id,
        iLearnVideo: values?.selectedCourseVideo?.id,
        topicId: values?.selectCapabilityTopic?.id,
      };
      editQuestionChapter(data);
      // console.log(data)
      setOpenPopup(false);
    },
  });

  useEffect(() => {
    const selectedCourseVideos = iLearnVideos?.find((item) => item.id === selectedChapter.IlearnCourse._id )
    // console.log("selectedCourseVideos123",selectedCourseVideos, selectedChapter?.IlearnCourse)
    setVideosLink(selectedCourseVideos)

    if (selectedChapter) {
      formik.setFieldValue(
        "chapterName",
        selectedChapter && selectedChapter.chapterName? selectedChapter.chapterName: ""
      );
      formik.setFieldValue(
        "selectCapabilityTopic",
        selectedChapter && selectedChapter.topic? selectedChapter.topic : {}
      );
      // formik.setFieldValue(
      //   "selectIlearnCourse",
      //   selectedChapter && selectedChapter.IlearnCourse? selectedChapter.IlearnCourse : {}
      // );
      // formik.setFieldValue(
      //   "selectedCourseVideo",
      //   selectedChapter && selectedChapter.ilearnVideo? selectedChapter.ilearnVideo : {}
      // );
    }
  }, []);


  const taskTypeList = [
    { name: "Zoom / Face to Face session" },
    { name: "iLearn Session" },
    { name: "iCapability Session" },
    { name: "iProductivity Session" },
    { name: "Lab Session" },
    { name: "Project Session" },
    { name: "Other" },
  ];

  const taskTypeList1 = [{ name: "1" }, { name: "2" }, { name: "3" }];

  const taskTypeList2 = [{ name: "1" }, { name: "2" }, { name: "3" }];

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextFieldGroup
            label="Chapter Name"
            name="chapterName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.chapterName}
            // {formik.values.chapterName}
            errors={
              formik.touched.chapterName && formik.errors.chapterName
                ? formik.errors.chapterName
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Capability Topic"
            name="selectCapabilityTopic"
            values={topicsList || []}
            onSelect={(batch) =>
              formik.setFieldValue("selectCapabilityTopic", batch)
            }
            // selected={{name:"Vishwas"}}
            selected={formik.values.selectCapabilityTopic}
            errors={
              formik.touched.selectCapabilityTopic &&
              formik.errors.selectCapabilityTopic
                ? formik.errors.selectCapabilityTopic
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select Ilearn course"
            name="selectIlearnCourse"
            values={iLearnVideos || []}
            onSelect={(batch) => {
              formik.setFieldValue("selectIlearnCourse", batch);
              setVideosLink(batch);
            }}
            selected={formik.values.selectIlearnCourse}
            errors={
              formik.touched.selectIlearnCourse &&
              formik.errors.selectIlearnCourse
                ? formik.errors.selectIlearnCourse
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectSingleValues
            label="Select selected course video"
            name="selectedCourseVideo"
            values={courseVideoLink || []}
            onSelect={(batch) =>
              formik.setFieldValue("selectedCourseVideo", batch)
            }
            selected={formik.values.selectedCourseVideo}
            errors={
              formik.touched.selectedCourseVideo &&
              formik.errors.selectedCourseVideo
                ? formik.errors.selectedCourseVideo
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={12} align="center">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="submit"
              name="submit"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

EditNewQuestionChapter.propTypes = {
  auth: PropTypes.object.isRequired,
  editQuestionChapter:PropTypes.func.isRequired,
  //   auth: PropTypes.object.isRequired,
  //   getCoachList: PropTypes.func.isRequired,
  //   programs: PropTypes.object.isRequired,
  //   getProgramBatchList: PropTypes.func.isRequired,
  //   getPrograms: PropTypes.func.isRequired,
  //   createSchedule: PropTypes.func.isRequired,
  //   getMentorBatchList: PropTypes.func.isRequired,

  // getchapters: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  editQuestionChapter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditNewQuestionChapter);
