import React, { useState, useEffect } from "react";
import { Grid, Button, TextField, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "../reusableComponents/is-empty";
import TextFieldGroup from "../reusableComponents/TextFieldGroup";
import Menu from "../menu/menu";
import { addSubGroup } from "../../redux/actions/customerActions";

const initialCustomerForm = {
  subGroupName: "",
  // adminFirstName: "",
  // adminLastName: "",
  // adminEmail: "",
  // adminPassword: "",
  // phoneNumber: "",
};

const AddUserGroup = ({ auth, history, addSubGroup }) => {
  const [formData, setFormData] = useState({ ...initialCustomerForm });
  const [validError, setValidError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { user } = auth;

  useEffect(() => {
    const { user } = auth;
    console.log(user);
    if (
      user &&
      user.role !== "Super Admin" &&
      user &&
      user.role !== "Customer Admin"
    ) {
      history.push("/login");
    }
    document.title = "Customer List - Capability App";
  }, [auth]);

  const validate = () => {
    let isError = false;

    const validError = {
      validError: {},
    };

    if (isEmpty(formData.subGroupName)) {
      isError = true;
      validError.validError.subGroupName = "User Group Name is required field";
    }

    // if (isEmpty(formData.adminFirstName)) {
    //   isError = true;
    //   validError.validError.adminFirstName =
    //     "Customer FIrst Name is required field";
    // }

    // if (isEmpty(formData.adminLastName)) {
    //   isError = true;
    //   validError.validError.adminLastName =
    //     "Customer Last Name is required field";
    // }

    // if (isEmpty(formData.adminEmail)) {
    //   isError = true;
    //   validError.validError.adminEmail = "Customer Email is required field";
    // }

    // if (isEmpty(formData.phoneNumber)) {
    //   isError = true;
    //   validError.validError.phoneNumber = "Phonenumber is required field";
    // }

    // if (
    //   !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.adminEmail)
    // ) {
    //   isError = true;
    //   validError.validError.adminEmail = "Please enter valid email";
    // }

    // if (isEmpty(formData.adminPassword)) {
    //   isError = true;
    //   validError.validError.adminPassword =
    //     "Customer Password is required field";
    // }

    // if (formData.adminPassword.length < 8) {
    //   isError = true;
    //   validError.validError.adminPassword =
    //     "Password should be at least 8 characters";
    // }

    setValidError({ ...validError.validError });

    return isError;
  };

  const handleChange = (e) => {
    let name = e.target.name;
    setFormData({ ...formData, [name]: e.target.value });
    setValidError({});
  };

  const onSubmit = (data) => {
    const err = validate();
    console.log(err);
    if (!err) {
      let subGroupData = JSON.parse(JSON.stringify(formData));
      subGroupData.addedBy = user && user.email;
      addSubGroup(subGroupData, history);
    } else {
      console.log(validError, err);
    }
  };

  return (
    <>
      <Grid>
        <form onSubmit={() => onSubmit()} noValidate autoComplete="off">
          <h4
            style={{
              color: "black",
              fontWeight: "bolder",
              textAlign: "center",
              padding: 5,
              fontSize: "20px",
              marginTop: "65px",
            }}
          >
            Add User Group
          </h4>
          <Grid container mad={6} spacing={2} justify="center">
            <Grid item md={7}>
              <TextFieldGroup
                heading="User Group Name"
                showLable={true}
                // label="Client Name"
                varient="outlined"
                type="email"
                name="subGroupName"
                onChange={(e) => handleChange(e)}
                value={formData.subGroupName}
              />
              {validError.subGroupName ? (
                <span style={{ color: "red" }}>{validError.subGroupName}</span>
              ) : null}
            </Grid>
          </Grid>
          {/* <Grid container mad={6} spacing={2} style={{ padding: "0px 50px" }}>
            <Grid item md={12}>
              <h4
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  textAlign: "center",
                  padding: 5,
                  fontSize: "20px",
                  marginTop: "5px",
                }}
              >
                Default Admin Details for above User Group
              </h4>
            </Grid>
            <Grid item md={6}>
              <TextFieldGroup
                heading="Default Admin First Name"
                showLable={true}
                // label="Client Name"
                varient="outlined"
                type="email"
                name="adminFirstName"
                onChange={(e) => handleChange(e)}
                value={formData.adminFirstName}
              />
              {validError.adminFirstName ? (
                <span style={{ color: "red" }}>
                  {validError.adminFirstName}
                </span>
              ) : null}
            </Grid>
            <Grid item md={6}>
              <TextFieldGroup
                heading="Default Admin Last Name"
                showLable={true}
                // label="Client Name"
                varient="outlined"
                type="email"
                name="adminLastName"
                onChange={(e) => handleChange(e)}
                value={formData.adminLastName}
              />
              {validError.adminLastName ? (
                <span style={{ color: "red" }}>{validError.adminLastName}</span>
              ) : null}
            </Grid>
            <Grid item md={6}>
              <TextFieldGroup
                heading="Default Admin Phone number"
                showLable={true}
                varient="outlined"
                type="number"
                name="phoneNumber"
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, 10);
                }}
                onChange={(e) => handleChange(e)}
                value={formData.phoneNumber}
              />
              {validError.phoneNumber ? (
                <span style={{ color: "red" }}>{validError.phoneNumber}</span>
              ) : null}
            </Grid>
            <Grid item md={6}>
              <TextFieldGroup
                heading="Default Admin Email"
                showLable={true}
                varient="outlined"
                type="email"
                name="adminEmail"
                onChange={(e) => handleChange(e)}
                value={formData.adminEmail}
              />
              {validError.adminEmail ? (
                <span style={{ color: "red" }}>{validError.adminEmail}</span>
              ) : null}
            </Grid>
            <Grid item md={6}>
              <TextFieldGroup
                heading="Default Admin Password"
                varient="outlined"
                showLable={true}
                type={showPassword ? "text" : "password"}
                name="adminPassword"
                passwordControl={() => setShowPassword(!showPassword)}
                showPassword={showPassword}
                onChange={(e) => handleChange(e)}
                value={formData.adminPassword}
              />
              {validError.adminPassword ? (
                <span style={{ color: "red" }}>{validError.adminPassword}</span>
              ) : null}
            </Grid>
          </Grid> */}
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              style={{ marginTop: "10px" }}
              onClick={() => onSubmit()}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="default"
              size="medium"
              style={{ marginTop: "10px", marginLeft: "5px" }}
              onClick={() => history.push("/admin/usergroups/0")}
            >
              Back
            </Button>
          </div>
        </form>
      </Grid>
    </>
  );
};

AddUserGroup.propTypes = {
  addSubGroup: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  customer: state.customer,
});

const mapDispatchToProps = { addSubGroup };

export default connect(mapStateToProps, mapDispatchToProps)(AddUserGroup);
