import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";

import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";

import { getTechnologyList } from "../../../redux/actions/productivityActions/domainAction";
import { addDomainData } from "../../../redux/actions/productivityActions/domainAction";
import { getDatabases } from "../../../redux/actions/productivityActions/databaseActions";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const AddNewDoamin = ({
  auth,
  getTechnologyList,
  addDomainData,
  setOpenPopup,
  domainReducer,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTech, setSelectedTech] = useState([]);
  const { productivityDatabases } = useSelector((state) => state);
  useEffect(() => {
    if (auth.isAuthenticated) {
      getTechnologyList();
      dispatch(getDatabases());
    }
  }, [auth.isAuthenticated]);

  const technologyList = domainReducer?.getTechnologyList?.map((item) => {
    return {
      _id: item._id,
      name: item.name,
    };
  });

  const dbValueList = productivityDatabases?.databaseList?.map((item) => {
    return {
      _id: item?._id,
      name: item?.name,
    };
  });

  const formik = useFormik({
    initialValues: {
      selectTechnology: "",
      selectDatabase: {},
      domainName: "",
      domainWebsite: "",
      domainCode: "",
      domainDescription: "",
    },
    validationSchema: Yup.object({
      // scheduleDescription: Yup.string()
      //   .trim()
      //   .required("Schedule Description is required field"),
      selectTechnology: Yup.object()
        .nullable()
        .required("Technology is reqired field"),
      domainName: Yup.string()
        .trim()
        .min(5)
        .max(25)
        .required("Domain Name is required field"),
      selectDatabase: Yup.object()
        .nullable()
        .required("Database is reqired field"),
      domainWebsite: Yup.string()
        .trim()
        .url()
        .required(
          "Domain Website is required field (ex: https://www.google.com/)"
        ),
      domainCode: Yup.string()
        .trim()
        .min(2)
        .max(4)
        .required("Domain Code is required field"),
      domainDescription: Yup.string()
        .trim()
        .required("Domain Description is required field"),
    }),

    onSubmit: async (values) => {
      let data = {};
      if (values.selectTechnology?.name === "RDBMS") {
        data = {
          technology: values?.selectTechnology?._id,
          name: values?.domainName,
          database: values?.selectDatabase?._id,
          domainWebsite: values?.domainWebsite,
          domainCode: values?.domainCode,
          domainDescription: values?.domainDescription,
        };
      } else {
        data = {
          technology: values?.selectTechnology?._id,
          name: values?.domainName,
          domainWebsite: values?.domainWebsite,
          domainCode: values?.domainCode,
          domainDescription: values?.domainDescription,
        };
      }

      // console.log(data);
      addDomainData(data);
      setOpenPopup(false);
    },
  });
  const handleReset = () => {
    formik.resetForm();
  }

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <SelectSingleValues
            label="Select Technology"
            name="selectTechnology"
            values={technologyList || []}
            onSelect={(batch) => {
              formik.setFieldValue("selectTechnology", batch);
              // console.log("formik.values.selectTechnology",formik.values.selectTechnology)
            }}
            selected={formik.values.selectTechnology}
            errors={
              formik.touched.selectTechnology && formik.errors.selectTechnology
                ? formik.errors.selectTechnology
                : null
            }
          />
        </Grid>
        {formik.values.selectTechnology?.name === "RDBMS" ? (
          <>
            <Grid item xs={12} md={4}>
              <SelectSingleValues
                variant="outlined"
                label="Select Database"
                name="selectDatabase"
                values={dbValueList || []}
                onSelect={(selectDatabase) =>
                  formik.setFieldValue("selectDatabase", selectDatabase)
                }
                selected={formik.values.selectDatabase}
                errors={
                  formik.touched.selectDatabase && formik.errors.selectDatabase
                    ? formik.errors.selectDatabase
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ marginTop: "-15px" }}>
              <TextFieldGroup
                variant="outlined"
                label="Domain Name"
                name="domainName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.domainName}
                errors={
                  formik.touched.domainName && formik.errors.domainName
                    ? formik.errors.domainName
                    : null
                }
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={4} style={{ marginTop: "-15px" }}>
              <TextFieldGroup
                variant="outlined"
                label="Domain Name"
                name="domainName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.domainName}
                errors={
                  formik.touched.domainName && formik.errors.domainName
                    ? formik.errors.domainName
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ marginTop: "-15px" }}></Grid>
          </>
        )}

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Domain Website"
            name="domainWebsite"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.domainWebsite}
            errors={
              formik.touched.domainWebsite && formik.errors.domainWebsite
                ? formik.errors.domainWebsite
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            variant="outlined"
            label="Domain Code"
            name="domainCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.domainCode}
            errors={
              formik.touched.domainCode && formik.errors.domainCode
                ? formik.errors.domainCode
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextFieldGroup
            variant="outlined"
            label="Domain Descripton"
            name="domainDescription"
            multiline="multiline"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.domainDescription}
            rows={4}
            errors={
              formik.touched.domainDescription &&
              formik.errors.domainDescription
                ? formik.errors.domainDescription
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6} align="right">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="save"
              name="save"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </Grid>

        <Grid item xs={12} md={6} align="left">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="reset"
              name="reset"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

AddNewDoamin.propTypes = {
  auth: PropTypes.object.isRequired,
  getTechnologyList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  domainReducer: state.domainReducer,
});

const mapDispatchToProps = {
  getTechnologyList,
  addDomainData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewDoamin);
