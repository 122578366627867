import React, { Component } from "react";
import "../../menu/menu.css";
import { NavLink, withRouter, Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../Api_Request";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { logoutUser } from "../../../redux/actions/authActions";
import { getincompletetest } from "../../../redux/actions/assignTestAction";
import { usertestsdata } from "../../../redux/actions/visualsAction";
import Logo from "../../../images/logo/ibridgelogo.png";
// import Logout from '../logout/Logout';

class testMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tests: null,
            userdata: [],
        };
    }

    componentDidMount() {
        const { user } = this.props.auth;
        const id = user && user.id;
        const username = JSON.parse(localStorage.getItem("capability"));
        const email = username.username;
        this.props.getincompletetest(id);
        this.props.usertestsdata(email);
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.assignedtests.getincompletetestdata) {
            return null;
        } else if (nextProps.assignedtests.getincompletetestdata.length < 0) {
            this.setState({
                tests: nextProps.assignedtests.getincompletetestdata.length,
            });
        } else {
            this.setState({
                tests: nextProps.assignedtests.getincompletetestdata.length,
            });
        }
        if (!nextProps.visuals.singleusertestsdata) {
            return null;
        } else if (nextProps.visuals.singleusertestsdata.length < 0) {
            this.setState({ userdata: nextProps.visuals.singleusertestsdata });
        } else {
            this.setState({ userdata: nextProps.visuals.singleusertestsdata });
        }
    }

    handleLogout() {
        localStorage.clear();
        this.props.logoutUser();
        this.props.history.push("/login");
        // document.body.style = 'background: ;';
    }

    handleVisual = (e) => {
        const { user } = this.props.auth;
        // const userid = localStorage.getItem('userid')
        this.props.history.push({
            pathname: `/menu/visuals`,
            state: {
                id: user && user.id,
                first_name: user && user.first_name,
                oldest: this.state.userdata.oldest,
                latest: this.state.userdata.latest,
            },
        });
    };

    handleResults = (e) => {
        const { user } = this.props.auth;
        this.props.history.push({
            pathname: `/menu/viewresults`,
            state: {
                email: user && user.email,
            },
        });
    };

    handleCancelTest = () => {
        // console.log("pop.location",this.props?.location?.pathname)
         Swal.fire({
            title: "Are you sure you want to cancel the Test?",
            // text: 'You will not be able to recover this imaginary file!',
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if (this.props.ibridge) {
                    window.location.href = `${process.env.REACT_APP_IBRIDGE_URL}/myPrograms`;
                }
                else {
                    this.props.history.push('/menu/userdashboard')
                }
            } else {
                return;
            }
        });
    };

    handleTests = (e) => {
        const { tests } = this.state;
        if (tests <= 3) {
            this.props.history.push(`/menu/selecttest`);
        } else {
            Swal.fire({
                icon: "warning",
                text: "You have already taken maximum number of tests for a free user. Please Subsccribe to take more tests",
            }).then(() => {
                this.props.history.push(`/menu/subscribe`);
            });
        }
    };

    render() {
        const { user } = this.props.auth;
        // console.log(this.state.tests)
        return (
            <div className="marginBottom">
                <nav className="navbar navbar-expand-lg fixed-top dashboard-menu">
                    <div className="container">
                        <div className="navbar-brand" to="/login">
                            <img
                                style={{
                                    width: "200px",
                                    height: "55px",
                                    objectFit: "cover",
                                }}
                                src={Logo} alt="" />
                        </div>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon">Menu</span>
                        </button>
                        <div
                            className="collapse navbar-collapse justify-content-end"
                            id="navbarNav"
                        >
                            <ul className="nav">

                                {(user && user.role === "Interview Admin") ? null :
                                    <button type="button" class="btn btn-primary" to="/menu/userdashboard"
                                        onClick={() => {
                                            this.handleCancelTest();
                                        }}>Stop Test</button>}


                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    assignedtests: state.assignedtests,
    visuals: state.visuals,
});

const mapDispatchToProps = {
    getincompletetest,
    logoutUser,
    usertestsdata,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(testMenu));
