import React from "react";
import Button from '@mui/material/Button';

const AILearningAssistantButton = ({ history }) => {
    const aiLogo = "https://zoom-chat-file-uploads.s3.ap-south-1.amazonaws.com/logo+with+active+.png";

    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={()=>history.push("/learningassistantai")}
            style={{ margin: 20 }}
            startIcon={<img src={aiLogo} alt="AI Logo" style={{ width: 48, height: 48 }} />}
        >
            AI Learning Assistant
        </Button>
    );
}

export default AILearningAssistantButton;
