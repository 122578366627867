import { ADDFEEDBACKTOTABLE } from "./type";
import axios from "axios";
import { API_URL } from "../../Api_Request";
import Swal from "sweetalert2";

export const adduserfeedback = (params) => (dispatch) => {
  axios
    .post(`${API_URL}/feedback/`, params)
    .then((res) => {
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
      Toast.fire({ icon: "success", title: "Feedback added successfully !!!" });
      dispatch({
        type: ADDFEEDBACKTOTABLE,
        payload: res.data,
      });

      // console.log(res.data)
    })
    .catch((err) => {
      console.log(err);
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const addFreeFormFeedback = (params) => (dispatch) => {
  axios
    .post(`${API_URL}/feedback/freeForm`, params)
    .then((res) => {
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
      Toast.fire({ icon: "success", title: "Feedback added successfully !!!" });
      dispatch({
        type: ADDFEEDBACKTOTABLE,
        payload: res.data,
      });

      // console.log(res.data)
    })
    .catch((err) => {
      console.log(err);
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};
