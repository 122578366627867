import React, { Component } from "react";
// import Menu from "../menu/menu";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { userlists } from "../../redux/actions/userlistsAction";
import Tables from "../tables/tables";
import "../questions/questions.css";
import loader from ".././805.gif";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultsData: [],
    };
  }

  componentDidMount() {
    this.props.userlists();
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.resultsData)
    if (!nextProps.allUsers.allUsers) {
      return null;
    } else if (nextProps.allUsers.allUsers.length < 0) {
      this.setState({
        resultsData: nextProps.allUsers.allUsers,
        isLoaded: true,
      });
    } else {
      this.setState({
        resultsData: nextProps.allUsers.allUsers.filter(
          (users) => users.role === "User" || users.role === "TestUser"
        ),
        isLoaded: false,
      });
    }
  }

  mapUsers(rowData) {
    // console.log("rowdata",rowData.rowData)
    const userdata = rowData.rowData;
    this.props.history.push({
      pathname: `/admin/assigntopic`,
      state: {
        userId: userdata[0],
        userName: userdata[1],
        userEmail: userdata[2],
        userPhone: userdata[3],
      },
    });
  }

  render() {
    const { user } = this.props.auth;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      const data = this.state.resultsData;
      // console.log(resultsData);

      // TABLE
      const columns = [
        {
          name: "id",
          label: "User Id",
          options: { filter: false, sort: false, display: false },
        },
        {
          name: "first_name",
          label: "Name",
          options: { filter: false, sort: true },
        },
        {
          name: "email",
          label: "Email_ID",
          options: { filter: false, sort: false },
        },
        {
          name: "phone",
          label: "Phone Number",
          options: { filter: false, sort: false },
        },
        user && user.role === "Super Admin"
          ? {
              name: "customer_name",
              label: "Customer",
              options: { filter: true, sort: false },
            }
          : "",
        {
          name: "user_group_name",
          label: "Sub-Group",
          options: { filter: true, sort: false },
        },
        {
          name: "Assign Topics",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <button
                  className="btn btn-primary"
                  onClick={(e) => this.mapUsers(tableMeta)}
                  style={{ backgroundColor: "#003399", color: "white" }}
                >
                  Assign Topics
                </button>
              );
            },
          },
        },
      ];

      const options = {
        filterType: "checkbox",
        serverSide: false,
        pagination: true,
        // searchText: true,
        download: false,
        selectableRows: "none",
        viewColumns: false,
        rowsPerPage: 6,
        selectableRowsOnClick: true,
        rowsPerPageOptions: [10],
        rowHover: true,
        search: true,
        print: false,
        responsive: "scrollMaxHeight",
      };

      return (
        <div>
          {/* <Menu /> */}
          <div>
            <div>
              {/* {(user && user.role === "admin") ||
              (user && user.role === "mentor") ||
              (user && user.role === "Customer Admin") ||
              (user && user.role === "Super Admin") ? (
                <Link to="/menu/addusers">
                  <button
                    type="button"
                    id="btn"
                    value="add new user"
                    className="btn btn-primary"
                    style={{ backgroundColor: "#003399", color: "white" }}
                  >
                    Add New user
                  </button>
                </Link>
              ) : null} */}
            </div>
            <div className="mutable">
              {data && data ? (
                <Tables
                  title="Users List"
                  data={data}
                  columns={columns}
                  options={options}
                />
              ) : (
                <div className="image-container">
                  <p className="image-holder">
                    <img src={loader} alt="" />
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  allUsers: state.allUsers,
});

export default connect(mapStateToProps, { userlists })(Users);
