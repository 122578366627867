import React, {useState, useEffect} from 'react';
import {Grid, Box, Button, TextField, Typography, Paper} from '@material-ui/core'
import { fetchOverallData, loadingCapabilityApplication } from '../../redux/actions/capabilityAnalysisApplicationAction';
import { useSelector, useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { API_URL } from '../../Api_Request';
import axios from 'axios';

const Overall = () => {

  const [userEmail, setUserEmail] = useState('');
  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const { capabilityAnalysisApplicationReducer } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();

  const fetchOverallDetails = async () => {
    await fetchTopics();
  }

  const fetchTopics = async () => {
    await axios.get(`${API_URL}/capabilityAnalysisApplication/topicList`, {params: {email: userEmail} })
    .then((res)=>{
      const temp = res.data.distinctTopics
      const sortedTopicList = temp.slice().sort((a,b)=>{
        return a.name.localeCompare(b.name);
      });
      dispatch(loadingCapabilityApplication());
      dispatch(fetchOverallData(userEmail));
    })
    .catch((err)=>{
      console.log(err);
      Swal.fire({
        icon: "error",
        text: "Email not found in database",
      });
    })
  }

  return (
      <Grid container spacing={1}>
        <Grid item xs={12}
        style={{
           marginLeft: 300,
           marginBottom: 40
          }}
        >
        <Typography variant='h6' >
          Personalized Recommender System
        </Typography>
        </Grid>
    
        <Grid
          container
          style={{
            display: 'flex',
            // justifyContent: 'flex-end',
            marginBottom: 8, 
            marginRight: 40
          }}
        >
          <Grid item xs={12}>
            <Box
              display="flex" 
              justifyContent='space-between'
              // alignItems="center" 
            >
              <TextField
                label="Enter User's Email"
                type="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                error={!isEmailValid(userEmail) && userEmail}
                helperText={userEmail && !isEmailValid(userEmail)}
              />
            </Box>
          </Grid>

          <Grid item xs = {12}>
          <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 40
          }}
          >
                <Button 
                    variant='outlined'
                    style={{
                        width: "150px",
                        textTransform: "none",                      
                      }}
                    onClick={fetchOverallDetails}
                    disabled={!userEmail||capabilityAnalysisApplicationReducer.loading}
                >
                    Analyse
                </Button>
                </Box>
            </Grid>
        </Grid>
        {capabilityAnalysisApplicationReducer.overallData ? 
        (
        <>
        <Grid container spacing={2}>
          <Grid item xs = {12}>
          <Typography 
                variant='body1'
            >
              Name: {capabilityAnalysisApplicationReducer.overallData.Name}
            </Typography>
          </Grid>
          <Grid item xs ={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
          >
            <Paper
              style={{
                flex: 1,
                border: '1px solid #ccc', 
                padding: '10px',
                marginRight: 30,
                flexDirection: "column",
              height: 400,
              overflow: "hidden",
              overflowY: "scroll", 
              }}
            >
              <Typography variant='body1'>Results</Typography>
              {Object.keys(capabilityAnalysisApplicationReducer.overallData.Result).map(key => (
    <Paper elevation={6} style={{ padding: 8, margin: 8 }} key={key}>
        <Typography variant='bold1' style={{ display: 'block', margin: 8 }} color='primary'>{key}:</Typography>
        {Object.entries(capabilityAnalysisApplicationReducer.overallData.Result[key]).map(([subKey, value]) => {
            let backgroundColor;
            switch (value) {
                case 'Excellent!!!':
                    backgroundColor = 'green';
                    break;
                case 'Very Good!!':
                case 'Good':
                case 'Good!':
                case 'Good!!':
                    backgroundColor = 'lightgreen';
                    break;
                case 'Satisfactory':
                    backgroundColor = 'lightblue';
                    break;
                case 'Bad':
                case 'Need Improvement':
                  backgroundColor = 'red'
                  break;
                default:
                    backgroundColor = 'white';
            }
            return (
                <Typography
                    variant='bold2'
                    style={{
                        display: 'block',
                        color: 'black',
                        backgroundColor: backgroundColor, 
                        padding: 8,
                        marginBottom: 8
                    }}
                    color='secondary'
                    key={subKey}
                >
                    {subKey}: {value}
                </Typography>
            );
        })}
    </Paper>
))}

            </Paper>
            <Paper  
            style={{
              flex: 2,
              border: '1px solid #ccc', 
              padding: '10px',
              marginLeft: 20,
              flexDirection: "column",
              height: 400,
              overflow: "hidden",
              overflowY: "scroll", 
            }}
            >
              <Typography variant='body1'>Recommendations</Typography>

              <Paper
              elevation={6}
              style={{
                padding: 8,
                margin: 8
              }}
              >
              <Typography
                variant='bold1'
                style={{
                  display: 'block',
                  margin: 8
                }}
                color='primary'
              >{Object.keys(capabilityAnalysisApplicationReducer.overallData.Recommendations)[0]}</Typography>
              <Typography
                    variant='bold2'
                    style={{
                      display: 'block'
                    }}
                    color='secondary'
                  >
                  {capabilityAnalysisApplicationReducer.overallData.Recommendations[Object.keys(capabilityAnalysisApplicationReducer.overallData.Recommendations)[0]]}
                  </Typography>
              </Paper>
              {Object.keys(capabilityAnalysisApplicationReducer.overallData.Recommendations).slice(1).map(key => (
              <Paper
                elevation={6}
                style={{
                  padding: 8,
                  margin: 8
                }}
                key={key}
              >
                <Typography
                  variant='bold1'
                  style={{
                    display: 'block',
                    margin: 8
                  }}
                  color='primary'
                >
                  {key}:
                </Typography>

                {Object.entries(capabilityAnalysisApplicationReducer.overallData.Recommendations[key]).map(([subKey, value]) => (
                  <Typography
                    variant='bold2'
                    style={{
                      display: 'block',
                      marginBottom: 2
                    }}
                    color='secondary'
                    key={subKey}
                  >
                    -- {value}
                  </Typography>
                ))}
              </Paper>
))}

            </Paper>
            </Grid>
        </Grid>
            </>
          )
          :
          capabilityAnalysisApplicationReducer.errorMessage && 
          (
            <Grid item xs={12}
              >
                <Box>
                  <Typography variant='h5' color='error'>
                    {capabilityAnalysisApplicationReducer.errorMessage}
                  </Typography>
              </Box>
          </Grid>
          )
          }
      </Grid>
  );
}

export default Overall;
