import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getQuestioncomplexity } from "../../../redux/actions/questionComplexityAction";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";
import { Grid } from "@mui/material";
import { addFeedbackSession } from "../../../redux/actions/productivityActions/debuggingCodeActions";

const SelectDebuggingComplexity = ({
  setOpenPopup,
  selectedTopic,
  history,
}) => {
  const { questioncomplexity, auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    //   dispatch(adminTopics());
    dispatch(getQuestioncomplexity());
  }, []);

  const handleSelect = (data) => {
    console.log(data);
    const apiData = {
      capabilityTopic: selectedTopic,
      capabilityComplexity: data,
      capabilityUser: auth?.user,
    };
    setOpenPopup(false);
    dispatch(addFeedbackSession(apiData, history));
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <SelectSingleValues
            label="Select Complexity"
            name="selectComplexity"
            values={questioncomplexity?.getquestioncomplexity?.filter(item => item?.name!=='Consolidated')}
            onSelect={(data) => {
              //   formik.setFieldValue("selectComplexity", data);
              handleSelect(data);
              // console.log("formik.values.selectComplexity",formik.values.selectComplexity)
            }}
            // selected={formik.values.selectComplexity}
            // errors={
            //   formik.touched.selectComplexity && formik.errors.selectComplexity
            //     ? formik.errors.selectComplexity
            //     : null
            // }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SelectDebuggingComplexity;
