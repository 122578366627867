import React, { Component } from "react";
// import Menu from "../menu/menu";
import "../questions_complexity/questionComplexity.css";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  getQuestionTypes,
  addnewtype,
  editQuestionType,
} from "../../redux/actions/questionTypeAction";
import loader from ".././805.gif";
import Spinner from "../reusableComponents/Spinner";

class QuestionType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      addType: false,
      error: false,
      typeData: [],
      editElement: -1,
      name: "",
      acronym: "",
      status: 1,
      editId: "",
      RecordsData: {
        name: "",
        acronym: "",
        status: "",
      },
    };
  }

  componentDidMount() {
    this.props.getQuestionTypes();
  }

  componentWillReceiveProps(nextProps) {
    let { getquestiontype } = nextProps.questiontypes;
    if (getquestiontype) {
      this.setState({
        typeData: getquestiontype,
        isLoaded: true,
      });
    }
  }

  handleType = () => {
    if (this.state.addType === false) {
      this.setState({
        editElement: -1,
        name: "",
        acronym: "",
        addType: true,
      });
    }
  };
  handleCancelData = () => {
    if (this.state.addType === true) {
      this.setState({
        addType: false,
      });
    }
  };
  handleClick() {
    this.setState({
      error: false,
    });
  }
  handleEdit(data, id) {
    console.log(data.id);
    if (this.state.addType === false) {
      this.setState({
        editId: data.id,
        name: data.name,
        acronym: data.acronym,
        status: data.status === 0 ? 0 : 1,
        editElement: id,
      });
    } else {
      Swal.fire({ icon: "error", text: `please enter the details` });
    }
  }

  validate = () => {
    let isError = this.state.error;
    if (this.state.name === "" || this.state.name === undefined) {
      isError = true;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: true,
      });

      Toast.fire({
        icon: "error",
        title: "Please enter Question Type name !!!",
      });
      this.setState({
        error: true,
      });
    } else if (this.state.acronym === "" || this.state.acronym === undefined) {
      isError = true;
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: true,
      });

      Toast.fire({
        icon: "error",
        title: "Please enter Question Type Acronym !!!",
      });
      this.setState({
        error: true,
      });
    }
    return isError;
  };

  handleUpdate(index) {
    let err = this.validate();
    if (!err) {
      const userTypeEdit = {
        name: this.state.name,
        acronym: this.state.acronym,
        status: this.state.status === 0 ? 0 : 1,
        id: this.state.editId,
      };
      this.props.editQuestionType(userTypeEdit);
      this.setState({
        editElement: -1,
        name: "",
        acronym: "",
        status: "",
        isLoaded: false,
      });
    }
  }

  handleName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  handleAcronym(e) {
    this.setState({
      acronym: e.target.value,
    });
  }

  handleSaveStatus() {
    this.setState({
      status: this.state.status === 0 ? 1 : 0,
    });
    console.log(this.state.status);
  }

  handleSaveData() {
    let err = this.validate();
    if (!err) {
      const userQType = {
        name: this.state.name,
        acronym: this.state.acronym,
        status: this.state.status === 0 ? 0 : 1,
      };
      this.props.addnewtype(userQType);
      this.setState({
        editElement: -1,
        addType: false,
        name: "",
        acronym: "",
        isLoaded: false,
      });
    }
  }

  handleStatus(data, status, index) {
    const dummyRecord = this.state.RecordsData;
    dummyRecord.name = data.name;
    dummyRecord.acronym = data.acronym;
    dummyRecord.status = status === 0 ? 1 : 0;
    const dummytypeData = this.state.typeData;
    dummytypeData[index] = dummyRecord;
    this.setState({
      RecordsData: dummyRecord,
      typeData: dummytypeData,
      status: status === 0 ? 1 : 0,
    });
  }

  render() {
    const { user, isAuthenticated } = this.props.auth;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      const { typeData } = this.state;
      const questionTypeData =
        typeData &&
        typeData.map((item, index) => {
          return (
            <tr
              key={index}
              style={{
                visibility:
                  this.state.typeData.status === false ? "hidden" : "visible",
              }}
            >
              <td>
                {this.state.editElement === index ? (
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.handleName(e);
                    }}
                    defaultValue={item.name}
                  />
                ) : (
                  item.name
                )}
              </td>

              <td>
                {this.state.editElement === index ? (
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.handleAcronym(e);
                    }}
                    defaultValue={item.acronym}
                  />
                ) : (
                  item.acronym
                )}
              </td>
              <td>
                <label className="switch" id="status">
                  <input
                    type="checkbox"
                    checked={item.status === 0 ? false : true}
                    disabled={this.state.editElement === index ? false : true}
                    onChange={() => this.handleStatus(item, item.status, index)}
                  />
                  <span className="slider round"></span>
                </label>
              </td>
              {/* {
                this.state.editElement === index ? 
                <button className="btn btn-primary" onClick={(e) => this.handleUpdate(index)}>Save</button>
                :
                <button className="btn btn-success" onClick={(e) => this.handleEdit(item, index)}>Edit</button>
              } */}
            </tr>
          );
        });
      return (
        <div>
          {/* <Menu /> */}
          <div className="side-content questionTopic">
            <div>
              {/* <center>
            <button
              type="button"
              id="add-row"
              value="add question type"
              className="btn btn-primary mt-5"
              onClick={this.handleType}
            >
              Add Question Type
            </button>
          </center> */}
            </div>
            <div
              className="image-container"
              style={{
                display: this.state.isLoaded === false ? "block" : "none",
              }}
            >
              <p className="image-holder">
                {/* <img src={loader} alt="" /> */}
                <Spinner/>
              </p>
            </div>

            <br />
            <div className="table-responsive p-5">
              <table
                className="table table-bordered table-hover"
                style={{
                  display:
                    this.state.isLoaded === true ? "inline-table" : "none",
                }}
              >
                <thead className="thead-bg headers">
                  <tr>
                    <th>Name</th>
                    <th>Acronym</th>
                    <th>Status</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      display:
                        this.state.addType === true ? "contents" : "none",
                    }}
                  >
                    <td>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        placeholder="Enter Single or Multiple Choice Question"
                        value={this.state.name}
                        onChange={(e) => {
                          this.handleName(e);
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id="acronym"
                        className="form-control"
                        placeholder="Enter SCQ or MCQ"
                        value={this.state.acronym}
                        onChange={(e) => {
                          this.handleAcronym(e);
                        }}
                      />
                    </td>
                    <td>
                      <label className="switch" id="status">
                        <input
                          type="checkbox"
                          value={this.state.status}
                          onChange={() => this.handleSaveStatus()}
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>
                    <td>
                      <button
                        type="button"
                        id="qtopicbutton"
                        className="btn btn-primary"
                        value="save"
                        onClick={(e) => this.handleSaveData(e)}
                      >
                        Save{" "}
                      </button>
                      <button
                        type="button"
                        id="qtopicbutton"
                        className="btn btn-danger"
                        value="Cancel"
                        onClick={(e) => this.handleCancelData(e)}
                      >
                        {" "}
                        Cancel{" "}
                      </button>
                    </td>
                  </tr>
                  {questionTypeData}
                </tbody>
              </table>
            </div>
            {/* </div>
        </div> */}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  questiontypes: state.questiontypes,
});

const mapDispatchToProps = {
  getQuestionTypes,
  addnewtype,
  editQuestionType,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionType);
