import { IBRIDGETEST, IBRIDGEUSER, GET_IBRIDGE_ICAP_TEST } from "./type";
import axios from "axios";
import { API_URL } from "../../Api_Request";
import Swal from "sweetalert2";

export const redirectToTest = (skill) => (dispatch) => {
  axios
    .get(`${API_URL}/ibridge?skill=${skill}`)
    .then((res) => {
      const result = res.data;
      dispatch({
        type: IBRIDGETEST,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getIbridgeUser = (skill) => (dispatch) => {
  axios
    .get(`${API_URL}/ibridge/user?skill=${skill}`)
    .then((res) => {
      const result = res.data;
      dispatch({
        type: IBRIDGEUSER,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getIbridgeTopics = (data) => (dispatch) => {
  console.log("inside actions");
  axios
    .post(`${API_URL}/ibridge/ibridgeTest`, data)
    .then((res) => {
      const result = res.data;
      console.log("data");
      dispatch({
        type: GET_IBRIDGE_ICAP_TEST,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
