import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { sendPasswordResetLink } from "../../redux/actions/authActions";
import { NavLink } from "react-router-dom";
import "./forgotPassword.css";
import Swal from "sweetalert2";

const ForgotPassword = ({ history, sendPasswordResetLink }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !email ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ) {
      Swal.fire({
        icon: "error",
        text: `Please enter vaild email`,
      });
    } else {
      const data = {
        email: email,
      };
      sendPasswordResetLink(data, history);
    }
  };

  return (
    <div className="main">
      <section className="login">
        <Grid container className="SigninIC">
          <Grid item md={1}></Grid>
          <Grid item className="PassBox" item md={10} xs={12} align="center">
            <p
              className="RightHeaderForgotPass"
              // style={{
              //   fontSize: "28px",
              //   fontFamily: "Verdana, Arial, Helvetica, sans-serif",
              //   marginTop: "-10px",
              // }}
            >
              Forgot your Password?
            </p>
            <dl>
              <dt>
                <p className="RightHeaderText">
                  Don't Worry! Just fill in your email and we'll send you a link
                  to reset password
                </p>
              </dt>
            </dl>
            <div style={{ height: "10px" }}></div>
            <form onSubmit={handleSubmit}>
              <div className="form-group login-form-data-FP mx-auto xs-auto">
                <div className=" ">
                  <div class="input-group-append">
                    <input
                      className="form-control"
                      required="required"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Email"
                      type="text"
                      name="name"
                    />
                    <span class="input-group-text mail-box">
                      <i className="fa fa-envelope mail-icon" style={{color:"#003399"}} />
                    </span>
                  </div>
                </div>
              </div>

              <div className=" col-sm-12 mt-4">
                <center>
                  <button
                    className="btn btn-primary  login-button"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </center>
              </div>
            </form>
            <div style={{ height: "10px" }}></div>
            <Typography className="btn  ml-auto  ">
              <NavLink to="/" style={{color:"#003399"}} >Home</NavLink>
            </Typography>
          </Grid>
        </Grid>
      </section>
      <section className="login-form">
        <div className="container"></div>
      </section>
    </div>
  );
};

ForgotPassword.propTypes = {
  sendPasswordResetLink: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { sendPasswordResetLink };

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

// import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
// // import "../signup/signup.css";
// import { connect } from "react-redux";
// import { loginUser } from "../../redux/actions/authActions";
// import TextFieldGroup from "../reusableComponents/TextFieldGroup";
// import { Grid, Button, TextField, Typography } from "@material-ui/core";
// import "./forgotPassword/forgotPassword.css";

// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       showPass: false,
//       uName: "",
//       Password: "",
//       isLogin: "",
//       valueLogin: "",
//       data: [],
//       err: "",
//     };
//   }

//   handletoggle() {
//     this.setState({ showPass: !this.state.showPass });
//     console.log(this.state.showPass);
//   }
//   handleName(e) {
//     this.setState({
//       uName: e.target.value,
//     });
//   }
//   handlePassword(e) {
//     this.setState({
//       Password: e.target.value,
//     });
//   }
//   handleSubmit = (e) => {
//     e.preventDefault();
//     const user = {
//       username: this.state.uName,
//       password: this.state.Password,
//     };
//     this.props.loginUser(user);
//   };

//   componentWillReceiveProps(nextProps) {
//     // console.log(nextProps.auth.user)
//     const { role } = nextProps.auth.user;
//     const { isAuthenticated } = nextProps.auth;
//     if (isAuthenticated) {
//       if (role === "user") {
//         this.props.history.push("/menu/userdashboard");
//       } else if (role === "admin" || role === "mentor") {
//         this.props.history.push("/menu/dashboard");
//       }
//     } else {
//       this.props.history.push("/login");
//     }
//   }

//   render() {
//     const loginPage = (
//       <div className="main">
//         <section className="login">
//           <Grid container className="SigninIC">
//             <Grid item md={1}></Grid>
//             <Grid item className="PassBox" item md={10} align="center">
//               <p
//                 className="RightHeader"
//                 style={{
//                   fontSize: "28px",
//                   fontFamily: "Verdana, Arial, Helvetica, sans-serif",
//                   marginTop: "-10px",
//                 }}
//               >
//                 Forgot your Password?
//               </p>
//               <dl>
//                 <dt>
//                   <p className="RightHeaderText">
//                     Don't Worry! Just fill in your email and we'll send you a
//                     link to reset password
//                   </p>
//                 </dt>
//               </dl>
//               <div style={{ height: "10px" }}></div>
//               <div className="form-group login-form-data mx-auto">
//                 <div className=" ">
//                   <div class="input-group-append">
//                     <input
//                       className="form-control"
//                       required="required"
//                       onChange={(e) => this.handleName(e)}
//                       placeholder="Enter Email"
//                       type="text"
//                       name="name"
//                     />
//                     <span class="input-group-text mail-box">
//                       <i className="fa fa-envelope mail-icon" />
//                     </span>
//                   </div>
//                 </div>
//               </div>

//               <div className=" col-sm-12 mt-4">
//                 <center>
//                   <button
//                     className="btn btn-primary  ml-auto login-button"
//                     type="submit"
//                     onClick={(e) => this.handleSubmit(e)}
//                   >
//                     Submit
//                   </button>
//                 </center>
//               </div>
//               <div style={{ height: "10px" }}></div>
//             </Grid>
//           </Grid>
//         </section>

//         <section className="login-form">
//           <div className="container"></div>
//         </section>
//       </div>
//     );

//     const { user, isAuthenticated } = this.props.auth;
//     const role = user && user.role;
//     return (
//       <div>
//         {!isAuthenticated
//           ? loginPage
//           : role === "admin" ||
//             role === "mentor" ||
//             role === "Super Admin" ||
//             role === "Customer Admin"
//           ? this.props.history.push("/menu/dashboard")
//           : this.props.history.push("/menu/userdashboard")}
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   auth: state.auth,
// });

// export default connect(mapStateToProps, { loginUser })(Login);
