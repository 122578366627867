import React, { Component } from "react";
import "../../questions/questions.css";
import Menu from "../usermenu/usermenu";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getincompletetest } from "../../../redux/actions/assignTestAction";
import { userResultsData } from "../../../redux/actions/resultsAction";
import Tables from "../../tables/tables";
import Swal from "sweetalert2";
import loader from ".././../805.gif";

class TestsAssigned extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testsdata: [],
      isLoaded: true,
      noOfTests: 0,
    };
  }

  componentDidMount() {
    const { user } = this.props.auth;
    const id = user && user.id;
    this.props.getincompletetest(id);
    this.props.userResultsData(user && user.email);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.assignedtests.getincompletetestdata) {
      return null;
    } else if (nextProps.assignedtests.getincompletetestdata.length < 0) {
      this.setState({
        testdata: nextProps.assignedtests.getincompletetestdata,
        isLoaded: true,
      });
    } else {
      this.setState({
        testdata: nextProps.assignedtests.getincompletetestdata,
        isLoaded: false,
      });
    }
    if (!nextProps.resultsData) {
      console.log(nextProps.resultsData);
      return null;
    } else if (nextProps.resultsData) {
      console.log(nextProps.resultsData);
      if (nextProps.resultsData.resultsData) {
        if (nextProps.resultsData.resultsData.length < 0) {
          this.setState({
            noOfTests: nextProps.resultsData.resultsData.length,
          });
        } else {
          this.setState({
            noOfTests: nextProps.resultsData.resultsData.length,
          });
        }
      }
    }
  }

  handleStart(rowData) {
    const { user } = this.props.auth;
    let role = user && user.role_id;
    let addedby = user && user.added_by;
    console.log("rowdata", rowData.rowData);
    const userdata = rowData.rowData;
    const uname = user && user.first_name;
    this.props.history.push({
      pathname: `/menu/rules`,
      state: {
        paperId: userdata[0],
        userId: userdata[1],
        topicName: userdata[3],
        skill: userdata[4],
        testId: userdata[5],
        uname: uname,
      },
    });
    //   }
    // }
  }

  render() {
    const { user } = this.props.auth;
    const columns = [
      {
        name: "questionpaperid",
        label: "Question Paper Id",
        options: { filter: false, sort: true, display: false },
      },
      {
        name: "user_id",
        label: "User Id",
        options: { filter: false, sort: true, display: false },
      },
      {
        name: "questionpaper",
        label: "Question Paper Name",
        options: { filter: false, sort: true },
      },
      { name: "topic", label: "Topic", options: { filter: true, sort: false } },
      {
        name: "skill",
        label: "Skill Level",
        options: { filter: true, sort: false },
      },
      {
        name: "test_id",
        label: "Test ID",
        options: { filter: true, sort: false },
      },
      {
        name: "date",
        label: "Assigned Date",
        options: { filter: false, sort: false },
      },
      {
        name: "Start Test",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <button
                className="btn btn-primary"
                onClick={(e) => this.handleStart(tableMeta)}
              >
                Start Test
              </button>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      serverSide: false,
      pagination: true,
      // searchText: true,
      download: false,
      selectableRows: "none",
      viewColumns: false,
      rowsPerPage: 6,
      selectableRowsOnClick: true,
      rowsPerPageOptions: [10],
      rowHover: true,
      search: true,
      print: false,
      responsive: "scrollMaxHeight",
    };
    const { getincompletetestdata } = this.props.assignedtests;
    if ((user && user.role === "admin") || (user && user.role === "mentor")) {
      return <Redirect to="/login" />;
    } else {
      return (
        <div className="mutable">
          <Menu />
          {getincompletetestdata ? (
            <Tables
              title="Assigned Tests"
              data={getincompletetestdata}
              columns={columns}
              options={options}
            />
          ) : (
            <div className="image-container">
              <p className="image-holder">
                <img src={loader} alt="" />
              </p>
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  assignedtests: state.assignedtests,
  resultsData: state.resultsData,
});

const mapDispatchToProps = {
  getincompletetest,
  userResultsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestsAssigned);
