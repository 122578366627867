import React, {useContext, useEffect, useState} from 'react';
import { Paper, Chip, Box, Button, Typography } from '@material-ui/core';
import Swal from "sweetalert2";
import { AiAssistantContext } from '../../contexts/ai_assistant_context';
import { useSelector } from 'react-redux'
import HomeIcon from '@material-ui/icons/Home';

const TestList = ({ history }) => {


    const { fetchSQLTestHistory, testHistory, selectTest, isLoadingTest, formatDate, selectedTest, userId } = useContext(AiAssistantContext);

    const [testList, setTestList] = useState(null);
    const { auth } = useSelector(state => state);
    console.log(testList);
    
    useEffect(()=>{
      console.log('auth', auth?.user?.id);
      if(userId){
        // fetchSQLTestHistory(auth?.user?.id);
        fetchSQLTestHistory(userId);
      }
    }, [userId])    
  
  
    return (              
      <Paper style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: "100%",
          margin: 0,
          padding: 0,
          // backgroundColor: '#f0f0f0',
          // backgroundColor: '#C6D1E8'
          backgroundColor: '#18479f'
        }}
        elevation= {16}
        >
            <img src="https://ibridge360.com/wp-content/uploads/2024/02/final-illustrator-logo-1-1-2048x590.png" alt="logo"
          style={{
            padding: 10,
            backgroundColor: "#f1f4fa",
            margin: 10,
            borderRadius: 10
          }}
          />
            <Box style={{flex: 9, padding: 20, paddingLeft: 10, marginBottom: 15, overflow: 'auto', overflowY: 'scroll'}}>
            <Chip
              label="Recent Sessions"
              variant="outlined"
              style={{ marginLeft: 7, width: '100%', borderRadius: 3, fontSize: '1.5rem', backgroundColor: "#f1f4fa", marginBottom: 10}}
            />          
            <Box>
            {testHistory && testHistory.map((test, index) => 
                ( 
              <Button key={test.id} variant='outlined'
              style={{
                padding: 10,
                margin: 15,
                width: 195,
                height:140,
                backgroundColor: test?.id != selectedTest?.id ? "#f1f4fa" :  "#C0C1BE"
              }}
              onClick={()=>selectTest(test, userId)}
              disabled={isLoadingTest}
              >
                <Box display='flex' flexDirection='column' justifyContent='space-between'>
                    <Typography variant='body2'>{`${test.domain_name}-${test.function_name}-${test.scenario_name}-${test.session_name.slice(-2)}`}</Typography>
                    <Box
                      sx={{
                        border: '0.1px solid',  
                        padding: 2,
                      }}
                    >
                      <Typography>{formatDate(test.created_at)}</Typography>
                    </Box>
                </Box>
              </Button>
            )
            )}

            </Box>
           
            </Box>
           
            <Box style={{flex: 1, padding: 20, paddingLeft: 10}}>
              <Button startIcon = {<HomeIcon />} variant='contained' style={{width: '100%', borderRadius: 5, backgroundColor: "#d3222b"}} onClick={()=>history.push("/menu/userdashboard")}>Home</Button>
            </Box>
        </Paper>

  )
}

export default TestList;