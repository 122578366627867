import {
  GET_TEST_ID,
  GET_TEST_QUESTIONS,
  EXECUTE_SQL,
  GET_SQL_SCHEMA,
  GET_ALL_PRODUCTIVITY_TESTS,
  GET_TEST_DATA,
  SET_PRODUCTIVITY_LOADING,
  GET_ALL_USER_TESTS,
  CLEAR_TERMINAL
} from "../type";
import axios from "axios";
import { API_URL } from "../../../Api_Request";
import Swal from "sweetalert2";

export const getTestId = (data, history, technology) => (dispatch) => {
  axios
    .post(`${API_URL}/test/addTest`, data)
    .then((res) => {
      dispatch({
        type: GET_TEST_ID,
        payload: res.data?.data,
      });
      // console.log("in technology action", technology);
      if (
        technology.toUpperCase() === "MYSQL" ||
        technology.toUpperCase() === "RDBMS" ||
        technology.toUpperCase() === "SQL"
      ) {
        // console.log("in technology SQL action",technology);
        history.push(`/menu/sqlCoding/${res.data?.data?._id}`);
      } else if (technology.toUpperCase() === "PYTHON") {
        // console.log("in technology python action",technology);
        history.push(`/menu/pythonCoding/${res.data?.data?._id}`);
      } else if (technology.toUpperCase() === "JAVA") {
        // console.log("in technology java action",technology);
        history.push(`/menu/javaCoding/${res.data?.data?._id}`);
      } else if (technology.toUpperCase() === "C") {
        // console.log("in technology java action",technology);
        history.push(`/menu/CCoding/${res.data?.data?._id}`);
      } else if (technology.toUpperCase() === "CPP") {
        // console.log("in technology java action",technology);
        history.push(`/menu/CppCoding/${res.data?.data?._id}`);
      } else if (technology.toUpperCase() === "JAVASCRIPT") {
        history.push(`/menu/javascriptCoding/${res.data?.data?._id}`);
      } else if (technology.toUpperCase().includes("HTML")) {
        history.push(`/menu/htmlEditor/${res.data?.data?._id}`);
      } else if (
        technology.toUpperCase().includes("C#") ||
        technology.toUpperCase().includes("CSHARP")
      ) {
        history.push(`/menu/cSharpCoding/${res.data?.data?._id}`);
      } else if (technology.toUpperCase() === "LINUX") {
        history.push(`/menu/linuxCoding/${res.data?.data?._id}`);
      }
    })
    .catch((err) => {
      console.log(err);
      const { message } = err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const getAllTests = () => (dispatch) => {
  dispatch(productivityLoading());
  axios
    .get(`${API_URL}/test/getAllTests`)
    .then((res) => {
      dispatch({
        type: GET_ALL_PRODUCTIVITY_TESTS,
        payload: res.data?.data,
      });
    })
    .catch((err) => {
      console.log(err);
      const { message } = err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const getFilteredTests = (data) => (dispatch) => {
  dispatch(productivityLoading());
  axios
    .post(`${API_URL}/test/getFilteredTests`, data)
    .then((res) => {
      dispatch({
        type: GET_ALL_PRODUCTIVITY_TESTS,
        payload: res.data?.data,
      });
    })
    .catch((err) => {
      console.log(err);
      const { message } = err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const getUserTests = () => (dispatch) => {
  dispatch(productivityLoading());
  axios
    .get(`${API_URL}/test/getUsersTests`)
    .then((res) => {
      dispatch({
        type: GET_ALL_USER_TESTS,
        payload: res.data?.data,
      });
    })
    .catch((err) => {
      console.log(err);
      const { message } = err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const getTestData = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/test/getTestData`, data)
    .then((res) => {
      dispatch({
        type: GET_TEST_DATA,
        payload: res.data?.data,
      });
    })
    .catch((err) => {
      console.log(err);
      const { message } = err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const executeMYSQL = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/execute/executeMySQL`, data)
    .then((res) => {
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "success",
          data: res?.data?.data,
        },
      });
      dispatch(clearSQLException());
    })
    .catch((err) => {
      const { message, error } = err?.response?.data;
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "error",
          data: error,
        },
      });
      dispatch(clearSQLData());
    });
};

export const executeOracle = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/execute/executeOracle`, data)
    .then((res) => {
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "success",
          data: res?.data?.data,
        },
      });
      dispatch(clearSQLException());
    })
    .catch((err) => {
      const { message, error } = err && err?.response?.data;
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "error",
          data: error,
        },
      });
      dispatch(clearSQLData());
    });
};

export const executePython = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/execute/executePython`, data)
    .then((res) => {
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "success",
          data: res?.data?.data,
        },
      });
      dispatch(clearSQLException());
    })
    .catch((err) => {
      const { message, error } = err && err?.response?.data;
      console.log(error, err);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "error",
          data: error,
        },
      });
      dispatch(clearSQLData());
    });

    
};

export const executePythonTerminal = (data) => (dispatch) => {
//terminal
    console.log('action terminal')
    
    axios
    .post(`${API_URL}/execute/executePythonTerminal`, data)
    .then((res) => {
      
    })
    .catch((err) => {
      const { message, error } = err && err?.response?.data;
      console.log(error, err);
      
    });
}

export const executeLinux = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/execute/executeLinux`, data)
    .then((res) => {
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "success",
          data: res?.data?.data,
        },
      });
      dispatch(clearSQLException());
    })
    .catch((err) => {
      const { message, error } = err && err?.response?.data;
      console.log(error, err);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "error",
          data: error,
        },
      });
      dispatch(clearSQLData());
    });
};

//execute c#
export const executeCSharp = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/execute/executeCSharp`, data)
    .then((res) => {
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "success",
          data: res?.data?.data,
        },
      });
      dispatch(clearSQLException());
    })
    .catch((err) => {
      const { message, error } = err && err?.response?.data;
      console.log(error, err);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "error",
          data: error,
        },
      });
      dispatch(clearSQLData());
    });
};

//execute Java
export const executeJava = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/execute/executeJava`, data)
    .then((res) => {
      // console.log(res?.data?.data);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "success",
          data: res?.data?.data,
        },
      });
      dispatch(clearSQLException());
    })
    .catch((err) => {
      const { message, error } = err && err?.response?.data;
      console.log(error, err);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "error",
          data: error,
        },
      });
      dispatch(clearSQLData());
    });
};

//execute C
export const executeC = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/execute/executeC`, data)
    .then((res) => {
      // console.log(res?.data?.data);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "success",
          data: res?.data?.data,
        },
      });
      dispatch(clearSQLException());
    })
    .catch((err) => {
      const { message, error } = err && err?.response?.data;
      console.log(error, err);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "error",
          data: error,
        },
      });
      dispatch(clearSQLData());
    });
};

//execute C
export const executeCpp = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/execute/executeCpp`, data)
    .then((res) => {
      // console.log(res?.data?.data);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "success",
          data: res?.data?.data,
        },
      });
      dispatch(clearSQLException());
    })
    .catch((err) => {
      const { message, error } = err && err?.response?.data;
      console.log(error, err);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "error",
          data: error,
        },
      });
      dispatch(clearSQLData());
    });
};

//execute JavaScript
export const executeJavaScript = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/execute/executeJavascript`, data)
    .then((res) => {
      // console.log(res?.data?.data);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "success",
          data: res?.data?.data,
        },
      });
      dispatch(clearSQLException());
    })
    .catch((err) => {
      const { message, error } = err && err?.response?.data;
      console.log(error, err);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "error",
          data: error,
        },
      });
      dispatch(clearSQLData());
    });
};

export const executeHTML = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/execute/executeHtml`, data)
    .then((res) => {
      // console.log(res?.data?.data);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "success",
          data: res?.data?.data,
        },
      });
      dispatch(clearSQLException());
    })
    .catch((err) => {
      const { message, error } = err && err?.response?.data;
      console.log(error, err);
      dispatch({
        type: EXECUTE_SQL,
        payload: {
          type: "error",
          data: error,
        },
      });
      dispatch(clearSQLData());
    });
};

export const getTestQuestions = (data) => (dispatch) => {
  axios
    .get(`${API_URL}/test/getTestQuestions/${data}`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_TEST_QUESTIONS,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => {
      console.log(err);
      const { message } = err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const getMySQLSchema = (data) => (dispatch) => {
  axios
    .get(`${API_URL}/execute/getMySQLSchema/${data}`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_SQL_SCHEMA,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => {
      console.log(err);
      const { message } = err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const getOracleSQLSchema = (data) => (dispatch) => {
  axios
    .get(`${API_URL}/execute/getOracleSQLSchema/${data}`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_SQL_SCHEMA,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => {
      console.log(err);
      const { message } = err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const completeTest = (data, history) => (dispatch) => {
  axios
    .put(`${API_URL}/test/completeTest/${data}`)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: `Test completed successfully !!!`,
      });
      history.push(`/menu/userdashboard`);
    })
    .catch((err) => {
      console.log(err);
      const { message } = err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

//Clear SQL result data
export const clearSQLData = () => {
  return {
    type: EXECUTE_SQL,
    payload: {
      type: "success",
      data: null,
    },
  };
};

//Clear SQL exception data
export const clearSQLException = () => {
  return {
    type: EXECUTE_SQL,
    payload: {
      type: "error",
      data: null,
    },
  };
};

export const productivityLoading = () => {
  return {
    type: SET_PRODUCTIVITY_LOADING,
  };
};

export const clearTerminal = (bool) => {
  console.log('action ', bool);
  return {
    type: CLEAR_TERMINAL,
    payload: { data: bool }
  };
};
