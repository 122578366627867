import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";

import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";

import { adminTopics } from "../../../redux/actions/TopicsAction";

import { editDebuggingCode } from "../../../redux/actions/productivityActions/debuggingCodeActions";
import { getQuestioncomplexity } from "../../../redux/actions/questionComplexityAction";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const EditDebuggingCode = ({ setOpenPopup, codeData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { assignedTopics, questioncomplexity, auth } = useSelector(
    (state) => state
  );

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(adminTopics());
      dispatch(getQuestioncomplexity());
    }
  }, [auth.isAuthenticated]);

  const formik = useFormik({
    initialValues: {
      selectComplexity: "",
      selectTopic: "",
      code: "",
      expectedComments: "",
    },
    validationSchema: Yup.object({
      selectTopic: Yup.object().nullable().required("Topic is reqired field"),
      selectComplexity: Yup.object()
        .nullable()
        .required("Complexity is reqired field"),
      code: Yup.string().trim().required("Code is required field"),
      // expectedComments: Yup.string(),
    }),

    onSubmit: async (values) => {
      let data = {};
      data = {
        _id: codeData?._id,
        capabilityTopic: values?.selectTopic,
        debuggingCode: values?.code,
        capabilityComplexity: values?.selectComplexity,
        expectedComments: values?.expectedComments,
      };

      dispatch(editDebuggingCode(data));
      setOpenPopup(false);
    },
  });

  useEffect(() => {
    if (codeData) {
      formik.setFieldValue("selectTopic", codeData?.capabilityTopic);
      formik.setFieldValue("selectComplexity", codeData?.capabilityComplexity);
      formik.setFieldValue("code", codeData?.debuggingCode);
      formik.setFieldValue("expectedComments", codeData?.expectedComments);
    }
  }, [codeData]);

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <SelectSingleValues
            label="Select Topic"
            name="selectTopic"
            values={assignedTopics?.adminTopics || []}
            onSelect={(batch) => {
              formik.setFieldValue("selectTopic", batch);
              // console.log("formik.values.selectTopic",formik.values.selectTopic)
            }}
            selected={formik.values.selectTopic}
            errors={
              formik.touched.selectTopic && formik.errors.selectTopic
                ? formik.errors.selectTopic
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectSingleValues
            label="Select Complexity"
            name="selectComplexity"
            values={questioncomplexity?.getquestioncomplexity || []}
            onSelect={(data) => {
              formik.setFieldValue("selectComplexity", data);
              // console.log("formik.values.selectComplexity",formik.values.selectComplexity)
            }}
            selected={formik.values.selectComplexity}
            errors={
              formik.touched.selectComplexity && formik.errors.selectComplexity
                ? formik.errors.selectComplexity
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextFieldGroup
            variant="outlined"
            label="code"
            name="code"
            multiline="multiline"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.code}
            rows={5}
            errors={
              formik.touched.code && formik.errors.code
                ? formik.errors.code
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextFieldGroup
            variant="outlined"
            label="Expected Comments"
            name="expectedComments"
            multiline="multiline"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.expectedComments}
            rows={2}
            errors={
              formik.touched.expectedComments && formik.errors.expectedComments
                ? formik.errors.expectedComments
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6} align="right">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="save"
              name="save"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </Grid>

        <Grid item xs={12} md={6} align="left">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="reset"
              name="reset"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

export default EditDebuggingCode;
