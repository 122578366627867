import React, { Component } from "react";
import Menu from "../menu/menu";
import "../questions/questions.css";
import CustomToolbarSelect from "./CustomToolbarSelect";
import { Redirect } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getquestionlists } from "../../redux/actions/questionPapersAction";
import Swal from "sweetalert2";
import loader from ".././805.gif";

class SelectQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      id: null,
      questionsDatas: [],
      paperName: "",
      topicName: "",
      skillName: "",
      topicId: "",
      skillId: "",
      data: [],
    };
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableHeadCell: {
          fixedHeaderCommon: {
            backgroundColor: "#1976d2",
          },
        },
        MuiTableCell: {
          /* color: rgba(0, 0, 0, 0.87); */
          head: {
            color: "#fff",
            fontWeight: "bold",
          },
        },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: "#2196F3",
          },
        },
        MUIDataTable: {
          responsiveScrollMaxHeight: {
            // margin : "0px 50px",
            border: "1px solid #dee2e6",
            borderCollapse: "collapse",
          },
        },
        MuiTypography: {
          h6: {
            marginLeft: "30px",
            fontWeight: "bold",
            color: "#2196F3",
          },
        },
        // MUIDataTableSelectCell: {
        //   root: {
        //     display: 'none'
        //   }
        // }
      },
    });

  handleQuestion = () => {
    let { id } = this.state;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    id = params.get("id");
    this.props.history.push(`/menu/questionsPage?id=${id}`);
    // window.location = '/menu/questionsPage';
  };

  componentDidMount() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state === ""
    ) {
      return this.props.history.push("/admin/questionpapers");
    } else {
      this.setState({
        paperName: this.props.location.state.paperName,
        topicName: this.props.location.state.topicName,
        skillName: this.props.location.state.skillName,
        topicId: this.props.location.state.topicId,
        skillId: this.props.location.state.skillId,
      });
      const params = {
        topicId: this.props.location.state.topicId,
        skillId: this.props.location.state.skillId,
      };
      this.props.getquestionlists(params);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.questionpapers.filteredquestions) {
      return null;
    } else if (nextProps.questionpapers.filteredquestions.length < 0) {
      this.setState({
        questionsDatas: nextProps.questionpapers.filteredquestions,
        isLoaded: true,
      });
    } else {
      this.setState({
        questionsDatas: nextProps.questionpapers.filteredquestions,
        isLoaded: false,
      });
    }
    if (!nextProps.questionpapers.postquestionset) {
      return null;
    } else {
      if (nextProps.questionpapers.postquestionset.success === true) {
        const Toast = Swal.mixin({
          toast: true,
          position: "center",
          showConfirmButton: true,
        });
        Toast.fire({
          icon: "success",
          text: `Question Paper ${this.state.paperName} has been successfully saved !!!`,
        });
        this.props.history.push("/admin/questionpapers");
      }
    }
  }

  handleEdit(rowData) {
    // console.log("rowdata",rowData.rowData)
    const userdata = rowData.rowData;
    this.props.history.push(`/menu/editQuestions?id=${userdata[0]}`);
  }

  render() {
    const { user, isAuthenticated } = this.props.auth;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      const { topicId } = this.state;
      // console.log(topicId)

      // TABLE
      const columns = [
        {
          name: "id",
          label: "Question Id",
          options: { filter: false, sort: true, display: false },
        },
        {
          name: "description",
          label: "Question",
          options: { filter: false, sort: true },
        },
        {
          name: "name",
          label: "Topic",
          options: { filter: true, sort: false },
        },
        {
          name: "type_name",
          label: "Type",
          options: { filter: true, sort: false },
        },
        {
          name: "com_name",
          label: "Complexity",
          options: { filter: true, sort: true },
        },
        {
          name: "chapter_name",
          label: "Chapter",
          options: { filter: true, sort: true },
        },
      ];
      const { filteredquestions } = this.props.questionpapers;
      const data = this.state.questionsDatas;

      const options = {
        filterType: "checkbox",
        serverSide: false,
        pagination: true,
        // searchText: true,
        selectableRows: "multiple",
        download: false,
        viewColumns: false,
        rowsPerPage: 6,
        selectableRowsOnClick: true,
        rowsPerPageOptions: [10],
        rowHover: true,
        search: true,
        print: false,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
          <CustomToolbarSelect
            paperName={this.state.paperName}
            topicId={this.state.topicId}
            skillId={this.state.skillId}
            selectedRows={selectedRows}
            displayData={displayData}
            setSelectedRows={setSelectedRows}
            questionPaperType={this.props.location.state.questionPaperType}
          />
        ),
        responsive: "scrollMaxHeight",
      };

      return (
        <div>
          <div className="row  font-weight-bold pr-5 pl-5">
            <div className="col-sm-3">
              Question Paper Name : {this.state.paperName}
            </div>
            <div className="col-sm-3">
              Question Topic : {this.state.topicName}
            </div>
            <div className="col-sm-3">
              Question Skill Level : {this.state.skillName}
            </div>
            <div className="col-sm-3">
              Question Paper Type :{" "}
              {this.props?.location?.state?.questionPaperType}
            </div>
          </div>
          <div className="mutable">
            {filteredquestions ? (
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  className="mt-5"
                  title={"Select Questions"}
                  data={filteredquestions}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            ) : (
              <div className="image-container">
                <p className="image-holder">
                  <img src={loader} alt="" />
                </p>
              </div>
            )}
          </div>
        </div>
        // </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  questionpapers: state.questionpapers,
});

const mapDispatchToProps = {
  getquestionlists,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectQuestions);
