import React, { Component } from "react";
// import Menu from "../usermenu/usermenu";
import "../../results/results.css";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userResultsData } from "../../../redux/actions/resultsAction";
import Tables from "../../tables/tables";
import loader from "../.././805.gif";
import Spinner from "../../reusableComponents/Spinner";

class userResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultsData: [],
      tests: false,
      isLoaded: false,
    };
  }

  componentDidMount() {
    // console.log(this.props.auth.email)
    // const {user} = this.props.auth;
    // const email = user && user.email;
    const username = JSON.parse(localStorage.getItem("capability"));
    const email = username.username;
    this.props.userResultsData(email);
    const { resultsData } = this.props.resultsData;
    this.setState({
      resultsData: resultsData,
      isLoaded: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.resultsData)
    if (!nextProps.resultsData.resultsData) {
      return null;
    } else if (nextProps.resultsData.resultsData.length < 0) {
      this.setState({
        resultsData: nextProps.resultsData.resultsData,
        tests: false,
        isLoaded: true,
      });
    } else {
      this.setState({
        resultsData: nextProps.resultsData.resultsData,
        tests: true,
        isLoaded: false,
      });
    }
  }

  render() {
    const { user, isAuthenticated } = this.props.auth;
    const role = user && user.role;
    // console.log(this.props.resultsData)
    if ((user && user.role === "admin") || (user && user.role === "mentor")) {
      return <Redirect to="/login" />;
    } else {
      const { tests } = this.state;
      const { resultsData } = this.props.resultsData;
      // console.log(resultsData);

      const data = resultsData;

      // TABLE
      const columns1 = [
        {
          name: "id",
          label: "User Id",
          options: { filter: false, sort: false, display: false },
        },
        {
          name: "test_id",
          label: "Test Id",
          options: { filter: false, sort: false, display: true },
        },
        {
          name: "first_name",
          label: "Name",
          options: { filter: false, sort: true, display: false },
        },
        {
          name: "email",
          label: "Email_ID",
          options: { filter: false, sort: false, display: false },
        },
        {
          name: "phone",
          label: "Phone Number",
          options: { filter: false, sort: false, display: false },
        },
        {
          name: "topicname",
          label: "Question Topic",
          options: { filter: true, sort: false },
        },
        {
          name: "skill_level",
          label: "Skill Level",
          options: { filter: true, sort: false },
        },
        {
          name: "tresult",
          label: "Results",
          options: { filter: false, sort: false },
        },
        {
          name: "insertedDate",
          label: "Date",
          options: { filter: false, sort: false },
        },
        {
          name: "expiredTime",
          label: "Extra Time Taken",
          options: { filter: false, sort: true },
        },
      ];

      const options1 = {
        filterType: "checkbox",
        serverSide: false,
        pagination: true,
        // searchText: true,
        download: false,
        rowsPerPage: 10,
        selectableRows: "single",
        rowsPerPageOptions: [10],
        viewColumns: false,
        rowHover: true,
        search: true,
        print: false,
        onRowClick: (rowData) =>
          this.props.history.push({
            pathname: `/menu/viewresults`,
            state: {
              userId: rowData[0],
              testId: rowData[1],
              topics: rowData[5],
            },
          }),
        responsive: "scrollMaxHeight",
      };

      return (
        <div className="mutable">
          {/* <Menu /> */}
          <div
            className="image-container"
            style={{
              display: this.state.isLoaded !== false ? "block" : "none",
            }}
          >
            <p className="image-holder">
              {/* <img src={loader} alt="" /> */}
              <Spinner />
            </p>
          </div>

          {tests === false ? (
            <div className="mt-5">
              <h2 className="cen">No tests taken yet</h2>
            </div>
          ) : (
            <Tables
              title="Results"
              data={data}
              columns={columns1}
              options={options1}
            />
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  resultsData: state.resultsData,
});

export default connect(mapStateToProps, { userResultsData })(userResults);
