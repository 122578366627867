import { FETCH_OVERALL, FETCH_CLUSTER, FETCH_TOPICWISE, SET_CAPABILITY_ANALYSIS_ERROR, LOADING_CAPABILITY_ANALYSIS_DATA } from "./type";
import axios from 'axios';

export const fetchOverallData = (data) => (dispatch) => {
    axios.get("/api/capabilityAnalysisApplication/overall", {params: {"email":data}})
    .then((res)=>{
        const overallDataResponse = JSON.parse(res.data)
        dispatch({type: FETCH_OVERALL, payload: overallDataResponse});
    })
    .catch((error)=>{
        if (error.response && error.response.status === 404) {
            dispatch({ type: SET_CAPABILITY_ANALYSIS_ERROR, payload: { error: "Incorrect Email/Email-Topic Combination" } });
        } else {
            dispatch({ type: SET_CAPABILITY_ANALYSIS_ERROR, payload: { error: "Internal Server Error" } });
        }
    })
}

export const fetchClusterData = (data) => (dispatch) => {
    axios.get("/api/capabilityAnalysisApplication/cluster", {params: data})
    .then((res)=>{
        const overallDataResponse = JSON.parse(res.data);
        dispatch({type: FETCH_CLUSTER, payload: overallDataResponse});
    })
    .catch((error)=>{
        if (error.response && error.response.status === 404) {
            dispatch({ type: SET_CAPABILITY_ANALYSIS_ERROR, payload: { error: "Incorrect Email/Email-Topic Combination" } });
        } else {
            dispatch({ type: SET_CAPABILITY_ANALYSIS_ERROR, payload: { error: "Internal Server Error" }});
        }
    })
}

export const fetchTopicWiseData = (data) => (dispatch) => {
    axios.get("/api/capabilityAnalysisApplication/topicwise", {params: data})
    .then((res)=>{
        const overallDataResponse = JSON.parse(res.data);
        dispatch({type: FETCH_TOPICWISE, payload: overallDataResponse});
    })
    .catch((error)=>{
        if (error.response && error.response.status === 404) {

            dispatch({ type: SET_CAPABILITY_ANALYSIS_ERROR, payload: { error: "Incorrect Email/Email-Topic Combination" } });
        } else {
            dispatch({ type: SET_CAPABILITY_ANALYSIS_ERROR, payload: { error: "Internal Server Error" } });
        }
    })
}

export const loadingCapabilityApplication =() => (dispatch)=>{
    dispatch({type: LOADING_CAPABILITY_ANALYSIS_DATA})
}