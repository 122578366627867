import React, { useState, useEffect } from 'react';
import { Grid, Skeleton, Typography, Button, Box, Alert, Snackbar, Dialog, DialogContent } from '@mui/material';
import PdfViewer from './pdfViewer';
import { useSelector, useDispatch } from "react-redux";
import { reset } from '../../redux/actions/productivityActions/productivityReportsActions'; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HtmlViewer from './htmlViewer';

const DisplayReport = () => {
    const [reportGenerating, setReportGenerating] = useState(true);
    const [s3Link, sets3Link] = useState(null);
    const {productivityReportsReducer} = useSelector((state)=>state);
    const dispatch = useDispatch();

    const backToTests = () => {
      dispatch(reset());
    }

    const [open, setOpen] = useState(true);

    const handleClose = () => {
      setOpen(false);
    };

    const openInNewTab =() =>{
      window.open(productivityReportsReducer.s3Link, '_blank');
    }
    
    return (
        <div>
        <Grid item xs={12}>
        {!productivityReportsReducer.s3Link && !productivityReportsReducer.emptyReportMessage && !productivityReportsReducer.errorMessage ?
          (<Skeleton variant='rectangular' 
          
          style={{
            padding: '20px',
            marginTop: '20px',
            marginLeft: 90,
            marginRight: 90,
            height: "600px"
          }}
          />)
          :
          productivityReportsReducer.emptyReportMessage ? (
          <Dialog open={productivityReportsReducer.emptyReportMessage}>
            <DialogContent>
              <Typography>{productivityReportsReducer.emptyReportMessage}</Typography>
            </DialogContent>
          </Dialog>
          ):
          productivityReportsReducer.errorMessage ? (
            <Dialog open={productivityReportsReducer.errorMessage}>
            <DialogContent>
              <Typography>{productivityReportsReducer.errorMessage}</Typography>
            </DialogContent>
          </Dialog>
          )
          :
          (
            <Box
            style={{
              padding: '20px',
              marginTop: '20px',
              marginLeft: 90,
              marginRight: 90,
              height: "600px"
            }}
            >
            <Grid container
            style={{
              display: "flex",
              justifyContent: "center"
            }}
            >
              <Button variant='outlined'
              style={{
                textTransform: "none",
                marginBottom: 30,
                // marginTop: 5,
                marginLeft: 50
              }}
              startIcon={<ArrowBackIcon />}
              onClick={()=>backToTests()}
              >Back To Tests</Button>

              <Button variant = 'outlined'
              style={{
                textTransform: "none",
                marginBottom: 30,
                // marginTop: 5,
                marginLeft: 50,
                marginRight: 30
              }}
              startIcon={<OpenInNewIcon />}
              onClick={()=>openInNewTab()}
              >Open in new tab</Button>
            </Grid>
              {/* <PdfViewer pdfUrl={productivityReportsReducer.pdfLink}/>  */}
              <HtmlViewer htmlUrl={productivityReportsReducer.s3Link} />
            </Box>
          )

          // (productivityReportsReducer.pdfLink && <Typography>PDF Loaded</Typography>)
        }
      </Grid>
        </div>
    )
}

export default DisplayReport;