import React, { Component } from "react";
// import Menu from "../menu/menu";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { comparitivechartlists } from "../../redux/actions/userlistsAction";
import Tables from "../tables/tables";
import loader from ".././805.gif";

class userLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultsData: [],
      // isLoaded: false
    };
  }

  componentDidMount() {
    this.props.comparitivechartlists();
    const { comparitivechartuserdata } = this.props.allUsers;
    if (comparitivechartuserdata) {
      // let data = comparitivechartuserdata.filter((user) => (user.role === "User" || user.role === "TestUser"))
      this.setState({
        resultsData: comparitivechartuserdata,
        // isLoaded:true
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.allUsers.comparitivechartuserdata)
    if (!nextProps.allUsers.comparitivechartuserdata) {
      return null;
    } else if (nextProps.allUsers.comparitivechartuserdata.length < 0) {
      this.setState({
        resultsData: nextProps.allUsers.comparitivechartuserdata,
      });
    } else {
      // let data = nextProps.allUsers.comparitivechartuserdata.filter(users => (users.role === "User" || users.role === "TestUser"))
      this.setState({
        resultsData: nextProps.allUsers.comparitivechartuserdata,
      });
    }
  }

  render() {
    const { user } = this.props.auth;
    if ((user && user.role === "user") || (user && user.role === "testuser")) {
      return <Redirect to="/login" />;
    } else {
      // const {resultsData} = this.state;
      // console.log(resultsData);

      let data = this.state.resultsData;
      // TABLE
      const columns1 = [
        {
          name: "id",
          label: "User Id",
          options: { filter: false, sort: false, display: false },
        },
        {
          name: "first_name",
          label: "Name",
          options: { filter: false, sort: true },
        },
        {
          name: "email",
          label: "Email_ID",
          options: { filter: false, sort: false },
        },
        {
          name: "phone",
          label: "Phone Number",
          options: { filter: false, sort: false },
        },
        {
          name: "skills",
          label: "Topics",
          options: { filter: false, sort: false },
        },
        {
          name: "oldest",
          label: "First Test Taken",
          options: { filter: false, sort: false },
        },
        {
          name: "latest",
          label: "Last Test Taken",
          options: { filter: false, sort: false },
        },
        {
          name: "total",
          label: "Total Marks",
          options: { filter: false, sort: true },
        },
        {
          name: "correct",
          label: "Correct Marks",
          options: { filter: false, sort: true },
        },
        {
          name: "wrong",
          label: "Wrong Marks",
          options: { filter: false, sort: true },
        },
      ];

      const options1 = {
        filterType: "checkbox",
        serverSide: false,
        pagination: true,
        // searchText: true,
        download: false,
        rowsPerPage: 10,
        selectableRows: "single",
        rowsPerPageOptions: [10],
        viewColumns: false,
        rowHover: true,
        search: true,
        print: false,
        onRowClick: (rowData) =>(
          this.props.history.push(`/admin/comparitive/${rowData[0]}`)
        )
      };

      return (
        <div>
          {/* <Menu /> */}
          {data && data ? (
            <Tables
              title="User Comaparitive Chart Lists"
              data={data}
              columns={columns1}
              options={options1}
            />
          ) : (
            <div className="image-container">
              <p className="image-holder">
                <img src={loader} alt="" />
              </p>
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  allUsers: state.allUsers,
});

export default connect(mapStateToProps, { comparitivechartlists })(userLists);
