import { GETQUESTIONTOPICS, ADDQUESTIONTOPIC, EDITQUESTIONTOPIC, GETSTATUSTOPICS } from './type';
import axios from 'axios';
import { API_URL } from '../../Api_Request';
import Swal from 'sweetalert2';



export const getQuestionTopics = () => dispatch => {
      axios.get(`${API_URL}/members`)
        .then(res => {
          dispatch({
              type : GETQUESTIONTOPICS, 
              payload : res.data
          })
          
          // console.log(res.data)
      })
      .catch(err => console.log(err))
  }

export const addnewtopic = (params) => dispatch => {
    axios.post(`${API_URL}/members`, params)
    .then(res => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000
      })
      
      Toast.fire({
        icon: 'success',
        title: 'Topic added successfully'
      })
      dispatch(getQuestionTopics())
      dispatch({
          type : ADDQUESTIONTOPIC, 
          payload : "Success"
      })
      
      // console.log(res.data)
  })
  .catch(err => console.log(err))
}

export const editQuestionTopic = (params) => dispatch => {
  axios.put(`${API_URL}/members/${params.id}`,params)
    .then(res => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000
      })
      
      Toast.fire({
        icon: 'success',
        title: 'Topic updated successfully'
      })
      dispatch(getQuestionTopics())
      dispatch({
          type : EDITQUESTIONTOPIC, 
          payload : res.data
      })
      
      // console.log(res.data)
  })
  .catch(err => console.log(err))
}

export const getstatusontopics = () => dispatch => {
  axios.get(`${API_URL}/members/status/`)
    .then(res => {
      dispatch({
          type : GETSTATUSTOPICS, 
          payload : res.data
      })
      
      // console.log(res.data)
  })
  .catch(err => console.log(err))
}