import { GET_QUESTION_LIST,GET_QUESTION_INFO } from "../type";
import axios from "axios";
import { API_URL } from "../../../Api_Request";
import Swal from "sweetalert2";

export const getQuestionList = () => (dispatch) => {
  axios
    .get(`${API_URL}/question/getAllQuestions`)
    .then((res) => {
      dispatch({
        type: GET_QUESTION_LIST,
        payload: res.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const getQuestionInfo = (id) => (dispatch) => {
  axios
    .get(`${API_URL}/question/getQuestionInfo/${id}`)
    .then((res) => {
      dispatch({
        type: GET_QUESTION_INFO,
        payload: res.data?.data,
      });
    })
    .catch((err) => console.log(err));
};

export const addQustionData = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/question/addQuestion`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: `Question Added successfully !!!`,
      });
      dispatch(getQuestionList());
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const editquestion= (data) => (dispatch) => {
  axios
    .put(`${API_URL}/question/updateQuestion`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: res.data.message,
      });
      dispatch(getQuestionList());
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};