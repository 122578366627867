import React,{Component} from 'react';
import Menu from '../users/usermenu/usermenu';
import axios from 'axios';
import { API_URL } from '../../Api_Request';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux'

class Subscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
          user : {},
          params: {},
          url: '',
          gotdata : false
        }
      }

    componentDidMount() {
        const {user} = this.props.auth;
        const id = user && user.email;
        axios.get(`${API_URL}/userlists/${id}`)
        .then(res => {
            const user = res.data[0]
            console.log(user)
            this.setState({
                user : user,
                count : 0
            })
        })
        .catch(err => console.log(err))
    }

    subscribe(e){
        
        const {user} = this.props.auth;
        const id = user && user.email;

        const data = {
            purpose: 'Starter Pack',
            amount: '100',
            phone: user && user.phone,
            buyer_name: user && user.first_name,
            redirect_url: `${API_URL}/subscribe/callback?user_id=${id}`,
            send_email: false,
            webhook_url: '/webhook/',
            send_sms: false,
            email: user.email
        }

        axios.post( `${API_URL}/subscribe`, data )
			.then( res => {
				console.log( 'resp', res.data );
				window.location.href = res.data;

			} )
			.catch( ( error ) => console.log( error.response.data ) );
    
    }

    paytmsubscribe(e){
        const {user} = this.props.auth;
        const id = user && user.email;

        const data = {
            orderid: '101',
            userid: user.id,
            amount: '500',
            redirect_url: `${API_URL}/subscribe/callback?user_id=${id}`,
            email: user.email,
            phone: user.phone,
        }

        axios.post( `${API_URL}/subscribe/paytm`, data )
			.then( res => {
                const url = res.data.url
                const params = res.data.params
				console.log( 'resp', res.data );
				this.setState({
                    url : url,
                    params : params,
                    gotdata : true
                })
                console.log("params",this.state.params)
			} )
			.catch( ( error ) => console.log( error ) );

    }
    componentDidUpdate(prevProps, prevState) {
    if(document.getElementById("f1")) {
        setTimeout(document.getElementById("f1").submit(), 5000) 
    }
    }

    render() { 
        const {user} = this.props.auth;
        if(user && user.role === "admin" || user && user.role === "mentor"){
            return <Redirect to='/login' />
          }
          else{
        return ( 
            <div>
                <Menu/>
                <div className="row mt-5">
                    <div className="col-sm-4 mt-5" style={{ display: this.state.gotdata === false ? 'block' : 'none' }}>
                        <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Starter Pack</h5>
                            <p className="card-text">You can taken 10 more additional tests with this subscription</p>
                            <button onClick={(e) => this.subscribe(e)}
                            className="btn btn-primary">Subscribe 100 Rs plan</button>
                        </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mt-5" style={{ display: this.state.gotdata === false ? 'block' : 'none' }}>
                        <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Monthly Pack</h5>
                            <p className="card-text">You can take unlimited tests for a month</p>
                            <button onClick={(e) => this.paytmsubscribe(e)}
                            className="btn btn-primary">Subscribe 500 Rs plan</button>
                        </div>
                        </div>
                    </div>
                    <div className="col-sm-4 mt-5" style={{ display: this.state.gotdata === false ? 'block' : 'none' }}>
                        <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Monthly</h5>
                            <p className="card-text">You can take unlimited tests for a month</p>
                            <button className="btn btn-primary">Subscribe 1000 Rs plan</button>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        {
                            this.state.gotdata &&

                        <form className="mx-auto" method="post" action={this.state.url} id="f1">
                            
                            <center><h1>Please do not refresh this page...</h1></center>
                            <table border="1">
                                <tbody>
                                {
                                    Object.keys(this.state.params).map((item) =>(
                                         <input type="hidden" key={item} name={item} defaultValue={this.state.params[item]}/>
                                    ))
                                }
                                {/* for (let param in this.state.params) {
                                <input type="text" name={param} value={this.state.params[param]}/>
                                } */}
                                
                                </tbody>
                            </table>
                           {/* { document.getElementById("f1").submit()} */}
                        </form>
                        }
                    </div>
                </div>
            </div>
         );
    }
    }
}


const mapStateToProps = state => ({
    auth : state.auth,
})

 
export default connect(mapStateToProps,)(Subscribe);