import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar } from "@material-ui/core";
import Table from "../../reusableComponents/MaterialTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllTests } from "../../../redux/actions/productivityActions/testAction";
import loader from "../../805.gif";
import ResultFilters from "../resultFilter";
import ButtonField from "../../reusableComponents/ButtonField";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  formSubmit: {
    textTransform: "capitalize",
    backgroundColor: "#003299",
    marginRight: "10px",
    fontWeight: 600,
    letterSpacing: 1,
    color: "#F8F9F9",
    "&:hover": {
      background: "#004499",
    },
  },
  industryList: {
    listStyleType: "none",
  },
}));

const QueryResult = ({ history }) => {
  const { auth, productivityTest } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAuthenticated } = auth;

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAllTests());
    }
  }, [isAuthenticated]);

  const data = productivityTest?.testList?.map((item) => {
    return {
      // _id: item._id,
      domain: item?.domain?.name,
      scenario: item?.scenario?.name,
      name: `${item?.user?.first_name} ${item?.user?.last_name || ""}`,
      // questionsAttended: item?.questionsAttended,
      createdAt: new Date(
        `${item?.month}-${item?.daym}-${item?.year}`
      ).toDateString(),
      day: item?.day,
      year: item?.year,
      userId: item?.user?.id,
      domainId: item?.domain?._id,
      scenarioId: item?.scenario?._id,
      daym: item?.daym,
      month: item?.month,
      details: "View Details",
    };
  });

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Domain",
      field: "domain",
    },
    {
      title: "Scenario",
      field: "scenario",
    },
    {
      title: "Date&Time",
      field: "createdAt",
    },
    // {
    //   title: "QuestionsAttended",
    //   field: "questionsAttended",
    // },
    {
      title: "View Details",
      field: "details",
      render: (rowData) => (
        <ButtonField
          buttonStyle={classes.formSubmit}
          type="button"
          name="View Details"
          onClick={() =>
            history.push({
              pathname: `/admin/viewqueryresult`,
              state: {
                day: rowData?.day,
                year: rowData?.year,
                userId: rowData?.userId,
                domain: rowData?.domainId,
                scenario: rowData?.scenarioId,
                daym: rowData?.daym,
                month: rowData?.month,
                domainName: rowData?.domain,
                scenarioName: rowData?.scenario,
                // functionName: rowData?.function.name,
              },
            })
          }
        />
        // <Link to={`/admin/viewqueryresult/${rowData._id}`}>
        //   {rowData.details}
        // </Link>
      ),
    },
  ];

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <>
            <ResultFilters productivityResult={true} />
            <Table
              title={<b>Productivity Results</b>}
              columns={columns}
              data={data}
              pageSize={10}
              selectedRow={
                (row) => console.log("")
                // history.push(`/menu/viewqueryresult/${row._id}`)
              }
            />
          </>
        </Grid>
      </Grid>
    </Container>
  );
};

QueryResult.propTypes = {
  auth: PropTypes.object.isRequired,
  getCoachList: PropTypes.func.isRequired,
  clearCoachInfo: PropTypes.func.isRequired,
  adminCoach: PropTypes.object.isRequired,
};

export default QueryResult;
