import axios from 'axios';
import { API_URL } from "../Api_Request"

const apiClient = axios.create({
  baseURL: `${API_URL}/AILearningAssistant`, 
});

export const fetchTestHistoryAPI = async (userId, programmingLanguage) => {
  try{
  const response = await apiClient.get('/testHistory', {params:  {userId, programmingLanguage}});
  return response.data;
  }
  catch(error)
  {
    console.log(error);
  }
};

export const fetchSqlTestQuestionsAnalysisAPI = async (testId, userId) => {
  const response = await apiClient.get(`/sqlTestAnalysis`, {params:  {testId, userId}});
  return response.data;
};

export const converseAPI = async (type, userId, messagesArray, sessionId, questionId) => {
  console.log(type, userId, messagesArray);
  const response = await apiClient.post('/respondToUser', { type, userId, messagesArray, sessionId, questionId });
  console.log(response);
  return response.data;
};

export const fetchDoubtConversationHistoryAPI = async(sessionId, questionId) => {
  const response = await apiClient.get('/doubtConversations', {params: {sessionId, questionId}})
  console.log(response.data);
  return response.data;
}
