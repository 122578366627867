import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";

import TextFieldGroup from "../reusableComponents/TextFieldGroup";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const UserModal = ({ setOpen, paper, history }) => {
  const classes = useStyles();

  const phoneRegExp =
    "^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("First Name is required field"),
      email: Yup.string().trim().required("Email is required field"),
      phone: Yup.string()
        .length(10, "Phone Number must have 10 numbers")
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required field"),
    }),

    onSubmit: async (values) => {
      // console.log("submit value", values);
      const data = {
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        paperId: paper?.id,
        paperName: paper.name,
      };
      axios
        .post(`/api/interviewModule/addInterviewUser`, data)
        .then((res) => {
          history.push({
            pathname: `/menu/rules`,
            state: {
              paperId: paper?.id,
              userId: res?.data?.id,
              topicName: paper.name,
              skill: "Interview",
              testId: paper.name,
              uname: values?.name,
              interview: true,
            },
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: err?.response?.data?.message,
          });
        });
      setOpen(false);
    },
  });

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextFieldGroup
            label="Full Name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            errors={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            label="Email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            va
            lue={formik.values.email}
            errors={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFieldGroup
            label="Phone Number"
            name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            va
            lue={formik.values.phone}
            errors={
              formik.touched.phone && formik.errors.phone
                ? formik.errors.phone
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={12} align="center">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="submit"
              name="submit"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

UserModal.propTypes = {
  auth: PropTypes.object.isRequired,
  // addnewchapter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  // addnewchapter,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);
