import React from "react";
import PrivateRoute from "./components/reusableComponents/PrivateRoutes";
import Layout from "./components/layout/Layout";
import AdminLayout from "./components/layout/adminLayout";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import QuestionsPage from "./components/questionspage/questionsPage";
import Login from "./components/login/login";

import UserResults from "./components/users/userresults/userresults";
import landingPage from "./components/landing/landing";
import PDF from "./components/viewresults/generatepdf";

// import SignUp from './components/signup/signup';
import SelectTest from "./components/selectTest/selecttest";
import Test from "./components/test/test";
import Subscribe from "./components/subscribe/subscribe";
import Success from "./components/subscribe/success";

import UserDashboard from "./components/users/userdashboard/userdashboard";

import AssignTests from "./components/assigntests/assigntests";
import AssignTestUsers from "./components/assigntests/users";
import Visuals from "./components/visuals/visuals";
import TestsAssigned from "./components/users/assignedtests/testsassigned";
import Rules from "./components/users/assignedtests/rules";
import IBridgeLanding from "./components/ibridge/landing";
import IBridgeTest from "./components/ibridge/ibridgeTest";
import ForgotPassword from "./components/forgotPassword/forgotPassword";
import ResetPassword from "./components/forgotPassword/resetPassword";

import ResultIbridge from "./components/ibridge/ResultIbridge";
import ibridgeUserDashboard from "./components/ibridge/ibridgeUserDashboard";
import UserInfo from "./components/UserInfo/UserInfo";
import InterviewDashboard from "./components/dashboard/interviewDashboard";
// import InterviewResult from "./components/results/interviewResult";

import query from "./components/executeQurey/Query/Query";
import Python from "./components/executeQurey/Python/Python";
// import ProductivityUserDashboard from "./components/dashboard/ProductivityUserDashboard";
import ProductivityTest from "./components/test/ProductivityTest";
import PythonCoding from "./components/test/PythonCoding";

import AdminRoutes from "./AdminRoutes";
import JavaCoding from "./components/test/JavaCoding";
import Viewresults from "./components/viewresults/viewresults";
import JavaScriptCoding from "./components/test/JavaScriptCoding";
import ProductivityResult from "./components/users/userresults/productivityResults";
// import ErrorPage from "./components/error/Error"
import CCoding from "./components/test/CCoding";
import CppCoding from "./components/test/CppCoding";
import HtmlEditorScreen from "./components/reusableComponents/HtmlEditorIntegration/HtmlEditorScreen";
import Linux from "./components/test/LinuxCoding";
import Csharp from "./components/test/C#coding";

import ProdResult from "./components/users/userresults/prodresult";
import ViewProductivityTest from "./components/results/ProductivityResults/ViewProductivityTest";
import DebuggingCodeTopics from "./components/domainArena/DeuggingCodes/DebuggingCodeTopics";
import DebuggingCode from "./components/domainArena/DeuggingCodes/DebuggingCode";
import DebugFeedback from "./components/domainArena/DeuggingCodes/DebugFeedback";
import ViewProductivitytest from "./components/results/ProductivityResults/ViewProductivityTest";
// import LearningAssistantAI from "./components/users/learningAssistantAI/learningAssistantAI"
import LearningAssistantAI from "./components/ai_learrning_assistant/ai_learning_assistant";

const Routes = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        {/* admin Routes */}
        <PrivateRoute path="/admin" component={AdminRoutes} />

        {/* user Routes */}
        <Route path="/" component={landingPage} exact />

        <PrivateRoute
          path="/menu/pythonCoding/:testId"
          component={PythonCoding}
        />

        <PrivateRoute path="/menu/javaCoding/:testId" component={JavaCoding} />

        <PrivateRoute path="/menu/CCoding/:testId" component={CCoding} />
        <PrivateRoute path="/menu/CppCoding/:testId" component={CppCoding} />
        <PrivateRoute path="/menu/LinuxCoding/:testId" component={Linux} />
        <PrivateRoute path="/menu/CsharpCoding/:testId" component={Csharp} />

        <PrivateRoute
          path="/menu/javascriptCoding/:testId"
          component={JavaScriptCoding}
        />

        <PrivateRoute
          path="/menu/htmlEditor/:testId"
          component={HtmlEditorScreen}
        />

        <PrivateRoute
          path="/menu/sqlCoding/:testId"
          component={ProductivityTest}
        />

        <PrivateRoute path="/menu/test" component={Test} />

        <Route path="/login" component={Login} />

        <Route exact path="/ibridge/test" component={IBridgeTest} />

        <PrivateRoute
          path="/menu/interviewDashboard"
          component={InterviewDashboard}
        />
        <PrivateRoute
          path="/menu/debugFeedback/:sessionId"
          component={DebugFeedback}
        />
      <PrivateRoute path="/learningassistantai" component={LearningAssistantAI} />
        <Layout>
          <Route path="/forgotPassword" component={ForgotPassword} />
          <Route path="/resetPassword/:token" component={ResetPassword} />
          <Route
            exact
            path="/menu/ibridge/:skill/:user/:topicId/:topicName/:acronym/:programId"
            component={IBridgeLanding}
          />
          <Route
            exact
            path="/menu/ibridgeResults/:user"
            component={ResultIbridge}
          />

          <Route
            exact
            path="/menu/ibridgeUserDashboard/:user"
            component={ibridgeUserDashboard}
          />
          
          <PrivateRoute path="/menu/UserResults" component={UserResults} />

          {/* <PrivateRoute
          path="/menu/productivity"
          component={ProductivityUserDashboard}
        /> */}

          {/* <PrivateRoute path="/menu/chapters" component={Chapters} /> */}

          <PrivateRoute path="/menu/selecttest" component={SelectTest} />

          <PrivateRoute path="/menu/query" component={query} />
          <PrivateRoute path="/menu/python" component={Python} />

          <PrivateRoute
            path="/menu/assigntestusers"
            component={AssignTestUsers}
          />
      
          <PrivateRoute path="/menu/assigntests" component={AssignTests} />

          <PrivateRoute path="/menu/userdashboard" component={UserDashboard} />
          <PrivateRoute path="/menu/visuals" component={Visuals} />

          <PrivateRoute path="/menu/subscribe" component={Subscribe} />
          {/* <Route path='/menu/answers' component={Answers} /> */}

          <PrivateRoute path="/menu/questionsPage" component={QuestionsPage} />

          <PrivateRoute path="/menu/success" component={Success} />
          <PrivateRoute path="/menu/pdf" component={PDF} />

          <PrivateRoute path="/menu/testsassigned" component={TestsAssigned} />
          {/* <PrivateRoute
            path="/menu/interviewDashboard"
            component={InterviewDashboard}
          /> */}
          {/* <PrivateRoute
            path="/menu/interviewResult"
            component={InterviewResult}
          /> */}
          <PrivateRoute path="/menu/rules" component={Rules} />

          <PrivateRoute path="/menu/userinfo" component={UserInfo} />

          <PrivateRoute path="/menu/viewresults" component={Viewresults} />
          <PrivateRoute
            path="/menu/codeTopics"
            component={DebuggingCodeTopics}
          />

          {/* <PrivateRoute path="/menu/productivityresults" component={ProductivityResult} /> */}

          <PrivateRoute
            path={`/menu/viewUserQueryresult`}
            component={ViewProductivitytest}
          />

          <PrivateRoute path="/menu/prodresult" component={ProdResult} />
        </Layout>
        {/* <Route path='/menu/logout' component={Logout} />             */}
        {/* <Route path="" component={ErrorPage} /> */}
      </Switch>
    </BrowserRouter>
  );
};
export default Routes;
