import { API_URL } from "../../../Api_Request";
import { ESTABLISH_SOCKET_CONNECTION, DISCONNECT_SOCKET } from "../type";


export const establishSocketConnection = (socket) => (dispatch) =>{
    dispatch({
            type: ESTABLISH_SOCKET_CONNECTION,
            payload: socket
        })
}

export const disconnectSocketConnection = () => (dispatch) =>{
    dispatch({
        type: DISCONNECT_SOCKET
    })
} 
