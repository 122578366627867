import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEnabledDomainList,
  getTechnologyInfo,
} from "../../../redux/actions/productivityActions/domainAction";
import { getEnabledFunctionList } from "../../../redux/actions/productivityActions/functionAction";
import { getEnabledScenarioList } from "../../../redux/actions/productivityActions/scenarioAction";
import { getTestId } from "../../../redux/actions/productivityActions/testAction";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Card, CardMedia } from "@mui/material";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";
import { useFormik } from "formik";
import * as Yup from "yup";

import CodeIcon from "@mui/icons-material/Code";
import CancelIcon from "@mui/icons-material/Cancel";
import "./selectProductivity.css";
import Swal from "sweetalert2";

import AILearningAssistantButton from "../../reusableComponents/ai_learning_assistant_option_button";

const SelectProductivityTest = ({ setOpenPopup, technologyId, history }) => {
  const { domainReducer, functionReducer, scenarioReducer, auth } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  const { user } = auth;
  const [functionList, setFunctionList] = useState([]);
  const [scenarioList, setScenarioList] = useState([]);
  const [domainList, setdomainList] = useState([]);
  const [enableSubmit, setEnableSubmit] = useState(true);

  useEffect(() => {
    dispatch(getTechnologyInfo(technologyId));
    dispatch(getEnabledDomainList());
    dispatch(getEnabledFunctionList());
    dispatch(getEnabledScenarioList());
  }, []);

  useEffect(() => {
    const filteredList = domainReducer?.enabledDomains?.filter((item) => {
      return item?.technology?._id === technologyId?.toString();
    });
    setdomainList(filteredList);
  }, [domainReducer?.enabledDomains]);

  const useStyles = makeStyles((theme) => ({
    button: {
      textAlign: "center",
      textTransform: "uppercase",
      cursor: "pointer",
      fontSize: "14px",
      letterSpacing: "2px",
      position: "relative",
      backgroundColor: "#00337C",
      border: "none",
      color: "#fff",
      padding: "8px",
      width: "150px",
      transitionDuration: "0.4s",
      overflow: "hidden",
      boxShadow: "0px 2px 2px 0px #193047",
      borderRadius: "4px",

      "&:hover": {
        background: "#16a085",
        boxShadow: "0px 2px 2px 0px 193047",
        color: "#fff",
      },
    },
    cancelbutton: {
      textAlign: "center",
      textTransform: "uppercase",
      cursor: "pointer",
      fontSize: "14px",
      letterSpacing: "2px",
      position: "relative",
      backgroundColor: "#00337C",
      border: "none",
      color: "#fff",
      padding: "8px",
      width: "120px",
      transitionDuration: "0.4s",
      overflow: "hidden",
      boxShadow: "0px 2px 2px 0px #193047",
      borderRadius: "4px",

      "&:hover": {
        background: "#BD2000",
        boxShadow: "0px 2px 2px 0px #193047",
        color: "#fff",
      },
    },
  }));

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      selectDomain: null,
      selectScenario: null,
      selectFunction: null,
    },
    validationSchema: Yup.object({
      selectScenario: Yup.object()
        .nullable()
        .required("Technology is reqired field"),
      selectDomain: Yup.object().nullable().required("Domain is reqired field"),
      selectFunction: Yup.object()
        .nullable()
        .required("Function is reqired field"),
    }),

    onSubmit: async (values) => {
      // console.log("selectDomain values",values)
      if (
        !values?.selectDomain ||
        !values?.selectFunction ||
        !values?.selectScenario
      ) {
        alert("Please select all the fields");
      }
      const data = {
        technology: technologyId,
        domain: values?.selectDomain?._id,
        function: values?.selectFunction._id,
        scenario: values?.selectScenario?._id,
        capabilityUserId: user?.id,
      };
      dispatch(getTestId(data, history, domainReducer?.technologyInfo?.name));
      setEnableSubmit(true);
      setOpenPopup(false);
    },
  });

  const handleDomain = (domain) => {
    if (domainReducer?.technologyInfo?.name === "RDBMS") {
      if (!domain.database) {
        Swal.fire({
          icon: "error",
          text: "Selected Domain does not include Database. Please select other domain or contact a Mentor",
        });
      } else {
        formik.setFieldValue("selectDomain", domain);
        setEnableSubmit(false);
        const filteredList = functionReducer?.enabledFunctions?.filter(
          (item) => {
            return item?.domain?._id === domain?._id?.toString();
          }
        );
        setFunctionList(filteredList);
      }
    } else {
      formik.setFieldValue("selectDomain", domain);
      setEnableSubmit(false);
      const filteredList = functionReducer?.enabledFunctions?.filter((item) => {
        return item?.domain?._id === domain?._id?.toString();
      });
      setFunctionList(filteredList);
    }
  };

  const handleFunction = (functionData) => {
    formik.setFieldValue("selectFunction", functionData);
    setEnableSubmit(false);
    const filteredList = scenarioReducer?.enabledScenarios?.filter((item) => {
      return item?.function?._id === functionData?._id?.toString();
    });
    setScenarioList(filteredList);
  };
  return (
    <>
      {" "}
      <form
        onSubmit={formik.handleSubmit}
        enableReinitialize={true}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {" "}
            <SelectSingleValues
              label="Select Domain"
              name="selectDomain"
              values={domainList}
              onSelect={(domain) => handleDomain(domain)}
              selected={formik.values.selectDomain}
              errors={
                formik.touched.selectDomain && formik.errors.selectDomain
                  ? formik.errors.selectDomain
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {" "}
            <SelectSingleValues
              label="Select Function"
              name="selectFunction"
              values={functionList}
              onSelect={(functionData) => handleFunction(functionData)}
              selected={formik.values.selectFunction}
              errors={
                formik.touched.selectFunction && formik.errors.selectFunction
                  ? formik.errors.selectFunction
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectSingleValues
              label="Select Scenario"
              name="selectScenario"
              values={scenarioList}
              onSelect={(scenarioData) =>
                formik.setFieldValue("selectScenario", scenarioData)
              }
              selected={formik.values.selectScenario}
              errors={
                formik.touched.selectScenario && formik.errors.selectScenario
                  ? formik.errors.selectScenario
                  : null
              }
            />
          </Grid>
          <Grid container xs={12} md={4}></Grid>
          <Grid container xs={12} md={5}>
            <Button
              buttonStyle="submit"
              type="submit"
              name="submit"
              variant="contained"
              disabled={enableSubmit}
              className={classes.button}
              startIcon={<CodeIcon />}
            >
              Let's Code
            </Button>
            <Button
              style={{ marginLeft: "15px" }}
              type="button"
              name="Cancel"
              variant="contained"
              onClick={() => setOpenPopup(false)}
              className={classes.cancelbutton}
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
            {technologyId == "63be4fb15c99d1f89bfe6430" && <AILearningAssistantButton history={history} />} {/* Applicable only for RDBMS*/} 
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SelectProductivityTest;
