import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar,Button } from "@material-ui/core";
import Table from "../../reusableComponents/MaterialTable";
import ButtonField from "../../reusableComponents/ButtonField";
import { getDatabases } from "../../../redux/actions/productivityActions/databaseActions";
import {
  ListAlt as ListAltIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import CustomizedDialogs from "../../reusableComponents/CustomizedDialogs";
import AddDatabase from "./addDatabase";
import EditDatabase from "./EditDatabase";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const Database = ({}) => {
  const [open, setOpen] = useState(false);
  const [dbId,setDbId] = useState();
  const [openEdit,setOpenEdit] = useState(false);
  const { auth, productivityDatabases } = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isAuthenticated } = auth;

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getDatabases());
    }
  }, [isAuthenticated]);

  const columns = [
    {
      title: "Database Type",
      field: "databaseType",
    },
    {
      title: "Database Name",
      field: "name",
    },
    {
      title: "Host Name",
      field: "databaseHost",
    },
    {
      title: "Port Number",
      field: "databasePort",
    },
    {
      title: "Schema Name",
      field: "databaseSchema",
    },
    {
      field: "edit",
      title: "Edit",
    },
  ];

  const databaseData = productivityDatabases?.databaseList?.map((item) =>{
    return{
      databaseType:item?.databaseType,
      name:item?.name,
      databaseHost:item?.databaseHost,
      databasePort:item?.databasePort,
      databaseSchema:item?.databaseSchema,
      edit:(<Button
      variant="contained"
      color="primary"
      type="submit"
      name="edit"
      onClick={()=>{
        setDbId(item._id)
        setOpenEdit(true)
      }}
    >
      Edit
    </Button>)
    }
  });
  // console.log("databaseData",databaseData)

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            title={
              <ButtonField
                variant="outlined"
                name="Add New Database"
                icon={<AddIcon />}
                onClick={() => setOpen(true)}
              />
            }
            columns={columns}
            // data={productivityDatabases?.databaseList}
            data={databaseData}
            pageSize={10}
            // selectedRow={(row) => history.push(`/admin/coach/${row._id}`)}
          />
        </Grid>
      </Grid>
      <CustomizedDialogs
        title="Add New Database "
        children={<AddDatabase setOpenPopup={setOpen} />}
        openPopup={open}
        setOpenPopup={setOpen}
      />
      <CustomizedDialogs
        title="Edit Database"
        children={
          <EditDatabase
            dbId={dbId}
            setOpenPopup={setOpenEdit}
          />
        }
        openPopup={openEdit}
        setOpenPopup={setOpenEdit}
      />
    </Container>
  );
};

export default Database;
