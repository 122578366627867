import {
  GET_ALL_DEBUGGING_CODES,
  GET_DEBUGGING_CODES_BY_TECHNOLOGY,
  GET_DEBUGGING_CODES_FOR_FEEDBACK,
  GET_DEBUGGING_SESSION,
  GET_ALL_USER_SESSIONS,
} from "../type";
import axios from "axios";
import { API_URL } from "../../../Api_Request";
import Swal from "sweetalert2";

export const getAllDebuggingCodes = () => (dispatch) => {
  axios
    .get(`${API_URL}/debugging/getAllDebuggingCodes`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_ALL_DEBUGGING_CODES,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const getAllUserSessions = () => (dispatch) => {
  axios
    .get(`${API_URL}/debugging/getAllUserSessions`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_ALL_USER_SESSIONS,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const getFeedbackSession = (id) => (dispatch) => {
  axios
    .get(`${API_URL}/debugging/getFeedBackSession/${id}`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_DEBUGGING_SESSION,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const getFeedbackQuestions = (data) => (dispatch) => {
  axios
    .post(`${API_URL}/debugging/getDebuggingCodesForFeedback`, data)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_DEBUGGING_CODES_FOR_FEEDBACK,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const getDebuggingCodesByTechnologyId = (id) => (dispatch) => {
  axios
    .get(`${API_URL}/debugging/getAllDebuggingCodesByTechnology/${id}`)
    .then((res) => {
      //   const result = res.data;
      dispatch({
        type: GET_DEBUGGING_CODES_BY_TECHNOLOGY,
        payload: res.data?.data,
      });

      // console.log(res.data)
    })
    .catch((err) => console.log(err));
};

export const addDebuggingCode = (params, history) => (dispatch) => {
  axios
    .post(`${API_URL}/debugging/addDebuggingCode`, params)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: res?.data?.message || "Debugging code added successfully",
      });

      dispatch(getAllDebuggingCodes());

      // console.log(res.data)
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const editDebuggingCode = (data) => (dispatch) => {
  axios
    .put(`${API_URL}/debugging/updateDebuggingCode`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: res?.data?.message,
      });

      dispatch(getAllDebuggingCodes());

      // console.log(res.data)
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const addFeedbackSession = (data, history) => (dispatch) => {
  axios
    .post(`${API_URL}/debugging/addFeedbackSession`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: res?.data?.message || "Domain added successfully",
      });

      history.push(`/menu/debugFeedback/${res?.data?.data?._id}`);

      // dispatch(getAllDebuggingCodes());

      // console.log(res.data)
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};

export const addUserFeedbacks = (data, history) => (dispatch) => {
  axios
    .put(`${API_URL}/debugging/addUserFeedbacks`, data)
    .then((res) => {
      Swal.fire({
        icon: "success",
        text: res?.data?.message,
      });

      history.push(`/menu/codeTopics`);

      // dispatch(getAllDebuggingCodes());

      // console.log(res.data)
    })
    .catch((err) => {
      const { message } = err && err.response.data;
      Swal.fire({ icon: "error", text: message });
    });
};
