import {USERTOPICS, ADMINTOPICS} from './type';
import axios from 'axios';
import { API_URL } from '../../Api_Request';



export const userTopics = (id) => dispatch => {
    // console.log(id)
    axios.get(`${API_URL}/dashboard/${id}`)
    .then (res => {
        dispatch({
            type : USERTOPICS,
            payload : res.data
        })
        
        // console.log(res.data)
    })
    .catch(err => console.log(err))
}

export const adminTopics = () => dispatch => {
      axios.get(`${API_URL}/dashboard`)
      .then (res => {
          dispatch({
              type : ADMINTOPICS,
              payload : res.data
          })
          
        //   console.log(res.data)
      })
      .catch(err => console.log(err))
  }